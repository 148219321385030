.keyboardShortcuts {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .dialog {
    width: 800px;
    height: 90vh;

    background-color: var(--color-popup);
    // padding: 1.875rem;
    border-radius: 0.5rem;
    animation: delay-dialog 0.3s ease-out forwards;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media screen and (max-width: 1025px) {
      width: 500px;
    }

    @media screen and (max-width: 1025px) and (max-height: 1380px) {
      max-height: 30rem;
    }

    @media screen and (min-width: 1600px) {
      max-height: 40rem;
    }

    .dialog-info-wrapper {
      height: 100%;
      overflow-y: auto;

      .dialog-heading {
        margin: 1.875rem;
        margin-block-end: 0.35rem;
        h4 {
          font-size: 1.35rem;
          font-weight: 400;
          color: var(--color-quaternary-l);
          line-height: 1.5;
        }
      }

      .dialog-info {
        display: flex;
        flex-wrap: wrap;

        padding-inline: 0.5rem;

        @media screen and (max-width: 1025px) {
          flex-direction: column;
        }

        .keys-for-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.75rem 1.5rem;
          //   border: 1px solid;

          flex-basis: 50%;

          .keys-for {
            // flex-basis: 50%;
            flex: 1;

            margin-inline-end: 0.625rem;
            .key-for-info {
              display: inline-block;
              color: var(--color-description);
              font-size: 1rem;
              line-height: 1.25;
            }
          }

          .key-names {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 5px;

            // flex-basis: 50%;

            .key-name {
              color: var(--color-quaternary-l);
              font-size: 0.875rem;
              padding: 0.1em 0.55em;
              background-color: #f4f4f4;
              border: 1px solid #d4d4d4;
              border-radius: 4px;
            }
          }
        }
      }
    }

    .dialog-cta {
      text-align: right;
      margin: 1.875rem;
      .btn-ok {
        font-size: 1rem;
        color: var(--color-secondary);
        background-color: var(--color-primary-d);
        border-radius: 5px;
        padding: 0.3em 1em;
      }
    }
  }
}
