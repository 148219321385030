// Remove the .messageReactionView class
.messageReactionView {
  background-color: hsl(0, 0%, 0%, 0.7);

  position: fixed;
  inset: 0;
  z-index: 100;

  display: grid;
  place-items: center;

  .messageReactionView-container {
    width: 420px;
    height: auto;
    border-radius: 0.875rem;
    background-color: var(--color-secondary);
    overflow: hidden;

    animation: delay 0.2s ease-out forwards;
    transform-origin: left top;

    // .header {
    //   background-color: var(--color-primary-d);
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;

    //   padding: 0.625rem 0.9375rem;
    //   padding-block-end: 0;
    //   border-block-end: 1px solid var(--color-border);

    //   position: relative;

    //   .total-number,
    //   .total-emoji {
    //     display: inline-flex;
    //     color: var(--color-white);
    //     font-size: 0.9375rem;
    //     font-weight: 400;
    //     margin-inline: 8px;
    //     padding-block-end: 0.625rem;

    //     flex-shrink: 0;
    //     flex: 1;

    //     cursor: pointer;

    //     .total,
    //     .emoji {
    //       margin-inline-end: 4px;
    //       flex-shrink: 0;
    //     }
    //   }

    //   // .active {
    //   //   border-block-end: 3px solid var(--color-white);
    //   // }

    //   .slider {
    //     width: 30px;
    //     height: 3px;
    //     border-radius: 3px;
    //     background-color: var(--color-white);
    //     position: absolute;
    //     bottom: 0;
    //     transition: all 250ms ease;
    //   }

    //   // .total-number.active {
    //   //   border-block-end: 3px solid var(--color-white);
    //   // }

    //   // .total-emoji.active {
    //   //   border-block-end: 3px solid var(--color-white);
    //   // }
    // }

    .heading {
      background-color: var(--color-primary-d);
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 0.625rem 0.9375rem;
      padding-block-end: 0;
      border-block-end: 1px solid var(--color-border);

      position: relative;

      .title {
        color: var(--color-offwhite);
      }

      .profiles {
        background-color: var(--color-secondary);
        // height: 100%;
        min-height: 140px;
        max-height: 420px;
        overflow-y: auto;

        .profile {
          display: flex;
          padding-inline-start: 0.9375rem;
          align-items: center;

          height: 3.75rem;

          cursor: pointer;
          transition: bachground-color 250ms ease-out;

          &:hover,
          &:focus-visible {
            background-color: var(--color-selected-bg);
          }

          &:focus-visible {
            outline: 0;
          }

          .profile-image {
            width: 2.5rem;
            height: 2.5rem;
            // background-color: var(--color-primary-d);
            border-radius: 50%;
            padding: 1px;
            margin-inline-end: 0.75rem;
            overflow: hidden;

            position: relative;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .profile-info-container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-block: 0.8125rem;
            padding-inline-end: 0.9375rem;

            height: 100%;

            .profile-info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              // align-items: center;

              flex: 1;
              .name {
                font-size: 0.9375rem;
                color: var(--color-quaternary-l);
                font-weight: 500;
              }

              .description {
                font-size: 0.8rem;
                color: var(--color-description);
                line-height: 1.25;
              }

              .remove-reaction {
                color: var(--color-description);
                font-size: 0.75rem;
              }
            }

            .status {
              display: flex;
              align-items: center;
              // gap: 0.25rem;

              .emoji {
                display: inline-block;
                // color: var(--color-description);
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
      scale: 0;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
