.viewPost {
  background-color: hsl(0, 0%, 0%, 0.7);

  position: fixed;
  inset: 0;
  z-index: 50;

  animation: delay 0.2s ease-out forwards;
  transform-origin: center;

  display: grid;
  place-items: center;

  .viewPost-popup {
    width: auto;
    max-width: calc(100% - 3rem);
    // height: 92vh;
    height: 562.5px;
    background-color: var(--color-popup);
    // background-color: var(--color-secondary);
    border-radius: 1rem;

    opacity: 0;
    scale: 0;

    display: flex;
    flex-direction: column;

    overflow: hidden;
    animation: delay-dialog 0.2s ease-out forwards;
    transition: all 0.2s ease-out;

    // @media (max-width: 1024px) and (max-height: 1380px) {
    //   // height: 30rem;
    //   height: 600px;
    // }

    // @media (max-width: 1440px), (max-width: 1599px) {
    // }

    @media (max-width: 1025px) {
      height: 550px;
    }

    // @media (min-width: 1600px) {
    //   height: 562px;
    // }

    .container {
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      overflow: hidden;
      transition: all 0.3s ease-out;

      .upload-image-container {
        width: 450px;
        // min-width: 450px;
        max-width: 550px;
        height: 100%;
        background-color: #000;

        display: flex;
        position: relative;

        box-shadow: 2px 4px 6px 0px #00000033;
        transition: all 0.3s ease-out;

        flex: 1;

        @media screen and (max-width: 1024px) {
          width: 325px;
        }

        .upload-image {
          background-position: center center;
          background-repeat: no-repeat;

          height: 100%;
          width: 100%;
          overflow: hidden;

          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;

          transition: all 0.2s ease-out;
        }

        .upload-video {
          height: 100%;
          width: 100%;

          cursor: pointer;

          object-fit: cover;
        }

        .video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;
          background-color: rgb(0, 0, 0, 0.5);
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            margin-inline-start: 3px;
          }
        }

        .image-number {
          position: absolute;
          top: 10px;
          right: 10px;
          border-radius: 500px;
          // background: hsl(227, 9%, 20%, 40%);
          background-color: var(--color-popup);
          display: grid;
          place-items: center;

          .number {
            display: inline-block;
            padding: 3px 0.8rem;
            font-size: 0.75rem;
            color: var(--color-offwhite);
          }
        }

        .image-sound {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          // background-color: var(--color-popup);
          // opacity: 0.5;
          background-color: #2e303850;

          position: absolute;
          bottom: 10px;
          right: 10px;

          display: grid;
          place-items: center;

          cursor: pointer;

          svg {
            width: 60%;
          }
        }

        .swipe-btns {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          padding-inline: 0.9375rem;

          .swipe-backwards,
          .swipe-forwards {
            width: 2rem;
            height: 2rem;
            background-color: hsl(0, 0%, 0%, 0.7);
            border-radius: 50%;
            display: grid;
            place-items: center;
            position: absolute;
            right: 10px;

            img {
              width: 1rem;
            }
          }

          .swipe-backwards {
            position: absolute;
            left: 10px;
          }
        }

        .cta-wrapper {
          padding-inline: 0.9375rem;
          padding-block-end: 0.9375rem;

          display: flex;
          align-items: center;
          gap: 5rem;

          width: 100%;

          position: absolute;
          bottom: 0;
          z-index: 10;

          .crop-zoom {
            display: flex;
            align-items: center;
            gap: 1rem;

            .crop-wrapper,
            .zoom-wrapper {
              position: relative;

              transition: all 0.3s ease-out;

              .btn-crop,
              .btn-zoom {
                width: 2rem;
                height: 2rem;
                background-color: hsl(0, 0%, 0%, 0.7);
                border-radius: 50%;

                display: grid;
                place-items: center;

                transition: all 0.3s ease-out;

                &:hover,
                &:active {
                  background-color: var(--color-primary-d);
                }

                svg {
                  width: 1rem;
                }
              }

              .btn-crop.active,
              .btn-zoom.active {
                background-color: var(--color-primary-d);
              }
            }

            .wrapper-none {
              opacity: 0;
              pointer-events: none;
            }

            .crop-wrapper {
              .crop-cta {
                width: 6.25rem;
                height: fit-content;
                background-color: hsl(0, 0%, 0%, 0.7);
                padding-block: 0.5rem;
                border-radius: 8px;

                position: absolute;
                top: -8rem;
                left: 0;

                transform-origin: left bottom;
                animation: openCropCta 250ms ease-out forwards;

                .cta {
                  padding: 0.35rem 0.9375rem;
                  opacity: 0.5;

                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  transition: all 0.3s ease-out;
                  cursor: pointer;

                  .text {
                    color: var(--color-offwhite);
                    font-size: 0.7rem;
                  }

                  .cta-crop-image {
                    width: 1.25rem;
                    height: 1.25rem;

                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                .cta.active {
                  background-color: hsl(0, 0%, 62%, 0.7);
                  opacity: 1;
                }

                .cta.disactive {
                  animation: slideDown 0.2s ease-out;
                }
              }
            }

            .zoom-wrapper {
              position: relative;

              .slider-wrapper {
                display: none;
                background-color: hsl(0, 0%, 0%, 0.7);
                padding: 0.25rem 0.625rem;
                border-radius: 8px;
                width: 6.25rem;

                position: absolute;
                top: -3.25rem;
                left: -1rem;

                animation: slideUp2 0.2s ease-out;
                transition: all 0.2s ease-out;

                .slider {
                  -webkit-appearance: none;
                  appearance: none;
                  background: transparent;
                  cursor: pointer;
                  width: 100%;
                  vertical-align: middle;

                  &:focus {
                    outline: none;
                  }

                  &::-webkit-slider-runnable-track {
                    background-color: var(--color-white);

                    border-radius: 0.5rem;
                    height: 3px;
                  }

                  &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    margin-top: -5px;
                    margin-left: -3px;

                    background-color: var(--color-primary);
                    width: 0.875rem;
                    height: 0.875rem;
                    border-radius: 50%;
                  }

                  &::-moz-range-track {
                    background-color: var(--color-white);
                    border-radius: 0.5rem;
                    height: 3px;
                  }

                  &::-moz-range-thumb {
                    border: none;
                    border-radius: 0;

                    background-color: var(--color-primary);
                    width: 0.875rem;
                    height: 0.875rem;
                    border-radius: 50%;
                  }
                }
              }

              .slider-wrapper.active {
                display: block;
              }
            }
          }

          .add-tepars-container {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 25vw;

            overflow: hidden;

            .add-tepars {
              display: flex;
              gap: 5px;
              align-items: center;
              justify-content: center;

              width: 100%;

              ::-webkit-scrollbar {
                width: 6px !important;
                height: 6px !important;
                background-color: transparent;
              }

              ::-webkit-scrollbar-thumb {
                background-color: var(--color-scroll-thumb);
                border-radius: 5000px;
              }

              .media-wrapper {
                display: flex;
                align-items: center;
                overflow-x: auto;
                white-space: nowrap;
                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;

                // scrollbar-gutter: stable;

                .media {
                  width: 3.125rem;
                  height: 3.125rem;
                  margin: 5px 2px;
                  border: 2px solid transparent;
                  border-radius: 1px;
                  transition: all 0.3s ease;
                  position: relative;

                  .spinner {
                    width: 1.25rem;
                    height: 1.25rem;
                  }

                  &:focus,
                  &:hover {
                    outline: 0;

                    .mediaDelete {
                      display: grid;
                    }
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }

                  .mediaDelete {
                    width: 100%;
                    height: 50%;

                    background: linear-gradient(
                      210deg,
                      rgba(0, 0, 0, 0.3) -8.48%,
                      rgba(0, 0, 0, 0) 72.11%
                    );

                    position: absolute;
                    right: 0;
                    top: 0;

                    display: none;

                    place-items: center;
                    transition: all 0.3s;
                    overflow: hidden;

                    img {
                      width: 1.2rem;
                      height: 1.2rem;
                      position: absolute;
                      right: 3px;
                      top: 3px;
                      pointer-events: auto;
                    }
                  }
                }

                .media.active {
                  border: 2px solid var(--color-primary);
                  overflow: hidden;
                }

                .spinner-wrapper {
                  height: 100%;
                  display: flex;

                  .spinner {
                    margin: auto;
                  }
                }

                &:focus-visible {
                  outline: 2px solid var(--color-primary);
                  outline-offset: 2px;
                }
              }

              .Imagedots {
                height: 6px;
                width: 6px;
                background-color: var(--color-white);
                margin: 4px;
                border-radius: 50%;
                cursor: pointer;
              }

              .activedots {
                height: 8px;
                width: 8px;
                background-color: #1790a3;
              }

              .media,
              .upload {
                border-radius: 1px;
                flex-shrink: 0;
                overflow: hidden;
                cursor: pointer;
              }

              .upload {
                width: 2.1875rem;
                height: 3rem;
                background-color: transparent;
                border: 1.5px solid var(--color-primary-d);
                margin: 5px 2px;

                display: grid;
                place-items: center;

                align-self: start;

                svg {
                  width: 1.125rem;
                  height: 1.125rem;
                }

                input[type="file"] {
                  display: none;
                }
              }
            }
          }
        }

        .gradient {
          width: 100%;
          height: 8.125rem;
          opacity: 0.5;
          background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);

          position: absolute;
          bottom: 0;
        }

        .doubleTepAnimation {
          position: absolute;
          top: 0;
          left: 0;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          // transform: scale(0);

          z-index: 10;

          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          pointer-events: none;
          will-change: transform, opacity;

          animation: doubleTeps 1000ms ease-in-out forwards;
        }

        .slick-dots {
          bottom: 15px;
        }
      }

      .filters {
        height: 100%;

        width: 400px;
        min-width: 400px;
        max-width: 500px;

        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1025px) {
          width: 350px;
          min-width: 350px;
        }

        height: 100%;

        .header {
          padding: 0.4rem 0.9375rem;

          display: flex;
          align-items: center;

          border-block-end: 1px solid var(--color-border);
          background-color: inherit;

          .btn-back,
          .btn-close {
            // width: 1.25rem;
            // height: 1.25rem;
            padding: 5px;

            flex-shrink: 0;

            // &:focus-visible {
            //   outline: 2px solid var(--color-focused);
            //   outline-offset: 2px;
            // }
          }

          .heading {
            flex: 1;

            .title {
              color: var(--color-quaternary-l);
              text-align: center;
              font-size: 1.25rem;
              font-weight: 400;
            }
          }

          .next {
            color: var(--color-primary-d);
            font-size: 1.25rem;

            cursor: pointer;

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }
          }
        }

        .privacy {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          background-color: var(--color-secondary);

          .privacy-wrapper {
            height: 100%;

            .tepars-profile {
              padding: 0.9375rem;
              padding-block-end: 0.625rem;

              .profile-details {
                display: flex;
                gap: 0.5rem;
                align-items: center;

                margin-block-end: 0.9375rem;

                .profile-image {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  overflow: hidden;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                .profile-info {
                  flex: 1;

                  display: flex;
                  flex-direction: column;
                  gap: 2px;

                  .name {
                    color: var(--color-quaternary-l);
                    font-size: 0.9375rem;
                    font-weight: 500;
                  }

                  .btn-location {
                    padding: 2px 4px;
                    background-color: var(--color-location-bg);
                    width: fit-content;
                    border-radius: 3px;
                    cursor: pointer;
                    display: flex;
                    gap: 2px;
                    align-items: center;

                    .text {
                      color: var(--color-primary-d);
                      font-size: 0.8rem;
                    }
                  }
                }
              }

              .profile-caption {
                display: flex;
                align-items: flex-start;
                gap: 6px;

                .caption {
                  width: 100%;
                  resize: none;
                  border: 0;
                  outline: 0;
                  padding: 5px;
                  color: var(--color-quaternary-l);
                  font-size: 0.875rem;
                  line-height: 1.25;
                  background-color: transparent;

                  &::placeholder {
                    color: var(--color-placeholder);
                    font-size: 0.875rem;
                  }
                }

                .profile-emoji {
                  width: 1rem;
                  height: 1rem;
                  padding-block-start: 5px;
                  cursor: pointer;
                }
              }
            }

            .pointer-none {
              opacity: 0.5;
              pointer-events: none;
            }

            .post-visible {
              .title {
                color: var(--color-quaternary-l);
                font-size: 1.125rem;
                font-weight: 600;
                margin-block-end: 8px;
                padding-inline: 0.9375rem;
                line-height: 1.35;

                @media screen and (max-width: 1025px) {
                  font-size: 1rem;
                }
              }

              .sub-info {
                color: var(--color-description);
                font-size: 0.9375rem;
                line-height: normal;
                padding-inline: 0.9375rem;
                padding-block-end: 5px;

                @media screen and (max-width: 1025px) {
                  font-size: 0.875rem;
                }
              }

              .visible-to-contacts {
                .contact-select,
                .onlyme-select {
                  padding: 0.9375rem;
                  padding-block-end: 0.75rem;

                  display: flex;
                  align-items: center;
                  gap: 0.9375rem;

                  .contact-select-info,
                  .onlyme-select-info {
                    flex: 1;

                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .wrapper {
                      flex: 1;

                      cursor: pointer;

                      display: flex;
                      flex-direction: column;
                      gap: 1px;

                      .title {
                        font-size: 0.875rem;
                        font-weight: 500;
                        margin-block-end: 0;
                        line-height: revert;
                        padding-inline: 0;

                        @media screen and (max-width: 1025px) {
                          font-size: 0.8rem;
                        }
                      }

                      .sub-text {
                        color: var(--color-description);
                        font-size: 0.8rem;

                        @media screen and (max-width: 1025px) {
                          font-size: 0.75rem;
                        }
                      }
                    }

                    .btn-more {
                      width: 0.625rem;
                      height: 1rem;

                      cursor: pointer;

                      svg {
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                }

                .contact-select {
                  border-block-end: 0.5px solid var(--color-border);
                }
              }
            }

            .share-group-wrapper,
            .visibility-group-wrapper,
            .privacy-settings {
              padding-inline: 0.9375rem;

              .share-group,
              .visibility-group,
              .privacy-settings-info {
                background-color: #f7f7f7;
                border-radius: 6px;
                padding: 0.75rem 0.625rem;

                margin-block-start: 5px;

                @media screen and (max-width: 1025px) {
                  padding: 0.625rem;
                }

                .text {
                  color: var(--color-description);
                  font-size: 0.875rem;
                  line-height: 1.2;

                  @media screen and (max-width: 1025px) {
                    font-size: 0.8rem;
                  }
                }
              }

              .reposting-settings,
              .comment-settings,
              .replyPost-settings {
                flex: 1;

                display: flex;
                align-items: center;
                gap: 10px;

                padding: 0.9375rem 0px;
                padding-block-end: 0.75rem;

                .reposting-info,
                .comment-info,
                .replyPost-info {
                  flex: 1;

                  cursor: pointer;

                  display: flex;
                  flex-direction: column;

                  .title {
                    color: var(--color-quaternary-l);
                    font-size: 0.875rem;
                    font-weight: 500;
                    margin-block-end: 0;

                    padding-inline: 0;

                    @media screen and (max-width: 1025px) {
                      font-size: 0.8rem;
                    }
                  }

                  .sub-text {
                    color: var(--color-description);
                    font-size: 0.8rem;

                    @media screen and (max-width: 1025px) {
                      font-size: 0.75rem;
                    }
                  }
                }
              }

              .group-select,
              .visibility-select {
                padding: 0.9375rem 0px;
                padding-block-end: 0.75rem;

                display: flex;
                align-items: center;
                gap: 0.9375rem;

                .group-select-info,
                .visibility-select-info {
                  flex: 1;

                  display: flex;
                  align-items: center;
                  gap: 10px;

                  .wrapper {
                    flex: 1;

                    cursor: pointer;

                    display: flex;
                    flex-direction: column;
                    gap: 1px;

                    .title {
                      font-size: 0.875rem;
                      font-weight: 500;
                      margin-block-end: 0;
                      color: var(--color-quaternary-l);

                      padding-inline: 0;
                    }

                    .sub-text {
                      color: var(--color-description);
                      font-size: 0.8rem;
                    }
                  }

                  .btn-more {
                    width: 0.625rem;
                    height: 1rem;

                    cursor: pointer;

                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }

            .visibility-select-info {
              margin-inline-start: 2rem;
            }

            .privacy-settings {
              padding-inline: 0px;

              .privacy-settings-info {
                margin-inline: 0.9375rem;
              }

              .reposting-settings,
              .comment-settings,
              .replyPost-settings {
                padding-inline: 3rem 0.9375rem;
                border-block-end: 0.5px solid var(--color-border);

                @media screen and (max-width: 1025px) {
                  padding-inline: 2.5rem 0.9375rem;
                }
              }
            }
          }

          .profile-emoji-popup {
            height: 500px;
            background-color: var(--color-secondary);
          }

          .group-check {
            input[type="radio"] {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;
            }

            .checkmark {
              display: inline-block;

              width: 1.125rem;
              height: 1.125rem;
              border: 1.25px solid var(--color-radio-border);
              border-radius: 50%;
              background-color: transparent;

              vertical-align: middle;
              transition: border 0.3s ease-out;
              position: relative;
              cursor: pointer;

              &::after {
                content: "";
                display: inline-block;
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 50%;
                background-color: var(--color-primary-d);

                opacity: 0;
                visibility: hidden;

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            input[type="radio"]:checked + .checkmark::after,
            input[type="radio"]:focus + .checkmark::after {
              opacity: 1;
              visibility: visible;
            }

            input[type="radio"]:checked + .checkmark,
            input[type="radio"]:focus + .checkmark {
              border: 1.25px solid var(--color-primary-d);
            }
          }

          .toggle-switch {
            .off {
              width: 38px;
              height: 22px;
              background-color: var(--color-description);
              border-radius: 25px;
              padding: 2px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
              transition: background-color 0.3s;

              @media screen and (max-width: 1025px) {
                width: 32px;
                height: 18px;
              }

              .on {
                width: 15px;
                height: 15px;
                background-color: var(--color-white);
                border-radius: 50%;
                margin: 2px;
                transition: background-color 0.3s, transform 0.3s;
                transform: translateX(0);

                @media screen and (max-width: 1025px) {
                  width: 12px;
                  height: 12px;
                }
              }

              &.toggled .on {
                transform: translateX(100%);
              }

              &.toggled {
                background-color: var(--color-primary-d);
              }
            }
          }
        }
      }
    }
  }
}

// for slick slider
// .slick-dots {
//     bottom: 15px;
// }
