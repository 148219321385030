.commentView {
  width: 100%;
  height: 100%;

  overflow-y: auto;
  // height: 92%;

  .commentView-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .heading-likes {
      padding: 1rem 0.9375rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;

      .liked-users {
        display: flex;
        align-items: center;
        gap: 5px;

        .liked-image {
          width: 1.75rem;
          height: 1.75rem;

          flex-shrink: 0;

          svg {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .users-names {
          // // color: var(--color-description);
          // color: var(--color-quaternary-l);
          // font-size: 1.125rem;
          // line-height: 1.35;

          color: var(--color-quaternary-l);
          font-weight: 300;
          font-size: 1rem;
          line-height: 1.35;

          span {
            font-weight: 400;
          }
        }
      }

      .btn-likepost {
        // width: 1.5rem;
        // height: 1.5rem;
        margin-inline-end: 5px;
        padding: 5px;

        flex-shrink: 0;
        img {
          width: 1.75rem;
          height: 1.75rem;
          object-fit: cover;
        }
        img.animate {
          animation-name: postLikeAnimation;
          animation-timing-function: ease-in-out;
          animation-duration: 0.45s;
        }
      }
    }

    .comment-section-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .filter-commment {
        .filter-options {
          position: relative;
          padding: 0px 0.9375rem;

          summary {
            background-color: transparent;
            color: var(--color-quaternary-l);
            font-size: 0.9375rem;

            display: inline-flex;
            align-items: center;
            gap: 0.625rem;

            position: relative;

            cursor: pointer;

            .dropdown-icon {
              width: 13px;
              height: 13px;
              transition: all 0.3s ease-in-out;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }

          .filter-comment-list {
            position: absolute;
            top: 110%;
            left: 0.9375rem;
            background-color: var(--color-secondary);
            border-radius: 10px;
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
            display: none;
            animation: slideIn 0.2s linear;
            z-index: 10;

            .filter-option {
              padding: 8px 12px;
              display: flex;
              align-items: center;
              gap: 0.5rem;
              border-block-end: 0.5px solid var(--color-border);
              cursor: pointer;
              width: 150px;

              &:last-child {
                border-block-end: 0;
              }

              .group-check {
                input[type="radio"] {
                  position: absolute;
                  opacity: 0;
                  height: 0;
                  width: 0;
                }

                .checkmark {
                  // display: inline-flex;
                  display: inline-block;

                  width: 0.9375rem;
                  height: 0.9375rem;
                  border: 1.25px solid var(--color-radio-border);
                  border-radius: 50%;
                  background-color: transparent;
                  vertical-align: middle;

                  transition: border 0.3s ease-out;
                  cursor: pointer;

                  position: relative;

                  &::after {
                    content: "";
                    display: block;
                    margin: auto;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: var(--color-primary-d);

                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.3s ease-out;

                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }

                input[type="radio"]:checked + .checkmark::after,
                input[type="radio"]:focus + .checkmark::after {
                  opacity: 1;
                  visibility: visible;
                }

                input[type="radio"]:checked + .checkmark,
                input[type="radio"]:focus + .checkmark {
                  border: 1.25px solid var(--color-primary-d);
                }
              }

              .description {
                color: var(--color-quaternary-l);
                font-size: 0.8rem;
              }
            }
          }

          &[open] {
            .dropdown-icon {
              transform: rotate(180deg);
            }

            .filter-comment-list {
              display: inline-block;
            }
          }
        }
      }

      .no-comments {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .no-comment-image {
          width: 90px;
          height: 90px;
          border: 2.25px solid var(--color-quaternary-l);
          border-radius: 50%;
          display: grid;
          place-items: center;
          margin-block-end: 0.75rem;

          // img {
          //   width: 50%;
          // }
        }

        .text {
          color: var(--color-quaternary-l);
          font-size: 1rem;
          margin-block-end: 3px;
        }

        .info {
          color: var(--color-description);
          font-size: 0.7rem;
        }
      }

      .post-comments {
        height: 100%;
        padding: 0.9375rem;
        padding-block-start: 0.75rem;

        .commented-user {
          display: flex;
          flex-direction: column;
          margin-block-end: 1rem;

          .comment-user-profile {
            display: flex;
            gap: 0.75rem;
            align-items: flex-start;

            .profile-image {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              overflow: hidden;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .profile-info-wrapper {
              flex: 1;
              width: 100%;

              .profile-info {
                display: flex;
                flex-direction: column;
                gap: 4px;
                border-radius: 10px;
                padding: 8px;
                background-color: var(--color-secondary);
                margin-block-end: 6px;
                box-shadow: 0px 0px 3.739px 0px rgba(0, 0, 0, 0.25);

                .profile-name {
                  display: flex;
                  align-items: flex-start;
                  justify-content: space-between;

                  .name {
                    display: inline-block;
                    // color: var(--color-quaternary-l);
                    color: var(--color-quaternary);
                    font-size: 0.8rem;
                    font-weight: 500;

                    cursor: pointer;
                  }

                  .commented-time {
                    display: inline-block;
                    color: var(--color-description);
                    font-size: 0.7rem;
                    font-weight: 40;
                    margin-block-start: 1px;
                  }
                }

                .commented-comment {
                  line-height: 1.25;
                  margin-block-end: 1px;
                  .comment {
                    display: inline-block;
                    font-size: 0.8rem;
                    // color: var(--color-quaternary-l);
                    color: var(--color-description);
                    line-height: 1.25;
                    word-break: break-all;
                    text-overflow: ellipsis;
                  }
                }
              }

              .profile-cta {
                display: flex;
                align-items: center;
                gap: 10px;
                padding-inline-start: 6px;

                .profile-cta-likes {
                  display: flex;
                  align-items: center;
                  gap: 2px;

                  .likes-image {
                    // width: 0.9375rem;
                    // height: 0.9375rem;
                    // width: 1rem;
                    // height: 1rem;
                    // margin-block-start: -4px;
                    padding: 4px;
                    cursor: pointer;

                    img {
                      width: 1.25rem;
                      height: 1.25rem;
                      // object-fit: cover;
                    }

                    &:focus-visible {
                      outline: 0;
                      border-radius: 2px;
                      box-shadow: 0 0 0 2px var(--color-focused);
                    }
                    img.animate {
                      animation-name: postLikeAnimation;
                      animation-timing-function: ease-in-out;
                      animation-duration: 0.45s;
                    }
                  }

                  .comments-likes {
                    display: inline-block;
                    // color: var(--color-description);
                    color: var(--color-profile-description);
                    font-size: 0.8rem;
                    margin-block-start: 2px;
                    line-height: 1.25;
                  }
                }

                .comment-reply {
                  color: var(--color-primary-d);
                  font-size: 0.8rem;
                  padding-left: 10px;
                  line-height: 1;
                  // position: relative;
                  border-inline-start: 0.5px solid var(--color-description);
                  margin-block-start: 2px;
                  cursor: pointer;

                  &:focus-visible {
                    outline: 0;
                    border-radius: 2px;
                    box-shadow: 0 0 0 2px var(--color-focused);
                  }

                  // &::before {
                  //   content: "";
                  //   display: inline-block;
                  //   background-color: var(--color-description);
                  //   width: 0.5px;
                  //   height: 9px;
                  //   position: absolute;
                  //   top: 50%;
                  //   left: 0;
                  //   transform: translateY(-50%);
                  // }
                }

                .comment-cta,
                .reply-cta {
                  padding: 4px;
                  height: 15px;
                  align-items: center;
                  display: none;

                  transition: display 0.3s ease-out;

                  cursor: pointer;

                  svg {
                    height: 100%;
                  }

                  &:focus-visible {
                    outline: 0;
                    border-radius: 2px;
                    box-shadow: 0 0 0 2px var(--color-focused);
                  }
                }
              }
            }

            &:focus-visible .profile-info-wrapper .profile-cta .comment-cta,
            &:hover .profile-info-wrapper .profile-cta .comment-cta,
            &:focus-visible .profile-info-wrapper .profile-cta .reply-cta,
            &:hover .profile-info-wrapper .profile-cta .reply-cta {
              display: flex;
            }
          }

          .commented-replys {
            margin-inline-start: 50px;
            margin-block-start: 0.5rem;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .view-replys {
              display: inline-flex;
              align-items: center;
              gap: 5px;
              color: var(--color-description);
              font-size: 0.75rem;
              width: fit-content;
              margin-inline: auto;
              cursor: pointer;

              &::before {
                content: "";
                display: inline-block;
                width: 25px;
                height: 0.5px;
                background-color: var(--color-description);
                vertical-align: middle;
              }
            }

            .comment-user-profile {
              .profile-info {
                border: 0;
                box-shadow: none;
                padding: 0;
                margin-block-end: 5px;
                border-block-end: 1px solid var(--color-border);
                padding-block-end: 8px;
                border-radius: 0;
                background-color: initial;
                gap: 1px;

                .commented-comment {
                  .replied-user-name {
                    color: var(--color-primary-d);
                    margin-inline-end: 4px;
                    cursor: pointer;
                  }
                }
              }

              .profile-cta {
                padding-inline-start: 0;
              }
            }
          }

          // &:hover .comment-user-profile .profile-info-wrapper .profile-cta .profile-cta-likes .reply-cta {
          //   display: flex;
          // }
        }
      }
    }

    .footer-box {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: 0.9375rem;

      .replying-to-wrapper {
        .reply-to {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding-inline: 10px;

          .reply-to-name {
            color: var(--color-description);
            font-size: 0.7rem;

            .name {
              color: var(--color-primary-d) !important;
              padding-inline-start: 3px;
              cursor: pointer;

              &:active,
              &:visited,
              &:link {
                color: inherit;
              }
            }
          }

          .cancel-reply {
            width: fit-content;
            color: var(--color-description);
            font-size: 0.7rem;
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
            cursor: pointer;

            &::before {
              content: "";
              display: inline-block;
              width: 2.5px;
              height: 2.5px;
              border-radius: 50%;
              background-color: var(--color-description);
              vertical-align: middle;
            }
          }
        }
      }

      .comment-to-post {
        display: flex;
        gap: 0.9375rem;
        align-items: center;

        .message-wrapper {
          flex: 1;
          border-radius: 5000px;
          border: 1px solid #8e8e93;
          font-size: 0.9375rem;
          padding: 10px;
          background-color: var(--color-secondary);
          display: flex;
          justify-content: space-between;
          min-width: 0;

          @media screen and (max-width: 1025px) {
            padding: 9px;
          }

          .input-wrapper {
            flex: 1;
            position: relative;
          }

          .messageInput {
            width: 100%;
            color: var(--color-quaternary-l);
            border: none;
            outline: none;
            padding-inline: 0.5rem;
            max-height: 3rem;
            overflow: hidden auto;
            white-space: pre-wrap;
            word-break: break-word;
            font-size: 0.9375rem;
            line-height: 1.25;

            @media screen and (max-width: 1025px) {
              font-size: 0.75rem;
            }

            /* Hide the Placeholder */
            &:empty::before {
              content: attr(placeHolder);
              font-size: 0.875rem;
              color: var(--color-placeholder);
              cursor: text;
            }

            &:empty:focus ::before {
              content: "";
            }
          }

          .placeHolder {
            position: absolute;
            inset: 0;
            display: inline-block;
            // color: #b3b3b3;
            color: var(--color-placeholder);
          }
        }

        .send-message {
          width: 36px;
          height: 36px;
          cursor: pointer;
          position: relative;

          svg {
            width: 100%;
            height: 100%;
          }

          .number {
            position: absolute;
            right: -3px;
            top: -3px;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            background-color: var(--color-secondary);
            font-size: 0.7rem;
            color: var(--color-quaternary);
            display: grid;
            place-items: center;
            cursor: default;
            box-shadow: 0 1px 3px rgba(11, 20, 26, 0.4);
          }
        }
      }
    }
  }
}

// .comment-action {
//   background-color: hsl(0, 0%, 0%, 0.7);
//   position: fixed;
//   inset: 0;
//   z-index: 100;
//   //   opacity: 0;
//   animation: delay 0.3s ease-out forwards;
//   transform-origin: center;

//   display: grid;
//   place-items: center;
//   .dialog {
//     width: 450px;
//     max-height: 90vh;
//     height: fit-content;

//     // position: absolute;
//     // top: 50%;
//     // left: 50%;
//     // transform: translate(-50%, -50%);
//     opacity: 0;
//     scale: 0;
//     // transform: translateX();

//     background-color: var(--color-secondary);
//     border-radius: 1rem;

//     animation: delay-dialog 0.3s ease-out forwards;
//     // transform-origin: center center;

//     // overflow-y: auto;

//     display: flex;
//     flex-direction: column;

//     overflow: hidden;
//     .actionUser {
//       .dialog-info {
//         margin-block: 1.875rem 0.5rem;

//         h4 {
//           font-size: 1rem;
//           font-weight: 400;
//           color: var(--color-quaternary-l);
//           text-align: center;
//         }
//       }

//       .discardPost-cta {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         width: 100%;
//         gap: 22px;

//         margin-block-end: 1.25rem;
//         .btn-cancel,
//         .btn-block {
//           background-color: transparent;
//           padding: 0.5rem 1.125rem;
//           font-size: 0.875rem;
//           color: var(--color-primary-d);
//           border: 1px solid var(--color-primary-d);
//           border-radius: 5px;
//           cursor: pointer;
//         }
//         .active {
//           background-color: var(--color-primary-d);
//           color: var(--color-secondary);
//         }
//       }
//     }
//   }
// }

.comment-action {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .dialog {
    width: 450px;
    opacity: 0;
    scale: 0;
    background-color: var(--color-secondary);
    padding: 1.125rem 1.35rem;
    border-radius: 0.5rem;
    animation: delay-dialog 0.3s ease-out forwards;

    .dialog-info {
      display: flex;
      gap: 1rem;
      margin-block-end: 1.875rem;

      h4 {
        font-size: 1.25rem;
        font-weight: 400;
        color: var(--color-quaternary-l);
      }

      .back {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--color-primary-d);
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
      }
    }

    .cta {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 20px;

      .cancel,
      .for-me,
      .for-everyone {
        background-color: transparent;
        padding: 0.5rem 1.125rem;
        font-size: 0.875rem;
        color: var(--color-primary-d);
        border: 1px solid var(--color-primary-d);
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease-out, color 0.3s ease-out;

        &:hover {
          background-color: var(--color-primary-d);
          color: var(--color-offwhite);
        }
      }

      .active {
        background-color: var(--color-primary-d);
        color: var(--color-offwhite);
      }
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes postLikeAnimation {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
}
