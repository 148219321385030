.default {
  height: 100%;

  // background-image: url(../image/SVG_Images/BG_dark.svg);
  background-image: url(../image/SVG_Images/web-bg-light.svg);
  background-size: contain;
  background-repeat: repeat;
  background-position: top center;
  background-color: var(--color-secondary-m-d);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: min(80%, 390px);
    margin-inline: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      width: 280px;
      // height: 244px;
      margin-block-end: 2.25rem;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 1025px) {
        width: 250px;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .no-post {
        text-align: center;
        .no-post-image {
          width: 100px;
          height: 100px;
          border: 2px solid var(--color-quaternary-l);
          border-radius: 50%;
          margin-block-end: 0.625rem;
          margin-inline: auto;

          overflow: hidden;

          display: grid;
          place-items: center;
        }
      }
    }

    .title {
      font-size: 1.4rem;
      // font-weight: 500;
      color: var(--color-quaternary-l);
      margin-block-end: 0.625rem;
    }

    .info {
      font-size: 0.8rem;
      font-weight: 500;
      color: var(--color-quaternary-l);
      text-align: center;
      line-height: 1.6;
    }
  }

  .security {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 2rem;

    svg {
      width: 0.625rem;
      height: 0.625rem;
    }

    .security-info {
      color: var(--color-description);
      font-size: 0.9375rem;
    }
  }
}
