.messageGroup-cta {
  display: flex;
  gap: 10px;
  align-items: center;

  .share,
  .btn-reactToMessage,
  .btn-deleteMessage {
    width: 28px;
    height: 28px;
    background-color: var(--color-message-bg);
    border-radius: 5000px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
  }
}
