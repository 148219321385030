.repostPost {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;

  display: grid;
  place-items: center;

  .dialog {
    width: 450px;

    height: 80vh;
    // height: 35rem;

    background-color: var(--color-popup);
    // padding: 1.125rem 1.35rem;
    border-radius: 0.5rem;
    animation: delay-dialog 0.3s ease-out;

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1025px) and (max-height: 1380px) {
      max-height: 30rem;
    }

    @media screen and (min-width: 1600px) {
      max-height: 40rem;
    }

    .header {
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;

      display: flex;
      align-items: center;

      border-block-end: 1px solid var(--color-border);

      .btn-back {
        padding: 4px;
      }

      .hidden {
        width: 1.125rem;
        height: 1.125rem;

        visibility: hidden;
        opacity: 0;
      }

      .heading {
        flex: 1;

        .title {
          color: var(--color-quaternary-l);
          text-align: center;
          font-size: 1.25rem;
          font-weight: 400;
        }
      }
    }

    .reposted-posts {
      height: 100%;

      .reposted-posts-info {
        display: flex;
        align-items: center;

        padding-inline-start: 0.9375rem;
        height: 4.375rem;
        cursor: pointer;

        .profile-image {
          width: 3.125rem;
          height: 3.125rem;
          border-radius: 50%;
          margin-inline-end: 0.75rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .profile-info {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-inline-end: 0.9375rem;
          border-block-end: 0.5px solid var(--color-border);

          height: 100%;

          .profile-name {
            .reposted-user-info {
              font-size: 0.9375rem;
              // font-weight: 600;
              color: var(--color-quaternary-l);

              .reposted-user-name {
                font-weight: 500;
              }
            }
          }

          .reposted-post-image {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 5px;
            margin-inline-end: 0.75rem;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
