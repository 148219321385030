.repost-post {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  // min-height: 50vh;

  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // overflow-y: auto;
  // display: none;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .default-repost-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // min-height: 50vh;
    // height: 100%;
    height: 50vh;
    .default-repost {
      .default-repost-image {
        width: 100px;
        height: 100px;
        margin-inline: auto;
        border-radius: 50%;
        border: 2px solid var(--color-quaternary-l);
        overflow: hidden;

        display: grid;
        place-items: center;

        margin-block-end: 10px;

        img {
          width: 60%;
        }
      }

      .default-text {
        color: var(--color-quaternary-l);
        font-size: 1.125rem;
      }
    }
  }

  .no-repost {
    height: 100%;
  }
}
