$progress-bg-color: rgba(255, 255, 255, 0.145);
$progress-fill-color: var(--color-white);
.progress-bar-container {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  // background-color: rgba(255, 255, 255, 0.145);
  .progress-segment {
    flex: 1;
    background-color: $progress-bg-color;
    margin-right: 5px;
    border-radius: 8px;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }
    &.active {
      background-color: $progress-bg-color;
    }
    &.filled {
      background-color: $progress-fill-color;
    }
    .progress-segment-fill {
      height: 100%;
      background-color: $progress-fill-color;
      transition: width 0.1s linear;
      width: 0;
      border-radius: 8px;
    }
  }
}
