.blockedContacts {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 10;

  &.open {
    animation: slideInFromRight 250ms ease-out forwards;
  }

  &.close {
    animation: slideOutToRight 250ms ease-out forwards;
  }

  .blockedContacts-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;
      min-height: 54px;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }
    }

    .contacts-wrapper {
      height: 100%;

      display: flex;
      flex-direction: column;

      .addBlockedContact {
        padding: 1.125rem;
        border-block-end: 1px solid var(--color-border);
        background-color: var(--color-secondary);

        display: flex;
        gap: 1rem;
        align-items: center;

        cursor: pointer;

        &:hover,
        &:focus-visible {
          background: var(--color-selected-bg);
        }

        &:focus-visible {
          outline: 0;
        }

        .addBlockedContact-image {
          width: 1.25rem;
          height: 1.25rem;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .text {
          color: var(--color-primary-d);
          font-size: 0.875rem;
          line-height: 1.25;
          margin-block-start: 2px;
          flex: 1;
        }
      }

      .profiles-wrapper {
        // height: 100%;
        // overflow-y: auto;

        // flex: 1;

        // /* height: 100%; */
        overflow-y: auto;
        /* flex: 1 1; */
        flex-grow: 1;
        flex-basis: 0;

        .profile {
          display: flex;
          padding-inline-start: 0.9375rem;
          align-items: center;
          height: 4.375rem;
          cursor: pointer;
          transition: 0.2s ease-out;

          &:hover,
          &:focus-visible {
            background: var(--color-selected-bg);
          }

          &:focus-visible {
            outline: 0;
          }

          .profile-image {
            width: 3.125rem;
            height: 3.125rem;
            border-radius: 50%;
            margin-inline-end: 0.75rem;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              // border-radius: 50%;
              object-fit: cover;
            }
          }

          .profile-info {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // padding-block: 0.8125rem;
            padding-inline-end: 0.9375rem;
            border-block-end: 0.5px solid var(--color-border);

            height: 100%;

            .profile-info-number {
              .name {
                display: inline-block;
                font-size: 1rem;
                color: var(--color-quaternary-l);
                font-weight: 500;

                @media screen and (max-width: 1025px) {
                  font-size: 0.875rem;
                }
              }

              .status {
                overflow-wrap: break-word;
                color: var(--color-description);
                font-size: 0.7rem;
                overflow: hidden;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }

            .close {
              width: 1rem;
              height: 1rem;

              svg {
                width: 1rem;
                height: 1rem;
              }
            }
          }
        }
      }
    }

    .blockedContacts-note {
      background-color: var(--color-background-dark);
      padding: 0.9375rem;

      .note {
        color: var(--color-description);
        font-size: 0.75rem;
        width: 85%;
      }
    }

    .default-blockedContacts-info {
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .default-blockedContacts-info-image {
        background-color: var(--color-primary-d);
        border-radius: 50%;
        width: 130px;
        height: 130px;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .info-wrapper {
        text-align: center;
        margin-block-start: 2rem;

        .heading {
          display: inline-block;
          font-size: 1rem;
          color: var(--color-quaternary-l);
          margin-block-end: 0.875rem;
        }

        .info {
          font-size: 0.875rem;
          color: var(--color-description);
          max-width: 85%;
          margin-inline: auto;
        }
      }
    }
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
}
