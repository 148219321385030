.profilePostView {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 60;
  animation: fadeIn 250ms ease forwards;
  transform-origin: center;
  // display: grid;
  // place-items: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .profilePostView-wrapper {
    display: flex;

    width: auto;
    height: 562.5px;
    background-color: var(--color-popup);
    border-radius: 8px;
    overflow: hidden;

    max-width: calc(100% - 3rem);

    @media (max-width: 1024px) and (max-height: 1380px) {
      height: 550px;
    }

    // @media (min-width: 1600px) {
    //   height: 38rem;
    // }

    & > * {
      width: 100%;
      height: 100%;
    }

    .container {
      overflow: hidden;
      // height: 562.5px;
      height: auto;

      display: flex;
      justify-content: center;
      align-items: center;

      box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.2);
      background-color: #000;
      transition: all 0.3s ease-out;
      position: relative;

      .doubleTepAnimation {
        position: absolute;
        top: 0;
        left: 0;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // transform: scale(0);

        z-index: 10;

        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        pointer-events: none;
        will-change: transform, opacity;

        animation: doubleTeps 1000ms ease-in-out forwards;
      }

      .upload-image-container {
        width: 450px;
        // width: 100%;
        height: 100%;

        display: flex;
        position: relative;

        min-width: 350px;
        max-width: 550px;

        transition: all 0.3s ease-out;

        @media screen and (max-width: 1024px) {
          width: 325px;
          min-width: 325px;
        }

        .image-number {
          position: absolute;
          top: 10px;
          right: 10px;
          border-radius: 500px;
          // background: hsl(227, 9%, 20%, 40%);
          background-color: var(--color-popup);
          display: grid;
          place-items: center;

          .number {
            display: inline-block;
            padding: 3px 0.8rem;
            font-size: 0.75rem;
            color: var(--color-offwhite);
          }
        }

        .image-sound {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          // background-color: var(--color-popup);
          // opacity: 0.5;
          background-color: #2e303850;

          position: absolute;
          bottom: 10px;
          right: 10px;

          display: grid;
          place-items: center;
          z-index: 20;

          cursor: pointer;

          svg {
            width: 60%;
          }
        }

        .upload-image {
          background-position: center center;
          background-repeat: no-repeat;
          // background-size: cover;

          height: 100%;
          width: 100%;
          overflow: hidden;

          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;

          transition: all 0.2s ease-out;
        }

        .video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;
          background-color: rgb(0, 0, 0, 0.5);
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            margin-inline-start: 3px;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .slick-slider.slick-initialized {
          position: absolute;
          inset: 0;
        }

        .cta-wrapper {
          padding-inline: 0.9375rem;
          padding-block-end: 0.9375rem;

          display: flex;
          align-items: center;
          gap: 5rem;

          width: 100%;

          position: absolute;
          bottom: 0;
          z-index: 10;

          .crop-zoom {
            display: flex;
            align-items: center;
            gap: 1rem;

            .crop-wrapper,
            .zoom-wrapper {
              position: relative;

              transition: all 0.3s ease-out;

              .btn-crop,
              .btn-zoom {
                width: 2rem;
                height: 2rem;
                background-color: hsl(0, 0%, 0%, 0.7);
                border-radius: 50%;

                display: grid;
                place-items: center;

                transition: all 0.3s ease-out;

                &:hover,
                &:active {
                  background-color: var(--color-primary-d);
                }

                svg {
                  width: 1rem;
                }
              }

              .btn-crop.active,
              .btn-zoom.active {
                background-color: var(--color-primary-d);
              }
            }

            .wrapper-none {
              opacity: 0;
              pointer-events: none;
            }

            .crop-wrapper {
              .crop-cta {
                width: 6.25rem;
                height: fit-content;
                background-color: hsl(0, 0%, 0%, 0.7);
                padding-block: 0.5rem;
                border-radius: 8px;

                position: absolute;
                top: -8rem;
                left: 0;

                transform-origin: left bottom;
                animation: openCropCta 250ms ease-out forwards;

                .cta {
                  padding: 0.35rem 0.9375rem;
                  opacity: 0.5;

                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  transition: all 0.3s ease-out;
                  cursor: pointer;

                  .text {
                    color: var(--color-offwhite);
                    font-size: 0.7rem;
                  }

                  .cta-crop-image {
                    width: 1.25rem;
                    height: 1.25rem;

                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                .cta.active {
                  background-color: hsl(0, 0%, 62%, 0.7);
                  opacity: 1;
                }

                .cta.disactive {
                  animation: slideDown 0.2s ease-out;
                }
              }
            }

            .zoom-wrapper {
              position: relative;

              .slider-wrapper {
                display: none;
                background-color: hsl(0, 0%, 0%, 0.7);
                padding: 0.25rem 0.625rem;
                border-radius: 8px;
                width: 6.25rem;

                position: absolute;
                top: -3.25rem;
                left: -1rem;

                animation: slideUp2 0.2s ease-out;
                transition: all 0.2s ease-out;

                .slider {
                  -webkit-appearance: none;
                  appearance: none;
                  background: transparent;
                  cursor: pointer;
                  width: 100%;
                  vertical-align: middle;

                  &:focus {
                    outline: none;
                  }

                  &::-webkit-slider-runnable-track {
                    background-color: var(--color-white);

                    border-radius: 0.5rem;
                    height: 3px;
                  }

                  &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    margin-top: -5px;
                    margin-left: -3px;

                    background-color: var(--color-primary);
                    width: 0.875rem;
                    height: 0.875rem;
                    border-radius: 50%;
                  }

                  &::-moz-range-track {
                    background-color: var(--color-white);
                    border-radius: 0.5rem;
                    height: 3px;
                  }

                  &::-moz-range-thumb {
                    border: none;
                    border-radius: 0;

                    background-color: var(--color-primary);
                    width: 0.875rem;
                    height: 0.875rem;
                    border-radius: 50%;
                  }
                }
              }

              .slider-wrapper.active {
                display: block;
              }
            }
          }

          .add-tepars-container {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 25vw;

            overflow: hidden;

            .add-tepars {
              display: flex;
              gap: 5px;
              align-items: center;
              justify-content: center;

              width: 100%;

              ::-webkit-scrollbar {
                width: 6px !important;
                height: 6px !important;
                background-color: transparent;
              }

              ::-webkit-scrollbar-thumb {
                background-color: var(--color-scroll-thumb);
                border-radius: 5000px;
              }

              .media-wrapper {
                display: flex;
                align-items: center;
                overflow-x: auto;
                white-space: nowrap;
                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;

                // scrollbar-gutter: stable;

                .media {
                  width: 3.125rem;
                  height: 3.125rem;
                  margin: 5px 2px;
                  border: 2px solid transparent;
                  border-radius: 1px;
                  transition: all 0.3s ease;
                  position: relative;

                  .spinner {
                    width: 1.25rem;
                    height: 1.25rem;
                  }

                  &:focus,
                  &:hover {
                    outline: 0;

                    .mediaDelete {
                      display: grid;
                    }
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }

                  .mediaDelete {
                    width: 100%;
                    height: 50%;

                    background: linear-gradient(
                      210deg,
                      rgba(0, 0, 0, 0.3) -8.48%,
                      rgba(0, 0, 0, 0) 72.11%
                    );

                    position: absolute;
                    right: 0;
                    top: 0;

                    display: none;

                    place-items: center;
                    transition: all 0.3s;
                    overflow: hidden;

                    img {
                      width: 1.2rem;
                      height: 1.2rem;
                      position: absolute;
                      right: 3px;
                      top: 3px;
                      pointer-events: auto;
                    }
                  }
                }

                .media.active {
                  border: 2px solid var(--color-primary);
                  overflow: hidden;
                }

                .spinner-wrapper {
                  height: 100%;
                  display: flex;

                  .spinner {
                    margin: auto;
                  }
                }

                &:focus-visible {
                  outline: 2px solid var(--color-primary);
                  outline-offset: 2px;
                }
              }

              .Imagedots {
                height: 6px;
                width: 6px;
                background-color: var(--color-white);
                margin: 4px;
                border-radius: 50%;
                cursor: pointer;
              }

              .activedots {
                height: 8px;
                width: 8px;
                background-color: #1790a3;
              }

              .media,
              .upload {
                border-radius: 1px;
                flex-shrink: 0;
                overflow: hidden;
                cursor: pointer;
              }

              .upload {
                width: 2.1875rem;
                height: 3rem;
                background-color: transparent;
                border: 1.5px solid var(--color-primary-d);
                margin: 5px 2px;

                display: grid;
                place-items: center;

                align-self: start;

                svg {
                  width: 1.125rem;
                  height: 1.125rem;
                }

                input[type="file"] {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .profilePostView-details {
      display: flex;
      flex-direction: column;
      height: 100%;
      // overflow-y: auto;

      width: 400px;
      min-width: 400px;
      max-width: 500px;

      @media screen and (max-width: 1025px) {
        min-width: 350px;
        width: 350px;
      }
      .reposted-notification {
        // background-color: #fafafa;
        padding: 0.625rem 1rem;
        margin-block-start: 5px;

        .reposted-notification-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;

          .text {
            color: var(--color-description);
            font-size: 0.75rem;
            font-weight: 400;

            .reposted-user-name {
              margin-inline: 4px;
              color: var(--color-primary-d);
              cursor: pointer;

              display: inline-flex;
              align-items: center;
              gap: 1px;
            }
          }
        }
      }
      .header {
        padding-inline: 18px 10px;
        // padding-block: 8px;

        border-block-end: 1px solid var(--color-border);

        .profile {
          display: flex;
          align-items: center;
          height: 4.375rem;

          .profile-image {
            width: 3.125rem;
            height: 3.125rem;
            border-radius: 50%;
            margin-inline-end: 0.75rem;
            overflow: hidden;

            position: relative;

            flex-shrink: 0;

            cursor: pointer;

            &:focus-visible {
              outline: 0;
              border-radius: 50%;
              box-shadow: 0 0 0 2px var(--color-focused);
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .profile-info {
            display: flex;
            align-items: center;
            gap: 0.625rem;
            flex: 1;

            .profile-info-wrapper {
              flex: 1;

              display: flex;
              flex-direction: column;

              @media screen and (max-width: 1025px) {
                gap: 2px;
              }

              .circle-member {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                line-height: 1.35;
                font-size: 0.8rem;
                color: var(--color-description);
                font-weight: 400;

                overflow: hidden;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                .name {
                  display: inline;
                  // color: var(--color-quaternary-l);
                  // font-size: 1rem;
                  // font-weight: 400;
                  // // margin-inline-end: 5px;
                  line-height: 1.25;

                  cursor: pointer;

                  &:focus-visible {
                    outline: 0;
                    border-radius: 2px;
                    box-shadow: 0 0 0 2px var(--color-focused);
                  }
                }

                .location-name {
                  display: inline;
                  align-items: center;
                  cursor: pointer;
                  // color: var(--color-quaternary-l);
                  // font-weight: 400;

                  .location-icon {
                    display: inline-block;
                    margin-inline: 0px 5px;

                    flex-shrink: 0;
                  }
                }
              }

              .profile-info-name {
                // display: flex;
                // flex-wrap: wrap;
                // align-items: center;

                font-size: 1rem;
                line-height: 1.35;
                color: var(--color-quaternary-l);
                font-weight: 300;

                overflow: hidden;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                @media screen and (max-width: 1025px) {
                  font-size: 0.875rem;
                  gap: 3px;
                }

                .name {
                  display: inline;
                  color: var(--color-quaternary-l);
                  font-size: 1rem;
                  font-weight: 400;
                  // margin-inline-end: 5px;
                  // line-height: 1.25;

                  cursor: pointer;

                  &:focus-visible {
                    outline: 0;
                    border-radius: 2px;
                    box-shadow: 0 0 0 2px var(--color-focused);
                  }
                }

                .location-name {
                  display: inline-flex;
                  align-items: baseline;
                  cursor: pointer;
                  color: var(--color-quaternary-l);
                  font-weight: 400;
                  line-height: 1.35;

                  &:focus-visible {
                    outline: 0;
                    border-radius: 2px;
                    box-shadow: 0 0 0 2px var(--color-focused);
                  }

                  .location-icon {
                    display: inline-block;
                    margin-inline: 0px 3px;

                    flex-shrink: 0;
                  }
                }
              }

              .profile-details {
                display: flex;
                align-items: center;
                gap: 0.3rem;
                .profile-details-time {
                  color: var(--color-description);
                  font-size: 0.8rem;
                }
                .circle-image {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                }

                .name {
                  display: inline-block;
                  font-size: 1rem;
                  font-weight: 400;
                  color: var(--color-quaternary-l);
                  cursor: pointer;

                  &:focus-visible {
                    outline: 0;
                    border-radius: 2px;
                    box-shadow: 0 0 0 2px var(--color-focused);
                  }
                }
              }
            }

            .profile-cta {
              width: 36px;
              height: 36px;
              padding: 6px;
              border-radius: 50%;

              display: flex;
              justify-content: center;
              align-items: center;
              gap: 2px;

              cursor: pointer;

              &:hover,
              &:focus-visible {
                background-color: var(--color-selected-bg);
              }
            }
          }
        }
      }

      .commentView-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;

        .comment-section-wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;

          .filter-commment {
            .filter-options {
              position: relative;
              padding: 0px 0.9375rem;

              summary {
                background-color: transparent;
                color: var(--color-quaternary-l);
                font-size: 0.9375rem;

                display: inline-flex;
                align-items: center;
                gap: 0.625rem;

                position: relative;

                cursor: pointer;

                .dropdown-icon {
                  width: 13px;
                  height: 13px;
                  transition: all 0.3s ease-in-out;

                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              .filter-comment-list {
                position: absolute;
                top: 110%;
                left: 0.9375rem;
                background-color: var(--color-secondary);
                border-radius: 10px;
                filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
                display: none;
                animation: slideIn 0.2s linear;
                z-index: 10;

                .filter-option {
                  padding: 8px 12px;
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                  border-block-end: 0.5px solid var(--color-border);
                  cursor: pointer;
                  width: 150px;

                  &:last-child {
                    border-block-end: 0;
                  }

                  .group-check {
                    input[type="radio"] {
                      position: absolute;
                      opacity: 0;
                      height: 0;
                      width: 0;
                    }

                    .checkmark {
                      // display: inline-flex;
                      display: inline-block;

                      width: 0.9375rem;
                      height: 0.9375rem;
                      border: 1.25px solid var(--color-radio-border);
                      border-radius: 50%;
                      background-color: transparent;
                      vertical-align: middle;

                      transition: border 0.3s ease-out;
                      cursor: pointer;

                      position: relative;

                      &::after {
                        content: "";
                        display: block;
                        margin: auto;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: var(--color-primary-d);

                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.3s ease-out;

                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      }
                    }

                    input[type="radio"]:checked + .checkmark::after,
                    input[type="radio"]:focus + .checkmark::after {
                      opacity: 1;
                      visibility: visible;
                    }

                    input[type="radio"]:checked + .checkmark,
                    input[type="radio"]:focus + .checkmark {
                      border: 1.25px solid var(--color-primary-d);
                    }
                  }

                  .description {
                    color: var(--color-quaternary-l);
                    font-size: 0.8rem;
                  }
                }
              }

              &[open] {
                .dropdown-icon {
                  transform: rotate(180deg);
                }

                .filter-comment-list {
                  display: inline-block;
                }
              }
            }
          }

          .no-comments {
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .no-comment-image {
              width: 90px;
              height: 90px;
              border: 2.25px solid var(--color-quaternary-l);
              border-radius: 50%;
              display: grid;
              place-items: center;
              margin-block-end: 0.75rem;

              // img {
              //   width: 50%;
              // }
            }

            .text {
              color: var(--color-quaternary-l);
              font-size: 1rem;
              margin-block-end: 3px;
            }

            .info {
              color: var(--color-description);
              font-size: 0.7rem;
            }
          }

          .post-comments {
            height: 100%;
            padding: 0.9375rem;
            padding-block-start: 0.75rem;

            .no-data {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .commented-user {
              display: flex;
              flex-direction: column;
              margin-block-end: 1rem;

              .comment-user-profile {
                display: flex;
                gap: 0.75rem;
                align-items: flex-start;

                .profile-image {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                  overflow: hidden;
                  flex-shrink: 0;

                  &:focus-visible {
                    outline: 0;
                    border-radius: 50%;
                    box-shadow: 0 0 0 2px var(--color-focused);
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                .profile-info-wrapper {
                  flex: 1;
                  width: 100%;

                  .profile-info {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    border-radius: 10px;
                    padding: 8px;
                    background-color: var(--color-secondary);
                    margin-block-end: 6px;
                    box-shadow: 0px 0px 3.739px 0px rgba(0, 0, 0, 0.25);

                    .profile-name {
                      display: flex;
                      align-items: flex-start;
                      justify-content: space-between;

                      .name {
                        display: inline-block;
                        // color: var(--color-quaternary-l);
                        color: var(--color-quaternary-l);
                        font-size: 0.8rem;
                        font-weight: 500;

                        &:focus-visible {
                          outline: 0;
                          border-radius: 2px;
                          box-shadow: 0 0 0 2px var(--color-focused);
                        }
                      }

                      .commented-time {
                        display: inline-block;
                        color: var(--color-description);
                        font-size: 0.7rem;
                        font-weight: 40;
                        margin-block-start: 1px;
                      }
                    }

                    .commented-comment {
                      line-height: 1.25;
                      margin-block-end: 1px;
                      .comment {
                        display: inline-block;
                        font-size: 0.8rem;
                        // color: var(--color-quaternary-l);
                        color: var(--color-description);
                        line-height: 1.25;
                        word-break: break-all;
                        text-overflow: ellipsis;
                      }
                    }
                  }

                  .profile-cta {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-inline-start: 6px;

                    .profile-cta-likes {
                      display: flex;
                      align-items: center;
                      gap: 2px;

                      .likes-image {
                        // width: 0.9375rem;
                        // height: 0.9375rem;
                        // width: 1rem;
                        // height: 1rem;
                        // margin-block-start: -4px;
                        padding: 4px;
                        cursor: pointer;

                        img {
                          width: 1.25rem;
                          height: 1.25rem;
                          // object-fit: cover;
                        }

                        &:focus-visible {
                          outline: 0;
                          border-radius: 2px;
                          box-shadow: 0 0 0 2px var(--color-focused);
                        }
                        img.animate {
                          animation-name: likeAnimation;
                          animation-timing-function: ease-in-out;
                          animation-duration: 0.45s;
                        }
                      }

                      .comments-likes {
                        display: inline-block;
                        // color: var(--color-description);
                        color: var(--color-profile-description);
                        font-size: 0.8rem;
                        margin-block-start: 2px;
                        line-height: 1.25;
                      }
                    }

                    .comment-reply {
                      color: var(--color-primary-d);
                      font-size: 0.8rem;
                      padding-left: 10px;
                      line-height: 1;
                      // position: relative;
                      border-inline-start: 0.5px solid var(--color-description);
                      margin-block-start: 2px;
                      cursor: pointer;

                      &:focus-visible {
                        outline: 0;
                        border-radius: 2px;
                        box-shadow: 0 0 0 2px var(--color-focused);
                      }

                      // &::before {
                      //   content: "";
                      //   display: inline-block;
                      //   background-color: var(--color-description);
                      //   width: 0.5px;
                      //   height: 9px;
                      //   position: absolute;
                      //   top: 50%;
                      //   left: 0;
                      //   transform: translateY(-50%);
                      // }
                    }

                    .comment-cta,
                    .reply-cta {
                      padding: 4px;
                      height: 15px;
                      align-items: center;
                      display: none;

                      transition: display 0.3s ease-out;

                      cursor: pointer;

                      svg {
                        height: 100%;
                      }

                      &:focus-visible {
                        outline: 0;
                        border-radius: 2px;
                        box-shadow: 0 0 0 2px var(--color-focused);
                      }
                    }
                  }
                }

                &:focus-visible .profile-info-wrapper .profile-cta .comment-cta,
                &:hover .profile-info-wrapper .profile-cta .comment-cta,
                &:focus-visible .profile-info-wrapper .profile-cta .reply-cta,
                &:hover .profile-info-wrapper .profile-cta .reply-cta {
                  display: flex;
                }
              }

              .commented-replys {
                margin-inline-start: 50px;
                margin-block-start: 0.5rem;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .comment-user-profile {
                  .profile-info {
                    border: 0;
                    box-shadow: none;
                    padding: 0;
                    margin-block-end: 5px;
                    border-block-end: 1px solid var(--color-border);
                    padding-block-end: 8px;
                    border-radius: 0;
                    background-color: initial;
                    gap: 1px;

                    .commented-comment {
                      .replied-user-name {
                        color: var(--color-primary-d);
                        margin-inline-end: 4px;
                        cursor: pointer;

                        &:focus-visible {
                          outline: 0;
                          border-radius: 2px;
                          box-shadow: 0 0 0 2px var(--color-focused);
                        }
                      }
                    }
                  }

                  .profile-cta {
                    padding-inline-start: 0;
                  }
                }
              }

              .view-replys {
                display: inline-flex;
                align-items: center;
                gap: 5px;
                color: var(--color-description);
                font-size: 0.75rem;
                width: fit-content;
                margin-inline: auto;
                cursor: pointer;

                &::before {
                  content: "";
                  display: inline-block;
                  width: 25px;
                  height: 0.5px;
                  background-color: var(--color-description);
                  vertical-align: middle;
                }

                &:focus-visible {
                  outline: 0;
                  border-radius: 2px;
                  box-shadow: 0 0 0 2px var(--color-focused);
                }
              }

              // &:hover .comment-user-profile .profile-info-wrapper .profile-cta .profile-cta-likes .reply-cta {
              //   display: flex;
              // }
            }
          }
        }
      }

      .profilePostView-cta {
        padding-block-start: 0.625rem;
        border-block-start: 1px solid var(--color-border);

        .post-media-cta {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin-block: 6px 0;
          padding-block: 6px;
          padding-inline: 12px;

          .media-cta {
            display: flex;
            align-items: center;
            gap: 0.875rem;

            .btn-comment,
            .btn-reply {
              padding: 4px;
            }

            .btn-like {
              padding: 4px;

              img {
                width: 28px;
                height: 28px;
              }
            }

            .btn-like .animate {
              animation-name: likeAnimation;
              animation-timing-function: ease-in-out;
              animation-duration: 0.45s;
            }
          }

          .change-media {
            width: 60px;
            height: 12px;
            background-color: var(--color-primary-d);
          }

          .repost {
            display: flex;
            align-items: center;
            gap: 0.25rem;

            .btn-repost {
              padding: 4px;
            }

            .repost-number {
              display: inline-block;
              font-size: 0.9375rem;
              color: var(--color-description);
              line-height: 1;
              margin-block-start: 2px;
            }
          }
        }

        .post-report {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          padding-inline: 18px;

          .users-liked {
            display: flex;
            align-items: center;
            gap: 5px;
            // margin-block: 0.625rem 0.35rem;
            // margin-block: 0.45rem;
            margin-block: 0px 10px;

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }

            .users-profiles-wrapper {
              display: flex;
              align-items: center;

              .users-profile {
                width: 1.125rem;
                height: 1.125rem;
                border-radius: 50%;
                // outline-offset: 1px;
                outline: 1.5px solid var(--color-white);
                z-index: 10;
                overflow: hidden;

                &:not(:first-child) {
                  margin-inline-start: -5px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }

            .users-liked-info {
              color: var(--color-quaternary-l);
              font-weight: 300;
              font-size: 0.875rem;
              cursor: pointer;

              span {
                font-weight: 400;
              }
            }
          }

          .users-comments {
            line-height: 1;
            margin-block-end: 8px;
            cursor: pointer;

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }

            .view-all {
              display: inline-block;
              color: var(--color-description);
              font-size: 0.875rem;
              line-height: inherit;
            }
          }

          .add-comment-wrapper {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-block-end: 8px;

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }

            .comment-user-profile {
              width: 1.125rem;
              height: 1.125rem;
              border-radius: 50%;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .add-comment {
              cursor: pointer;

              .comment {
                display: inline-block;
                color: var(--color-placeholder);
                font-size: 0.875rem;
              }
            }
          }

          .why-seen-post {
            // margin-block-end: 0.625rem;

            cursor: pointer;

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }

            .text {
              color: var(--color-primary-d);
              font-size: 0.8rem;
            }
          }
        }

        .footer-box {
          display: flex;
          flex-direction: column;
          gap: 5px;
          margin: 0.9375rem;

          .replying-to-wrapper {
            .reply-to {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              padding-inline: 10px;

              .reply-to-name {
                color: var(--color-description);
                font-size: 0.7rem;

                .name {
                  color: var(--color-primary-d) !important;
                  padding-inline-start: 3px;
                  cursor: pointer;

                  &:active,
                  &:visited,
                  &:link {
                    color: inherit;
                  }
                }
              }

              .cancel-reply {
                width: fit-content;
                color: var(--color-description);
                font-size: 0.7rem;
                display: inline-flex;
                align-items: center;
                gap: 0.5rem;
                cursor: pointer;

                &::before {
                  content: "";
                  display: inline-block;
                  width: 2.5px;
                  height: 2.5px;
                  border-radius: 50%;
                  background-color: var(--color-description);
                  vertical-align: middle;
                }
              }
            }
          }

          .comment-to-post {
            display: flex;
            gap: 0.9375rem;
            align-items: center;

            .message-wrapper {
              flex: 1;
              border-radius: 5000px;
              border: 1px solid #8e8e93;
              font-size: 0.9375rem;
              padding: 10px;
              background-color: var(--color-secondary);
              display: flex;
              justify-content: space-between;
              min-width: 0;

              @media screen and (max-width: 1025px) {
                padding: 9px;
              }

              .input-wrapper {
                flex: 1;
                position: relative;
              }

              .messageInput {
                width: 100%;
                color: var(--color-quaternary-l);
                border: none;
                outline: none;
                padding-inline: 0.5rem;
                max-height: 3rem;
                overflow: hidden auto;
                white-space: pre-wrap;
                word-break: break-word;
                font-size: 0.9375rem;
                line-height: 1.25;

                @media screen and (max-width: 1025px) {
                  font-size: 0.875rem;
                }

                /* Hide the Placeholder */
                &:empty::before {
                  content: attr(placeHolder);
                  font-size: 0.875rem;
                  color: var(--color-placeholder);
                  cursor: text;
                }

                &:empty:focus ::before {
                  content: "";
                }
              }

              .placeHolder {
                position: absolute;
                inset: 0;
                display: inline-block;
                // color: #b3b3b3;
                color: var(--color-placeholder);
              }
            }

            .send-message {
              width: 36px;
              height: 36px;
              cursor: pointer;
              position: relative;

              svg {
                width: 100%;
                height: 100%;
              }

              .number {
                position: absolute;
                right: -3px;
                top: -3px;
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 50%;
                background-color: var(--color-secondary);
                font-size: 0.7rem;
                color: var(--color-quaternary);
                display: grid;
                place-items: center;
                cursor: default;
                box-shadow: 0 1px 3px rgba(11, 20, 26, 0.4);
              }
            }
          }
        }
      }
    }
  }

  .btn-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    padding: 4px;
    @media screen and (max-width: 769px) {
      top: 0.625rem;
    }
  }

  .reportPost .dialog .reportNudity-wrapper .reportNudity .heading .btn-close {
    position: static;
    padding: 0;
  }

  .btn-backwards,
  .btn-forward {
    display: inline-block;
    width: 45px;
    height: 45px;
    // background-color: var(--color-primary-d);
    background-color: rgb(0, 0, 0, 50%);
    // background-color: var(--color-primary-d);
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    display: grid;
    place-items: center;

    &:focus-visible {
      border-radius: 50%;
    }
  }

  .btn-backwards,
  .slick-prev {
    // left: 1.5rem;
    left: 0px;
  }

  .btn-forward,
  .slick-next {
    right: 1.5rem;
  }

  .postLikes {
    .btn-close {
      position: static;
    }
  }
  @keyframes likeAnimation {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
}
