.exceptContact {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 10;

  animation: slideInLeft 250ms ease forwards;

  .exceptContact-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    height: 100%;
    scrollbar-width: none;
    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;
      min-height: 54px;

      display: flex;
      justify-content: space-between;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }

      .done {
        cursor: pointer;
        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          line-height: 1.35;
        }
      }
    }

    .shareContact-wrapper {
      height: 100%;

      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .shareContact-selected {
        padding-inline-start: 0.9375rem;
        padding-block-start: 0.625rem;
        .selected-number {
          color: var(--color-description);
          font-size: 0.875rem;
        }
      }

      .shareContact-header {
        padding: 0.9375rem;
        padding-block: 0.625rem;

        box-shadow: 0px 2px 10.5px 0px #0000000d;

        .selected-contacts-wrapper {
          padding: 0.9375rem 0 0 0;

          .selected-contacts {
            display: flex;
            gap: 1rem;

            overflow-x: auto;
            padding-block-end: 5px;

            &::-webkit-scrollbar {
              width: 6px !important;
              height: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: var(--color-scroll-thumb);
              border-radius: 50px;
            }

            &::-webkit-scrollbar-track {
              background-color: var(--color-scroll-bg);
            }

            .selected-contact-wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 5px;
              flex-shrink: 0;

              .selected-contact {
                flex-shrink: 0;

                width: 50px;
                height: 50px;
                border-radius: 50%;
                overflow: visible;

                position: relative;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 50%;
                }

                .delete {
                  width: 1rem;
                  height: 1rem;
                  background-color: var(--color-white);
                  border-radius: 50%;
                  filter: drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.1));

                  display: flex;
                  justify-content: center;
                  align-items: center;

                  position: absolute;
                  bottom: 0;
                  right: 0;

                  cursor: pointer;

                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              .selected-contact-name {
                font-size: 0.7rem;
                color: var(--color-quaternary-l);
              }
            }

            .noselected {
              display: inline-block;
              color: var(--color-quaternary-l);
              font-size: 1rem;
              margin-inline-end: 5px;

              @media screen and (max-width: 1025px) {
                font-size: 0.9375rem;
              }
            }
          }
        }

        .search-bar {
          .search-bar-wrapper {
            // padding-inline: 6px;
            border-radius: 0.5rem;
            background-color: rgba(118, 118, 128, 0.12);
            height: 30px;
            // padding: 10px 6px;
            padding-inline: 6px;

            display: flex;
            align-items: center;
            gap: 10px;
            input {
              // color: rgba(60, 60, 67, 0.6);
              color: var(--color-quaternary-l);
              flex: 1;
              appearance: none;
              border: none;
              outline: none;
              background: transparent;
              font-size: 0.875rem;
              height: 100%;

              @media screen and (max-width: 1025px) {
                font-size: 0.8rem;
              }

              &::placeholder {
                color: var(--color-placeholder);
              }
            }
          }
        }
      }

      .shareContact-list {
        height: 100%;
        overflow-y: auto;

        display: flex;
        flex-direction: column;

        .profile {
          display: flex;
          padding-inline-start: 0.9375rem;
          align-items: center;
          height: 4.375rem;
          cursor: pointer;
          transition: 0.2s ease-out;

          //   border-block-end: 0.5px solid var(--color-border);

          &:hover,
          &:focus-visible {
            background: var(--color-selected-bg);
          }

          &:focus-visible {
            outline: 0;
          }

          .profile-image {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            margin-inline-end: 0.75rem;
            overflow: hidden;

            position: relative;

            // @media screen and (max-width: 1025px) {
            //   width: 2.875rem;
            //   height: 2.875rem;
            // }

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .profile-info-container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5px;

            padding-block: 0.8125rem;
            padding-inline-end: 0.9375rem;

            height: 100%;

            border-block-end: 0.5px solid var(--color-border);

            &:first-child {
              border-block-start: 0.5px solid var(--color-arrow);
            }

            .profile-info {
              display: flex;
              flex-direction: column;
              gap: 2px;

              flex: 1;
              cursor: pointer;

              .name {
                display: inline-block;
                font-size: 1rem;
                color: var(--color-quaternary-l);
                font-weight: 500;

                @media screen and (max-width: 1025px) {
                  font-size: 0.875rem;
                }
              }

              .profile-status-info {
                color: var(--color-description);
                font-size: 0.7rem;
                overflow: hidden;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }

            .group-check {
              input[type="checkbox"] {
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;
              }

              .checkmark {
                display: inline-flex;
                justify-content: center;
                align-items: center;

                width: 1.125rem;
                height: 1.125rem;
                border: 1.5px solid var(--color-radio-border);
                border-radius: 50%;
                background-color: transparent;

                vertical-align: middle;

                transition: border-color 0.3s, background-color 0.3s;
                cursor: pointer;

                &::after {
                  content: "";
                  display: inline-block;
                  width: 5.5px;
                  height: 10px;
                  border: solid transparent;
                  border-width: 0 1.75px 1.75px 0;
                  transform: rotate(45deg);
                  margin-block-start: -2px;

                  transition: border-color 0.3s;
                }
              }

              input[type="checkbox"]:checked + .checkmark {
                background-color: var(--color-primary-d);
                border: 1.5px solid var(--color-primary-d);
              }

              input[type="checkbox"]:checked + .checkmark::after {
                border-color: var(--color-white);
              }
            }
          }
        }
        .no-contact-waraper {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .no-contact {
            text-align: center;

            .no-contact-img {
              width: 100px;
              height: 100px;
              border: 2px solid var(--color-quaternary-l);
              border-radius: 50%;
              display: grid;
              place-items: center;
              margin-block-end: 0.625rem;
            }

            .text {
              color: var(--color-quaternary-l);
              font-size: 0.9375rem;
            }
          }
        }
      }
    }
  }

  .no-data {
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
