// ::-webkit-scrollbar {
//   width: 6px !important;
//   height: 6px !important;
//   background-color: transparent;
// }
// ::-webkit-scrollbar-thumb {
//   background-color: #d9d9d9;
//   border-radius: 5000px;
// }

// <-------------------------- Default Screen --------------------->
.default {
  .noPostContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;

    .noPost-image {
      width: 5.3125rem;
      height: 5.3125rem;

      svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      color: var(--color-quaternary-l);
      font-size: 1rem;
    }
  }
}

// <-------------------------- Tepears List --------------------->
.newTeapersList {
  display: flex;
  flex-direction: column;

  height: 100%;
  overflow-y: auto;

  .feed-post-container {
    display: flex;
    flex-direction: column;

    .new-post {
      display: flex;
      padding-inline-start: 0.9375rem;
      align-items: center;
      height: 4.375rem;

      border-block-end: 1px solid var(--color-border);

      cursor: pointer;
      transition: 0.2s ease-out;

      &:hover {
        background: var(--color-selected-bg);
      }

      &:focus-visible {
        outline: 0;
        // border-radius: 2px;
        // box-shadow: 0 0 0 2px var(--color-focused);
      }

      .feed-image,
      .post-image {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        background-color: transparent;

        margin-inline-end: 0.75rem;
      }

      .feed-image {
        // background: var(--color-selected-bg);
        svg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-block: 0.8125rem;
        padding-inline-end: 0.9375rem;
        color: var(--color-quaternary-l);

        height: 100%;
      }

      &:hover {
        background: var(--color-selected-bg);
      }
    }

    .your-feed {
      .text {
        border-block-end: 0.5px solid var(--color-border);
      }
    }

    .new-post {
      .post-image {
        background-color: var(--color-primary-d);
        border-radius: 50%;
        padding: 1px;

        overflow: hidden;

        display: grid;
        place-items: center;

        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }

    .my-status {
      display: flex;
      padding-inline-start: 0.9375rem;
      align-items: center;
      height: 4.375rem;
      cursor: pointer;

      .myStatus-profile-image {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        margin-inline-end: 0.75rem;

        overflow: hidden;
        position: relative;

        .profile-avatar {
          width: 42px;
          height: 42px;
          object-fit: cover;
          margin-block-start: 4px;
          margin-inline-start: 4px;
          border-radius: 50%;
          position: absolute;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .statusCircle {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
        }
      }

      .profile-info-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-block: 0.8125rem;
        padding-inline-end: 0.9375rem;
        border-block-end: 0.5px solid var(--color-border);

        height: 100%;

        &:first-child {
          border-block-start: 0.5px solid var(--color-arrow);
        }

        .profile-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          flex: 1;

          .name {
            font-size: 0.9375rem;
            color: var(--color-quaternary-l);
            line-height: 1.35;
          }

          .date {
            display: inline-block;
            color: var(--color-description);
            // color: #4a4a4a;
            font-size: 0.8rem;
          }
        }
      }

      &:focus-visible {
        outline: 0;
        // border-radius: 2px;
        // box-shadow: 0 0 0 2px var(--color-focused);
      }
    }
  }

  .users-tepears-list-container {
    height: 100%;
    overflow-y: auto;

    .search-text {
      display: inline-block;
      color: var(--color-primary-d);
      font-size: 0.75rem;
      width: 100%;
      padding: 0.875rem 0.9375rem;
      padding-block-end: 0;
      margin-block-end: 5px;
      text-transform: capitalize;
      font-size: 17px;
    }

    .profile {
      display: flex;
      padding-inline-start: 0.9375rem;
      align-items: center;
      height: 4.375rem;
      cursor: pointer;
      transition: 0.2s ease-out;

      &:hover,
      &:focus-visible {
        background: var(--color-selected-bg);
      }

      &:focus-visible {
        outline: 0;
      }

      .profile-image {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        margin-inline-end: 0.75rem;

        overflow: hidden;
        position: relative;

        .profile-avatar {
          width: 42px;
          height: 42px;
          object-fit: cover;
          margin-block-start: 4px;
          margin-inline-start: 4px;
          border-radius: 50%;
          position: absolute;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .statusCircle {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
        }
      }

      .profile-info-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-block: 0.8125rem;
        padding-inline-end: 0.9375rem;
        border-block-end: 0.5px solid var(--color-border);

        height: 100%;

        &:first-child {
          border-block-start: 0.5px solid var(--color-arrow);
        }

        .profile-info {
          display: flex;
          justify-content: space-between;
          gap: 3px;

          flex: 1 1;
          flex-direction: column;

          .name {
            font-size: 0.9375rem;
            color: var(--color-quaternary-l);
            font-weight: 500;
            line-height: 1.25;

            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }

        .status {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          min-width: fit-content;

          .date {
            display: inline-block;
            color: var(--color-description);
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .teppers-not-found-story {
    // height: 100%;
    height: 4rem;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 14px;
      color: var(--color-description);
    }
  }

  .teppers-not-found {
    // height: 100%;
    height: calc(100% - 40px);
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .search-text {
      display: inline-block;
      color: var(--color-description);
      font-size: 0.75rem;
      width: 100%;
      padding: 0.875rem 0.9375rem;
      padding-block-end: 0;

      margin-block-end: 5px;
    }

    .noPostContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;

      height: 100%;

      .noPost-image {
        width: 5.3125rem;
        height: 5.3125rem;
        border: 2px solid var(--color-quaternary-l);
        border-radius: 50%;
        display: grid;
        place-items: center;

        img {
          object-fit: cover;
        }
      }

      .text {
        color: var(--color-quaternary-l);
        font-size: 1rem;
      }
    }
  }
}

// <-------------------------- Upload Tepears --------------------->
.uploadTepars {
  background-color: hsl(0, 0%, 0%, 0.7);

  position: fixed;
  inset: 0;
  z-index: 60;

  animation: delay 0.2s ease-out forwards;
  transform-origin: center;

  display: grid;
  place-items: center;

  .uploadTepars-popup {
    // width: 43.75rem;
    width: auto;
    // height: 93vh;
    height: 562.5px;
    overflow: hidden;

    opacity: 0;
    scale: 0;

    background-color: var(--color-popup);
    border-radius: 1rem;

    animation: delay-dialog 0.2s ease-out forwards;
    transition: all 0.2s ease-out;

    display: flex;
    flex-direction: column;

    max-width: calc(100% - 3rem);

    @media screen and (max-width: 1025px) {
      height: 550px;
    }

    // @media (min-width: 1600px) {
    //   height: 562px;
    // }

    .header {
      padding: 0.4rem 0.9375rem;

      display: flex;
      align-items: center;

      border-block-end: 1px solid #dbdbdb;
      background-color: inherit;

      .btn-back,
      .btn-close {
        // width: 1.25rem;
        // height: 1.25rem;
        padding: 5px;

        flex-shrink: 0;

        // &:focus-visible {
        //   outline: 2px solid var(--color-focused);
        //   outline-offset: 2px;
        // }
      }

      .heading {
        flex: 1;

        .title {
          color: var(--color-quaternary-l);
          text-align: center;
          font-size: 1.25rem;
          font-weight: 400;
        }
      }

      .next {
        color: var(--color-primary-d);
        font-size: 1.25rem;

        cursor: pointer;

        &:focus-visible {
          outline: 0;
          border-radius: 2px;
          box-shadow: 0 0 0 2px var(--color-focused);
        }
      }
    }

    .container {
      overflow: hidden;
      // position: relative;
      // display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      transition: all 0.3s ease-out;

      .upload-image-container,
      .upload-media-container {
        // width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        flex-basis: 60%;
        background-color: #000;
        box-shadow: 2px 4px 6px 0px #00000033;

        transition: all 0.3s ease-out;

        .upload-image {
          // background-image: url("../image/lady.png");
          background-position: center center;
          background-repeat: no-repeat;
          // background-size: cover;
          background-size: contain;
          height: 100%;
          width: 100%;
          overflow: hidden;

          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;

          transition: all 0.2s ease-out;
        }

        .upload-video {
          height: 100%;
          width: 100%;

          object-fit: contain;
          cursor: pointer;
        }

        .video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;
          background-color: rgb(0, 0, 0, 0.5);
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            margin-inline-start: 3px;
          }
        }

        .image-number {
          position: absolute;
          top: 10px;
          right: 10px;
          border-radius: 500px;
          // background: hsl(227, 9%, 20%, 40%);
          background-color: var(--color-popup);
          display: grid;
          place-items: center;

          .number {
            display: inline-block;
            padding: 3px 0.8rem;
            font-size: 0.75rem;
            color: var(--color-offwhite);
          }
        }

        .image-sound {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          // background-color: var(--color-popup);
          // opacity: 0.5;
          background-color: #2e303850;

          position: absolute;
          bottom: 10px;
          right: 10px;

          display: grid;
          place-items: center;

          cursor: pointer;

          svg {
            width: 60%;
          }
        }

        .doubleTepAnimation {
          position: absolute;
          top: 0;
          left: 0;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          // transform: scale(0);

          z-index: 10;

          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          pointer-events: none;
          will-change: transform, opacity;

          animation: doubleTeps 1000ms ease-in-out forwards;
        }

        .swipe-btns {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          padding-inline: 0.9375rem;

          .swipe-backwards,
          .swipe-forwards {
            width: 2rem;
            height: 2rem;
            background-color: hsl(0, 0%, 0%, 0.7);
            border-radius: 50%;
            display: grid;
            place-items: center;
            position: absolute;
            right: 10px;

            img {
              // width: 1.25rem;
              width: 1rem;
            }
          }

          .swipe-backwards {
            position: absolute;
            left: 10px;
          }
        }

        .cta-wrapper {
          padding-inline: 0.9375rem;
          padding-block-end: 0.9375rem;

          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5rem;

          width: 100%;

          position: absolute;
          bottom: 0;
          z-index: 10;

          .crop-zoom {
            display: flex;
            align-items: center;
            gap: 1rem;

            .crop-wrapper,
            .zoom-wrapper {
              position: relative;

              transition: all 0.3s ease-out;

              .btn-crop,
              .btn-zoom {
                width: 2rem;
                height: 2rem;
                background-color: hsl(0, 0%, 0%, 0.7);
                border-radius: 50%;

                display: grid;
                place-items: center;

                transition: all 0.3s ease-out;

                &:hover,
                &:active {
                  background-color: var(--color-primary-d);
                }

                svg {
                  width: 1rem;
                }
              }

              .btn-crop.active,
              .btn-zoom.active {
                background-color: var(--color-primary-d);
              }
            }

            .wrapper-none {
              opacity: 0;
              pointer-events: none;
            }

            .crop-wrapper {
              .crop-cta {
                // display: none;
                width: 6.25rem;
                height: fit-content;
                background-color: hsl(0, 0%, 0%, 0.7);
                padding-block: 0.5rem;
                border-radius: 8px;

                position: absolute;
                top: -8rem;
                left: 0;

                transform-origin: left bottom;

                animation: openCropCta 250ms ease-out forwards;
                // transition: all 0.2s ease-out;

                .cta {
                  padding: 0.35rem 0.9375rem;

                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  transition: all 0.3s ease-out;
                  cursor: pointer;

                  opacity: 0.5;

                  .text {
                    color: var(--color-offwhite);
                    font-size: 0.7rem;
                  }

                  .cta-crop-image {
                    width: 1.25rem;
                    height: 1.25rem;

                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                .cta.active {
                  background-color: hsl(0, 0%, 62%, 0.7);
                  opacity: 1;
                }

                .cta.disactive {
                  animation: slideDown 0.2s ease-out;
                }
              }

              // .crop-cta.active {
              //   display: block;
              // }
            }

            .zoom-wrapper {
              position: relative;

              .slider-wrapper {
                display: none;
                background-color: hsl(0, 0%, 0%, 0.7);
                padding: 0.25rem 0.625rem;
                border-radius: 8px;
                width: 6.25rem;

                position: absolute;
                top: -3.25rem;
                left: -1rem;

                height: 32px;
                align-content: center;

                animation: slideUp2 0.2s ease-out;

                .slider {
                  -webkit-appearance: none;
                  appearance: none;
                  background: transparent;
                  cursor: pointer;
                  width: 100%;
                  vertical-align: middle;

                  &:focus {
                    outline: none;
                  }

                  /***** Chrome, Safari, Opera and Edge Chromium styles *****/
                  /* slider track */
                  &::-webkit-slider-runnable-track {
                    background-color: var(--color-white);

                    border-radius: 0.5rem;
                    height: 3px;
                  }

                  /* slider thumb */
                  &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    margin-top: -5px;

                    background-color: var(--color-primary);
                    width: 0.875rem;
                    height: 0.875rem;
                    border-radius: 50%;
                  }

                  /******** Firefox styles ********/
                  &::-moz-range-track {
                    background-color: var(--color-white);
                    border-radius: 0.5rem;
                    height: 3px;
                  }

                  &::-moz-range-thumb {
                    border: none;
                    border-radius: 0;

                    background-color: var(--color-primary);
                    width: 0.875rem;
                    height: 0.875rem;
                    border-radius: 50%;
                  }
                }
              }

              .slider-wrapper.active {
                display: block;
              }
            }
          }

          .add-tepars-container {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 25vw;

            overflow: hidden;

            .add-tepars {
              display: flex;
              gap: 5px;
              align-items: center;
              justify-content: center;

              width: 100%;

              ::-webkit-scrollbar {
                width: 6px !important;
                height: 6px !important;
                background-color: transparent;
              }

              ::-webkit-scrollbar-thumb {
                background-color: var(--color-scroll-thumb);
                border-radius: 5000px;
              }

              .media-wrapper {
                display: flex;
                align-items: center;
                overflow-x: auto;
                white-space: nowrap;
                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;

                // scrollbar-gutter: stable;

                .media {
                  width: 3.125rem;
                  height: 3.125rem;
                  margin: 5px 2px;
                  border: 2px solid transparent;
                  border-radius: 1px;
                  transition: all 0.3s ease;
                  position: relative;

                  .spinner {
                    width: 1.25rem;
                    height: 1.25rem;
                  }

                  &:focus,
                  &:hover {
                    outline: 0;

                    .mediaDelete {
                      display: grid;
                    }
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }

                  .mediaDelete {
                    width: 100%;
                    height: 50%;

                    background: linear-gradient(
                      210deg,
                      rgba(0, 0, 0, 0.3) -8.48%,
                      rgba(0, 0, 0, 0) 72.11%
                    );

                    position: absolute;
                    right: 0;
                    top: 0;

                    display: none;

                    place-items: center;
                    transition: all 0.3s;
                    overflow: hidden;

                    img {
                      width: 1.2rem;
                      height: 1.2rem;
                      position: absolute;
                      right: 3px;
                      top: 3px;
                      pointer-events: auto;
                    }
                  }
                }

                .media.active {
                  border: 2px solid var(--color-primary);
                  overflow: hidden;
                }

                .spinner-wrapper {
                  height: 100%;
                  display: flex;

                  .spinner {
                    margin: auto;
                  }
                }

                &:focus-visible {
                  outline: 2px solid var(--color-primary);
                  outline-offset: 2px;
                }
              }

              .Imagedots {
                height: 6px;
                width: 6px;
                background-color: var(--color-white);
                margin: 4px;
                border-radius: 50%;
                cursor: pointer;
              }

              .activedots {
                // border: #1790a3 1px solid;
                height: 8px;
                width: 8px;
                background-color: #1790a3;
              }

              .media,
              .upload {
                border-radius: 1px;
                flex-shrink: 0;
                overflow: hidden;
                cursor: pointer;
              }

              .upload {
                width: 2.1875rem;
                height: 3rem;
                background-color: transparent;
                border: 1.5px solid var(--color-primary-d);
                // margin-block-end: 5px;
                margin: 5px 2px;

                display: grid;
                place-items: center;

                align-self: start;

                svg {
                  width: 1.125rem;
                  height: 1.125rem;
                }

                input[type="file"] {
                  display: none;
                }
              }
            }
          }

          .hidden-crop-zoom {
            min-width: 5rem;
            visibility: hidden;
            opacity: 0;
          }
        }

        .gradient {
          // border-radius: 0px 0px 20px 20px;
          opacity: 0.5;
          background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
          // border-bottom-left-radius: 1rem;
          // border-bottom-right-radius: 1rem;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 8.125rem;
        }

        .slick-dots {
          bottom: 15px;
        }
      }

      .upload-image-container {
        width: 450px;

        @media screen and (max-width: 1025px) {
          width: 325px;
        }
      }

      .filters {
        // flex-basis: 40%;
        // width: 40%;
        // height: 100%;

        // @media screen and (max-width: 1025px) {
        //   width: 40%;
        // }

        height: 100%;
        width: 400px;
        min-width: 400px;
        max-width: 500px;

        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1025px) {
          width: 350px;
          min-width: 350px;
        }

        .header {
          padding: 0.4rem 0.9375rem;

          display: flex;
          align-items: center;

          border-block-end: 1px solid var(--color-border);
          background-color: inherit;

          .btn-back,
          .btn-close {
            // width: 1.25rem;
            // height: 1.25rem;
            padding: 5px;

            flex-shrink: 0;

            // &:focus-visible {
            //   outline: 2px solid var(--color-focused);
            //   outline-offset: 2px;
            // }
          }

          .heading {
            flex: 1;

            .title {
              color: var(--color-quaternary-l);
              text-align: center;
              font-size: 1.25rem;
              font-weight: 400;
            }
          }

          .next {
            color: var(--color-primary-d);
            font-size: 1.25rem;

            cursor: pointer;

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }
          }
        }

        .privacy {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          background-color: var(--color-secondary);

          .privacy-wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;

            .tepars-profile {
              padding: 0.9375rem;
              padding-block-end: 0.625rem;

              .profile-details {
                display: flex;
                gap: 0.5rem;
                align-items: center;

                margin-block-end: 0.9375rem;

                .profile-image {
                  width: 50px;
                  height: 50px;
                  // background-color: var(--color-primary-d);
                  border-radius: 50%;
                  overflow: hidden;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                .profile-info {
                  flex: 1;

                  display: flex;
                  flex-direction: column;
                  gap: 2px;

                  .name {
                    color: var(--color-quaternary-l);
                    font-size: 0.9375rem;
                    font-weight: 500;
                  }

                  .btn-location {
                    padding: 2px 4px;
                    background-color: var(--color-location-bg);
                    width: fit-content;
                    border-radius: 3px;
                    cursor: pointer;
                    display: flex;
                    gap: 2px;
                    align-items: center;

                    .text {
                      color: var(--color-primary-d);
                      font-size: 0.8rem;
                    }
                  }
                }
              }

              // .profile-caption {
              //   .caption {
              //     width: 100%;
              //     resize: none;
              //     border: 0;
              //     outline: 0;
              //     // border: 1px solid black;

              //     padding: 5px;
              //     color: var(--color-white);
              //     font-size: 0.875rem;
              //     line-height: 1.25;

              //     background-color: transparent;

              //     &::placeholder {
              //       color: var(--color-description);
              //       font-size: 0.8rem;
              //     }
              //   }
              // }

              .profile-caption {
                display: flex;
                align-items: flex-start;
                gap: 6px;

                .caption {
                  width: 100%;
                  resize: none;
                  border: 0;
                  outline: 0;
                  // border: 1px solid black;
                  padding: 5px;
                  color: var(--color-quaternary-l);
                  font-size: 0.875rem;
                  line-height: 1.25;
                  background-color: transparent;

                  &::placeholder {
                    color: var(--color-placeholder);
                    font-size: 0.875rem;
                  }
                }

                .profile-emoji {
                  width: 1rem;
                  height: 1rem;
                  padding-block-start: 5px;
                  cursor: pointer;
                }
              }
            }

            .pointer-none {
              opacity: 0.5;
              pointer-events: none;
            }

            .post-visible {
              .title {
                color: var(--color-quaternary-l);
                font-size: 1.125rem;
                font-weight: 600;
                margin-block-end: 8px;
                padding-inline: 0.9375rem;
                line-height: 1.35;

                @media screen and (max-width: 1025px) {
                  font-size: 1rem;
                }
              }

              .sub-info {
                color: var(--color-description);
                font-size: 0.9375rem;
                line-height: normal;
                padding-inline: 0.9375rem;
                padding-block-end: 5px;

                @media screen and (max-width: 1025px) {
                  font-size: 0.875rem;
                }
              }

              .visible-to-contacts {
                .contact-select,
                .onlyme-select {
                  padding: 0.9375rem;
                  padding-block-end: 0.75rem;

                  display: flex;
                  align-items: center;
                  gap: 0.9375rem;

                  .contact-select-info,
                  .onlyme-select-info {
                    flex: 1;

                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .wrapper {
                      flex: 1;

                      cursor: pointer;

                      display: flex;
                      flex-direction: column;
                      gap: 1px;

                      .title {
                        font-size: 0.875rem;
                        font-weight: 500;
                        margin-block-end: 0;
                        line-height: revert;
                        padding-inline: 0;

                        @media screen and (max-width: 1025px) {
                          font-size: 0.8rem;
                        }
                      }

                      .sub-text {
                        color: var(--color-description);
                        font-size: 0.8rem;
                        // line-height: 1.35;

                        @media screen and (max-width: 1025px) {
                          font-size: 0.75rem;
                        }
                      }
                    }

                    .btn-more {
                      width: 0.625rem;
                      height: 1rem;

                      cursor: pointer;

                      svg {
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                }

                .contact-select {
                  border-block-end: 0.5px solid var(--color-border);
                }
              }
            }

            .share-group-wrapper,
            .visibility-group-wrapper,
            .privacy-settings {
              padding-inline: 0.9375rem;

              .share-group,
              .visibility-group,
              .privacy-settings-info {
                background-color: #f7f7f7;
                border-radius: 6px;
                padding: 0.75rem 0.625rem;

                margin-block-start: 5px;

                @media screen and (max-width: 1025px) {
                  padding: 0.625rem;
                }

                .text {
                  // display: inline-block;
                  color: var(--color-description);
                  font-size: 0.875rem;
                  line-height: 1.2;

                  @media screen and (max-width: 1025px) {
                    font-size: 0.8rem;
                  }
                }
              }

              .reposting-settings,
              .comment-settings,
              .replyPost-settings,
              .sharePost-settings {
                flex: 1;

                display: flex;
                align-items: center;
                gap: 10px;

                padding: 0.9375rem 0px;
                padding-block-end: 0.75rem;

                .reposting-info,
                .comment-info,
                .replyPost-info,
                .sharePost-info {
                  flex: 1;

                  cursor: pointer;

                  display: flex;
                  flex-direction: column;

                  .title {
                    color: var(--color-quaternary-l);
                    font-size: 0.875rem;
                    font-weight: 500;
                    margin-block-end: 0;

                    padding-inline: 0;

                    @media screen and (max-width: 1025px) {
                      font-size: 0.8rem;
                    }
                  }

                  .sub-text {
                    color: var(--color-description);
                    font-size: 0.8rem;

                    @media screen and (max-width: 1025px) {
                      font-size: 0.75rem;
                    }
                  }
                }
              }

              .group-select,
              .visibility-select {
                padding: 0.9375rem 0px;
                padding-block-end: 0.75rem;

                display: flex;
                align-items: center;
                gap: 0.9375rem;

                .group-select-info,
                .visibility-select-info {
                  flex: 1;

                  display: flex;
                  align-items: center;
                  gap: 10px;

                  .wrapper {
                    flex: 1;

                    cursor: pointer;

                    display: flex;
                    flex-direction: column;
                    gap: 1px;

                    .title {
                      font-size: 0.875rem;
                      font-weight: 500;
                      margin-block-end: 0;
                      color: var(--color-quaternary-l);

                      padding-inline: 0;
                    }

                    .sub-text {
                      color: var(--color-description);
                      font-size: 0.8rem;
                    }
                  }

                  .btn-more {
                    width: 0.625rem;
                    height: 1rem;

                    cursor: pointer;

                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }

            .visibility-select-info {
              margin-inline-start: 2rem;
            }

            .privacy-settings {
              padding-inline: 0px;

              .privacy-settings-info {
                margin-inline: 0.9375rem;
              }

              .reposting-settings,
              .comment-settings,
              .replyPost-settings,
              .sharePost-settings {
                padding-inline: 3rem 0.9375rem;
                border-block-end: 0.5px solid var(--color-border);

                @media screen and (max-width: 1025px) {
                  padding-inline: 2.5rem 0.9375rem;
                }
              }

              .note {
                margin-block: 0.9375rem;
              }
            }
          }

          .profile-emoji-popup {
            height: 500px;
            background-color: var(--color-secondary);
          }

          .group-check {
            margin-block-start: -2px;
            input[type="radio"] {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;
            }

            .checkmark {
              display: inline-flex;
              align-items: center;
              justify-content: center;

              position: relative;

              min-width: 20px;
              width: 20px;
              height: 20px;
              vertical-align: middle;

              &:focus-visible {
                outline: 0;
                border-radius: 2px;
                box-shadow: 0 0 0 2px var(--color-focused);
              }

              .radio-cmn {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;
                border-radius: 50%;

                line-height: 0;
                white-space: nowrap;

                cursor: pointer;
              }

              .offRadio {
                border: 1.5px solid var(--color-description);
                background-color: transparent;

                transition: border 250ms ease;
              }

              .onRadio {
                will-change: transform;
                background-color: var(--color-primary-d);

                scale: 0;
                transition: scale 250ms ease;
              }
            }

            input[type="radio"]:checked + .checkmark .onRadio,
            input[type="radio"]:focus + .checkmark .onRadio {
              scale: 0.6;
            }
            input[type="radio"]:checked + .checkmark .offRadio,
            input[type="radio"]:focus + .checkmark .offRadio {
              border-color: var(--color-primary-d);
            }
          }

          .toggle-switch {
            .off {
              width: 38px;
              height: 22px;
              background-color: var(--color-description);
              border-radius: 25px;
              padding: 2px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
              transition: background-color 0.3s;

              @media screen and (max-width: 1025px) {
                width: 32px;
                height: 18px;
              }

              .on {
                width: 15px;
                height: 15px;
                background-color: var(--color-white);
                border-radius: 50%;
                margin: 2px;
                transition: background-color 0.3s, transform 0.3s;
                transform: translateX(0);

                @media screen and (max-width: 1025px) {
                  width: 12px;
                  height: 12px;
                }
              }

              &.toggled .on {
                transform: translateX(100%);
              }

              &.toggled {
                background-color: var(--color-primary-d);
              }
            }
          }
        }

        .filters-wrapper {
          width: 100%;
          height: 100%;
          overflow-y: auto;
        }
      }

      .uploadTepars-loading {
        background-color: hsl(0, 0%, 0%, 0.7);

        position: absolute;
        inset: 0;
        z-index: 80;

        display: grid;
        place-content: center;
      }
    }
  }
}

// <-------------------------- Who can see your privacy post --------------------->
.seePost {
  padding-block-start: 0.9375rem;

  .post-visible {
    .title {
      color: var(--color-quaternary-l);
      font-size: 1.125rem;
      font-weight: 600;
      margin-block-end: 8px;
      padding-inline: 0.9375rem;

      @media screen and (max-width: 1025px) {
        font-size: 1rem;
      }
    }

    .sub-info {
      color: var(--color-description);
      font-size: 0.9375rem;
      line-height: normal;
      padding-inline: 0.9375rem;
      padding-block-end: 5px;

      @media screen and (max-width: 1025px) {
        font-size: 0.875rem;
      }
    }

    .visible-to-contacts {
      .contact-select {
        padding: 0.9375rem;
        padding-block-end: 0.75rem;
        display: flex;
        align-items: center;
        gap: 0.9375rem;

        .group-check {
          margin-block-start: -2px;
          input[type="radio"] {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }

          .checkmark {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            position: relative;

            min-width: 20px;
            width: 20px;
            height: 20px;
            vertical-align: middle;

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }

            .radio-cmn {
              position: absolute;
              top: 0;
              left: 0;

              width: 100%;
              height: 100%;
              border-radius: 50%;

              line-height: 0;
              white-space: nowrap;

              cursor: pointer;
            }

            .offRadio {
              border: 1.5px solid var(--color-description);
              background-color: transparent;

              transition: border 250ms ease;
            }

            .onRadio {
              will-change: transform;
              background-color: var(--color-primary-d);

              scale: 0;
              transition: scale 250ms ease;
            }
          }

          input[type="radio"]:checked + .checkmark .onRadio,
          input[type="radio"]:focus + .checkmark .onRadio {
            scale: 0.6;
          }
          input[type="radio"]:checked + .checkmark .offRadio,
          input[type="radio"]:focus + .checkmark .offRadio {
            border-color: var(--color-primary-d);
          }
        }

        .contact-select-info {
          flex: 1;

          display: flex;
          align-items: center;
          gap: 10px;

          .wrapper {
            flex: 1;

            cursor: pointer;

            display: flex;
            flex-direction: column;

            .title {
              font-size: 0.875rem;
              font-weight: 500;
              margin-block-end: 0;

              padding-inline: 0;

              @media screen and (max-width: 1025px) {
                font-size: 0.8rem;
              }
            }

            .sub-text {
              color: var(--color-description);
              font-size: 0.8rem;

              @media screen and (max-width: 1025px) {
                font-size: 0.75rem;
              }
            }
          }

          .excluded {
            display: flex;
            align-items: center;
            gap: 7px;
            cursor: pointer;

            .excluded-info {
              color: var(--color-primary-d);
              font-size: 0.75rem;

              @media screen and (max-width: 1025px) {
                font-size: 0.7rem;
              }
            }

            .btn-more {
              width: 0.625rem;
              height: 1rem;

              cursor: pointer;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .contact-select:not(:last-child) {
        border-block-end: 0.25px solid var(--color-border);
      }
    }

    .seePost-info {
      background-color: #f7f7f7;
      border-radius: 6px;
      padding: 0.75rem 0.625rem;
      margin-block-start: 0px;
      margin-inline: 0.9375rem;

      .info {
        color: var(--color-description);
        font-size: 0.875rem;
        line-height: 1.2;
      }
    }
  }
}

// <-------------------------- Tepars Contact --------------------->
.shareContact {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .shareContact-header {
    padding: 0.9375rem;
    // padding-block-end: 0.625rem;
    border-block-end: 0.5px solid var(--color-border);

    .search-bar {
      .search-bar-wrapper {
        // padding-inline: 6px;
        border-radius: 0.5rem;
        background-color: rgba(118, 118, 128, 0.12);
        height: 30px;
        // padding: 10px 6px;
        padding-inline: 6px;

        display: flex;
        align-items: center;
        gap: 10px;

        input {
          // color: rgba(60, 60, 67, 0.6);
          color: var(--color-quaternary-l);
          flex: 1;
          appearance: none;
          border: none;
          outline: none;
          background: transparent;
          font-size: 0.875rem;
          height: 100%;

          @media screen and (max-width: 1025px) {
            font-size: 0.8rem;
          }

          &::placeholder {
            color: var(--color-placeholder);
          }
        }
      }
    }

    .selected-contacts-wrapper {
      padding: 0.9375rem 0 0 0;

      .selected-contacts {
        display: flex;
        gap: 1rem;

        overflow-x: auto;
        padding-block-end: 5px;

        &::-webkit-scrollbar {
          width: 6px !important;
          height: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--color-scroll-thumb);
          border-radius: 50px;
        }

        &::-webkit-scrollbar-track {
          background-color: var(--color-scroll-bg);
        }

        .selected-contact-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          flex-shrink: 0;

          .selected-contact {
            flex-shrink: 0;

            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: visible;

            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }

            .delete {
              width: 1rem;
              height: 1rem;
              background-color: var(--color-white);
              border-radius: 50%;
              filter: drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.1));

              display: flex;
              justify-content: center;
              align-items: center;

              position: absolute;
              bottom: 0;
              right: 0;

              cursor: pointer;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }

          .selected-contact-name {
            font-size: 0.7rem;
            color: var(--color-quaternary-l);
          }
        }

        .noselected {
          display: inline-block;
          color: var(--color-quaternary-l);
          font-size: 1rem;
          margin-inline-end: 5px;

          @media screen and (max-width: 1025px) {
            font-size: 0.9375rem;
          }
        }
      }
    }
  }

  .shareContact-list {
    height: 100%;
    overflow-y: auto;

    .no-data {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .profile {
      display: flex;
      padding-inline-start: 0.9375rem;
      align-items: center;
      height: 4.375rem;
      cursor: pointer;
      transition: 0.2s ease-out;

      border-block-end: 0.5px solid var(--color-border);

      &:hover,
      &:focus-visible {
        background: var(--color-selected-bg);
      }

      &:focus-visible {
        outline: 0;
      }

      .profile-image {
        width: 3.125rem;
        height: 3.125rem;
        // background-color: var(--color-primary-d);
        border-radius: 50%;
        // padding: 1px;
        margin-inline-end: 0.75rem;
        overflow: hidden;

        flex-shrink: 0;
        position: relative;

        @media screen and (max-width: 1025px) {
          width: 2.875rem;
          height: 2.875rem;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .profile-info-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding-block: 0.8125rem;
        padding-inline-end: 0.9375rem;

        height: 100%;

        // &:first-child {
        //   border-block-start: 0.5px solid var(--color-arrow);
        // }

        .profile-info {
          display: flex;
          flex-direction: column;

          flex: 1;

          .name {
            display: inline-block;
            font-size: 0.9375rem;
            color: var(--color-quaternary-l);
            font-weight: 500;

            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            @media screen and (max-width: 1025px) {
              font-size: 0.875rem;
            }
          }

          .profile-status-info {
            color: var(--color-description);
            font-size: 0.8rem;

            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .group-check {
          margin-block-start: -2px;
          input[type="radio"] {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }

          .checkmark {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            position: relative;

            min-width: 20px;
            width: 20px;
            height: 20px;
            vertical-align: middle;

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }

            .radio-cmn {
              position: absolute;
              top: 0;
              left: 0;

              width: 100%;
              height: 100%;
              border-radius: 50%;

              line-height: 0;
              white-space: nowrap;

              cursor: pointer;
            }

            .offRadio {
              border: 1.5px solid var(--color-description);
              background-color: transparent;

              transition: border 250ms ease;
            }

            .onRadio {
              will-change: transform;
              background-color: var(--color-primary-d);

              scale: 0;
              transition: scale 250ms ease;
            }
          }

          input[type="radio"]:checked + .checkmark .onRadio,
          input[type="radio"]:focus + .checkmark .onRadio {
            scale: 0.6;
          }
          input[type="radio"]:checked + .checkmark .offRadio,
          input[type="radio"]:focus + .checkmark .offRadio {
            border-color: var(--color-primary-d);
          }
        }
      }
    }

    .no-contact-waraper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .no-contact {
        text-align: center;

        .no-contact-img {
          width: 100px;
          height: 100px;
          border: 2px solid var(--color-quaternary-l);
          border-radius: 50%;
          display: grid;
          place-items: center;
          margin-block-end: 0.625rem;
        }

        .text {
          color: var(--color-quaternary-l);
          font-size: 0.9375rem;
        }
      }
    }

    .no-group-waraper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .no-group {
        text-align: center;

        .no-group-img {
          width: 100px;
          height: 100px;
          border: 2px solid var(--color-quaternary-l);
          border-radius: 50%;
          display: grid;
          place-items: center;
          margin-block-end: 0.625rem;
        }

        .text {
          color: var(--color-quaternary-l);
          font-size: 0.9375rem;
        }
      }
    }
  }
}

// <-------------------------- Post Visibility --------------------->
.postVisibility {
  // background-color: orangered;
  padding: 0.9375rem;

  .heading {
    color: var(--color-quaternary-l);
    font-size: 1.25rem;
    font-weight: 400;

    margin-block-end: 0.875rem;

    @media screen and (max-width: 1025px) {
      font-size: 1.125rem;
    }
  }

  .visibility-cta {
    display: flex;
    flex-direction: column;
    gap: 1.7rem;

    .month {
      display: flex;
      align-items: center;
      gap: 1rem;

      .group-check {
        margin-block-start: -2px;
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;
        }

        .checkmark {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          position: relative;

          min-width: 20px;
          width: 20px;
          height: 20px;
          vertical-align: middle;

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          .radio-cmn {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            border-radius: 50%;

            line-height: 0;
            white-space: nowrap;

            cursor: pointer;
          }

          .offRadio {
            border: 1.5px solid var(--color-description);
            background-color: transparent;

            transition: border 250ms ease;
          }

          .onRadio {
            will-change: transform;
            background-color: var(--color-primary-d);

            scale: 0;
            transition: scale 250ms ease;
          }
        }

        input[type="radio"]:checked + .checkmark .onRadio,
        input[type="radio"]:focus + .checkmark .onRadio {
          scale: 0.6;
        }
        input[type="radio"]:checked + .checkmark .offRadio,
        input[type="radio"]:focus + .checkmark .offRadio {
          border-color: var(--color-primary-d);
        }
      }

      .text {
        color: var(--color-quaternary-l);
        font-size: 1rem;

        flex: 1;
        line-height: 25px;
        cursor: pointer;

        @media screen and (max-width: 1025px) {
          font-size: 0.9375rem;
        }
      }
    }
  }
}

// <-------------------------- Location --------------------->
.location {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .location-header {
    padding: 0.9375rem;
    padding-block-end: 0.875rem;
    border-block-end: 0.5px solid var(--color-border);

    &:has(.turnOn-location-wrapper, + .selected.location-list-wrapper) {
      border-block-end: 0;
    }

    .search-bar {
      .search-bar-wrapper {
        // padding-inline: 6px;
        border-radius: 0.5rem;
        background-color: rgba(118, 118, 128, 0.12);
        height: 30px;
        // padding: 10px 6px;
        padding-inline: 6px;

        display: flex;
        align-items: center;
        gap: 10px;

        input {
          color: var(--color-quaternary-l);
          flex: 1;
          appearance: none;
          border: none;
          outline: none;
          background: transparent;
          font-size: 0.875rem;
          height: 100%;

          @media screen and (max-width: 1025px) {
            font-size: 0.8rem;
          }

          &::placeholder {
            color: var(--color-placeholder);
          }
        }
      }
    }

    .turnOn-location-wrapper {
      width: 100%;
      margin-block-start: 1.75rem;

      .turnOn-location {
        width: 80%;
        margin-inline: auto;

        display: flex;
        flex-direction: column;
        align-items: center;

        .earth-image {
          width: 200px;

          margin-block-end: 1rem;
        }

        .text {
          color: var(--color-quaternary-l);
          font-size: 0.875rem;
          font-weight: 500;

          margin-block-end: 0.25rem;
        }

        .info {
          color: var(--color-description);
          font-size: 0.75rem;
          text-align: center;

          margin-block-end: 0.625rem;
        }

        .btn-turnOn {
          background-color: #1790a3;
          font-size: 0.875rem;
          color: var(--color-offwhite);
          padding: 0.4rem 0.875rem;
          border-radius: 5px;
          letter-spacing: 0.25px;

          cursor: pointer;
        }
      }
    }

    // .location-list {
    //   padding: 0.9375rem 0 0 0;

    //   // .selected-contacts {
    //   //   display: flex;
    //   //   gap: 1rem;

    //   //   overflow-x: auto;
    //   //   padding-block-end: 5px;

    //   //   .selected-contact {
    //   //     flex-shrink: 0;

    //   //     width: 50px;
    //   //     height: 50px;
    //   //     border-radius: 50%;
    //   //     overflow: visible;

    //   //     position: relative;

    //   //     img {
    //   //       width: 100%;
    //   //       height: 100%;
    //   //       object-fit: cover;
    //   //     }

    //   //     .delete {
    //   //       width: 1rem;
    //   //       height: 1rem;
    //   //       background-color: var(--color-secondary);
    //   //       border-radius: 50%;
    //   //       filter: drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.1));

    //   //       display: grid;
    //   //       place-items: center;

    //   //       position: absolute;
    //   //       bottom: 0;
    //   //       right: 0;

    //   //       cursor: pointer;

    //   //       img {
    //   //         width: 80%;
    //   //       }
    //   //     }
    //   //   }
    //   // }
    // }
  }

  .location-list-wrapper {
    height: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;

    .location-places {
      display: inline-block;
      font-size: 0.8rem;
      color: var(--color-quaternary-l);
      font-weight: 500;
      padding-block-start: 0.9375rem;
      padding-inline-start: 0.9375rem;
    }

    .location-list {
      // height: 100%;
      overflow-y: auto;

      .location-heading {
        padding-block: 0.25rem 0.125rem;
        padding-inline-start: 0.9375rem;
        color: var(--color-quaternary-l);
        font-size: 0.9375rem;
      }

      .location {
        display: flex;
        flex-direction: row;
        padding-inline-start: 0.9375rem;
        align-items: center;
        height: 4.375rem;
        cursor: pointer;
        transition: 0.2s ease-out;

        &:hover {
          background: var(--color-selected-bg);
        }

        .location-image {
          width: 2.5rem;
          height: 2.5rem;
          background-color: #edecec;
          border-radius: 50%;
          // padding: 1px;
          margin-inline-end: 0.625rem;
          overflow: hidden;

          display: grid;
          place-items: center;

          img {
            width: 60%;
            height: 60%;
            // border-radius: 50%;
            object-fit: cover;
          }
        }

        .location-info-container {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-block: 0.8125rem;
          padding-inline-end: 0.9375rem;

          height: 100%;

          border-block-end: 0.5px solid var(--color-border);

          // &:first-child {
          //   border-block-start: 0.5px solid var(--color-description);
          // }

          .location-info {
            display: flex;
            flex-direction: column;

            flex: 1;

            .name {
              display: inline-block;
              font-size: 0.875rem;
              color: var(--color-quaternary-l);
              font-weight: 500;

              overflow: hidden;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }

            .location-status-info {
              color: #454545;
              font-size: 0.7rem;
              line-height: 1.35;

              overflow: hidden;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .location-meters {
              display: inline-block;
              color: var(--color-description);
              font-size: 0.625rem;
            }
          }

          .btn-locationCancel {
            border-radius: 50%;
            background-color: #2a2a2a;
            padding: 8px;
          }
        }
      }

      .selected.location {
        border-block-end: 0.5px solid var(--color-border);

        .location-info-container {
          border-block-end: 0;
          gap: 0.625rem;
        }
      }

      .no-location {
        margin-block-start: 2.5rem;
        margin-inline: auto;
        width: 100%;
        font-size: 0.9375rem;
        color: var(--color-description);
        display: inline-block;
        text-align: center;
      }
    }
  }

  .selected.location-list-wrapper {
    height: auto;
    overflow-y: revert;
    display: block;
  }

  @keyframes delay {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }

    100% {
      opacity: 1;
      scale: 1;
    }
  }

  @keyframes openCropCta {
    0% {
      opacity: 0;
      scale: 0;
    }

    100% {
      opacity: 1;
      scale: 1;
    }
  }

  @keyframes slideUp2 {
    0% {
      opacity: 0;
      transform: translateY(16px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideDown {
    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  }
}

.upload-media-container,
.upload-image-container {
  flex-basis: revert;

  .slick-slider.slick-initialized {
    position: absolute;
    // width: 100%;
    // height: 100%;
    inset: 0;

    .slick-list {
      // width: 100%;
      height: 100%;

      .slick-track {
        height: 100%;

        .slick-slide {
          height: 100%;

          .next-img {
            height: 100%;
          }

          .slick-slide.slick-cloned > * {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .slick-prev {
      // left: 20px;
      left: 0px;
    }

    .slick-next {
      right: 25px;
    }
  }
}

.crop-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.slick-initialized .slick-slide > * {
  width: 100%;
  height: 100%;

  & > * {
    width: 100%;
    height: 100%;
  }
}

.upload-image > * {
  width: 100%;
  height: 100%;
}

.slick-track {
  transition: all 250ms ease;
}
