.mediaLinksDocs {
  background-color: var(--color-secondary);
  height: 100%;
  // overflow-y: auto;

  display: flex;
  flex-direction: column;
  .header {
    background-color: var(--color-primary);
    height: 3.75rem;
    padding-inline: 0.9375rem;

    display: flex;
    align-items: center;
    gap: 0.7rem;

    .title {
      color: var(--color-offwhite);
      font-size: 1.2rem;
      font-weight: 500;
    }

    .btn-back {
      // width: 1rem;
      // height: 1rem;
      padding: 4px;

      // svg {
      //   width: 100%;
      //   height: 100%;
      // }
    }
  }

  .mediaLinksDocs-btn {
    // padding-block-start: 0.9375rem;

    display: flex;
    justify-content: center;
    align-items: center;

    .btns-wrapper {
      border-block-end: 2.25px solid var(--color-border);

      display: flex;

      flex: 1;
      position: relative;

      & > * {
        flex-basis: 33%;
      }

      .links,
      .docs,
      .media {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        padding: 12px 13px;
        color: var(--color-description);
        font-size: 1.05rem;

        cursor: pointer;

        position: relative;

        transition: all 0.3s ease;
        // animation: slideInRight 2s linear;

        .media-image,
        .docs-image,
        .link-image {
          width: 18px;
          height: 18px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .active {
        color: var(--color-primary-d);
      }

      .slider {
        position: absolute;
        bottom: 0;
        height: 2.5px;
        background-color: var(--color-primary-d);

        box-shadow: 0px 0px 5px 0px rgb(0, 0, 0, 0.15);
        transition: all 250ms ease;
      }
    }
  }

  .medias-wrapper {
    height: 100%;
    flex: 1;
    overflow-y: auto;

    padding-block-start: 5px;
    .medias {
      padding-inline: 0.9875rem;
      padding-block: 0 10px;
      .date {
        display: inline-block;
        color: var(--color-description);
        font-size: 0.75rem;

        margin-block-end: 0.35rem;
      }
      .media-container {
        display: flex;
        flex-wrap: wrap;
        gap: 2px;
        .media {
          width: 93px;
          height: 93px;

          cursor: pointer;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .group-check {
            position: absolute;
            top: 4px;
            right: 4px;
          }
        }
      }

      // <--------------------working2---------------->
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
      }

      .checkmark {
        display: none;
        width: 0.9375rem;
        height: 0.9375rem;
        border: 1.2px solid var(--color-white);
        border-radius: 50%;
        transition: border-color 0.3s, background-color 0.3s;
        background-color: transparent;
        position: relative;

        &::after {
          content: "";
          width: 5px;
          height: 9px;
          border: solid transparent;
          border-width: 0 1.5px 1.5px 0;
          transform: rotate(45deg);
          position: absolute;
          top: 1px;
          left: 4px;
          transition: border-color 0.3s;
        }
      }

      .media {
        position: relative;

        &:hover .checkmark {
          display: block; /* Show the checkmark on hover */
        }
      }

      input[type="checkbox"]:checked + .checkmark {
        display: block;
        background-color: var(--color-primary-d);
        border: 1.2px solid var(--color-primary-d);
      }

      input[type="checkbox"]:focus + .checkmark {
        border-color: var(--color-white);
      }

      input[type="checkbox"]:not(:checked) + .checkmark {
        border-color: var(--color-white);
      }

      input[type="checkbox"]:checked:hover + .checkmark {
        display: block;
      }

      input[type="checkbox"]:checked + .checkmark::after {
        display: block;
        border-color: var(--color-white);
      }
    }

    .total-medias {
      text-align: center;
      margin-block: 1.5rem;

      .info {
        color: var(--color-description);
        font-size: 0.875rem;
      }
    }
  }

  .links-wrapper,
  .docs-wrapper {
    height: 100%;
    flex: 1;
    overflow-y: auto;

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }

    .checkmark {
      display: block;
      width: 1.125rem;
      height: 1.125rem;
      border: 1.5px solid var(--color-radio-border);
      border-radius: 50%;
      transition: border-color 0.3s, background-color 0.3s;
      background-color: transparent;
      position: relative;
      visibility: hidden;
      opacity: 0;

      transition: all 0.3s;

      &::after {
        content: "";
        width: 5px;
        height: 9px;
        border: solid transparent;
        border-width: 0 1.5px 1.5px 0;
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 6px;
        transition: border-color 0.3s;
      }
    }

    .links,
    .docs {
      display: flex;
      flex-direction: column;
      gap: 5px;

      background-color: transparent;
      padding-inline: 0.9375rem;
      padding-block-end: 0.875rem;
      padding-block-start: 0.625rem;
      transition: all 0.3s;

      position: relative;
      overflow-x: clip;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 40px;
        width: 100%;
        height: 1px;
        background-color: var(--color-border);
      }

      &:first-child {
        padding-block-start: 0.9375rem;
      }

      &:hover {
        background-color: hsl(188, 75%, 36%, 0.1);
        cursor: pointer;
      }
      &:hover .checkmark {
        visibility: visible;
        opacity: 1;
      }

      .links-details {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .link-userinfo {
          display: flex;
          align-items: center;
          gap: 5px;

          .profile-image {
            width: 30px;
            height: 30px;
            background-color: var(--color-primary-d);
            border-radius: 50%;
            padding: 1px;
            margin-inline-end: 0.25rem;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .user {
            color: var(--color-description);
            font-size: 0.75rem;
          }

          .username {
            color: var(--color-quaternary-l);
            font-size: 0.75rem;
          }
        }

        .link-date {
          color: var(--color-description);
          font-size: 0.75rem;

          display: flex;
          align-items: center;
          gap: 8px;

          .arrow {
            width: 8px;
            height: 13px;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .links-preview,
      .docs-preview {
        display: flex;
        align-items: center;
        gap: 5px;

        .link-preview-info,
        .group-preview-info {
          display: flex;
          flex-direction: column;

          border-radius: 5px;
          // padding: 3px;

          background-color: var(--color-quinary);
          // margin-block-end: 0.625rem;
          // border-block-end: 1px solid var(--color-border);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);

          .link-preview-details {
            display: flex;
            align-items: flex-start;
            gap: 10px;

            margin: 3px;
            background-color: var(--color-background-dark);

            .preview-image {
              width: 65px;
              height: 65px;
              background-color: #ececec;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;

              flex-shrink: 0;
              overflow: hidden;

              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              .preview-link-image {
                width: 1.5rem;
                height: 1.5rem;

                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .details {
              display: flex;
              flex-direction: column;
              // gap: 5px;

              padding-block: 3px;
              // background-color: #f5f5f5;

              .details-link {
                // color: var(--color-quaternary-l);
                color: #2a2a2a;
                font-size: 0.7rem;
                font-weight: 600;
                padding-inline: 1px;

                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              .name {
                color: var(--color-description);
                font-size: 0.625rem;
              }
            }
          }

          .go-toLink {
            padding: 3px;
            a {
              color: var(--color-primary-d);
              font-size: 0.75rem;
              overflow: hidden;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        }

        .link-preview-info.youtube {
          width: 250px;
          max-width: 100%;
          .link-preview-details {
            flex-direction: column;

            .preview-image {
              width: 100%;
              height: 140px;

              .preview-link-image.play {
                width: 45px;
                height: 45px;
              }
            }
          }

          .go-toLink {
            a {
              display: -webkit-box;
              -webkit-line-clamp: 2;
            }
          }
        }

        .group-check {
          position: relative;

          input[type="checkbox"]:checked + .checkmark,
          input[type="checkbox"]:focus + .checkmark {
            background-color: var(--color-primary-d);
            border-color: var(--color-primary-d);
            visibility: visible;
            opacity: 1;
          }

          input[type="checkbox"]:not(:checked) + .checkmark {
            border-color: var(--color-radio-border);
          }

          input[type="checkbox"]:checked + .checkmark::after,
          input[type="checkbox"]:focus + .checkmark::after {
            border-color: var(--color-radio-border);
          }
        }
      }
    }
  }

  .docs-wrapper {
    .group-preview-info {
      width: 80%;
      height: 100px;
      background-color: var(--color-primary-d);
    }
  }

  @keyframes slideInRight {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
}
