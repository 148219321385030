.privacySettings {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 11;

  &.open-right {
    animation: slideInFromRight 250ms ease-out forwards;
  }

  &.close-right {
    animation: slideOutToRight 250ms ease-out forwards;
  }
  &.open-left {
    animation: slideInFromLeft 250ms ease-out forwards;
  }
  &.close-left {
    animation: slideOutToLeft 250ms ease-out forwards;
  }

  .privacySettings-wrapper {
    display: flex;
    flex-direction: column;
    // background-color: var(--color-settings-bg);
    background-color: var(--color-secondary);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;
      min-height: 54px;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }
    }

    .privacySettings-cta {
      height: 100%;
      flex: 1;
      overflow-y: auto;

      .personal-info,
      .disappearing-messages,
      .blocked-contacts {
        // height: 100%;
        // overflow-y: auto;

        background-color: var(--color-secondary);

        .privacy-heading {
          padding: 1.25rem 0.9375rem 0.875rem 0.9375rem;

          .heading {
            font-size: 0.875rem;
            color: var(--color-primary-d);
          }
        }

        .privacy-setting {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;

          padding-inline: 0.9375rem;
          padding-block: 0.9375rem;
          // height: 4.375rem;
          border-block-end: 0.5px solid var(--color-border);

          cursor: pointer;
          transition: background 0.2s ease-out;

          &:hover,
          &:focus-visible {
            background: var(--color-selected-bg);
          }

          &:focus-visible {
            outline: 0;
          }

          .info {
            flex: 1;

            display: flex;
            flex-direction: column;

            cursor: pointer;

            .title {
              font-size: 1rem;
              color: var(--color-quaternary-l);
            }

            .sub-title {
              color: var(--color-description);
              font-size: 0.8rem;
              line-height: 1.35;
            }
          }

          .more {
            width: 0.5625rem;
            height: 0.9375rem;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          .group-check {
            input[type="checkbox"] {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;
            }

            .checkmark {
              display: inline-flex;
              width: 1rem;
              height: 1rem;
              border: 1.2px solid var(--color-radio-border);
              border-radius: 50%;
              transition: border-color 0.3s, background-color 0.3s;
              background-color: transparent;
              position: relative;
              justify-content: center;
              align-items: center;

              &::after {
                content: "";
                width: 5px;
                height: 9px;
                border: solid transparent;
                border-width: 0 1.75px 1.75px 0;

                transform: rotate(45deg);
                margin-block-start: -2px;

                transition: border-color 0.3s;
              }
            }

            input[type="checkbox"]:checked + .checkmark {
              background-color: var(--color-primary-d);
              border: 1.2px solid var(--color-primary-d);
            }

            input[type="checkbox"]:focus-visible + .checkmark {
              border-color: var(--color-primary-d);
            }

            // input[type="checkbox"]:not(:checked) + .checkmark {
            //   border-color: var(
            //     --color-border
            //   ); /* Border color when not checked */
            // }

            // input[type="checkbox"]:checked:hover + .checkmark {
            //   display: block;
            // }

            input[type="checkbox"]:checked + .checkmark::after {
              display: block;
              border-color: var(--color-white);
            }
          }

          .toggle-switch {
            .off {
              width: 38px;
              height: 22px;
              background-color: var(--color-description);
              border-radius: 25px;
              padding: 2px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
              transition: background-color 0.3s;

              @media screen and (max-width: 1025px) {
                width: 32px;
                height: 18px;
              }

              .on {
                width: 15px;
                height: 15px;
                background-color: var(--color-white);
                border-radius: 50%;
                margin: 2px;
                transition: background-color 0.3s, transform 0.3s;
                transform: translateX(0);

                @media screen and (max-width: 1025px) {
                  width: 12px;
                  height: 12px;
                }
              }

              &.toggled .on {
                transform: translateX(100%);
              }

              &.toggled {
                background-color: var(--color-primary-d);
              }
            }
          }
        }

        .show-previews,
        .show-reaction-previews {
          border-block-end: 0;

          // height: 3rem;

          // opacity: 0.2;
        }

        .privacy-setting {
          .last-seen-more {
            display: flex;
            align-items: center;
            gap: 7px;

            .last-seen-more-info {
              color: var(--color-description);
              font-size: 0.8rem;
            }
          }
        }
      }

      .personal-info,
      .disappearing-messages {
        // margin-block-end: 0.9375rem;
      }

      .sounds {
        height: 100%;
      }
    }
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutToLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
}
