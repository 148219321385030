.notFound {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  opacity: 0;
  animation: delay 3s linear forwards;

  .dialog {
    width: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -50%);
    opacity: 0;
    scale: 0;
    background-color: var(--color-secondary);
    padding: 1.25rem 1.35rem;
    animation: delay-dialog 1s linear forwards;
    transform-origin: center center;

    .description {
      font-size: 1rem;
      color: var(--color-tertiary);
      margin-block-end: 1.5rem;
    }

    .ok {
      all: unset;
      background-color: var(--color-primary-d);
      padding: 0.5rem 1.5rem;
      color: var(--color-secondary);
      border-radius: 5px;
      cursor: pointer;
    }
  }

  @keyframes delay {
    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
