.sendContacts {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  position: relative;

  animation: delay 0.3s ease-out forwards;

  .sendContacts-container {
    width: 400px;
    margin-block: 1rem;
    background-color: var(--color-secondary);
    display: flex;
    flex-direction: column;
    height: 90vh;

    animation: delay-dialog 0.3s ease-out forwards;

    @media screen and (max-width: 1380px) {
      height: 30rem;
    }

    @media screen and (min-width: 1600px) {
      height: 40rem;
    }

    .sendContacts-to {
      background-color: var(--color-primary-d);

      .sendContacts-to-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.625rem;

        .title {
          font-size: 1.25rem;
          font-weight: 500;
          color: var(--color-offwhite);
        }

        .btn-close {
          // width: 20px;
          // height: 20px;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }
      }
    }

    .sendContact-search {
      height: 33px;
      padding-inline: 0.9375rem;
      margin-block: 0.9375rem;

      .search-bar {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;

        input {
          color: var(--color-quaternary-l);
          flex: 1;
          height: 100%;
          appearance: none;
          border: none;
          outline: none;
          background-color: rgba(118, 118, 128, 0.12);
          padding: 10px;
          padding-inline-start: 30px;
          border-radius: 0.5rem;
          font-size: 0.875rem;

          &::placeholder {
            color: var(--color-description);
          }
        }

        .search {
          position: absolute;
          width: 12px;
          height: 12px;
          left: 10px;
          cursor: pointer;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .sendContact-Wrapper {
      height: 100%;
      overflow-y: auto;

      flex: 1 1;
      display: flex;
      flex-direction: column;

      .sendContact-details {
        // height: 3.75rem;
        height: 4.375rem;
        flex-shrink: 0;

        .sendContact-wrapper {
          display: block;
          height: 100%;
          cursor: pointer;

          .sendContact {
            display: flex;
            align-items: center;

            padding-inline-start: 15px;
            height: 100%;

            &:hover,
            &:focus-visible {
              background-color: var(--color-selected-bg);
            }

            &:focus-visible {
              outline: 0;
            }

            .sendContact-image {
              width: 40px;
              height: 40px;
              background-color: var(--color-secondary);
              border-radius: 50%;
              margin-inline-end: 0.75rem;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .sendContact-info {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              // padding-block-end: 10px;
              padding-inline-end: 15px;
              // margin-block-end: 10px;
              border-block-end: 0.5px solid var(--color-border);
              height: 100%;

              .sendContact-info-name {
                height: 100%;

                display: flex;
                flex-direction: column;
                justify-content: center;

                .sendContact-name {
                  font-size: 0.9375rem;
                  font-weight: 500;
                  color: var(--color-quaternary-l);
                }

                .sendContact-info-message {
                  color: var(--color-description);
                  font-size: 0.75rem;
                  font-weight: 400;
                  overflow: hidden;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }
              }

              .group-check {
                display: revert;
                margin-inline-start: auto;

                input[type="checkbox"] {
                  position: absolute;
                  opacity: 0;
                  height: 0;
                  width: 0;
                }

                .checkmark {
                  display: inline-flex;
                  width: 1.125rem;
                  height: 1.125rem;
                  border: 1.2px solid var(--color-radio-border);
                  border-radius: 50%;
                  transition: border-color 0.3s, background-color 0.3s;
                  background-color: transparent;
                  position: relative;
                  justify-content: center;
                  align-items: center;

                  &::after {
                    content: "";
                    width: 5px;
                    height: 9px;
                    border: solid transparent;
                    border-width: 0 1.75px 1.75px 0;

                    transform: rotate(45deg);
                    margin-block-start: -2px;

                    transition: border-color 0.3s;
                  }
                }

                input[type="checkbox"]:checked + .checkmark {
                  background-color: var(--color-primary-d);
                  border: 1.2px solid var(--color-primary-d);
                }

                input[type="checkbox"]:focus + .checkmark {
                  border-color: var(--color-primary-d);
                }

                input[type="checkbox"]:not(:checked) + .checkmark {
                  border-color: var(--color-radio-border);
                }

                input[type="checkbox"]:checked + .checkmark::after {
                  display: block;
                  border-color: var(--color-secondary);
                }
              }
            }
          }
        }

        &:last-child .sendContact-info {
          border-block-end: 0 !important;
        }
      }

      .contact-notFound {
        margin-block-start: 3rem;
        text-align: center;
        color: var(--color-description);
        font-size: 0.75rem;
        font-weight: 400;
      }
    }

    .sendContact-message,
    .sendContact-message-media {
      background-color: var(--color-popup);
      padding-inline: 0.9375rem;
      padding-block: 0.3rem;
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);

      .sendContact-message-names {
        color: var(--color-description);
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-inline-end: 1.5rem;

        .name {
          display: inline-block;
          color: inherit;
          font-size: 0.9375rem;
        }
      }

      .btn-send-message {
        width: 45px;
        height: 45px;
        background-color: var(--color-primary-d);
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .sendContact-message-media {
      min-height: 80px;

      .wrapper {
        display: flex;
        gap: 0.5rem;
        justify-content: flex-start;
        background-color: var(--color-secondary);

        .media {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          border: 1px solid var(--color-primary-d);
          overflow: hidden;
          position: relative;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .media-type {
            position: absolute;
            inset: 0;
            background-color: hsl(0, 0, 0, 0.2);
            display: grid;
            place-items: center;

            img {
              width: 1rem;
              height: 1rem;
            }
          }
        }

        .input-wrapper {
          align-self: stretch;
          flex: 1;
          border-radius: 5px;
          border: 1px solid var(--color-footer-border);

          .messageInput {
            height: 100%;
            width: 100%;
            color: var(--color-quaternary);
            border: none;
            outline: none;
            padding-inline: 0.5rem;
            max-height: 4rem;
            overflow: hidden auto;
            white-space: pre-wrap;
            word-break: break-word;

            /* Hide the Placeholder */
            &:empty::before {
              content: attr(placeHolder);
              color: #b3b3b3;
            }

            &:empty:focus ::before {
              content: "";
            }
          }
        }
      }
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
