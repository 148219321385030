.delete-notification {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .dialog {
    width: 310px;
    background-color: var(--color-popup);
    border-radius: 0.5rem;
    overflow: hidden;
    opacity: 0;
    scale: 0;
    animation: delay-dialog 0.3s ease-out forwards;

    .dialog-info {
      margin-block: 1.875rem 0.5rem;

      h4 {
        font-size: 1.125rem;
        font-weight: 400;
        color: var(--color-quaternary-l);
        text-align: center;
      }
    }

    .description {
      font-size: 0.8rem;
      color: var(--color-description);
      margin-block-end: 1.5rem;
      width: 43ch;
      text-align: center;
      margin-inline: auto;
      line-height: 1.25;
    }

    .delete-notification-cta {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 22px;
      margin-block-end: 1.25rem;

      .btn-cancel,
      .btn-delete {
        background-color: transparent;
        padding: 0.4rem 1.125rem;
        font-size: 0.875rem;
        color: var(--color-primary-d);
        border: 1px solid var(--color-primary-d);
        border-radius: 5px;
        cursor: pointer;
      }

      .active {
        background-color: var(--color-primary-d);
        color: var(--color-offwhite);
      }
    }
  }
}
