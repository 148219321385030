@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root,
body.light {
  --color-primary: #168e9f;
  --color-primary-m-d: #168fa0;
  --color-primary-d: #1891a2;
  --color-primary-bg: #005460;

  --color-secondary: #ffffff;
  // --color-secondary-d: #ffffff1f;
  --color-secondary-m-d: #f4feff;

  --color-description: #999999;
  --color-profile-description: #686868;
  --color-border: #ebebeb;
  --color-message-border: #e5e5e5;
  --color-radio-border: #999999;
  --color-tertiary: #48484a;
  --color-tertiary-v-l: #838383;
  // --color-tertiary-m-l: #c5c5c5;
  --color-tertiary-l: #8e8e93;
  // --color-tertiary-d: #3d4445;
  // --color-tertiary-v-d: #3e4546;
  --color-settings-bg: #efeff0;

  --color-quaternary: #000;
  --color-quaternary-l: #2a2a2a;
  --color-quaternary-m-d: #182b2f;
  // --color-quaternary-d: #111212;

  --color-quinary: #d6f3f7;
  --color-quinary-l: #dff2f4;

  --color-error: #e72915;
  --color-white: #ffffff;
  --color-offwhite: #f5f5f5;

  --color-popup: #ffffff;
  --color-tepars-bg: #ffffff;

  --color-scroll-thumb: #777777;
  --color-scroll-bg: #d2d2d2;

  --color-background-dark: #f5f5f5;
  --color-location-bg: #efefef;
  --color-input-bg: #fafafa;

  --color-networkError-bg: rgb(166, 166, 166, 0.12);

  --color-selected-text: #99d7de;

  // --color-focused: #dff2f4;
  --color-focused: #64d3e2;
  --color-selected-bg: rgb(23 144 163 / 10%);

  --color-footer-border: #d7d7d7;

  --color-chatBg: #d6f3f7;
  --color-message-bg: #ffffff;

  // 1 VS 1 Chat Colors
  --color-message-time: var(--color-tertiary-v-l);
  --color-message-main-text: #2a2a2a;
  --message-info: #686868;
  --color-message-description: #838383;
  // --color-link: #1891a2;
  --color-sendMessage-bg: #d6f3f7;
  --color-recievedMessage-bg: var(--color-sendMessage-bg);

  --color-placeholder: #999999;

  --font-family-main: "Roboto", sans-serif;
}

@media (prefers-color-scheme: light) {
  :root {
    --color-primary: #168e9f;
    --color-primary-l: #c3eaf0;
    --color-primary-m-d: #168fa0;
    --color-primary-d: #1891a2;
    --color-primary-bg: #005460;

    --color-secondary: #ffffff;
    --color-secondary-l: #ffffffb3;
    --color-secondary-m-l: #f3f3f3;
    --color-secondary-d: #ffffff1f;
    --color-secondary-m-d: #f4feff;

    --color-description: #999;
    --color-border: #ebebeb;
    --color-tertiary: #48484a;
    --color-tertiary-v-l: #838383;
    --color-tertiary-m-l: #c5c5c5;
    --color-tertiary-l: #8e8e93;
    --color-tertiary-d: #3d4445;
    --color-tertiary-v-d: #3e4546;
    --color-settings-bg: #efeff0;

    --color-quaternary: #000;
    --color-quaternary-l: #2a2a2a;
    --color-quaternary-m-d: #182b2f;
    --color-quaternary-d: #111212;

    --color-quinary: #d6f3f7;
    --color-quinary-l: #dff2f4;

    --color-error: #e72915;
    --color-white: #fff;

    --color-popup: #2e3038;
    --color-tepars-bg: #f8f8f8;

    --color-scroll-thumb: #d9d9d9;
    --color-scroll-bg: #d9d9d9;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    // --color-bg: hsl(225, 14%, 11%);
    --color-secondary: #181a20;
    --color-border: #25272c;
    --color-arrow: #c4c4c4;
    // --color-title: hsl(0, 0%, 100%);
    --color-quaternary-l: #fff;
    --color-description: #999999;
    --color-main: #1890a0;
    --color-main-l: #178ea11a;
    --color-popup: #2e3038;
    --color-tepars-bg: #1f2127;
  }
}

body.light {
  .teparsView
    .teparsView-wrapper
    .post-wrapper
    .post-info-wrapper
    .post-media-wrapper
    .image-number,
  .teparsView
    .teparsView-wrapper
    .post-wrapper
    .post-info-wrapper
    .post-media-wrapper
    .image-sound,
  .viewPost .viewPost-popup .container .upload-image-container .image-number,
  .uploadTepars
    .uploadTepars-popup
    .container
    .upload-image-container
    .image-number {
    background-color: #2e3037;
  }
}

body.dark {
  // --color-bg: hsl(225, 14%, 11%);
  --color-secondary: #181a20;
  --color-border: #25272c;
  --color-arrow: #c4c4c4;
  // --color-title: hsl(0, 0%, 100%);
  --color-quaternary-l: #f5f5f5;
  --color-description: #999999;
  --color-main: #1890a0;
  --color-main-l: #178ea11a;
  --color-popup: #2e3038;
  --color-tepars-bg: #1f2127;

  --color-scroll-thumb: #393939;
  --color-scroll-bg: #141414;

  --color-background-dark: var(--color-popup);

  --color-settings-bg: var(--color-popup);

  --color-location-bg: #1d1e22;

  --color-input-bg: var(--color-popup);

  // --color-focused: #dff2f450;

  --color-chatBg: var(--color-popup);

  --color-message-bg: #364152;

  --color-message-border: var(--color-border);

  --color-recievedMessage-bg: #364152;

  // --color-radio-border: #cdcdcf;

  .uploadTepars-popup {
    // background-color: var(--color-popup);

    .header {
      border-color: var(--color-border);
    }

    .privacy {
      background-color: var(--color-popup) !important;

      .share-group,
      .visibility-group,
      .privacy-settings-info {
        background-color: var(--color-location-bg) !important;
      }
    }

    .seePost .post-visible .seePost-info {
      background-color: var(--color-location-bg) !important;
    }
  }

  .chatpage .content,
  .default,
  .editMessage .dialog .editMessage-container,
  .messageInfo .messageInfo-status-container .messageInfo-message {
    // background-image: url(../image/SVG_Images/BG_dark.svg);
    // background-image: url(../image/SVG_Images/newBgDark.svg);
    background-image: url(../image/SVG_Images/web-bg-dark.svg);

    background-color: var(--color-secondary);
  }

  .commentView {
    .profile-info {
      background-color: var(--color-popup) !important;
    }
  }

  .teparsProfile
    .user-profile-wrapper
    .user-profile
    .user-profile-details
    .user-stats
    .stats
    .posts::after {
    background-color: #ffffff30;
  }

  .shareContact .shareContact-header .search-bar input,
  .location .location-header .search-bar input {
    color: var(--color-offwhite);

    // &::placeholder {
    //   font-weight: 400;
    //   color: var(--color-white);
    // }
  }

  .location
    .location-list-wrapper
    .location-list
    .location
    .location-info-container
    .location-info
    .location-status-info {
    color: #e1e1e1;
  }

  .messageCtaNotification-container
    .messageCtaNotification
    .messageCtaNotification-wrapper,
  .repostedNotification {
    background-color: var(--color-popup);
  }

  // .slick-dots li button:before {
  //   color: var(--color-white);
  //   opacity: 1;
  // }

  // .slick-dots li.slick-active button:before {
  //   color: var(--color-primary-d);
  // }

  .commentView .commentView-wrapper .heading-likes .liked-users .users-names {
    color: var(--color-offwhite);
  }

  .commentView
    .commentView-wrapper
    .footer-box
    .comment-to-post
    .message-wrapper {
    background-color: var(--color-popup);
    border-color: var(--color-description);

    // .messageInput {
    //   &:empty::before {
    //     color: var(--color-white);
    //   }
    // }
  }

  .unkownContact .unkownContact-wrapper .unkownContact-cta .btn-block,
  .unkownContact .unkownContact-wrapper .unkownContact-cta .btn-ok {
    border-color: rgb(215, 215, 215, 0.3);
  }

  // .content-profile .content-profile-details .content-profile-search .menu-icon {
  //   &:hover,
  //   &:focus-within {
  //     background-color: #dff2f450;
  //   }
  // }

  .chatpage .content .message-box .message-wrapper2 .btns .canvasAudio {
    background-color: var(--color-popup);
  }

  .mediaLinksDocs
    .links-wrapper
    .links
    .links-preview
    .link-preview-info
    .link-preview-details {
    background-color: #f5f5f5;
  }
  .mediaLinksDocs {
    .links-preview,
    .docs-preview {
      .link-preview-info,
      .group-preview-info {
        background-color: var(--color-popup) !important;
      }
    }
  }

  .message-group .mainMessage-wrapper .messageGroup-cta {
    .share,
    .btn-reactToMessage,
    .btn-deleteMessage {
      background-color: var(--color-message-bg) !important;
    }
  }

  .chatpage .content .content-message .msgReceivedText {
    background-color: var(--color-message-bg);

    .message-status {
      color: var(--color-offwhite);
    }
  }

  .contactRecieved-Multiple
    .wrapper
    .contact-wrapper
    .contact
    .contact-multiple-info
    .multiple-info {
    color: var(--color-offwhite);
  }

  .contactSend .wrapper .contact-wrapper .contact-cta,
  .contactSend-Multiple .wrapper .contact-wrapper .contact-cta,
  .contactSend-Multiples .wrapper .contact-wrapper .contact-cta,
  .contactSend .wrapper .contact-wrapper .contact-cta .cta:first-child {
    border-color: #e5e5e5;
  }

  .contactRecieved .wrapper .contact-status,
  .contactRecieved-Multiple .wrapper .contact-status {
    color: var(--color-offwhite);
  }

  .setDevicePassword .dialog .enter-password .password {
    border-bottom: 2px solid var(--color-description);

    &:focus {
      border-bottom: 2px solid var(--color-primary-d);
    }
  }

  .takeProfilePhoto,
  .adjustProfileImage {
    background-color: hsl(225, 14%, 11%, 0.95);
  }

  .default .wrapper .info {
    color: var(--color-description);
  }

  .forwardMessage
    .forwardMessage-container
    .forward-message-media
    .forward-media
    .input-wrapper {
    border-color: var(--color-border);
  }

  .leavePoll .dialog .leavePoll-cta .btn-leave {
    border-color: #181a20;
  }

  .pollChat-received .view-votes {
    border-color: var(--color-border);
  }

  .pollChat-received
    .pollChat-container
    .poll-options
    .poll-option
    .poll-option-info
    .poll-option-info-value
    .value {
    color: var(--color-offwhite);
  }

  .pollChat-received .pollChat-container .poll-question,
  .pollChat-received .pollChat-container .message-status,
  .comingSoon .dialog .dialog-info .info {
    color: var(--color-offwhite);
  }

  .commentView
    .commentView-wrapper
    .comment-section-wrapper
    .post-comments
    .commented-user
    .comment-user-profile
    .profile-info-wrapper
    .profile-info
    .profile-name
    .name {
    color: var(--color-offwhite);
  }

  .trimVideo .trimVideo-wrapper .trimVideo-warning {
    background-color: var(--color-secondary);
  }

  .trimVideo
    .trimVideo-wrapper
    .trimVideo-container
    .trimVideo-container-trimSection
    .trimSection-container
    .selectedContainer {
    .slider {
      background-color: var(--color-white);
    }

    .sideLeft,
    .sideRight {
      background-color: var(--color-white);

      &::after {
        background-color: #737373;
      }
    }
  }

  .history .chat-channel .chat,
  .history .chat-channel .channel {
    color: #f2f2f2;
  }

  .history .chat-channel .chat.menu-selected,
  .history .chat-channel .channel.menu-selected {
    color: var(--color-selected-text);
  }

  .teparsProfile
    .user-profile-wrapper
    .medias-wrapper
    .mediaLinksDocs-btn
    .btns-wrapper
    .repost.menu-selected,
  .teparsProfile
    .user-profile-wrapper
    .medias-wrapper
    .mediaLinksDocs-btn
    .btns-wrapper
    .media.menu-selected,
  .profileView
    .profileView-wrapper
    .profileView-info-container
    .profile-medias
    .mediaLinksDocs-btn
    .btns-List
    .repost.menu-selected,
  .profileView
    .profileView-wrapper
    .profileView-info-container
    .profile-medias
    .mediaLinksDocs-btn
    .btns-List
    .media.menu-selected {
    .text {
      // color: var(--color-primary-d);
      color: var(--color-selected-text);
    }

    .media-image svg path {
      // fill: var(--color-primary-d);
      stroke: var(--color-selected-text);
    }

    .repost-image svg path {
      // stroke: var(--color-primary-d);
      stroke: var(--color-selected-text);
    }
  }

  .notification .wrapper .notification-cta .btn-cta {
    background-color: var(--color-popup);
  }

  .teparsView .teparsView-wrapper .post-wrapper .reposted-notification {
    background-color: var(--color-popup);

    .text {
      color: var(--color-offwhite);
    }
  }

  .viewPost .viewPost-popup .header {
    border-color: var(--color-border);
  }

  .sponsoredCircle {
    // background-color: #2a2a2a;
    background-color: var(--color-secondary);

  }

  .sponsoredCircle .sponsoredCircle-wrapper .sponsoredCircle-circles .showMore {
    color: #f5f5f5;
  }

  .storyPrivacy .storyPrivacy-wrapper .story-visible .storyPrivacy-info {
    background-color: var(--color-location-bg) !important;
  }
}

html:focus-within {
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img,
picture,
svg,
video {
  max-width: 100%;
  display: block;
}

img,
picture {
  user-select: none;
  pointer-events: none;
}

video {
  pointer-events: auto;
}

input,
button,
textarea,
select {
  font: inherit;
}

body {
  min-height: 100svh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 100%;
  font-family: var(--font-family-main);
  background: linear-gradient(
    var(--color-primary-bg) 1px,
    var(--color-primary-bg) 125px,
    var(--color-secondary) 125px,
    var(--color-secondary) 100%
  );
  background-repeat: no-repeat;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  &::before {
    content: "";
    width: 5rem;
    height: 5rem;
    background-color: var(--color-secondary);
    position: absolute;
    right: 0;
    top: 2.8125rem;

    z-index: -10;
  }

  &::after {
    content: "";
    width: 3.125rem;
    height: 3.125rem;
    background-color: #cedbdc;
    position: absolute;
    right: 1.875rem;
    top: 7.8125rem;
    z-index: -10;
  }

  /* --- scrollbar --- */
  ::-webkit-scrollbar {
    // width: 6px !important;
    width: 6px !important;
    height: 6px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll-thumb);
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--color-scroll-bg);
  }
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.btn {
  display: inline-block;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus-visible {
    outline: 0;
    border-radius: 2px;
    box-shadow: 0 0 0 2px var(--color-focused);
  }
}

.loginpage {
  max-width: 1920px;
  margin-inline: auto; // height: 100%;
  min-height: 100svh;

  .loginpage-wrapper {
    height: 100%;

    .header-wrapper {
      background-image: url("../image//SVG_Images//loading_bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #1690a1;
      background-blend-mode: multiply;

      width: 100%;
      height: 8.75rem;

      position: relative;

      &::before,
      &::after {
        content: "";

        position: absolute;
      }

      &::before {
        width: 5rem;
        height: 5rem;
        background-color: #ffffff;

        right: 0;
        top: 4rem;
      }

      &::after {
        width: 2.5rem;
        height: 2.5rem;
        background-color: #cedbdc;

        right: 2.5rem;
        top: 8.75rem;
      }

      .header {
        max-inline-size: 62.5rem;
        margin-inline: auto;
        padding-block-start: 20px;
        padding-inline-start: 40px;

        .loginPage-logo {
          width: 190px;
          height: 33px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .hero-section {
      width: 100%;
      background-color: #ffffff;
      padding-block-end: 1rem;

      .card-wrapper {
        position: relative;

        max-inline-size: 62.5rem;
        margin-inline: auto;
      }

      .card {
        background-color: white;
        width: 56.25rem;
        height: 37.5rem;
        border-radius: 1rem;
        margin-inline: auto;

        display: flex;

        transform: translateY(-60px);
        overflow: hidden;

        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

        @media screen and (max-width: 1025px) {
          width: 45rem;
          height: 30rem;
        }

        @media screen and (max-width: 801px) {
          width: 40rem;
          height: 25rem;
        }

        .hero-image {
          flex-basis: 50%;

          background-image: url("../image/card1bg.png");
          background-size: cover;
          background-color: #f1f5f5;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2rem;

          .phoneimg {
            // width: 85%;
            width: 22rem;
            height: 20rem;
            margin-inline: auto;

            @media screen and (max-width: 1025px) {
              width: 20rem;
              height: 18rem;
            }

            @media screen and (max-width: 1025px) {
              width: 15rem;
              height: 12rem;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .logoimg {
            width: 190px;
            height: 33px;

            svg {
              width: 100%;
              height: 100%;
            }

            @media screen and (max-width: 801px) {
              width: 150px;
            }
          }
        }

        .hero-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-basis: 50%;

          .qrcode {
            position: relative;
            // height: 9.375rem;
            // width: 9.375rem;
            // margin-top: 15vh;

            width: 11.375rem;
            height: 11.375rem;
            margin-block-start: 5rem;

            position: relative;

            @media screen and (max-width: 1025px) {
              width: 8rem;
              height: 8rem;
            }

            svg {
              height: 100%;
              width: 100%;
              // border-radius: 12px;
            }

            img {
              height: 50px;
              width: 50px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              margin-top: 10%;
              position: absolute;
            }

            .reloadQrcode {
              position: absolute;
              inset: 0;

              background-color: rgb(255, 255, 255, 0.95);

              display: grid;
              place-items: center;

              .reload-wrapper {
                background-color: #1891a2;

                width: 7.8125rem;
                height: 7.8125rem;
                border-radius: 50%;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;

                cursor: pointer;

                .reload-text {
                  font-size: 0.875rem;
                  color: #ffffff;
                  text-align: center;
                  line-height: 1.25;
                  margin-inline: 0.625rem;
                }
              }
            }

            .qrcode-spinner {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              .spinner {
                width: 2.5rem;
              }
            }
          }

          .hero-info-title {
            margin-block-start: 1rem;

            h3 {
              font-size: 1.4rem;
              font-family: var(--font-family-main);
              color: #2a2a2a;
              font-weight: 500;

              @media screen and (max-width: 1025px) {
                font-size: 1.25rem;
              }
            }
          }

          .hero-info-details {
            padding: 0.9375rem 6.25rem 4.6875rem;

            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 1rem;
            font-family: var(--font-family-main);
            color: #999999;
            line-height: 1.35;

            @media screen and (max-width: 1025px) {
              padding: 0.9375rem 4.25rem 3.6875rem;
              font-size: 0.9375rem;
            }

            ol li:not(:last-child) {
              margin-block-end: 0.875rem;
            }
          }
        }
      }
    }

    .tutorial-section {
      padding-block: 4.375rem 5rem;

      background-image: url("../image/bg.png");
      background-color: #f1f5f5;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      position: relative;

      @media screen and (max-width: 1025px) {
        padding-block: 3rem 3.5rem;
      }

      &::before,
      &::after {
        content: "";

        position: absolute;
      }

      &::before {
        background-color: #168e9f;
        opacity: 0.23;
        width: 2.5rem;
        height: 2.5rem;
        left: 2.5rem;
        top: -40px;
      }

      &::after {
        left: 0;
        top: 0;
        width: 5rem;
        height: 5rem;
        background-color: white;
      }

      .tutorial-title {
        margin-block-end: 10px;

        h3 {
          font-size: 2.25rem;
          font-weight: 500;
          color: #2a2a2a;
          text-align: center;

          @media screen and (max-width: 1025px) {
            font-size: 1.875rem;
          }
        }
      }

      .tutorial-video-wrapper {
        position: relative;

        width: 650px;
        height: 380px;
        margin-inline: auto;
        border-radius: 1rem;

        background-image: url("../image//SVG_Images//video_bg.png");
        background-position: center center;
        background-size: 150%;

        overflow: hidden;
        box-shadow: 0px 16px 16px rgba(34, 38, 30, 0.5);

        @media screen and (max-width: 1025px) {
          width: 550px;
          height: 300px;
        }

        video::-webkit-media-controls-fullscreen-button {
          display: none !important;
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &::before {
          content: "";
          position: absolute;
          inset: 0;

          background-color: rgba(0, 0, 0, 0.5);
          mix-blend-mode: multiply;

          z-index: 1;
        }

        .tutorial-video {
          position: absolute;
          inset: 0;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          z-index: 10;

          .tutorial-video-title {
            display: inline-block;
            // font-family: var(--font-family-sub);
            font-size: 0.875rem;
            font-weight: 600;
            color: #ffffff;
            text-transform: uppercase;
            margin-block-end: 5px;
          }

          .tutorial-video-info {
            width: 70%;
            margin-inline: auto;
            margin-block-end: 8px;

            text-align: center;
            // font-family: var(--font-family-sub);
            font-size: 2.25rem;
            font-weight: 700;
            color: #ffffff;
            line-height: 1.25;

            @media screen and (max-width: 1025px) {
              font-size: 1.875rem;
            }
          }

          @media screen and (max-width: 1025px) {
            .btn-play {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }
  }

  // .footer {
  //   background-color: #168fa0;
  //   width: 100%;
  //   padding-block: 5rem 0;

  //   position: relative;
  //   z-index: 10;

  //   @media screen and (max-width: 1025px) {
  //     padding-block: 3rem 0;
  //   }

  //   &::before,
  //   &::after {
  //     content: "";

  //     position: absolute;
  //   }

  //   &::before {
  //     width: 5rem;
  //     height: 5rem;
  //     background-color: #ffffff;

  //     right: 0;
  //     top: 0;

  //     z-index: -1;
  //   }

  //   &::after {
  //     width: 2.5rem;
  //     height: 2.5rem;
  //     background-color: #cedbdc;

  //     right: 2.5rem;
  //     top: -2.5rem;
  //   }

  //   .footer-wrapper {
  //     max-inline-size: 62.5rem;
  //     margin-inline: auto;

  //     display: flex;
  //     flex-wrap: wrap;

  //     @media screen and (max-width: 1025px) {
  //       // flex-direction: column;
  //       row-gap: 1.5rem;
  //       margin-inline: 2rem;
  //       // justify-content: center;
  //       // align-items: center;
  //     }

  //     .about {
  //       width: 33%;

  //       // @media screen and (max-width: 1025px) {
  //       //   width: 100%;
  //       // }

  //       .footer-logo {
  //         width: fit-content;
  //         height: 33px;

  //         margin-block-end: 1rem;

  //         svg {
  //           width: 100%;
  //           height: 100%;
  //         }
  //       }

  //       .about-info {
  //         width: 25ch;
  //         margin-block-end: 10px;
  //         font-size: 0.875rem;
  //         color: rgb(255, 255, 255, 0.7);
  //         line-height: 1.6;

  //         @media screen and (max-width: 1025px) {
  //           width: 30ch;
  //         }
  //       }

  //       .social-media-icons {
  //         display: flex;
  //         align-items: center;
  //         gap: 10px;

  //         .iconItem {
  //           flex-shrink: 0;

  //           width: 40px;
  //           height: 40px;
  //           background-color: #000;
  //           border-radius: 50%;

  //           display: grid;
  //           place-items: center;

  //           cursor: pointer;
  //           transition: scale 250ms ease-out;

  //           &:hover,
  //           &:focus {
  //             scale: 1.15;
  //           }

  //           a {
  //             display: inline-block;
  //           }
  //         }
  //       }
  //     }

  //     .social-links {
  //       width: 33%;

  //       display: flex;

  //       @media screen and (max-width: 1025px) {
  //         width: 50%;
  //         flex: 1;
  //         gap: 1.5rem;

  //         // .whatWeDo {
  //         //   display: none;
  //         // }
  //       }

  //       .social-links-menu {
  //         width: 50%;

  //         @media screen and (max-width: 1025px) {
  //           margin-inline-start: 3rem;
  //         }

  //         .social-links-heading {
  //           display: inline-block;
  //           text-transform: uppercase;
  //           font-size: 0.875rem;
  //           font-weight: 600;
  //           color: #ffffff;
  //           margin-block-end: 0.9375rem;
  //         }

  //         .menu-info a {
  //           display: inline-block;
  //           color: rgb(255, 255, 255, 0.7);
  //           font-size: 0.875rem;
  //           margin-block-end: 0.625rem;

  //           transition: color 250ms ease-out;

  //           &:hover,
  //           &:focus {
  //             color: #ffffff;
  //             transition: all 0.3s ease;
  //           }
  //         }
  //       }
  //     }

  //     .newsletter {
  //       width: 33%;

  //       display: flex;
  //       flex-direction: column;

  //       @media screen and (max-width: 1025px) {
  //         width: 100%;
  //       }

  //       .newsletter-heading {
  //         display: inline-block;
  //         text-transform: uppercase;
  //         font-size: 0.875rem;
  //         font-weight: 600;
  //         color: #ffffff;
  //         margin-block-end: 0.9375rem;
  //       }

  //       .newsletter-info {
  //         width: 35ch;
  //         margin-block-end: 0.875rem;
  //         font-size: 0.875rem;
  //         color: rgb(255, 255, 255, 0.7);
  //         line-height: 1.6;

  //         // @media screen and (max-width: 1025px) {
  //         //   width: 100%;
  //         // }
  //       }

  //       .newsletter-email {
  //         display: flex;

  //         .email-input {
  //           font-size: 15px;
  //           border: none;
  //           outline: none;
  //           padding-block: 0.8rem;
  //           padding-inline-start: 16px;
  //           color: #2a2a2a;

  //           &::placeholder {
  //             font-size: 0.875rem;
  //             color: rgb(140, 140, 140, 0.7);
  //           }
  //         }

  //         .btn-subscribe {
  //           background-color: #000000;
  //           color: white;
  //           font-size: 15px;
  //           letter-spacing: 0.5px;
  //           font-weight: 700;
  //           padding: 12px;
  //           text-transform: uppercase;
  //         }
  //       }
  //     }
  //   }

  //   .privacy {
  //     max-inline-size: 61.25rem;
  //     margin-inline: auto;
  //     margin-block-start: 5rem;
  //     border-top: 0.9px solid rgb(212, 222, 249, 0.12);

  //     @media screen and (max-width: 1025px) {
  //       margin-block-start: 2rem;
  //       margin-inline: 2rem;
  //     }

  //     .copyright {
  //       display: flex;
  //       justify-content: space-between;

  //       padding-block: 0.875rem;

  //       .copyright-info {
  //         span {
  //           display: inline-block;
  //           font-size: 0.875rem;
  //           color: rgb(255, 255, 255, 0.7);
  //         }
  //       }

  //       .termsAndPrivacy {
  //         display: flex;
  //         gap: 2.5rem;

  //         .terms,
  //         .privacyPolicy {
  //           a {
  //             font-size: 0.875rem;
  //             color: rgb(255, 255, 255, 0.7);

  //             transition: color 250ms ease-out;
  //             cursor: pointer;

  //             &:hover,
  //             &:focus {
  //               color: #ffffff;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.chatpage {
  --block-margins: 20px;
  // width: 1600px;
  // width: 1200px;
  // max-width: 1440px;
  // width: calc(100% - 40px);
  // height: calc(100% - 40px);
  // width: 1396px;
  margin-inline: auto;

  position: fixed;
  left: 0;
  right: 0;
  top: var(--block-margins);
  bottom: var(--block-margins);
  overflow: hidden;
  box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.15);

  transition: all 0.3s ease-in;

  display: grid;
  // grid-template-columns: minmax(450px, 30%) 1fr;

  // grid-template-columns: 30% 1fr auto;
  grid-template-columns: 30% 1fr;
  &.userProfile {
    grid-template-columns: 30% 1fr 30%;
  }
  @media screen and (max-width: 1300px) {
    &.userProfile {
      grid-template-columns: 30% 1fr 70%;
    }
  }

  @media screen and (max-width: 1025px) {
    grid-template-columns: 40% 1fr auto;

    &.userProfile {
      grid-template-columns: 40% 1fr 60%;
    }
  }

  @media screen and (min-width: 1440px) {
    width: 1350px;
  }

  @media screen and (max-width: 1439px) {
    --block-margins: 0;
  }

  .status_seen {
    width: 14px;
  }

  & > * {
    max-height: calc(100svh - 40px);
  }

  .aside-wrapper {
    position: relative;
    box-shadow: 4px 0px 22px 0px rgba(0, 0, 0, 0.0784313725);
    overflow-x: clip;
  }

  @media (max-width: 767px),
    (min-width: 768px) and (max-width: 1023px),
    (min-width: 1024px) and (max-width: 1439px) {
    & > * {
      max-height: 100svh;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    // background-image: url(../image/SVG_Images/BG_light.svg);
    background-image: url(../image/SVG_Images/web-bg-light.svg);
    background-repeat: repeat;
    background-size: contain;
    background-color: var(--color-secondary-m-d);
    background-position: top center;

    overflow-x: hidden;

    .content-message {
      // padding-inline: 25px;
      padding-block-end: 1rem;
      flex: 1;
      overflow-y: auto;
      height: 100%;

      .message-date {
        width: fit-content;
        margin-inline: auto;
        margin-block: 16px 40px;
        background-color: var(--color-quinary-l);
        border-radius: 8px;
        padding: 6px 18px 4px;
        margin-block: 16px 40px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

        position: sticky;
        top: 1rem;

        z-index: 20;

        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

        .time {
          color: var(--color-quaternary);
          font-size: 0.8rem;
          line-height: normal;
        }
      }

      .message {
        display: flex;
        flex-direction: column;
        gap: 6px;
        // gap: 0.625rem;
        // overflow-y: auto;

        .message-group {
          // padding-block: 3px;
          width: 100%;
          padding-inline: 25px;

          position: relative;

          display: flex;
          flex-direction: column;
          overflow: hidden;

          .message-check {
            width: 120%;
            height: 100%;
            background-color: hsl(188, 75%, 36%, 0.1);

            position: absolute;
            top: -1px;
            // z-index: 100;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            left: -20px;
            padding-left: 44px;

            // input[type="radio"] {
            //   position: absolute;
            //   opacity: 0;
            //   height: 0;
            //   width: 0;
            // }

            // .checkmark {
            //   display: block;
            //   width: 1.125rem;
            //   height: 1.125rem;
            //   border: 1.2px solid var(--color-description);
            //   border-radius: 50%;
            //   background-color: transparent;
            //   position: relative;
            //   transition: all 0.3s;

            //   cursor: pointer;

            //   &::after {
            //     content: "";
            //     width: 70%;
            //     height: 70%;
            //     border-radius: 50%;
            //     background-color: var(--color-primary-d);

            //     position: absolute;
            //     top: 50%;
            //     left: 50%;
            //     transform: translate(-50%, -50%);
            //     transition: all 0.3s ease-out;

            //     opacity: 0;
            //     visibility: hidden;
            //   }
            // }

            // input[type="radio"]:checked + .checkmark::after,
            // input[type="radio"]:focus + .checkmark::after {
            //   opacity: 1;
            //   visibility: visible;
            // }

            input[type="checkbox"] {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;
            }

            .checkmark {
              display: inline-flex;
              justify-content: center;
              align-items: center;

              width: 1.125rem;
              height: 1.125rem;
              border: 1.5px solid var(--color-radio-border);
              border-radius: 50%;
              background-color: transparent;

              vertical-align: middle;

              transition: border-color 0.3s, background-color 0.3s;
              cursor: pointer;

              &::after {
                content: "";
                display: inline-block;
                width: 5.5px;
                height: 10px;
                border: solid transparent;
                border-width: 0 1.75px 1.75px 0;
                transform: rotate(45deg);
                margin-block-start: -2px;

                transition: border-color 0.3s;
              }
            }

            input[type="checkbox"]:checked + .checkmark {
              background-color: var(--color-primary-d);
              border: 1.5px solid var(--color-primary-d);
            }

            input[type="checkbox"]:checked + .checkmark::after {
              border-color: var(--color-white);
            }
          }

          .mainMessage-wrapper {
            display: flex;
            // justify-content: flex-end;
            gap: 10px;
            // flex-direction: column;

            // padding-inline: 25px;
            padding-block: 3px;

            &:hover .messageGroup-cta .btn-reactToMessage {
              display: flex;
            }

            // .messageGroup-cta {
            //   display: flex;
            //   gap: 10px;

            //   .share,
            //   .btn-reactToMessage,
            //   .btn-deleteMessage {
            //     width: 28px;
            //     height: 28px;
            //     background-color: var(--color-secondary);
            //     border-radius: 5000px;
            //     box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

            //     display: flex;
            //     align-items: center;
            //     justify-content: center;

            //     z-index: 10;

            //     cursor: pointer;

            //     img {
            //       width: 20px;
            //     }
            //   }

            //   .btn-reactToMessage {
            //     display: none;
            //   }
            //   .btn-deleteMessage {
            //     display: none;
            //   }
            // }

            // .error-image {
            //   position: absolute;
            //   top: 50%;
            //   transform: translateY(-50%);
            //   right: 0;

            //   width: 25px;
            //   height: 25px;
            //   background: red;
            //   border-radius: 50%;

            //   // margin-inline: 22px;
            // }
          }

          .messageReactedEmojis {
            align-self: flex-end;

            width: fit-content;
            height: fit-content;

            border-radius: 500px;
            background-color: #daf5f9;
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);

            // position: absolute;
            // bottom: -23px;
            // right: 5px;

            position: relative;
            top: -2px;
            right: 10px;

            .reacted-emoji {
              display: inline-block;
            }
          }

          .messageReactedEmojis-right {
            right: 10px;
            align-self: flex-end;
          }

          .messageReactedEmojis-left {
            left: 10px;
          }
        }
      }
    }
    .EmojiPickerReact .epr-preview {
      display: none !important;
    }
    .epr-category-nav {
      padding: 1% !important;
    }
    .EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
      position: relative !important;
    }
    .message-box {
      // min-height: 10vh;
      background-color: var(--color-secondary);
      padding: 1rem;
      // display: flex;
      // gap: 0.9375rem;
      // align-items: center;

      max-width: 100%;

      &:has(.reply) {
        padding-block-start: 0;
      }

      .emoji {
        width: 25px;
        cursor: pointer;
      }

      .message-container {
        display: flex;
        align-items: center;
        gap: 0.9375rem;
        // padding: 10px 0px;
      }

      .message-wrapper {
        flex: 1;
        border-radius: 5000px;
        border: 1px solid var(--color-footer-border);
        font-size: 0.9375rem;
        padding: 0px 15px 0px 0px;
        // padding: 10px;

        display: flex;
        justify-content: space-between;
        min-width: 0;
        // max-height: 5rem;
        // overflow-x: hidden;
        // overflow-y: auto;
        // .text-input {
        //   outline: none;
        //   width: 100%;
        //   white-space: pre-wrap;
        //   word-break: break-word;
        //   padding-inline: 0.5rem;

        //   &::before {
        //     content: "Type Message...";
        //     color: #b3b3b3;
        //   }
        // }

        // &::-webkit-scrollbar {
        //   display: none;
        // }

        .input-wrapper {
          flex: 1;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          min-width: 0;
          // max-height: 5rem;
          // white-space: normal;
          // &::-webkit-scrollbar {
          //   display: none;
          // }

          position: relative;
        }

        .messageInput {
          // resize: none;
          width: 100%;
          color: var(--color-quaternary);
          // color: #b3b3b3;
          border: none;
          outline: none;
          // width: 100%;
          // flex: 1;
          padding-inline: 0.625rem;
          max-height: 4rem;
          overflow: hidden auto;
          white-space: pre-wrap;
          word-break: break-word;

          // overflow-wrap: break-word;
          // white-space: pre-wrap;

          // &::placeholder {
          //   color: #b3b3b3;
          // }

          // &::-webkit-scrollbar {
          //   display: none;
          // }

          /* Hide the Placeholder */
          &:empty::before {
            content: attr(placeHolder);
            color: #b3b3b3;
            cursor: text;
          }

          &:empty:focus ::before {
            content: "";
          }
        }
        .chosen-emoji {
          display: inline-block;
          transform: translateZ(4px);
          /* Add other transform properties as needed */
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
        }
        .placeHolder {
          position: absolute;
          inset: 0;
          display: inline-block;
          color: #b3b3b3;
        }

        .btns {
          display: flex;
          align-items: center;
          gap: 1rem;

          position: relative;

          .fileMenu {
            display: none;
            width: 450px;
            height: 200px;
            padding: 0.75rem;
            align-self: end;
            // border: 2px solid black;

            position: fixed;
            right: 7rem;
            bottom: 5rem;
            // inset: 0;
            // animation: openFile 1s forwards;

            z-index: 100;
            opacity: 0;

            // transform: translateZ(0);
            .wrapper {
              position: absolute;
              bottom: 0;

              // transform-origin: bottom left;
              opacity: 1;
              // transform: translateY(0%) scaleX(1) scaleY(1);
              .file-type {
                justify-content: flex-end;
                li {
                  width: 40px;
                  height: 40px;
                  cursor: pointer;
                }

                display: flex;
                align-items: center;
                gap: 0.9375rem;
              }
            }
          }

          .fileMenuOpen {
            display: block;
          }

          .fileMenuOpen .file-type {
            transform-origin: left;
          }

          .btn-files,
          .btn-audio {
            width: 10px;
            height: 18px;
          }

          .btn-audio {
            width: 13px;
          }
        }
      }

      .message-wrapper2 {
        // transform: translateX(-200%);
        flex: 1;
        height: 50px;
        // border: 1px solid var(--color-footer-border);
        // border-radius: 100px;
        font-size: 0.9375rem;
        color: var(--color-primary-d);
        padding: 10px;

        display: flex;
        justify-content: flex-end;

        .btns {
          display: flex;
          align-items: center;
          gap: 1rem;

          .btn-pause {
            margin-inline-end: 10px;
          }

          .audio {
            width: 13px;
            height: 18px;
          }
          .play,
          .delete {
            cursor: pointer;
          }

          .delete {
            svg {
              height: 25px;
              width: 25px;
            }
          }
          .recorder {
            width: fit-content;
            img {
              width: 200px;
            }
          }
          .canvasAudio {
            height: 3.125rem;
            display: flex;
            align-items: center;
            gap: 1.25rem;
            background-color: #e9f7fa;
            padding-inline: 0.875rem;
            border-radius: 50px;

            .btn-play {
              border: none;
              background: none;
              cursor: pointer;
              svg {
                height: 25px;
                width: 25px;
              }
            }

            // #duration,
            // #time {
            //   font-size: 0.875rem;
            //   color: var(--color-primary-d);
            // }
          }
        }
      }

      .translate {
        // animation: moveIn 1s forwards;
      }
      @keyframes moveIn {
        0% {
          transform: translateX(-200%);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
    .send-message {
      width: 45px;
      height: 45px;
      cursor: pointer;

      &:focus-visible {
        outline: 2px solid var(--color-primary-d);
        outline-offset: 2px;
      }
    }

    .audio-duration-visibility {
      display: none;
    }
  }

  @import "./map.scss";
  @import "./image.scss";

  .info-hide {
    // display: block;
    // // width: 350%;
    // background-color: rgb(72, 0, 255);

    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    z-index: 100;
  }
}

@keyframes openFile {
  0% {
    // opacity: 0;
    // background-color: transparent;
    // transform: translateX(200%);

    opacity: 0;
    transform: translate(0);
  }

  // 50% {
  //   opacity: 1;
  //   bottom: 90px;
  //   width: 50px;
  // }

  100% {
    // opacity: 1;
    // bottom: -190px;
    // width: fit-content;
    // right: 7rem;
    // background-color: var(--color-secondary);

    // transform: translateX(0);

    // position: revert;
    // right: inherit;
    // width: fit-content;
    // height: auto;

    opacity: 1;
    transform: translateY(0%) scaleX(1) scaleY(1);
  }
}

.emoji-picker-react .emoji-mart-preview {
  display: none;
}

.react-input-emoji--container {
  border: none !important;
  background-color: var(--color-secondary);
}

.react-emoji-picker--wrapper {
  left: 0;
  bottom: 1rem;

  animation: openUp 0.3s ease-out;
  transform-origin: bottom left;
  transition: scale 0.3s ease-out, opacity 0.3s ease-out;
}

.react-input-emoji--button__show svg {
  fill: #1891a2;
}

.temoji {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-word;
}

.react-input-emoji--button {
  position: absolute !important;
  left: -48px;

  svg {
    fill: var(--color-primary-d);
  }
}

.canve_waves {
  margin-right: 10px;
  display: flex;
}

// #waveform ::part(cursor) {
//   top: -4.5px;
//   background-color: transparent;
// }

// #waveform ::part(cursor):after {
//   content: "";
//   height: 10px;
//   width: 10px;
//   border-radius: 50%;
//   background-color: var(--color-primary-d);
//   display: inline-block;
//   vertical-align: middle;
// }

.play_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.react-input-emoji--input {
  max-height: 5rem;
  color: var(--color-quaternary-l);
}

@keyframes openUp {
  0% {
    scale: 0;
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}
