.comingSoon {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .dialog {
    width: 270px;

    background-color: var(--color-popup);
    padding-inline: 1.35rem;
    border-radius: 0.5rem;
    animation: delay-dialog 0.3s ease-out forwards;

    .dialog-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-block: 2.875rem 2.625rem;

      .comingSoon-image {
        margin-block-end: 1.35rem;
      }

      .info {
        font-size: 1.5rem;
        font-weight: 500;
        text-transform: capitalize;
        color: #505050;
        line-height: 33px;
      }
    }
  }
}
