.sponsoredCircle {
  background-color: hsl(0, 0%, 80%, 0.2);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .sponsoredCircle-wrapper {
    padding: 0.625rem 1.25rem 1.125rem;

    .sponsporedCircle-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-block-end: 0.625rem;

      .title {
        font-size: 1.125rem;
        color: var(--color-quaternary-l);
        font-weight: 400;
      }

      .sponsoredCircle-info {
        cursor: pointer;

        span {
          color: var(--color-primary-d);
          text-decoration: underline;
        }

        &:focus-visible {
          outline: 0;
          border-radius: 2px;
          box-shadow: 0 0 0 2px var(--color-focused);
        }
      }
    }

    .sponsoredCircle-circles {
      display: flex;
      align-items: center;
      gap: 1rem;

      overflow-x: scroll;
      scrollbar-width: none;

      .sponsoredCircle-circles-wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;

        .sponsoredCircle-circles-circleItem {
          background-color: var(--color-tepars-bg);
          width: 170px;
          border-radius: 8px;
          padding: 0.9375rem 8px;

          display: flex;
          flex-direction: column;
          align-items: center;

          .btn-close {
            align-self: flex-end;
            margin-inline-end: 5px;
          }

          .circleItem-image {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            overflow: hidden;

            margin-block-end: 0.75rem;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .circleItem-name {
            text-align: center;
            margin-block-end: 0.625rem;

            .name {
              font-size: 0.875rem;
              font-weight: 500;
              line-height: 1.25;
              color: var(--color-quaternary-l);

              overflow: hidden;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }

            .total-participants {
              color: var(--color-profile-description);
              font-size: 0.8rem;
              font-weight: 400;
              line-height: 1.25;
            }
          }

          .btn-join {
            background-color: var(--color-primary-d);
            color: var(--color-white);
            width: 100%;
            border-radius: 5px;
            padding-block: 0.35rem;
            font-size: 0.9375rem;
          }
        }
      }

      .showMore {
        min-width: fit-content;
        font-size: 1.125rem;
        color: hsl(0, 0%, 41%, 0.8);

        cursor: pointer;

        .no-data {
          min-width: 90px;
        }
      }
    }
  }
}
