.gallerySend,
.galleryRecieved {
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
  align-self: self-end;

  .wrapper {
    width: 270px;
    background-color: var(--color-quinary);
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    position: relative;

    .gallery-info {
      padding: 5px;

      .name {
        border-radius: 8px;
        padding-inline: 8px;
        background: hsl(227, 74%, 36%, 0.1);

        span {
          display: inline-block;
          color: #0037ff;
          font-size: 0.75rem;
        }
      }
    }

    .gallery-forwarded-from {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-block-start: 5px;

      .forwarded-image {
        width: 0.875rem;
        height: 0.875rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .text {
        display: inline-block;
        color: var(--color-description);
        font-size: 0.875rem;
        font-style: italic;
        line-height: 1.25;
      }
    }

    .collection {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1px;
      grid-template-areas:
        "pic1 pic2"
        "pic3 pic4";

      .picture {
        border: 0.5px solid var(--color-secondary-m-d);
        overflow: hidden;
        width: 100%;
        height: 125px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .pic1 {
          grid-area: pic1;
        }
        .pic2 {
          grid-area: pic2;
        }
        .pic3 {
          grid-area: pic3;
        }
        .pic4 {
          grid-area: pic4;
        }
      }

      .number {
        width: 50%;
        height: 125px;
        position: absolute;
        bottom: 0;
        right: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.5)
        );
        box-shadow: inset 0px -35px 15px -11px rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-size: 1.2rem;
          color: var(--color-secondary);
        }
      }
    }

    .gallery-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);
      z-index: 10;

      .status {
        width: 14px;
      }
    }
  }
}

.galleryRecieved {
  align-self: self-start;

  .wrapper {
    background-color: var(--color-secondary);
  }
}
