.updateProfile {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 11;

  &.open {
    animation: slideInFromRight 250ms ease-out forwards;
  }

  &.close {
    animation: slideOutToRight 250ms ease-out forwards;
  }

  .updateProfile-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;

      min-height: 54px;

      display: flex;
      justify-content: space-between;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }

      .done {
        cursor: pointer;

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          line-height: 1.35;
        }
      }
    }

    .updateProfile-info {
      height: 100%;
      overflow-y: auto;

      .profile-image-change {
        margin-block: 2rem;

        position: relative;

        .profile-change {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          margin-inline: auto;

          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;

            object-fit: cover;
          }

          .edit-image {
            width: 1.85rem;
            height: 1.85rem;
            border-radius: 50%;
            background-color: var(--color-primary-d);
            border: 2.5px solid var(--color-secondary);

            display: grid;
            place-items: center;

            position: absolute;
            right: 0;
            bottom: 0.625rem;

            cursor: pointer;

            svg {
              width: 65%;
              height: 65%;
              border-radius: none;
            }

            &:focus-visible {
              box-shadow: 0 0 0 2px var(--color-focused);
            }
          }
        }
      }

      .updateProfile-details {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        padding-inline: 0.9375rem;
        padding-block-end: 0.9375rem;

        .name-details,
        .about-details,
        .tags-details,
        .link-details,
        .phone-details,
        .birthDate-details {
          display: flex;
          flex-direction: column;
          gap: 6px;

          label[for="name"],
          label[for="about"],
          label[for="link"],
          label[for="phoneNumber"],
          label[for="birthDate"] {
            font-size: 0.8rem;
            color: var(--color-description);
          }

          .tag-heading {
            font-size: 0.8rem;
            color: var(--color-description);
          }

          .name-container,
          .about-details-info,
          .tags-container,
          .link-container,
          .phone-container,
          .birthDate-container {
            width: 100%;
            padding: 0.75rem 0.9375rem;
            border: 1px solid hsl(0, 0%, 41%, 0.4);
            border-radius: 5px;
            background-color: var(--color-input-bg);

            transition: border 150ms ease, box-shadow 150ms ease;

            #name,
            #about,
            #link,
            #phoneNumber,
            #birthDate {
              border: 0;
              outline: none;
              background-color: transparent;
              color: var(--color-quaternary-l);
              font-size: 0.875rem;

              &:-webkit-autofill,
              &:-webkit-autofill:hover,
              &:-webkit-autofill:focus,
              &:-webkit-autofill:active {
                transition: background-color 5000000s ease-in-out 0s,
                  color 5000000s ease-in-out 0s;
                // color: inherit !important;
              }
            }

            .edit-icon {
              // width: 1rem;
              // height: 1rem;
              padding: 2px;

              cursor: pointer;

              // svg {
              //   width: 100%;
              //   height: 100%;
              // }

              &:focus-visible {
                outline: 0;
                border-radius: 2px;
                box-shadow: 0 0 0 2px var(--color-focused);
              }
            }

            #name,
            #link,
            #phoneNumber {
              flex: 1;
            }

            #about {
              resize: none;
              width: 100%;
              height: 4rem;
            }
          }

          .name-container,
          .tags-container,
          .link-container,
          .phone-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .letters-allowed {
              span {
                color: var(--color-description);
                font-size: 0.7rem;
              }
            }
          }

          .error {
            color: var(--color-error);
            font-size: 0.8rem;
          }

          .name-details-note {
            font-size: 0.8rem;
            color: var(--color-description);
            line-height: 1.25;
          }

          .about-details-info {
            position: relative;

            // img {
            //   width: 100%;
            //   height: 100%;
            //   border-radius: 50%;
            // }

            .letters-allowed {
              position: absolute;
              bottom: 5px;
              right: 10px;

              span {
                color: var(--color-description);
                font-size: 0.7rem;
              }
            }
          }

          // .editing-container {
          //   box-shadow: 0 1px 0 0 var(--color-primary-d);
          // }
        }

        .tags-details {
          .tags-container {
            cursor: pointer;

            .tag-name {
              color: var(--color-quaternary-l);
              font-size: 0.875rem;
            }

            .more {
              // width: 0.5625rem;
              // height: 0.9375rem;
              padding: 2px;

              cursor: pointer;

              // svg {
              //   width: 100%;
              //   height: 100%;
              // }

              &:focus-visible {
                outline: 0;
                border-radius: 2px;
                box-shadow: 0 0 0 2px var(--color-focused);
              }

              // svg {
              //   margin-inline-start: auto;
              // }
            }
          }
        }

        .phone-container,
        .birthDate-container {
          cursor: default;
          #phoneNumber,
          #birthDate {
            cursor: inherit;
          }
        }
      }
    }
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
}
