.uploadMedia {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
  }

  .video-heading,
  .document-heading {
    width: 100%;
    padding: 1rem;
    padding-block-end: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-close {
      // width: 20px;
      // height: 20px;

      padding: 4px;
    }

    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;

      .name {
        color: var(--color-quaternary-l);
        font-size: 0.9375rem;
        font-weight: 600;
      }

      .details {
        font-size: 0.75rem;
        color: var(--color-description);
        display: flex;
        align-items: center;
        gap: 8px;

        .dot {
          display: inline-block;
          flex-shrink: 0;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: var(--color-description);
        }
      }
    }
  }

  .uploadMedia-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .heading {
      width: 100%;
      padding: 1rem;
      padding-block-end: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .filters {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1;

        .filters-wrapper {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 10px;

          .filter {
            transition: all 0.3s;
            width: 70px;
            height: auto;
            border-radius: 5px;
            background-color: var(--color-popup);
            padding-block: 5px;
            box-shadow: 0px 0px 2.5px 0px rgba(0, 0, 0, 0.25);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:active,
            &:focus-visible {
              background-color: var(--color-selected-bg);
            }

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }

            svg {
              width: 1rem;
              height: 1rem;
              margin-block-end: 1px;
            }

            .text {
              color: var(--color-quaternary-l);
              font-size: 0.65rem;
            }
          }
        }
      }

      .btn-close {
        width: 20px;
      }
    }

    .uploadedMedia-wrapper,
    .uploadedMedia-wrapper.document {
      width: calc(100% - 2rem);
      height: 100%;
      display: flex;
      margin-block: 10px;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .uploadedMedia {
        width: 50vw;
        height: 50vh;
        max-width: fit-content;
        margin: auto;

        img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .uploadedMediaSmallWidth {
        animation-duration: 1s;
        height: 50vh;
        max-width: fit-content;
        margin: auto;

        img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .uploadedMediaSmallHeight {
        animation-duration: 1s;
        width: 100%;
        max-width: fit-content;
        margin: auto;
        display: flex;
        align-items: center;

        img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .setImageInSection {
        width: auto;
        max-height: calc(100vh - 350px);
        max-width: calc(100vw - 400px);
        overflow: hidden;
      }

      .uploadedMediaSmallHeight {
        width: 100%;
        max-width: fit-content;
        margin: auto;
        display: flex;
        align-items: center;

        img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .uploadedMedia-wrapper.document {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .uploadedMedia {
        width: 80px;
        height: 90px;
        margin: revert;
      }

      .text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;

        .name {
          color: var(--color-quaternary-l);
          font-size: 0.9375rem;
          font-weight: 600;
        }

        .details {
          font-size: 0.75rem;
          color: var(--color-description);
          display: flex;
          align-items: center;
          gap: 8px;

          .dot {
            display: inline-block;
            flex-shrink: 0;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--color-description);
          }
        }
      }
    }

    .uploadedMedia-wrapper.pdf {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .uploadedMedia {
        width: 50vw;
        height: 50vh;
        margin: revert;
      }

      .text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;

        .name {
          color: var(--color-quaternary-l);
          font-size: 0.9375rem;
          font-weight: 600;
        }

        .details {
          font-size: 0.75rem;
          color: var(--color-description);
          display: flex;
          align-items: center;
          gap: 8px;

          .dot {
            display: inline-block;
            flex-shrink: 0;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--color-description);
          }
        }
      }
    }
  }

  .footer-box {
    display: flex;
    gap: 0.9375rem;
    align-items: center;
    width: min(650px, 100%);
    margin-block-end: 1.25rem;
    margin-inline: auto;
    max-width: 70%;

    .message-wrapper {
      flex: 1;
      border-radius: 5000px;
      border: 1px solid var(--color-footer-border);
      font-size: 0.9375rem;
      padding: 10px;
      background-color: var(--color-secondary);
      display: flex;
      justify-content: space-between;
      min-width: 0;

      .input-wrapper {
        flex: 1;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        min-width: 0;
        max-height: 5rem;
        position: relative;
      }

      .messageInput {
        width: 100%;
        color: var(--color-quaternary-l);
        border: none;
        outline: none;
        padding-inline: 0.5rem;
        max-height: 5rem;
        overflow: hidden auto;
        white-space: pre-wrap;
        word-break: break-word;

        /* Hide the Placeholder */
        &:empty::before {
          content: attr(placeHolder);
          color: #b3b3b3;
          cursor: pointer;
        }

        &:empty:focus ::before {
          content: "";
        }
      }

      .placeHolder {
        position: absolute;
        inset: 0;
        display: inline-block;
        color: #b3b3b3;
      }
    }

    .send-Mediamessage {
      width: 3.125rem;
      height: 3.125rem;
      border-radius: 50%;
      padding: 4px;
      cursor: pointer;
      position: relative;

      &:focus-visible {
        outline: 0;
        box-shadow: 0 0 0 2px var(--color-focused);
      }

      .number {
        position: absolute;
        right: -3px;
        top: -3px;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        background-color: var(--color-offwhite);
        font-size: 0.7rem;
        color: var(--color-quaternary);
        display: grid;
        place-items: center;

        cursor: default;
        box-shadow: 0 1px 3px rgba(11, 20, 26, 0.4);
      }
    }
  }

  .select-media {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding-inline: 2rem;
    padding-block: 5px;

    ::-webkit-scrollbar {
      width: 6px !important;
      height: 6px !important;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--color-scroll-thumb);
      border-radius: 5000px;
    }

    .media-wrapper {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;

      .media {
        width: 50px;
        height: 50px;
        margin: 5px 2px 7px;
        border: 2px solid transparent;
        border-radius: 1px;
        transition: all 0.3s ease;
        position: relative;

        &:focus,
        &:hover {
          outline: 0;
          .mediaDelete {
            display: grid;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .mediaDelete {
          width: 100%;
          height: 50%;
          background: linear-gradient(
            210deg,
            rgba(0, 0, 0, 0.3) -8.48%,
            rgba(0, 0, 0, 0) 72.11%
          );
          position: absolute;
          right: 0;
          top: 0;
          display: none;
          place-items: center;
          transition: all 0.3s;
          overflow: hidden;

          img {
            width: 1.2rem;
            height: 1.2rem;
            position: absolute;
            right: 3px;
            top: 3px;
          }
        }
      }

      .media.active {
        border: 2px solid var(--color-primary);
        overflow: hidden;
      }
    }

    .media,
    .btn-upload {
      border-radius: 1px;
      flex-shrink: 0;
      overflow: hidden;
      cursor: pointer;
    }

    .btn-upload {
      width: 35px;
      height: 45px;
      background-color: transparent;
      border: 1.5px solid var(--color-primary);
      margin-block-end: 5px;
      display: grid;
      place-items: center;

      // img {
      //   width: 1.125rem;
      //   height: 1.125rem;
      // }

      input[type="file"] {
        display: none;
      }
    }

    .discard-popup {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);

      .popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 410px;
        background-color: var(--color-secondary);
        border-radius: 1rem;
        padding: 1.25rem;
        display: flex;
        flex-direction: column;
        gap: 0.9375rem;

        .text {
          font-size: 1.125rem;
          letter-spacing: 1px;
        }

        .cta-btn {
          align-self: flex-end;
          display: flex;
          align-items: center;
          gap: 2.5rem;

          .cancel,
          .discard {
            font-size: 1rem;
            padding: 0.2em;
            color: var(--color-primary-d);
          }
        }
      }
    }
  }

  .discard-popup {
    position: fixed;
    inset: 0 0 0 30%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .popup {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 410px;
      background-color: var(--color-popup);
      border-radius: 1rem;
      padding: 1.25rem;
      display: flex;
      flex-direction: column;
      gap: 0.9375rem;

      .text {
        font-size: 1.125rem;
        letter-spacing: 1px;
      }

      .cta-btn {
        align-self: flex-end;
        display: flex;
        align-items: center;
        gap: 2.5rem;

        .cancel,
        .discard {
          font-size: 1rem;
          padding: 0.2em;
          color: var(--color-primary-d);
        }
      }
    }
  }
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 35px;
  }
}
