@import "animation";

.forwardMessage {
  width: 100%;
  min-height: 100vh;
  background-color: hsl(0, 0%, 16%, 0.6);
  display: grid;
  place-items: center;
  position: relative;

  @include delay();

  .forwardMessage-container {
    width: 400px;
    margin-block: 1rem;
    background-color: var(--color-secondary);

    display: flex;
    flex-direction: column;
    height: 90vh;

    @include delay-dialog();

    @media screen and (max-width: 1025px) and (max-height: 1380px) {
      max-height: 30rem;
    }

    @media screen and (min-width: 1600px) {
      max-height: 40rem;
    }

    .forward-to {
      background-color: var(--color-primary-d);

      .forward-to-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.625rem;

        .title {
          font-size: 1.25rem;
          font-weight: 500;
          color: var(--color-offwhite);
        }

        .btn-close {
          padding: 4px;
        }
      }
    }

    .forward-search {
      height: 33px;
      padding-inline: 0.9375rem;
      margin-block: 0.9375rem;

      .search-bar {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;

        input {
          color: var(--color-quaternary-l);
          flex: 1;
          height: 100%;
          appearance: none;
          border: none;
          outline: none;
          background-color: rgba(118, 118, 128, 0.12);
          padding: 10px;
          padding-inline-start: 30px;
          border-radius: 0.5rem;
          font-size: 0.875rem;

          &::placeholder {
            color: var(--color-description);
          }
        }

        .search,
        .back {
          position: absolute;
          left: 10px;

          cursor: pointer;
        }

        .search.rotate,
        .back.rotate {
          opacity: 0;
          transform: rotate(135deg);
        }
      }
    }

    .forward-contacts {
      overflow-y: auto;
      height: 100%;
      max-height: 100vh;
      flex: 1;

      .profile {
        display: flex;
        padding-inline-start: 0.9375rem;
        align-items: center;
        height: 4.375rem;

        cursor: pointer;
        transition: 0.2s ease-out;

        &:hover,
        &:focus-visible {
          background-color: var(--color-selected-bg);
        }

        &:focus-visible {
          outline: 0;
        }

        .profile-image {
          width: 3.125rem;
          height: 3.125rem;
          border-radius: 50%;
          margin-inline-end: 0.75rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .profile-info {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.625rem;

          padding-inline-end: 0.9375rem;
          border-block-end: 0.5px solid var(--color-border);

          height: 100%;

          &:first-child {
            border-block-start: 0.5px solid var(--color-arrow);
          }
          .profile-info-name {
            .profile-name {
              font-size: 0.9375rem;
              font-weight: 500;
              color: var(--color-quaternary-l);
            }

            .profile-info-message {
              color: var(--color-description);
              font-size: 0.7rem;
              font-weight: 400;
              overflow: hidden;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }

          .profile-check {
            align-self: center;
            flex-shrink: 0;

            input[type="checkbox"] {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;
            }

            .checkmark {
              display: inline-flex;
              justify-content: center;
              align-items: center;

              width: 1.125rem;
              height: 1.125rem;
              border: 1.5px solid var(--color-radio-border);
              border-radius: 50%;
              background-color: transparent;

              vertical-align: middle;

              transition: border-color 0.3s, background-color 0.3s;
              cursor: pointer;

              &::after {
                content: "";
                display: inline-block;
                width: 5.5px;
                height: 10px;
                border: solid transparent;
                border-width: 0 1.75px 1.75px 0;
                transform: rotate(45deg);
                margin-block-start: -2px;

                transition: border-color 0.3s;
              }
            }

            input[type="checkbox"]:checked + .checkmark {
              background-color: var(--color-primary-d);
              border: 1.5px solid var(--color-primary-d);
            }

            input[type="checkbox"]:checked + .checkmark::after {
              border-color: var(--color-white);
            }
          }
        }
      }

      .contact-notFound {
        margin-block-start: 3rem;
        text-align: center;
        color: var(--color-description);
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
    // .forward-message,
    // .forward-message-media {
    //   background-color: inherit;
    //   padding: 0.35rem 0.5rem;

    //   display: flex;
    //   align-items: center;
    //   // box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);

    //   .forward-message-names {
    //     color: var(--color-description);
    //     padding-inline-end: 1.5rem;

    //     flex: 1;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     white-space: nowrap;

    //     .name {
    //       display: inline-block;
    //       color: inherit;
    //       font-size: 0.9375rem;
    //     }
    //   }

    //   .send-message {
    //     cursor: pointer;

    //     &:focus-visible {
    //       outline: 0;
    //       border-radius: 2px;
    //       box-shadow: 0 0 0 2px var(--color-focused);
    //     }
    //   }
    // }

    // .forward-message-media {
    //   min-height: 80px;
    //   box-shadow: 0px 0px 12px 0px #00000026;

    //   .wrapper {
    //     // padding: 0.35rem 0.5rem;

    //     width: 100%;

    //     display: flex;
    //     gap: 0.5rem;
    //     justify-content: flex-start;
    //     background-color: var(--color-popup);

    //     .media {
    //       width: 4.375rem;
    //       height: 4.375rem;
    //       border-radius: 5px;
    //       border: 1px solid var(--color-primary-d);

    //       overflow: hidden;
    //       position: relative;
    //       flex-shrink: 0;

    //       img {
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //       }

    //       .media-type {
    //         position: absolute;
    //         inset: 0;
    //         background-color: hsl(0, 0, 0, 0.2);
    //         display: grid;
    //         place-items: center;

    //         img {
    //           width: 1rem;
    //           height: 1rem;
    //         }
    //       }
    //     }

    //     .input-wrapper {
    //       align-self: stretch;
    //       flex: 1;
    //       border-radius: 5px;
    //       border: 1px solid var(--color-footer-border);

    //       .messageInput {
    //         height: 100%;
    //         width: 100%;
    //         color: var(--color-quaternary-l);
    //         border: none;
    //         outline: none;
    //         padding-inline: 0.5rem;
    //         max-height: 4rem;
    //         overflow: hidden auto;
    //         white-space: pre-wrap;
    //         word-break: break-word;

    //         /* Hide the Placeholder */
    //         &:empty::before {
    //           content: attr(placeHolder);
    //           color: var(--color-description);
    //         }

    //         &:empty:focus ::before {
    //           content: "";
    //         }
    //       }
    //     }
    //   }
    // }

    // .forward-message {
    //   animation: scaleUp 3s ease;
    // }

    .forward-message-media {
      background-color: inherit;
      padding: 0.35rem 0.5rem;

      display: flex;
      flex-direction: column;
      overflow-y: clip;
      box-shadow: 0px 0px 12px 0px #00000026;

      .forward-media {
        // height: 80px;
        background-color: var(--color-popup);

        width: 100%;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;

        .media {
          width: 4.375rem;
          height: 4.375rem;
          border-radius: 5px;
          border: 1px solid var(--color-primary-d);

          overflow: hidden;
          position: relative;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .media-type {
            position: absolute;
            inset: 0;
            background-color: hsl(0, 0, 0, 0.2);
            display: grid;
            place-items: center;
          }
        }

        .input-wrapper {
          align-self: stretch;
          flex: 1;
          border-radius: 5px;
          border: 1px solid var(--color-footer-border);

          .messageInput {
            height: 100%;
            width: 100%;
            color: var(--color-quaternary-l);
            border: none;
            outline: none;
            padding-inline: 0.5rem;
            max-height: 4rem;
            overflow: hidden auto;
            white-space: pre-wrap;
            word-break: break-word;

            /* Hide the Placeholder */
            &:empty::before {
              content: attr(placeHolder);
              color: var(--color-description);
            }

            &:empty:focus ::before {
              content: "";
            }
          }
        }
      }

      .forward-message {
        animation: scaleUp 250ms ease;

        background-color: inherit;
        padding: 0.35rem 0.5rem;

        display: flex;
        align-items: center;

        .forward-message-names {
          color: var(--color-description);
          padding-inline-end: 1.5rem;

          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .name {
            display: inline-block;
            color: inherit;
            font-size: 0.9375rem;
          }
        }

        .send-message {
          cursor: pointer;

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }
        }
      }
    }
  }

  @keyframes scaleUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
}
