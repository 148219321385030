.archiveSettings {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 11;

  &.open {
    animation: slideInFromRight 250ms ease-out forwards;
  }

  &.close {
    animation: slideOutToRight 250ms ease-out forwards;
  }

  .archiveSettings-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    height: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: var(--color-primary-d);
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;

      min-height: 54px;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          padding: 4px;
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
        }
      }

      .btn-archive-menu {
        background-color: transparent;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        transition: background-color 0.3s ease-out;

        svg {
          margin-inline: auto;
        }

        &:active {
          background-color: hsl(186, 49%, 92%, 0.2);
        }

        &:focus-visible {
          outline: 0;
        }
      }
    }

    .archived-posts {
      height: 100%;
      overflow-y: auto;

      // padding-block: 0.9375rem;

      .media-container {
        display: flex;
        gap: 3px;
        flex-wrap: wrap;

        .media {
          width: 32.5%;
          padding-bottom: 32.5%;
          height: 0;

          position: relative;
          overflow: hidden;
          cursor: pointer;

          @media screen and (max-width: 769px) {
            width: 49%;
            padding-bottom: 49%;
          }

          @media screen and (max-width: 501px) {
            width: 48%;
            padding-bottom: 48%;
          }

          img,
          video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            position: absolute;

            inset: 0;
          }

          &:focus-visible {
            outline: 2px solid var(--color-focused);
          }
        }

        .blank-image {
          position: absolute;
          inset: 0;
          background-color: var(--color-description);
        }

        .media-type {
          position: absolute;
          top: 0px;
          right: 0px;

          padding: 6px;

          z-index: 9;

          background: radial-gradient(
            50% 50% at 50% 50%,
            #000000 0%,
            rgba(0, 0, 0, 0) 100%
          );

          svg {
            width: 1rem;
          }
        }

        .story-viewed-number {
          &::after {
            content: "";
            width: 100%;
            height: 30px;
            display: inline-block;
            background: linear-gradient(
              0deg,
              #000000 0%,
              rgba(0, 0, 0, 0) 100%
            );
            opacity: 0.4;
            position: absolute;
            right: 0;
            bottom: 0;
          }

          .viewIcon {
            display: inline-flex;
            flex-direction: row-reverse;
            align-items: center;
            gap: 3px;

            position: absolute;
            right: 3px;
            bottom: 0;

            z-index: 10;
            svg {
              width: 0.75rem;
            }

            .viewed-number {
              color: var(--color-secondary);
              font-size: 0.75rem;
            }
          }
        }

        .select-post {
          position: absolute;
          inset: 0;

          z-index: 10;
          background-color: hsl(0, 0%, 0%, 0.7);

          .group-check {
            position: absolute;
            top: 4px;
            right: 4px;

            input[type="checkbox"] {
              position: absolute;
              height: 0;
              width: 0;
            }

            .checkmark {
              display: block;
              width: 0.9375rem;
              height: 0.9375rem;
              border: 1.2px solid var(--color-radio-border);
              border-radius: 50%;
              transition: border-color 0.3s, background-color 0.3s;
              background-color: transparent;
              position: relative;

              &::after {
                content: "";
                width: 5px;
                height: 9px;
                border: solid transparent;
                border-width: 0 1.5px 1.5px 0;
                transform: rotate(45deg);
                position: absolute;
                top: 1px;
                left: 4px;
                transition: border-color 0.3s;
              }
            }

            input[type="checkbox"]:checked + .checkmark {
              display: block;
              background-color: var(--color-primary-d);
              border: 1.2px solid var(--color-primary-d);
            }

            input[type="checkbox"]:focus + .checkmark {
              border-color: var(--color-primary-d);
            }

            input[type="checkbox"]:not(:checked) + .checkmark {
              border-color: var(--color-radio-border);
            }

            input[type="checkbox"]:checked + .checkmark::after {
              display: block;
              border-color: var(--color-white);
            }
          }
        }
      }
    }

    .no-data {
      margin: auto;
    }
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
}
