.viewStory {
  position: fixed;
  inset: 0;

  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;

  width: 100vw;
  height: 100svh;
  background-color: #4a4a4a;

  overflow: hidden;

  .viewStory-wrapper {
    position: relative;

    width: 100%;
    height: 100svh;

    .header-viewStory {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 17vh;
      background: linear-gradient(
        180deg,
        #000000 -46.82%,
        rgba(0, 0, 0, 0) 100%
      );

      padding-inline: 1.5rem 1rem;
      padding-block-start: 1.5rem;

      z-index: 10;

      .container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .btn-back,
        .btn-close {
          padding: 4px;
        }

        .status-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;

          width: 480px;

          .total-status {
            flex: 1 1;
            width: 100%;

            .total-status-wrapper {
              display: flex;
              justify-content: center;
              gap: 10px;

              .status-progrss {
                width: 125px;
                height: 5px;
                background-color: var(--color-description);
                border-radius: 500px;

                position: relative;

                .progress {
                  position: absolute;
                  inset: 0;

                  width: 70%;
                  background-color: var(--color-white);
                  border-radius: inherit;
                }
              }
            }
          }

          .status-info {
            display: flex;
            gap: 8px;

            flex: 1 1;
            width: 100%;

            .status-user-info {
              display: flex;
              align-items: center;

              .user-image {
                width: 2.5rem;
                height: 2.5rem;
                background-color: var(--color-primary-d);
                border-radius: 50%;
                margin-inline-end: 0.5rem;

                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .info {
                display: flex;
                flex-direction: column;

                .name,
                .uploaded-date {
                  display: inline-block;
                  color: var(--color-offwhite);
                }

                .name {
                  font-size: 1rem;

                  overflow: hidden;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  width: 36ch;
                }

                .uploaded-date {
                  font-size: 0.75rem;
                }
              }
            }

            .status-menu {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              flex: 1;

              position: relative;

              .btn-play,
              .btn-volume,
              .btn-menu {
                flex-shrink: 0;
              }

              .btn-play {
                margin-inline-end: 5px;
              }

              .btn-volume {
                margin-inline-end: 0.5rem;
              }

              .btn-menu {
                width: 24px;
                height: 24px;

                padding: 4px 8px;
                border-radius: 50%;

                &:focus {
                  background-color: var(--color-selected-bg);
                }

                svg {
                  margin-inline: auto;
                }

                .menu-options {
                  display: flex;
                  flex-direction: column;
                  gap: 2px;
                  align-items: flex-start;

                  background-color: var(--color-popup);
                  padding-block: 8px;
                  width: 115px;
                  border-radius: 4px;

                  position: absolute;
                  top: 2.875rem;
                  right: 0.875rem;

                  animation: menuOpen 250ms ease-out forwards;
                  transform-origin: top right;

                  .menu-option {
                    color: var(--color-quaternary-l);
                    font-size: 0.875rem;
                    padding: 8px 1rem;
                    width: 100%;
                    text-align: start;
                    background-color: transparent;
                    transition: background-color 250ms ease-out;

                    &:hover {
                      background-color: var(--color-selected-bg);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .btn-backwards,
    .btn-forward {
      display: inline-block;
      width: 45px;
      height: 45px;
      background-color: rgb(0, 0, 0, 50%);
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;

      display: grid;
      place-items: center;
    }

    .btn-backwards,
    .slick-prev {
      left: 2rem;
    }

    .btn-forward,
    .slick-next {
      right: 2rem;
    }

    .background-blurred {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      position: absolute;
      inset: 0;

      z-index: -1;

      width: 100%;
      height: 100vh;
      opacity: 0.5;
      filter: blur(20px);
    }

    // <--------------------- Original ---------------->

    .storyMedia-view {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100svh;
      max-height: 100svh;
      // background-color: #000;
      // overflow: auto;

      .loading-spinner {
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
      }

      .media-container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .media-view-container {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .thumbnail-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;

            .thumbnail {
              width: 100%;
              height: 100%;
              object-fit: contain;
              max-width: 100vw;
              max-height: 100vh;
            }
          }
        }

        .media-element {
          position: relative;
          z-index: 2;
          width: 100%;
          height: 100%;
          max-width: 100vw;
          max-height: 100vh;
          object-fit: contain;
        }

        .story-text-wrapper {
          width: 100%;
          // height: 120px;
          background-color: hsl(0, 0%, 0%, 0.4);
          padding-block: 0.75rem 5rem;
          z-index: 10;
          position: absolute;
          bottom: 0;

          .story-text-info {
            color: var(--color-offwhite);
            font-size: 1rem;
            text-align: center;
            line-height: 1.35;

            .read-more {
              display: inline-block;
              font-size: 0.8rem;
              color: var(--color-primary-d);
              margin-inline-start: 3px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .story-viewed-number {
      display: inline-flex;
      align-items: center;
      gap: 5px;

      background-color: hsl(0, 0%, 7%, 0.6);
      padding: 4px 12px;
      border-radius: 5px;

      position: absolute;
      bottom: 1.25rem;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      z-index: 10;

      @media screen and (max-width: 1025px) {
        bottom: 2rem;
      }

      // .viewIcon {
      //   width: 1rem;
      //   height: 1rem;

      //   svg {
      //     width: 100%;
      //     height: 100%;
      //   }
      // }

      .viewed-number {
        color: var(--color-offwhite);
        font-size: 0.875rem;
      }
    }

    .story-reply {
      position: absolute;
      bottom: 0;

      width: 100%;
      // height: 10vh;
      padding-block-end: 0.875rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 192.14%);

      z-index: 10;

      display: flex;
      justify-content: center;

      transition: all 0.3s;

      .story-reply-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        background-color: transparent;
        padding: 0.875rem 0.9375rem;
        border-radius: 1rem;

        transition: all 0.3s cubic-bezier(1, 1, 1, 1);

        width: 100%;
        max-width: 900px;

        &:has(.message-wrapper:focus-within) {
          background-color: hsl(0, 0%, 8%, 0.8);
          // padding: 0.875rem 2rem;
          // border-radius: 1rem;
        }

        &:has(.message-wrapper:focus-within) .btn-cancel {
          display: block;
        }

        .emojis-stickers-container {
          width: 100%;
          // height: 100%;
          height: 350px;
          background-color: rebeccapurple;

          margin-block-end: 8px;

          display: none;
        }

        .story-sendMessage {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          // width: 100%;
          width: calc(100% - 50px);

          flex: 1;
        }

        .story-sendMessage-cta {
          display: flex;
          gap: 0.625rem;

          align-self: flex-end;

          .btn-cancel,
          .btn-emoji,
          .btn-sticker {
            width: 40px;
            height: 40px;
            background-color: #323232;
            border-radius: 50%;
            overflow: hidden;

            svg {
              // width: 50%;
              margin-inline: auto;
            }
          }

          .btn-cancel {
            display: none;

            svg {
              width: 45%;
            }
          }
        }

        .message-wrapper {
          border-radius: 5000px;
          font-size: 0.9375rem;
          padding: 0px 15px 0px 0px;
          background-color: #323232;
          scroll-margin-inline: 10px;

          display: flex;
          justify-content: space-between;
          min-width: 0;

          max-height: 350px;
          overflow: hidden auto;
          white-space: pre-wrap;
          word-break: break-word;

          // &:not(:empty) {
          //   border-radius: 10px;
          // }

          flex: 1;
          width: calc(100% - 80px);

          cursor: pointer;

          transition: all 0.3s ease-out;

          &:focus {
            border-radius: 10px;
          }

          &:focus .story-reply-wrapper {
            background-color: hsl(0, 0%, 8%, 0.89);
            padding: 0.875rem 0.9375rem;
            border-radius: 1rem;
          }

          .input-wrapper {
            flex: 1;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            min-width: 0;
            // max-height: 5rem;
            // white-space: normal;
            // &::-webkit-scrollbar {
            //   display: none;
            // }

            position: relative;
          }

          .messageInput {
            // resize: none;
            width: 100%;
            color: var(--color-white);
            // color: #b3b3b3;
            border: none;
            outline: none;
            // width: 100%;
            // flex: 1;
            padding: 0.625rem;
            // max-height: 8rem;
            // overflow: hidden auto;
            white-space: pre-wrap;
            word-break: break-word;

            line-height: 1.25;
            // overflow-wrap: break-word;
            // white-space: pre-wrap;

            // &::placeholder {
            //   color: #b3b3b3;
            // }

            // &::-webkit-scrollbar {
            //   display: none;
            // }

            /* Hide the Placeholder */
            &:empty::before {
              content: attr(placeHolder);
              color: var(--color-description);
              cursor: text;

              // position: absolute;
            }

            &:empty:focus ::before {
              content: "";
            }
          }
        }

        .send-message {
          width: 38px;
          height: 38px;
          border-radius: 50%;

          background-color: var(--color-primary-d);

          display: grid;
          place-items: center;

          cursor: pointer;

          align-self: flex-end;

          svg {
            width: 1.625rem;
            // height: 1.5rem;
          }
        }
      }
    }
  }
}

.slick-slider {
  position: revert;
  height: 100%;

  .slick-list {
    height: 100%;

    .slick-track {
      height: 100%;
    }
  }
}

@keyframes menuOpen {
  0% {
    opacity: 0;
    scale: 0;
  }

  100% {
    opacity: 1;
    scale: 1;
  }
}

// .slick-prev {
//   left: -210px;
//   top: 30%;
// }
// .slick-next {
//   left: -185px;
//   top: 30%;
// }
