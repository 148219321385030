.notallowCamera {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  opacity: 0;
  // animation: delay 3s linear forwards;
  animation: delay 0.3s ease forwards;

  display: grid;
  place-items: center;

  .dialog {
    width: 450px;
    background-color: var(--color-popup);
    padding: 1.25rem 1.35rem;
    border-radius: 0.625rem;

    display: flex;
    flex-direction: column;

    animation: delay-dialog 0.3s ease forwards;

    .dialog-info {
      display: flex;
      gap: 1rem;
      margin-block-end: 1rem;

      h4 {
        font-size: 1.35rem;
        font-weight: 400;
        color: var(--color-quaternary-l);
      }

      // .btn-back {
      //   width: 2.5rem;
      //   height: 2.5rem;
      //   border-radius: 50%;
      //   background-color: var(--color-primary-d);
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   align-self: flex-end;
      // }
    }

    .description {
      font-size: 0.875rem;
      color: var(--color-quaternary-l);
      line-height: 1.45;
      margin-block-end: 2rem;
    }

    // .description.space {
    //   padding-inline-start: 3.5rem;
    // }

    .btn-allow {
      font-size: 0.875rem;
      background-color: var(--color-primary-d);
      padding: 0.5rem 1.5rem;
      color: var(--color-offwhite);
      border-radius: 5px;
      align-self: flex-end;
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
