.mediaAutoDownload {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 11;

  animation: slideInLeft 250ms ease forwards;

  &.open {
    animation: slideInLeft 250ms ease forwards;
  }

  &.close {
    animation: slideOutLeft 250ms ease forwards;
  }

  .mediaAutoDownload-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-settings-bg);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;
      min-height: 54px;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }
    }

    .mediaAutoDownload-options {
      background-color: var(--color-secondary);
      padding-block: 1.5rem;
      padding-block-end: 0;

      margin-block-end: 0.9375rem;

      .list {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        padding-block-end: 1.5rem;
        padding-inline: 0.9375rem;

        .mediaAutoDownload-listItem {
          display: flex;
          align-items: center;
          gap: 0.75rem;

          .group-check {
            margin-block-start: -2px;
            input[type="radio"] {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;
            }

            .checkmark {
              display: inline-flex;
              align-items: center;
              justify-content: center;

              position: relative;

              min-width: 18px;
              width: 18px;
              height: 18px;
              vertical-align: middle;

              &:focus-visible {
                outline: 0;
                border-radius: 2px;
                box-shadow: 0 0 0 2px var(--color-focused);
              }

              .radio-cmn {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;
                border-radius: 50%;

                line-height: 0;
                white-space: nowrap;

                cursor: pointer;
              }

              .offRadio {
                border: 1.5px solid var(--color-description);
                background-color: transparent;

                transition: border 250ms ease;
              }

              .onRadio {
                will-change: transform;
                background-color: var(--color-primary-d);

                scale: 0;
                transition: scale 250ms ease;
              }
            }

            input[type="radio"]:checked + .checkmark .onRadio,
            input[type="radio"]:focus + .checkmark .onRadio {
              scale: 0.6;
            }
            input[type="radio"]:checked + .checkmark .offRadio,
            input[type="radio"]:focus + .checkmark .offRadio {
              border-color: var(--color-primary-d);
            }
          }

          .description {
            font-size: 1rem;
            color: var(--color-quaternary-l);
            flex: 1;

            cursor: pointer;
          }
        }
      }

      .mediaAutoDownload-options-info {
        padding: 0.9375rem;
        border-block: 0.5px solid var(--color-border);

        .info {
          color: var(--color-description);
          font-size: 0.8rem;
        }
      }
    }

    .mediaAutoDownload-cta {
      height: 100%;
      background-color: var(--color-secondary);

      .reset-autoDownload {
        display: flex;
        gap: 1.5rem;
        align-items: center;

        height: 4rem;
        padding-inline-start: 0.9375rem;
        border-block-end: 0.5px solid var(--color-border);

        transition: background 0.2s ease-out;
        cursor: pointer;

        &:hover,
        &:focus-visible {
          background: var(--color-selected-bg);
        }

        &:focus-visible {
          outline: 0;
        }

        .reset-autoDownload-listItem-image {
          width: 30px;
          height: 30px;
          background-color: var(--color-primary-d);
          border-radius: 5px;
          overflow: hidden;
          display: grid;
          place-items: center;
          flex-shrink: 0;

          svg {
            width: 65%;
          }
        }

        .reset-autoDownload-listItem-info {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-inline-end: 0.625rem;
          height: 100%;

          .reset-autoDownload-listItem-name {
            color: var(--color-quaternary-l);
            font-size: 1rem;
          }
        }
      }
    }
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}
