.notification {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 11;

  animation: slideInLeft 250ms ease forwards;

  &.open {
    animation: slideInLeft 250ms ease forwards;
  }

  &.close {
    animation: slideOutLeft 250ms ease forwards;
  }

  .notification-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    .header {
      background-color: var(--color-primary-d);
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;

      min-height: 54px;

      display: flex;
      justify-content: space-between;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          padding: 4px;
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    overflow-y: auto;

    .notification-cta {
      display: flex;
      gap: 5px;
      align-items: center;

      margin-block: 0.625rem;
      padding: 5px 0.35rem;

      overflow-y: hidden;
      overflow-x: auto;

      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .btn-cta {
        background-color: #f3f3f3;
        padding: 0.35rem 0.875rem;
        font-size: 0.875rem;
        color: var(--color-quaternary-l);
        border-radius: 5px;
        min-width: fit-content;
      }

      .btn-cta.active {
        background-color: var(--color-primary-d) !important;
        color: var(--color-offwhite);
      }
    }

    .notification-userList {
      height: 100%;
      overflow-y: auto;

      .notification-posts-info {
        display: flex;
        align-items: center;

        padding-inline-start: 0.9375rem;
        height: 4.375rem;

        .profile-image {
          width: 3.125rem;
          height: 3.125rem;
          border-radius: 50%;
          margin-inline-end: 0.75rem;
          overflow: hidden;

          cursor: pointer;

          &:focus-visible {
            outline: 0;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          @media screen and (max-width: 1025px) {
            width: 2.5rem;
            height: 2.5rem;
          }
        }

        .profile-info {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.625rem;
          padding-inline-end: 0.9375rem;
          // padding-block-end: 5px;
          border-block-end: 0.5px solid var(--color-border);

          height: 100%;

          // &:has(:not(.notification-post-image)) .profile-name {
          //   flex-basis: 90%;
          // }

          .profile-name {
            display: flex;
            flex-direction: column;
            gap: 2px;

            flex-basis: 90%;

            .notification-user-info {
              font-size: 0.875rem;
              line-height: 1.25;
              color: var(--color-quaternary-l);
              font-weight: 300;

              overflow: hidden;
              word-break: break-word;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

              .notification-user-name {
                font-weight: 400;

                cursor: pointer;

                &:focus-visible {
                  outline: 0;
                  border-radius: 2px;
                  box-shadow: 0 0 0 2px var(--color-focused);
                }
              }

              .tag-user-name {
                color: var(--color-primary-d);
                font-weight: 400;
                padding-inline-start: 2px;

                cursor: pointer;

                &:focus-visible {
                  outline: 0;
                  border-radius: 2px;
                  box-shadow: 0 0 0 2px var(--color-focused);
                }
              }

              @media screen and (max-width: 1025px) {
                font-size: 0.8rem;
              }
            }

            .notification-time {
              font-size: 0.75rem;
              color: var(--color-description);
            }
          }

          .profile-cta {
            display: flex;
            align-items: center;
            gap: 5px;

            .notification-delete {
              display: none;
              transition: display 250ms ease;

              flex-shrink: 0;
              cursor: pointer;

              &:focus-visible {
                outline: 0;
                border-radius: 2px;
                box-shadow: 0 0 0 2px var(--color-focused);
              }
            }

            .notification-post-image,
            .blank-image {
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 5px;
              overflow: hidden;

              flex-shrink: 0;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              cursor: pointer;

              &:focus-visible {
                outline: 0;
                border-radius: 2px;
                box-shadow: 0 0 0 2px var(--color-focused);
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .blank-image {
                background-color: var(--color-description);
              }
            }
          }

          &:has(.notification-post-image) .profile-name {
            flex-basis: 70%;
          }
        }

        &:hover .profile-cta .notification-delete,
        &:focus-visible .profile-cta .notification-delete {
          display: block;
        }

        &:focus-visible {
          outline: none;
        }
      }
    }

    .notification-default {
      height: 100%;

      .notification-default-info {
        height: 100%;
        display: flex;
        text-align: center;

        .default-text {
          display: inline-block;
          margin: auto;
          color: var(--color-quaternary-l);
          font-weight: 500;
          letter-spacing: 0.2px;
        }
      }
    }
  }

  .no-data {
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100%);
    }
  }
}
