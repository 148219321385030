.takePhoto {
  width: 100%;
  height: 100%;
  // height: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: hidden;
  transform: translateY(100%);
  animation: translateup 0.5s ease forwards;
  // transform-origin: bottom top;

  .heading {
    display: flex;
    gap: 1.5rem;
    align-items: center;

    padding-block: 1.5rem 2.5rem;
    padding-inline: 2rem;
    color: var(--color-quaternary-l);
    z-index: 10;

    .close {
      width: 1.5rem;
      cursor: pointer;
    }

    span {
      font-size: 1rem;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;

    @media screen and (min-width: 1900px) {
      justify-content: center;
    }

    .photo-container {
      // flex: 1;
      // width: 100%;
      // height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      // width: max(500px, 100%);
      height: 65vh;

      @media screen and (max-width: 1600px) {
        width: 45vw;
      }

      @media screen and (min-width: 1900px) {
        justify-content: center;
      }

      .photo {
        width: 100%;
        height: 450px;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .takePhoto-camera {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 65px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
}

@keyframes translateup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    // scale: 1;
    transform: translateY(0);
    // height: 100%;
  }
}
