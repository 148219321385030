.contactmenu {
  width: 200px;
  position: absolute;
  white-space: nowrap;
  height: auto;
  border-radius: 10px;
  background-color: var(--color-secondary);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding-block: 10px;
  position: absolute;
  z-index: 1;


  .menu-list {
    padding-inline-start: 10px;
    li {
      border-bottom: 1px solid #f0f0f0;
      padding-block-end: 5px;
      margin-block-end: 5px;
      font-size: 0.875rem;
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
        padding-block-end: 0;
        margin-block-end: 0;
      }
    }
  }
}
