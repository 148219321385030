.teparsProfile {
  width: 100%;
  // height: 100%;
  overflow-y: auto;
  background-color: var(--color-secondary);
  scrollbar-gutter: stable;

  transform: translateZ(0);
  will-change: transform;

  // -webkit-overflow-scrolling: touch;

  .user-profile-wrapper {
    height: 100%;
    .user-profile {
      display: flex;
      flex-direction: column;

      padding-block-end: 5px;

      .user-profile-details {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        margin-block: 1.5rem;

        .profile-image {
          width: 130px;
          height: 130px;
          border-radius: 50%;
          margin-inline: auto;
          position: relative;

          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }

          .edit-image {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: var(--color-primary-d);
            border: 2.5px solid var(--color-secondary);

            display: grid;
            place-items: center;

            position: absolute;
            bottom: 4px;
            right: 0;

            cursor: pointer;

            img {
              width: 60%;
              height: 60%;
            }
          }
        }

        .user-stats {
          display: flex;
          flex-direction: column;
          // gap: 0.625rem;
          width: 70%;
          margin-inline: auto;

          .user-name {
            color: var(--color-quaternary-l);
            font-size: 1.25rem;
            font-weight: 600;
            text-align: center;

            margin-block-end: 0.2rem;
          }

          .user-phone {
            color: var(--color-quaternary-l);
            font-size: 0.9375rem;
            text-align: center;
            margin-block-end: 0.625rem;
          }

          .stats {
            display: flex;
            justify-content: center;
            align-items: center;

            .posts,
            .teps {
              display: flex;
              flex-direction: column;
              align-items: center;

              .number {
                font-size: 1.25rem;
                font-weight: 500;
                color: var(--color-primary-d);
                line-height: 1.25;
              }

              .visual-text {
                display: flex;
                align-items: center;
                gap: 5px;

                .text {
                  font-size: 1rem;
                  line-height: 1.25;
                  color: var(--color-quaternary-l);
                }

                .post-image,
                .teps-image {
                  width: 1.125rem;
                  height: 1.125rem;

                  flex-shrink: 0;

                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }

                .teps-image {
                  // margin-block-start: -5px;
                }
              }
            }

            .posts {
              padding-inline-end: 3rem;
              position: relative;

              &::after {
                content: "";
                display: inline-block;
                width: 1.25px;
                height: 38px;
                background-color: #00000030;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            .teps {
              padding-inline-start: 3rem;
            }
          }
        }
      }

      .user-status {
        padding-inline: 0.9375rem;
        margin-block-end: 0.9375rem;

        .main {
          display: inline-block;
          font-size: 1.125rem;
          line-height: 1.25;
          font-weight: 500;
          color: var(--color-description);
          margin-block-end: 3px;
        }

        .sub-status,
        .sub-link {
          font-size: 1rem;
          color: var(--color-profile-description);
        }

        .sub-status {
          line-height: 1.35;

          overflow-wrap: break-word;

          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 10;
          -webkit-box-orient: vertical;
        }

        .profile-link {
          width: fit-content;
          .sub-link {
            display: flex;
            align-items: center;
            gap: 2px;
            line-height: 1.4;
            color: var(--color-primary-d);

            svg {
              margin-block-start: 1.875px;
            }

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }
          }
        }
      }

      .user-tepars-cta {
        display: flex;
        // justify-content: space-between;
        justify-content: flex-start;
        align-items: center;
        // row-gap: 10px;
        gap: 10px;
        padding-inline: 0.9375rem;
        flex-wrap: wrap;

        .editProfile,
        .editGroups,
        .editSettings {
          background-color: var(--color-primary-d);
          padding-block: 0px;
          padding-inline: 1.625rem;
          border-radius: 5px;
          height: 2.5rem;

          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          flex-shrink: 0;

          cursor: pointer;

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          @media screen and (max-width: 1281px) {
            padding-block: 5px;
            padding-inline: 1.5rem;
          }

          .edit-profile-image,
          .edit-groups-image {
            width: 1.5rem;
            height: 1.5rem;

            @media screen and (max-width: 1281px) {
              width: 1.25rem;
              height: 1.25rem;
            }

            svg {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .btn-text {
            display: inline-block;
            font-weight: 400;
            font-size: 1.1rem;
            color: var(--color-offwhite);
            margin-block-start: 3px;
            line-height: 1.25;

            @media screen and (max-width: 1281px) {
              font-size: 1rem;
            }
          }
        }

        .editSettings {
          padding-inline: 0.75rem;
          align-self: stretch;

          svg {
            width: 1.2rem;
            height: 1.2rem;

            @media screen and (max-width: 1281px) {
              width: 1.125rem;
            }
          }
        }

        & > * {
          flex: 1;
          min-width: fit-content;
        }
      }
    }

    .medias-wrapper {
      display: flex;
      flex-direction: column;

      background-color: var(--color-secondary);
      // height: 100%;

      .mediaLinksDocs-btn {
        // padding-block-start: 0.9375rem;
        background-color: inherit;

        display: flex;
        justify-content: center;
        align-items: center;

        position: sticky;
        top: 0;
        z-index: 9;

        .btns-wrapper {
          border-block-end: 2.25px solid var(--color-border);
          padding-block-start: 5px;

          display: flex;
          flex: 1;

          position: relative;
          overflow: hidden;

          & > * {
            flex-basis: 50%;
          }

          .repost,
          .media {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            gap: 5px;

            padding: 6px 13px;
            // border-block-end: 2.5px solid transparent;

            // position: absolute;
            // top: 0;
            // left: 0;
            // width: 100%;
            // height: 100%;
            // overflow-y: auto;
            // display: none;

            &.active {
              display: block;
            }

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }

            cursor: pointer;
            transition: border 0.3s ease-out;

            .media-image,
            .repost-image {
              // width: 1.4375rem;
              // height: 1.4375rem;

              // @media screen and (max-width: 1281px) {
              //   width: 1.25rem;
              //   height: 1.25rem;
              // }
            }

            .text {
              color: var(--color-description);
              font-size: 1.25rem;

              @media screen and (max-width: 1281px) {
                font-size: 1rem;
              }
            }
          }

          .repost.menu-selected,
          .media.menu-selected {
            .text {
              color: var(--color-primary-d);
              // color: var(--color-selected-text);
            }

            .media-image svg path {
              stroke: var(--color-primary-d);
              // fill: var(--color-selected-text);
            }

            .repost-image svg path {
              stroke: var(--color-primary-d);
              // stroke: var(--color-selected-text);
            }
          }

          .slider {
            position: absolute;
            bottom: 0;
            height: 2.5px;
            background-color: var(--color-primary-d);

            box-shadow: 0px 0px 5px 0px rgb(0, 0, 0, 0.15);
            transition: all 250ms ease;
          }

          // &:has(.active) .active {
          //   border-block-end: 2.5px solid var(--color-primary-d);
          // }
        }
      }

      .media-wrapper {
        position: relative;
        // height: calc(100vh - 200px);
        // height: 100vh;
        // height: calc(100svh - 50px);
        // overflow-y: auto;
        // min-height: 50vh;

        scrollbar-gutter: stable;

        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        // -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }

        .container {
          height: 100%;
        }
      }
    }
  }
}
