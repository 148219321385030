.viewMedia {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: rgba(0, 0, 0, 0.93);
  user-select: none;

  transform-origin: center;
  animation: openMedia 0.3s cubic-bezier(0.1, 0.82, 0.25, 1);

  .viewMedia-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;

    .heading {
      padding-block: 0.625rem;
      padding-inline: 2rem;
      background-color: rgba(43, 43, 44, 0.6);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content-profile-detail {
        display: flex;
        align-items: center;
        gap: 0.875rem;

        .content-profile-image {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          background-color: #168e9f;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .content-profile-info {
          .info-name {
            font-size: 0.9375rem;
            color: var(--color-offwhite);
          }

          .info-status {
            font-size: 0.75rem;
            color: var(--color-tertiary-l);
          }
        }
      }

      .media-cta {
        display: flex;
        gap: 0.5rem;

        .common-cta {
          // width: 25px;
          // height: 25px;
          padding: 8px;
          background-color: transparent;
          // border-radius: 50%;
          transition: background-color 0.3s;
          cursor: pointer;

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }
      }
    }

    // .uploadedMedia-wrapper {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   padding: 1rem 2rem;

    //   .uploadedMedia {
    //     z-index: 9;
    //     width: fit-content;
    //     height: 350px;
    //     max-height: fit-content;
    //     order: 2;
    //     background-color: #fff;
    //     width: auto;
    //     max-height: calc(100vh - 221px);
    //     max-width: calc(100vw - 400px);

    //     video {
    //       width: 100%;
    //       height: 100%;
    //       object-fit: cover;
    //     }

    //     @media screen and (min-width: 1300px) and (max-width: 2560px) {
    //       height: 500px;
    //     }
    //   }

    //   .uploadedMediaSmallHeight {
    //     z-index: 9;
    //     width: 100%;
    //     max-height: fit-content;
    //     order: 2;
    //     background-color: #fff;

    //     @media screen and (min-width: 1024px) and (max-width: 2560px) {
    //       height: 120px;
    //     }
    //   }

    //   .uploadedMediaSmallWidth {
    //     z-index: 9;
    //     width: fit-content;
    //     height: 100%;
    //     max-height: fit-content;
    //     order: 2;
    //     background-color: #fff;
    //   }

    //   .setImageInSection {
    //     width: auto;
    //     max-height: calc(100vh - 221px);
    //     max-width: calc(100vw - 300px);
    //   }

    //   .zoom {
    //     transition: transform 0.5s;
    //     cursor: zoom-in;
    //   }

    //   .img-wrap.zoomed img {
    //     cursor: zoom-out;
    //     transition: transform 300ms cubic-bezier(0.1, 0.82, 0.25, 1) 0s;
    //     overflow-y: hidden;
    //     overflow-x: hidden;
    //   }

    //   .zoom_image {
    //     margin: 0 auto;
    //     border-radius: 0.5rem;
    //     transition: transform 0.5s;
    //     cursor: zoom-in;

    //     &.zoom_mode_active {
    //       cursor: zoom-out;
    //     }

    //     img {
    //       transition: transform 200ms ease-out;
    //     }
    //   }

    //   .left-arrow,
    //   .right-arrow {
    //     width: 2.875rem;
    //     height: 2.875rem;
    //     border-radius: 50%;
    //     background-color: var(--color-primary);

    //     display: grid;
    //     place-content: center;
    //     background-color: var(--color-primary);
    //     place-items: center;

    //     cursor: pointer;
    //     z-index: 99;

    //     &:focus-visible {
    //       outline: 2px solid var(--color-primary-d);
    //       outline-offset: 4px;
    //     }
    //   }

    //   // .left-arrow-disable,
    //   // .right-arrow-disable {
    //   //   background-color: hsl(0, 0%, 100%, 0.1);
    //   // }

    //   .left-arrow {
    //     order: 1;
    //   }

    //   .right-arrow {
    //     order: 3;
    //   }
    // }

    // .select-media {
    //   display: flex;
    //   align-items: center;
    //   width: 100%;

    //   .media-wrapper {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     gap: 10px;
    //     padding-block: 0.625rem;
    //     width: 5000px;
    //     overflow-x: scroll;

    //     &::-webkit-scrollbar-track {
    //       background-color: transparent !important;
    //     }
    //   }

    //   .media {
    //     width: 3.625rem;
    //     height: 3.625rem;

    //     flex-shrink: 0;

    //     transition: border 150ms ease-out, transform 250ms ease-out;
    //     overflow: hidden;
    //     cursor: pointer;

    //     img {
    //       width: 100%;
    //       height: 100%;
    //       object-fit: cover;
    //     }

    //     &.active,
    //     &:focus-visible,
    //     &:hover {
    //       outline: 2px solid var(--color-primary);
    //       outline-offset: 3px;
    //     }
    //   }

    //   .media.active {
    //     transform: scale(0.85);
    //   }
    // }

    .gallery {
      width: 100%;
      margin: auto;

      // max-width: 620px;
      // margin: 40px auto;
      &-slider {
        width: 100%;
        height: auto;
        margin: 0 0 100px 0;

        .swiper-slide {
          width: auto;
          height: 400px;

          img {
            display: block;
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }

        .swiper-button-prev,
        .swiper-button-next {
          background-color: var(--color-primary);
          border-radius: 50%;
          width: 50px;
          height: 50px;

          &:focus-visible {
            // outline: 2px auto var(--color-focused);
            // outline-offset: 4px;
            outline: 0;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      &-thumbs {
        width: 100%;
        padding: 10px;
        overflow: hidden;
        position: absolute;
        bottom: 0px;

        .swiper-slide {
          width: 3.625rem;
          height: 3.625rem;
          text-align: center;
          overflow: hidden;
          // opacity: 0.1;
          margin: auto;
          display: flex;

          transition: scale 250ms ease-out;

          &-active {
            opacity: 1;
            outline: 2px solid var(--color-primary);
            outline-offset: 3px;
            scale: 0.8;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  @keyframes openMedia {
    0% {
      opacity: 0;
      transform: scale(1.25);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.opacity {
  opacity: 0.4;
}

.disable {
  opacity: 0.4;
}
