.audioSend {
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
  align-self: self-end;

  .wrapper {
    width: 205px;
    background-color: var(--color-quinary);
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    position: relative;

    .audio {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      .audio-img {
        width: 18px;
        height: 18px;
        align-self: flex-start;
        cursor: pointer;
      }

      .audio-details {
        display: flex;
        gap: 6px;
        flex-direction: column;

        .wave {
          width: 90px;
          height: auto;
        }

        .duration {
          font-size: 0.55rem;
          color: var(--color-tertiary-v-l);
        }
      }
    }

    #waveform ::part(cursor) {
      top: -6px;
      background-color: transparent;
    }

    #waveform ::part(cursor):after {
      content: "";
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: var(--color-primary-d);
      display: inline-block;
      vertical-align: middle;
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }

      .audio-status {
        width: 14px;
      }
    }

    .down-arrow {
      width: 8px;
      height: 8px;
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
      display: none;
      animation: slideIn 0.3s ease-in;
    }
  }

  &:hover .down-arrow {
    display: block;
  }
}

.audioRecieved {
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
  align-self: self-start;

  .wrapper {
    width: 205px;
    background-color: var(--color-secondary);
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    position: relative;

    .audio {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      .audio-img {
        width: 18px;
        height: 18px;
        align-self: flex-start;
      }

      .audio-details {
        display: flex;
        flex-direction: column;

        .wave {
          width: 90px;
          height: 25px;
        }

        .duration {
          font-size: 0.55rem;
          color: var(--color-quaternary-m-d);
        }
      }
    }

    #waveform ::part(cursor) {
      top: -6px;
      background-color: transparent;
    }

    #waveform ::part(cursor):after {
      content: "";
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: var(--color-primary-d);
      display: inline-block;
      vertical-align: middle;
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }

      .audio-status {
        width: 14px;
      }
    }

    .down-arrow {
      width: 8px;
      height: 8px;
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
      display: none;
      animation: slideIn 0.3s ease-in;
    }
  }

  &:hover .down-arrow {
    display: block;
  }

  @keyframes slideIn {
    0% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
}
