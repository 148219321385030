$progress: 0%;
.loadingPage-wrapper {
  background-color: var(--color-secondary);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;

  .loadingPage {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 25rem;
    // background-color: white;

    .loadingPage-image {
      width: 18.75rem;
      height: 16.25rem;

      margin-block-end: 3.4375rem;

      @media screen and (max-width: 1281px) {
        width: 15.625rem;
        height: 13.125rem;

        margin-block-end: 2.5rem;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .loader-line {
      width: 100%;
      height: 4px;
      background-color: #d9d9d9;
      border-radius: 50px;

      margin-block-end: 1rem;

      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        width: $progress;
        height: 4px;
        background-color: var(--color-primary-m-d);
        animation: loaderAnimation 5s linear;
      }

      .percentage {
        display: block;
        color: #70c542;
        font-size: 32px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        opacity: 0;
        transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
      }
      .percentage {
        opacity: 1;
        top: 155%;
      }
      .in-progress {
        .percentage {
          opacity: 1;
          top: 155%;
        }
      }
    }

    .loadingPage-text {
      margin-block-end: 7px;
      .title {
        font-size: 1.35rem;
        font-weight: 500;
        color: var(--color-quaternary-l);
        letter-spacing: 0.5px;
      }
    }

    .loadingPage-info {
      display: flex;
      gap: 0.3rem;
      align-items: center;
      justify-content: center;

      margin-block-end: 5.3125rem;

      @media screen and (max-width: 1281px) {
        margin-block-end: 4.5rem;
      }

      svg {
        width: 0.625rem;
        height: 0.625rem;
      }

      .info {
        color: var(--color-description);
        font-size: 0.9375rem;
      }
    }

    .btn-logout {
      font-size: 1.125rem;
      font-weight: 600;
      // padding: 6px 2rem;
      text-align: center;

      width: 125px;
      height: 2.5rem;

      border-radius: 7px;
      background-color: var(--color-quaternary-l);
      color: var(--color-secondary);

      transition: scale 250ms ease-out;

      &:hover,
      &:focus-visible {
        scale: 0.95;
      }
    }
  }

  @keyframes loaderAnimation {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }
}
