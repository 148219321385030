.menuPopup {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  //   opacity: 0;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;

  display: grid;
  place-items: center;

  .dialog {
    width: 310px;
    background-color: var(--color-popup);
    border-radius: 0.5rem;

    overflow: hidden;

    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    opacity: 0;
    scale: 0;
    // transform: translateX();

    animation: delay-dialog 0.3s ease-out forwards;
    // transform-origin: center center;

    .dialog-cta-list-wrapper {
      display: flex;
      flex-direction: column;

      .dialog-cta-list {
        display: flex;
        align-items: center;
        gap: 1rem;

        padding: 1rem 0.9375rem;
        border-block-end: 0.5px solid var(--color-border);

        cursor: pointer;

        &:hover,
        &:focus-visible {
          background-color: var(--color-selected-bg);
        }

        &:focus-visible {
          outline: none;
        }

        &:last-child {
          border-block-end: 0;
        }

        .hideCount-image {
          width: 1.625rem;
          height: 1.625rem;
          margin-inline-start: -4px;
        }

        .text {
          display: inline-block;
          color: var(--color-quaternary-l);
          font-size: 1rem;
        }
      }

      .report,
      .delete {
        .text {
          color: var(--color-error);
        }
      }

      .dialog-cta-list.main {
        justify-content: space-evenly;

        &:hover,
        &:focus-visible {
          background-color: initial;
        }

        cursor: default;

        .main-cta {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2px;
          padding: 2px;

          cursor: pointer;

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          .save-image,
          .repost-image,
          .share-image {
            width: 35px;
            height: 35px;
            // background-color: var(--color-secondary);
            background-color: transparent;
            border: 1px solid var(--color-primary-d);
            border-radius: 50%;

            display: grid;
            place-items: center;
          }

          .text {
            font-size: 0.75rem;
          }
        }
      }
    }

    /* Styles for deleteTepars component */
    .deleteTepars {
      .dialog-info {
        margin-block: 1.875rem 0.5rem;

        h4 {
          font-size: 1.125rem;
          font-weight: 400;
          color: var(--color-quaternary-l);
          text-align: center;
        }
      }

      .description {
        font-size: 0.8rem;
        color: var(--color-description);
        margin-block-end: 1.5rem;
        width: 43ch;
        text-align: center;
        margin-inline: auto;
        line-height: 1.25;
      }

      .discardPost-cta {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 22px;
        margin-block-end: 1.25rem;

        .btn-cancel,
        .btn-delete {
          background-color: transparent;
          padding: 0.4rem 1.125rem;
          font-size: 0.875rem;
          color: var(--color-primary-d);
          border: 1px solid var(--color-primary-d);
          border-radius: 5px;
          cursor: pointer;
        }

        .active {
          background-color: var(--color-primary-d);
          color: var(--color-offwhite);
        }
      }
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
      // scale: 0;
    }

    100% {
      opacity: 1;
      // scale: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }

    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
