.mapSend,
.mapRecieved {
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;

  .wrapper {
    width: 205px;
    height: fit-content;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    position: relative;

    .map {
      height: 77%;
    }

    .map-details {
      padding: 6px 8px;
      display: flex;
      justify-content: space-between;

      .detail {
        display: flex;
        flex-direction: column;

        .text {
          font-size: 0.75rem;
          color: var(--color-tertiary-v-l);
          letter-spacing: -0.5px;

          .space {
            display: inline-block;
            width: 60px;
            height: 0;
            opacity: 0;
            visibility: hidden;
            margin-inline-start: 3px;
          }
        }

        .location {
          font-size: 0.7rem;
          font-weight: 500;
          color: var(--color-quaternary-m-d);
        }
      }
    }

    .shadow-background {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1.75rem;
      z-index: 10;
      background-image: linear-gradient(
        to top,
        rgb(11, 20, 26, 0.4),
        rgb(10, 20, 26, 0)
      );

      .image-container {
        width: fit-content;
        height: fit-content;
        position: relative;

        .chat-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .blur {
          filter: blur(8px);
        }

        .loader {
          position: absolute;
          inset: 0;
          display: grid;
          place-content: center;

          img {
            width: 50px;
            height: 50px;
          }

          .size {
            text-align: center;
          }
        }
      }
    }

    .down-arrow {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer;
      display: none;
      animation: slideIn 0.3s ease-in;
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);
      z-index: 1000;

      .important {
        width: 10px;
      }

      .map-status {
        width: 14px;
      }
    }

    &:hover .down-arrow {
      display: block;
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateX(20px);
    }
    20% {
      transform: translateY(0);
    }
  }
}

.mapSend {
  align-self: self-end;

  .wrapper {
    background-color: var(--color-quinary);
  }

  .map-details {
    padding: 6px 8px;
    display: flex;
    justify-content: space-between;

    .detail {
      display: flex;
      flex-direction: column;

      .text {
        font-size: 0.75rem;
        color: var(--color-tertiary-v-l);
        letter-spacing: -0.5px;
      }

      .space {
        display: inline-block;
        width: 60px;
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-inline-start: 3px;
      }
    }
  }
}

.mapRecieved {
  align-self: self-start;

  .wrapper {
    background-color: var(--color-secondary);
  }

  &:has(.shadow-background) .message-status {
    color: var(--color-secondary);
  }

  .message-status:is(.shadow-background) {
    color: var(--color-secondary);
  }
}
