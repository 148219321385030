.circular-progress {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    height: 90%;
    width: 90%;
    border-radius: 50%;
    background-color: #fff;
  }

  .download-arrow,
  .close-button {
    width: 20px;
    height: 20px;
    z-index: 0;
    cursor: pointer;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .close-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .circular-progress.in-progress::before {
    content: "";
    position: absolute;
    height: 210px;
    width: 210px;
    border-radius: 50%;
    background-color: #fff;
    z-index: 1;
  }
}
