.securitySettings {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 11;

  animation: slideInLeft 250ms ease forwards;

  &.open {
    animation: slideInLeft 250ms ease forwards;
  }
  
  &.close {
    animation: slideOutLeft 250ms ease forwards;
  }
  

  .securitySettings-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;
      min-height: 54px;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }
    }

    .securitySettings-info-wrapper {
      height: 100%;
      overflow-y: auto;

      padding-block-end: 1rem;

      .security-info {
        padding: 1.5rem 0.9375rem 0.625rem;

        .security-image {
          width: 75px;
          height: 75px;
          background-color: var(--color-primary-d);
          border-radius: 50%;
          overflow: hidden;

          display: grid;
          place-items: center;

          margin-inline: auto;

          margin-block-end: 1.5rem;

          svg {
            width: 50%;
          }
        }

        .heading {
          color: var(--color-quaternary-l);
          font-size: 1.125rem;

          margin-block-end: 0.625rem;
        }

        .info {
          color: var(--color-description);
          font-size: 0.8rem;
        }
      }

      .securitySettings-cta-info {
        border-block-end: 0.5px solid var(--color-border);

        .security-settings {
          display: flex;
          gap: 1.5rem;
          padding-inline-start: 0.9375rem;
          align-items: center;
          height: 4rem;
          // transition: background 0.2s ease-out;
          // cursor: pointer;

          // &:hover {
          //   background: var(--color-selected-bg);
          // }

          .security-listItem-image {
            width: 30px;
            height: 30px;
            background-color: var(--color-primary-d);
            border-radius: 5px;
            overflow: hidden;
            display: grid;
            place-items: center;
            flex-shrink: 0;

            svg {
              width: 65%;
            }
          }

          .security-listItem-info {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-inline-end: 0.625rem;
            border-block-end: 0.5px solid var(--color-border);
            height: 100%;

            &:last-child {
              border-block-end: 0;
            }

            .security-listItem-name {
              color: var(--color-quaternary-l);
              font-size: 1rem;
            }
          }
        }

        .learnMore {
          display: inline-block;
          padding: 0.625rem 0.9375rem;
          a {
            &:link,
            &:active,
            &:visited {
              font-size: 0.8rem;
              color: var(--color-primary-d);
            }

            &:focus-visible {
              outline: 2px solid var(--color-primary-d);
              outline-offset: 2px;
            }
          }
        }
      }

      .security-notification {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.625rem;

        padding-inline: 0.9375rem;
        padding-block: 0.9375rem;
        // height: 4.375rem;

        cursor: pointer;
        transition: background 0.2s ease-out;

        &:hover {
          background: var(--color-selected-bg);
        }

        .info {
          flex: 1;

          display: flex;
          flex-direction: column;
          gap: 3px;

          .title {
            font-size: 1rem;
            color: var(--color-quaternary-l);
          }

          .sub-title {
            color: var(--color-description);
            font-size: 0.75rem;

            .learnMore {
              display: inline-block;
              a {
                &:link,
                &:active,
                &:visited {
                  font-size: 0.8rem;
                  color: var(--color-primary-d);
                  padding-inline-start: 2px;
                }

                &:focus-visible {
                  outline: 2px solid var(--color-primary-d);
                  outline-offset: 2px;
                }
              }
            }
          }
        }

        .group-check {
          input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }

          .checkmark {
            display: inline-flex;
            width: 1rem;
            height: 1rem;
            border: 1.2px solid var(--color-radio-border);
            border-radius: 50%;
            transition: border-color 0.3s, background-color 0.3s;
            background-color: transparent;
            position: relative;
            justify-content: center;
            align-items: center;

            &::after {
              content: "";
              width: 5px;
              height: 9px;
              border: solid transparent;
              border-width: 0 1.75px 1.75px 0;
              // position: absolute;
              // // top: 1.5px;
              // // left: 5px;
              // top: 50%;
              // left: 50%;
              transform: rotate(45deg);
              margin-block-start: -2px;

              transition: border-color 0.3s;
            }
          }

          input[type="checkbox"]:checked + .checkmark {
            background-color: var(--color-primary-d);
            border: 1.2px solid var(--color-primary-d);
          }

          input[type="checkbox"]:focus + .checkmark {
            border-color: var(--color-primary-d);
          }

          input[type="checkbox"]:checked:hover + .checkmark {
            display: block;
          }

          input[type="checkbox"]:checked + .checkmark::after {
            display: block;
            border-color: var(--color-secondary);
          }
        }
      }
    }
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

