.storyViewed {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .storyViewed-container {
    width: 325px;
    height: auto;
    min-height: 165px;
    max-height: 420px;
    // overflow-y: auto;

    background-color: var(--color-popup);
    border-radius: 0.75rem;
    overflow: hidden;

    animation: delay-dialog 0.3s ease-out forwards;

    display: flex;
    flex-direction: column;

    .no-data {
      margin: auto;
    }

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.625rem 0.9375rem;

      background-color: var(--color-primary-d);
      padding-block-end: 0.625rem;
      border-block-end: 1px solid var(--color-border);

      .title {
        display: inline-block;
        color: var(--color-offwhite);
        font-size: 1rem;
        font-weight: 400;
      }

      .btn-close {
        padding: 4px;
      }
    }

    .no-views {
      text-align: center;
      font-size: 1rem;
      color: var(--color-description);
      margin: auto;
    }

    .profiles {
      // background-color: var(--color-secondary);
      height: 100%;
      //   min-height: 140px;
      //   max-height: 420px;
      overflow-y: auto;

      

      .profile {
        display: flex;
        padding-inline-start: 0.9375rem;
        align-items: center;

        height: 3.75rem;

        cursor: pointer;
        transition: background-color 250ms ease-out;

        // &:hover,
        &:focus-visible {
          background-color: var(--color-selected-bg);
        }

        &:focus-visible {
          outline: 0;
        }

        .profile-image {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          margin-inline-end: 0.75rem;
          overflow: hidden;

          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .profile-info-container {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-block: 0.8125rem;
          padding-inline-end: 0.9375rem;

          height: 100%;

          .profile-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // align-items: center;

            flex: 1;
            .name {
              font-size: 0.9375rem;
              color: var(--color-quaternary-l);
              font-weight: 500;
            }

            .description {
              font-size: 0.75rem;
              color: var(--color-description);
              line-height: 1.25;
            }
          }

          .status {
            display: flex;
            align-items: center;
            // gap: 0.25rem;

            .emoji {
              display: inline-block;
              // color: var(--color-description);
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
  @keyframes delay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
