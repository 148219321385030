.messageCtaNotification-container {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  z-index: 50;

  .messageCtaNotification {
    margin-block-end: 0.625rem;
    &:last-child {
      margin-block-end: 0;
    }

    .messageCtaNotification-wrapper {
      max-width: 45ch;
      background-color: var(--color-primary-d);
      border-radius: 5px;

      padding: 0.625rem;

      display: flex;
      align-items: center;
      gap: 0.9375rem;

      .info {
        color: var(--color-offwhite);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.35;
      }

      .btn-undo {
        color: #1790a3;
        font-size: 0.9375rem;
        font-weight: 600;
        line-height: 1.25;
      }

      .close {
        flex-shrink: 0;
        cursor: pointer;
      }
    }
  }
}
