.postLikes {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .postLikes-container {
    width: 325px;
    height: auto;
    min-height: 250px;
    max-height: 420px;
    // overflow-y: auto;

    background-color: var(--color-popup);
    border-radius: 0.75rem;
    overflow: hidden;

    animation: delay-dialog 0.3s ease-out forwards;

    display: flex;
    flex-direction: column;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.625rem 0.9375rem;

      background-color: var(--color-primary-d);
      padding-block-end: 0.625rem;
      border-block-end: 1px solid var(--color-border);

      .title {
        display: inline-block;
        color: var(--color-offwhite);
        font-size: 1rem;
        font-weight: 400;
      }

      .btn-close {
        padding: 4px;
      }
    }

    .search-bar {
      margin: 0.625rem 0.85rem;

      .search-bar-wrapper {
        // padding-inline: 6px;
        border-radius: 0.5rem;
        background-color: rgba(118, 118, 128, 0.12);
        height: 30px;
        // padding: 10px 6px;
        padding-inline: 6px;

        display: flex;
        align-items: center;
        gap: 10px;
        input {
          // color: rgba(60, 60, 67, 0.6);
          color: var(--color-quaternary-l);
          flex: 1;
          appearance: none;
          border: none;
          outline: none;
          background: transparent;
          font-size: 0.875rem;
          height: 100%;

          @media screen and (max-width: 1025px) {
            font-size: 0.8rem;
          }

          &::placeholder {
            color: var(--color-placeholder);
          }
        }
      }
    }


    .profiles {
      // background-color: var(--color-secondary);
      height: 100%;
      //   min-height: 140px;
      //   max-height: 420px;
      overflow-y: auto;

      .teps-header {
        display: flex;
        justify-content: space-between;
        padding: 0 0.9375rem 0.5rem .9375rem;
    
        .teps-by {
          font-size: 13px;
          font-weight: 400;
          color: var(--color-quaternary-l);
        }
    
        .count-teps {
          font-size: 13px;
          font-weight: 400;
          color: var(--color-description);
        }
      }

      .no-results {
        text-align: center;
      font-size: 1rem;
      color: var(--color-description);
      
      }

      .profile {
        display: flex;
        padding-inline-start: 0.9375rem;
        align-items: center;

        height: 3.75rem;

        cursor: pointer;
        transition: bachground-color 250ms ease-out;

        &:hover,
        &:focus-visible {
          background-color: var(--color-selected-bg);
        }

        &:focus-visible {
          outline: 0;
        }

        .profile-image {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          margin-inline-end: 0.75rem;
          overflow: hidden;

          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .profile-info-container {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-block: 0.8125rem;
          padding-inline-end: 0.9375rem;

          height: 100%;

          .profile-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // align-items: center;

            flex: 1;

            .name {
              font-size: 0.9375rem;
              color: var(--color-quaternary-l);
              font-weight: 400;
            }

            .description {
              font-size: 0.75rem;
              color: var(--color-description);
              line-height: 1.25;
              overflow-wrap: break-word;

              overflow: hidden;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }

          .status {
            display: flex;
            align-items: center;
            // gap: 0.25rem;

            .emoji {
              display: inline-block;
              // color: var(--color-description);
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }

    100% {
      opacity: 1;
      scale: 1;
    }
  }
}