.adjustProfileImage {
  background-color: hsl(0, 100%, 100%, 0.95);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .dialog {
    width: 600px;

    background-color: var(--color-secondary);
    // border-radius: 10px;

    animation: delay-dialog 0.3s ease-out;
    overflow: hidden;

    box-shadow: 0px 0px 14px 0px #00000040;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: var(--color-primary-d);
      padding: 0.75rem 0.875rem;

      .adjust-heading,
      .retake-heading {
        display: flex;
        align-items: center;
        gap: 1rem;

        h4,
        .text {
          color: var(--color-offwhite);
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 1.35;
        }

        .text {
          font-size: 1rem;
          font-weight: 400;
        }

        .btn-close {
          // width: 1.25rem;
          // height: 1.25rem;

          // cursor: pointer;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }
      }

      .retake-heading {
        gap: 5px;

        cursor: pointer;

        .retake {
          // width: 1rem;
          // height: 1rem;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }
        }
      }
    }

    .photo-container {
      background-color: var(--color-quaternary);
      height: 400px;
      position: relative;

      .container {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;

        .crop-container {
          position: static;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @media screen and (max-width: 64.0625em) {
        height: 350px;
        position: relative;
      }
    }

    .extra-space {
      height: 90px;
      background-color: var(--color-popup);

      position: relative;

      @media screen and (max-width: 1025px) {
        height: 70px;
      }

      .done-icon {
        background-color: var(--color-primary-d);
        width: fit-content;
        margin-inline-start: auto;
        margin-inline-end: 3rem;
        padding: 0.75rem;
        border-radius: 50%;

        cursor: pointer;

        transform: translateY(-50%);
        svg {
          width: 25px;
          height: 25px;
        }

        &:focus-visible {
          outline: 0;
          box-shadow: 0 0 0 2px var(--color-focused);
        }
      }
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
