.reply {
  height: 64px;
  margin: 8px 0;
  animation: slideUpReply 250ms ease forwards;
  .wrapper {
    // padding: 8px 16px 8px 8px;
    height: 100%;
    padding: 4px 4px 4px 16px;
    background-color: var(--color-chatBg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    border-radius: 5px;

    .info {
      overflow: hidden;
      width: 100%;

      .sender {
        font-size: 0.875rem;
        color: var(--color-primary-d);
      }

      .description {
        font-size: 0.75rem;
        color: var(--color-description);
        display: flex;
        align-items: center;
        gap: 5px;

        p {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: pre-wrap;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    .btn-cancel {
      width: 1.25rem;
      height: 1.25rem;
      margin-inline-end: 0.75rem;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .document {
      width: 55px;
      border-radius: 8px;

      align-self: stretch;
      flex-shrink: 0;

      position: relative;
      overflow: hidden;

      .document-reply {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .cancel {
        position: absolute;
        inset: 0;

        background-color: hsl(0, 0, 0, 0.2);

        display: grid;
        place-items: center;

        svg {
          width: 1rem;
          height: 1rem;
          cursor: pointer;
        }
      }
    }
  }

  @keyframes slideUpReply {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
