.viewContacts {
  width: 100%;
  min-height: 100vh;
  background-color: hsl(0, 0%, 16%, 0.6);
  display: grid;
  place-items: center;
  position: relative;

  .viewContacts-container {
    width: 400px;
    height: 90vh;
    // height: clamp(30rem, 90vh, 40rem);

    margin-block: 1rem;
    background-color: var(--color-secondary);
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1025px) {
      height: 30rem;
    }

    @media screen and (min-width: 1600px) {
      height: 40rem;
    }

    .viewContacts-to {
      background-color: var(--color-primary-d);

      .viewContacts-to-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.625rem;

        .title {
          font-size: 1.25rem;
          font-weight: 500;
          color: var(--color-offwhite);
        }

        .btn-close {
          // width: 20px;
          // height: 20px;
          padding: 4px;
        }
      }

      .viewContacts-to-container.send {
        justify-content: flex-start;
        gap: 1rem;
      }
    }

    .viewContact-container {
      overflow-y: auto;
      max-height: 100vh;
      flex: 1;

      .viewContact-details {
        padding-inline: 1rem;
        border-block-end: 0.5px solid var(--color-border);

        .viewContact {
          display: flex;
          padding-block: 0.755rem 0.5rem;
          padding-inline-start: 0.5rem;

          .viewContact-image {
            width: 40px;
            height: 40px;
            background-color: var(--color-secondary);
            border-radius: 50%;
            margin-inline-end: 0.75rem;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .viewContact-info {
            flex: 1;
            display: flex;
            align-items: center;

            .viewContact-name {
              font-size: 0.9375rem;
              font-weight: 500;
              color: var(--color-quaternary-l);
            }
          }
        }

        .viewContact-number-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-block-end: 0.625rem;
          padding-inline-start: 0.5rem;

          .viewContact-number-detail {
            display: flex;
            flex-direction: column;

            .viewContact-number {
              font-size: 0.875rem;
              color: var(--color-quaternary-l);
            }

            .viewContact-number-type {
              font-size: 0.75rem;
              color: var(--color-description);
            }
          }

          .viewContact-message {
            width: 1.35rem;
            height: 1.35rem;
            cursor: pointer;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .sendContact-message {
      background-color: var(--color-popup);
      padding-inline: 0.9375rem;
      padding-block: 0.3rem;
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);

      .sendContact-message-names {
        color: var(--color-description);
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-inline-end: 1.5rem;

        .name {
          display: inline-block;
          color: inherit;
          font-size: 0.875rem;
        }
      }

      .btn-send-message {
        width: 45px;
        height: 45px;
        background-color: var(--color-primary-d);
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
