.documentSend,
.documentRecieved {
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;

  .wrapper {
    width: 280px;
    height: fit-content;
    background-color: var(--color-quinary);
    border-radius: 8px;
    padding-bottom: 1.25rem;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    position: relative;

    .document-details {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      padding: 10px 14px 10px 8px;
      background-color: #eaf1f2;
      cursor: pointer;
      width: 100%;

      .file {
        width: 28px;
        height: 28px;
      }

      .download-progress,
      #CircularProgressbarWithChildren,
      .download {
        width: 24px;
        height: 24px;

        align-self: center;
      }

      .file {
        img {
          height: 100%;
        }
      }

      .detail {
        margin-block-start: -5px;
        color: var(--color-quaternary-m-d);
        flex: 1;

        // please add your own js code here to shorten the line
        .name {
          font-size: 0.875rem;
          line-height: 19px;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          max-width: 22ch;
        }

        .size {
          font-size: 0.625rem;
        }
      }
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }

      .text-status {
        width: 14px;
      }
    }

    .down-arrow {
      --outgoing-background-rgb: 0, 92, 75;
      --overlay-rgb: 11, 20, 26;

      position: absolute;
      top: 0;
      right: 0;

      display: none;

      pointer-events: none;

      // background: linear-gradient(
      //   15deg,
      //   rgba(11, 20, 26, 0),
      //   rgba(11, 20, 26, 0) 45%,
      //   rgba(11, 20, 26, 0.12) 70%,
      //   rgba(11, 20, 26, 0.33)
      // );

      // background: radial-gradient(
      //   at top right,
      //   rgba(var(--outgoing-background-rgb), 1) 60%,
      //   rgba(var(--outgoing-background-rgb), 0) 80%
      // );

      background: linear-gradient(
        15deg,
        rgba(var(--overlay-rgb), 0),
        rgba(var(--overlay-rgb), 0) 45%,
        rgba(var(--overlay-rgb), 0.12) 70%,
        rgba(var(--overlay-rgb), 0.33)
      );
      width: 156px;
      height: 40px;
      // clip-path: polygon(100% 0, 100% 100%, 50% 50%, 0 0);

      opacity: 0;

      img {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 12px;
        height: 12px;

        animation: slideIn 0.2s ease-out;

        pointer-events: all;
      }
    }
  }

  &:hover .down-arrow {
    display: block;
    opacity: 1;
  }

  @keyframes slideIn {
    0% {
      transform: translateX(20px);
    }
    20% {
      transform: translateY(0);
    }
  }
}

.documentSend {
  align-self: self-end;

  .pdf-details {
    background-color: #eaf1f2;
  }
}

.documentRecieved {
  align-self: self-start;

  .wrapper {
    background-color: var(--color-secondary);
  }
}
