.contactSend,
.contactRecieved,
.contactSend-Multiple,
.contactRecieved-Multiple,
.contactSend-Multiples,
.contactRecieved-Multiples,
.contactSend-Simple,
.contactRecieved-Simple {
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;

  .wrapper {
    width: 230px;
    background-color: var(--color-quinary);
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

    .contact-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;

      .contact {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0.75rem;
        position: relative;

        .contact-img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;

          .contact-thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .contact-multiple-info {
          .multiple-info {
            line-height: 1;
            color: var(--color-quaternary);
            font-size: 0.75rem;
          }
        }

        .contact-details {
          .name,
          .number {
            font-size: 0.75rem;
          }
        }
      }

      .contact-cta {
        display: flex;
        border-top: 0.5px solid var(--color-message-border);

        .cta {
          flex-basis: 50%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-block: 0.35rem;
          cursor: pointer;

          span {
            color: var(--color-primary-d);
            font-size: 0.75rem;
          }
        }

        .cta-view {
          width: 100%;
          text-align: center;
          padding-block: 0.25rem;
          cursor: pointer;

          span {
            color: var(--color-primary-d);
            font-size: 0.75rem;
          }
        }

        .cta:first-child {
          border-right: 0.5px solid var(--color-message-border);
        }
      }

      .down-arrow {
        width: 8px;
        height: 8px;
        position: absolute;
        cursor: pointer;
        display: inline-block;
        top: 8px;
        right: 10px;
        display: none;
        animation: slideIn 0.3s ease-in;
      }

      &:hover .down-arrow {
        display: block;
      }
    }

    .contact-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }

      .status {
        width: 14px;
      }
    }
  }

  &:is(
      .contactSend-Multiple,
      .contactRecieved-Multiple,
      .contactSend-Multiples,
      .contactRecieved-Multiples
    )
    .contact-img-wrapper {
    display: flex;
    align-items: center;

    .contact-multiple {
      margin-left: -25px;
      z-index: 10;
      outline: 1.5px solid var(--color-white);
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateX(20px);
    }
    100% {
      transform: translateX(0);
    }
  }
}

.contactSend,
.contactSend-Multiple,
.contactSend-Multiples,
.contactSend-Simple {
  align-self: self-end;

  .wrapper {
    background-color: var(--color-quinary);

    .name,
    .number {
      color: var(--color-quaternary);
    }
  }
}

.contactRecieved,
.contactRecieved-Multiple,
.contactRecieved-Multiples,
.contactRecieved-Simple {
  align-self: self-start;

  .wrapper {
    background-color: var(--color-message-bg);

    .name,
    .number {
      color: var(--color-quaternary-l);
    }
  }
}

// .message-group {
//   width: 100%;
//   padding-inline: 25px;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;

//   .contact-check {
//     width: 120%;
//     height: 100%;
//     background-color: hsla(188, 75%, 36%, 0.1);
//     position: absolute;
//     top: -1px;
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     padding-left: 44px;
//     left: -20px;

//     // input[type="radio"] {
//     //   position: absolute;
//     //   opacity: 0;
//     //   height: 0;
//     //   width: 0;
//     // }

//     // .checkmark {
//     //   display: block;
//     //   width: 1.125rem;
//     //   height: 1.125rem;
//     //   border: 1.2px solid var(--color-description);
//     //   border-radius: 50%;
//     //   background-color: transparent;
//     //   position: relative;
//     //   transition: all 0.3s;
//     //   cursor: pointer;

//     //   &::after {
//     //     content: "";
//     //     width: 70%;
//     //     height: 70%;
//     //     border-radius: 50%;
//     //     background-color: var(--color-primary-d);
//     //     position: absolute;
//     //     top: 50%;
//     //     left: 50%;
//     //     transform: translate(-50%, -50%);
//     //     transition: all 0.3s ease-out;
//     //     opacity: 0;
//     //     visibility: hidden;
//     //   }
//     // }

//     // input[type="radio"]:checked + .checkmark::after,
//     // input[type="radio"]:focus + .checkmark::after {
//     //   opacity: 1;
//     //   visibility: visible;
//     // }
//   }

//   .mainMessage-wrapper {
//     display: flex;
//     gap: 10px;
//     // z-index: 10;

//     // .messageGroup-cta {
//     //   display: flex;
//     //   gap: 10px;
//     //   align-items: center;

//     //   .share,
//     //   .btn-reactToMessage,
//     //   .btn-deleteMessage {
//     //     width: 28px;
//     //     height: 28px;
//     //     background-color: var(--color-secondary);
//     //     border-radius: 5000px;
//     //     box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
//     //     display: flex;
//     //     align-items: center;
//     //     justify-content: center;
//     //     z-index: 10;
//     //     cursor: pointer;

//     //     img {
//     //       width: 20px;
//     //     }
//     //   }
//     // }
//   }

//   .messageReactedEmojis {
//     width: -moz-fit-content;
//     width: fit-content;
//     height: -moz-fit-content;
//     height: fit-content;
//     border-radius: 500px;
//     background-color: #daf5f9;
//     box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
//     position: relative;
//     top: -2px;
//     z-index: 10;

//     .reacted-emoji {
//       display: inline-block;
//     }
//   }

//   .messageReactedEmojis-right {
//     right: 10px;
//     align-self: flex-end;
//   }

//   .messageReactedEmojis-left {
//     left: 10px;
//   }
// }
