.pollChat-send,
.pollChat-received {
  background-color: var(--color-quinary);
  border-radius: 0.625rem;
  width: 280px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);

  align-self: flex-end;

  .pollChat-container {
    padding: 0.625rem;
    padding-block-end: 0;

    position: relative;
    .poll-question {
      font-size: 1rem;
      color: var(--color-quaternary-m-d);
      margin-block-end: 0.45rem;
    }

    .poll-cta {
      display: flex;
      align-items: center;
      gap: 0.3rem;

      margin-block-end: 0.875rem;

      .poll-cta-image {
        // width: 1rem;
        // height: 0.625rem;

        svg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .poll-cta-info {
        color: var(--color-description);
        font-size: 0.75rem;
      }
    }

    .poll-options {
      display: flex;
      flex-direction: column;

      .poll-option {
        display: flex;
        gap: 0.625rem;

        padding: 0.5rem 0.625rem;
        margin-block-end: 0.875rem;
        border-radius: 5px;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);

        transition: background-color 250ms ease;

        &:nth-child(4) {
          margin-block-end: 0;
        }

        //Test 3
        .poll-option-select {
          // margin-block-start: 3px;
          // height: fit-content;
          // cursor: pointer;
          // user-select: none;

          input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }

          .checkmark {
            display: inline-flex;
            justify-content: center;
            align-items: center;

            width: 0.875rem;
            height: 0.875rem;
            border: 1.5px solid var(--color-radio-border);
            border-radius: 50%;
            background-color: transparent;

            vertical-align: middle;

            transition: border-color 0.3s, background-color 0.3s;
            cursor: pointer;

            &::after {
              content: "";
              display: inline-block;
              width: 4px;
              height: 8px;
              border: solid transparent;
              border-width: 0 1.75px 1.75px 0;
              transform: rotate(45deg);
              margin-block-start: -2px;

              transition: border-color 0.3s;
            }
          }

          input[type="checkbox"]:checked + .checkmark {
            background-color: var(--color-white);
            border: 1.5px solid var(--color-white);
          }

          input[type="checkbox"]:checked + .checkmark::after {
            border-color: var(--color-primary-d);
          }
        }

        .poll-option-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2rem;

          flex: 1;
          width: 100%;

          .poll-option-info-value {
            width: 100%;
            .value {
              // color: var(--color-quaternary-l);
              color: #2a2a2a;
              font-size: 0.8rem;
              display: inline-block;
              width: 100%;
              cursor: pointer;
            }
          }
          .poll-value {
            color: var(--color-description);
            font-size: 0.625rem;
          }

          .range-wrapper {
            border-radius: 500px;
            margin-block-end: 0.875rem;
            width: 100%;
            height: 8px;

            .range {
              background-color: var(--color-primary-d);
              width: 50%;
              height: 100%;
              border-radius: inherit;

              transition: all 0.3s;
            }
          }
        }
      }

      .poll-option.poll-option-selected {
        .checkmark {
          border-color: var(--color-white);
        }
      }

      .poll-option-selected {
        background-color: var(--color-primary-d);
      }
    }

    .message-status {
      // position: absolute;
      // bottom: 2px;
      // right: 5px;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;

      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);
      margin-block: 10px 7px;

      // .important,
      // .poll-status {
      //   width: 10px;
      // }
      // .status {
      //   width: 14px;
      // }

      .date {
        line-height: 1.35;
        margin-block-start: 1px;
      }
    }
  }

  .view-votes {
    padding-block: 0.5rem;
    text-align: center;
    border-block-start: 0.5px solid #a7d7de;

    cursor: pointer;

    span {
      color: var(--color-primary-d);
      font-size: 0.8rem;
      font-weight: 500;
    }
  }

  .view-votes.no-votes {
    span {
      opacity: 0.5;
    }
  }
}

.pollChat-received {
  background-color: var(--color-message-bg);
  align-self: flex-start;
}

.pollChat-send
  .pollChat-container
  .poll-options
  .poll-option.poll-option-selected
  .poll-option-info
  .poll-option-info-value
  .value {
  color: var(--color-offwhite);
}
