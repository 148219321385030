// <----------------------------------------Audio--------------------------------->
.audioReplySend,
.audioReplyRecieved {
  border-radius: 8px;
  width: 230px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  align-self: flex-end;

  .wrapper {
    padding: 4px;
    background-color: #eaf1f2;

    .sender {
      font-size: 0.625rem;
      color: var(--color-primary-d);
    }

    .description {
      font-size: 0.875rem;
      color: #939499;
      display: flex;
      align-items: center;
      gap: 5px;

      img {
        width: 12px;
        height: 12px;
      }
    }

    .document {
      border-radius: 8px;
      overflow: hidden;

      .document-reply {
        height: 40px;
        width: 40px;
      }
    }
  }

  .replyText {
    padding: 4px 8px;
    background-color: var(--color-quinary);

    .reply-message {
      font-size: 0.9375rem;
      color: var(--color-quaternary-m-d);

      .additional {
        color: var(--color-primary-d);
        &:hover {
          text-decoration: underline var(--color-primary-d);
        }
      }

      .space {
        display: inline-block;
        width: 60px;
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-inline-start: 3px;
      }
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }

      .reply-status {
        width: 14px;
      }
    }
  }

  .down-arrow {
    width: 8px;
    height: 8px;
    position: absolute;
    cursor: pointer;
    display: inline-block;
    top: 8px;
    right: 10px;
    display: none;
    animation: slideIn 0.3s ease-in;
  }

  &:hover .down-arrow {
    display: block;
  }
}

.audioReplyRecieved {
  align-self: flex-start;

  .wrapper {
    background-color: var(--color-quinary);
  }

  .replyText {
    background-color: var(--color-secondary);
  }
}

// <---------------------------------Document------------------------------------->
.documentReplySend,
.documentReplyRecieved {
  border-radius: 8px;
  width: 230px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  align-self: flex-end;

  .wrapper {
    padding: 4px;
    background-color: #eaf1f2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      .sender {
        font-size: 0.625rem;
        color: var(--color-primary-d);
      }

      .description {
        font-size: 0.875rem;
        color: #939499;
        display: flex;
        align-items: center;
        gap: 5px;

        img {
          width: 12px;
          height: 12px;
          object-fit: contain;
        }
      }
    }
  }

  .replyText {
    padding: 4px 8px;
    background-color: var(--color-quinary);

    .reply-message {
      font-size: 0.9375rem;
      color: var(--color-quaternary-m-d);

      .additional {
        color: var(--color-primary-d);
        &:hover {
          text-decoration: underline var(--color-primary-d);
        }
      }

      .space {
        display: inline-block;
        width: 60px;
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-inline-start: 3px;
      }
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }

      .reply-status {
        width: 14px;
      }
    }
  }

  .down-arrow {
    width: 8px;
    height: 8px;
    position: absolute;
    cursor: pointer;
    display: inline-block;
    top: 8px;
    right: 10px;
    display: none;
    animation: slideIn 0.3s ease-in;
  }

  &:hover .down-arrow {
    display: block;
  }
}

.documentReplyRecieved {
  align-self: flex-start;

  .wrapper {
    background-color: var(--color-quinary);
  }

  .replyText {
    background-color: var(--color-secondary);
  }
}

// <-----------------------------------Contact------------------------------------>
.contactReplySend,
.contactReplyRecieved {
  border-radius: 8px;
  width: 230px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  align-self: flex-end;

  .wrapper {
    padding: 4px;
    background-color: #eaf1f2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      .sender {
        font-size: 0.625rem;
        color: var(--color-primary-d);
      }

      .description {
        font-size: 0.875rem;
        color: #939499;
        display: flex;
        align-items: center;
        gap: 5px;

        .name {
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    .contact {
      border-radius: 8px;
      overflow: hidden;

      .contact-reply {
        height: 40px;
        width: 40px;
        object-fit: cover;
      }
    }
  }

  .replyText {
    padding: 4px 8px;
    background-color: var(--color-quinary);

    .reply-message {
      font-size: 0.9375rem;
      color: var(--color-quaternary-m-d);

      .additional {
        color: var(--color-primary-d);
        &:hover {
          text-decoration: underline var(--color-primary-d);
        }
      }

      .space {
        display: inline-block;
        width: 60px;
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-inline-start: 3px;
      }
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }

      .reply-status {
        width: 14px;
      }
    }
  }
  .down-arrow {
    width: 8px;
    height: 8px;
    position: absolute;
    cursor: pointer;
    display: inline-block;
    top: 8px;
    right: 10px;
    display: none;
    animation: slideIn 0.3s ease-in;
  }

  &:hover .down-arrow {
    display: block;
  }
}

.contactReplyRecieved {
  align-self: flex-start;

  .wrapper {
    background-color: var(--color-quinary);
  }

  .replyText {
    background-color: var(--color-secondary);
  }
}

// <------------------------------------Text-------------------------------------->
.textReplySend,
.textReplyRecieved {
  border-radius: 8px;
  width: fit-content;
  max-width: 60%;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  align-self: flex-end;

  .wrapper {
    padding: 4px 8px;
    background-color: #eaf1f2;

    .sender {
      font-size: 0.625rem;
      color: var(--color-primary-d);
    }

    .common-message {
      font-size: 0.875rem;
      color: #939499;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      line-height: 1.3;

      .space {
        display: inline-block;
        width: 60px;
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-inline-start: 3px;
      }
    }
  }

  .replyText {
    padding: 4px 8px;
    background-color: var(--color-quinary);

    .reply-message {
      font-size: 0.75rem;
      color: var(--color-quaternary-l);

      .additional {
        color: var(--color-primary-d);
        &:hover {
          text-decoration: underline var(--color-primary-d);
        }
      }

      .space {
        display: inline-block;
        width: 60px;
        height: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }

      .reply-status {
        width: 14px;
      }
    }
  }

  .down-arrow {
    width: 8px;
    height: 8px;
    position: absolute;
    cursor: pointer;
    display: inline-block;
    top: 8px;
    right: 10px;
    display: none;
    animation: slideIn 0.3s ease-in;
  }

  &:hover .down-arrow {
    display: block;
  }
}

.textReplyRecieved {
  align-self: flex-start;

  .wrapper {
    background-color: var(--color-quinary);
  }

  .replyText {
    background-color: var(--color-secondary);
  }
}

// <------------------------------------Video------------------------------------->
.videoReplySend,
.videoReplyRecieved {
  border-radius: 8px;
  width: 230px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  align-self: flex-end;

  .wrapper {
    padding: 4px;
    background-color: #eaf1f2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      .sender {
        font-size: 0.625rem;
        color: var(--color-primary-d);
      }

      .description {
        font-size: 0.875rem;
        color: #939499;
        display: flex;
        align-items: center;
        gap: 5px;

        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    .video {
      border-radius: 8px;
      overflow: hidden;

      .video-reply {
        height: 40px;
        width: 40px;
        object-fit: cover;
      }
    }
  }

  .replyText {
    padding: 4px 8px;
    background-color: var(--color-quinary);

    .reply-message {
      font-size: 0.9375rem;
      color: var(--color-quaternary-m-d);

      .additional {
        color: var(--color-primary-d);
        &:hover {
          text-decoration: underline var(--color-primary-d);
        }
      }

      .space {
        display: inline-block;
        width: 60px;
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-inline-start: 3px;
      }
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }

      .reply-status {
        width: 14px;
      }
    }
  }

  .down-arrow {
    width: 8px;
    height: 8px;
    position: absolute;
    cursor: pointer;
    display: inline-block;
    top: 8px;
    right: 10px;
    display: none;
    animation: slideIn 0.3s ease-in;
  }

  &:hover .down-arrow {
    display: block;
  }
}

.videoReplyRecieved {
  align-self: flex-start;

  .wrapper {
    background-color: var(--color-quinary);
  }

  .replyText {
    background-color: var(--color-secondary);
  }
}

// <-------------------------------------Img--------------------------------------->
.imgReplySend,
.imgReplyRecieved {
  border-radius: 8px;
  width: 230px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  align-self: flex-end;

  .wrapper {
    padding: 4px;
    background-color: #eaf1f2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      .sender {
        font-size: 0.625rem;
        color: var(--color-primary-d);
      }

      .description {
        font-size: 0.875rem;
        color: #939499;
        display: flex;
        align-items: center;
        gap: 5px;

        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    .image {
      border-radius: 8px;
      overflow: hidden;

      .img-reply {
        height: 40px;
        width: 40px;
        object-fit: cover;
      }
    }
  }

  .replyText {
    padding: 4px 8px;
    background-color: var(--color-quinary);

    .reply-message {
      font-size: 0.9375rem;
      color: var(--color-quaternary-m-d);

      .additional {
        color: var(--color-primary-d);
        &:hover {
          text-decoration: underline var(--color-primary-d);
        }
      }

      .space {
        display: inline-block;
        width: 60px;
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-inline-start: 3px;
      }
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }

      .reply-status {
        width: 14px;
      }
    }
  }

  .down-arrow {
    width: 8px;
    height: 8px;
    position: absolute;
    cursor: pointer;
    display: inline-block;
    top: 8px;
    right: 10px;
    display: none;
    animation: slideIn 0.3s ease-in;
  }

  &:hover .down-arrow {
    display: block;
  }
}

.imgReplyRecieved {
  align-self: flex-start;

  .wrapper {
    background-color: var(--color-quinary);
  }

  .replyText {
    background-color: var(--color-secondary);
  }
}

// <------------------------------------Location----------------------------------->
.locationReplySend,
.locationReplyRecieved {
  border-radius: 8px;
  width: 230px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  align-self: flex-end;

  .wrapper {
    padding: 4px;
    background-color: #eaf1f2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      .sender {
        font-size: 0.625rem;
        color: var(--color-primary-d);
      }

      .description {
        font-size: 0.875rem;
        color: #939499;
        display: flex;
        align-items: center;
        gap: 5px;

        img {
          width: 12px;
          height: 12px;
          object-fit: contain;
        }
      }
    }

    .location {
      border-radius: 8px;
      overflow: hidden;

      .location-reply {
        height: 40px;
        width: 40px;
        object-fit: cover;
      }
    }
  }

  .replyText {
    padding: 4px 8px;
    background-color: var(--color-quinary);

    .reply-message {
      font-size: 0.9375rem;
      color: var(--color-quaternary-m-d);

      .additional {
        color: var(--color-primary-d);
        &:hover {
          text-decoration: underline var(--color-primary-d);
        }
      }

      .space {
        display: inline-block;
        width: 60px;
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-inline-start: 3px;
      }
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }
      .reply-status {
        width: 14px;
      }
    }
  }

  .down-arrow {
    width: 8px;
    height: 8px;
    position: absolute;
    cursor: pointer;
    display: inline-block;
    top: 8px;
    right: 10px;
    display: none;
    animation: slideIn 0.3s ease-in;
  }

  &:hover .down-arrow {
    display: block;
  }
}

.locationReplyRecieved {
  align-self: flex-start;

  .wrapper {
    background-color: var(--color-quinary);
  }

  .replyText {
    background-color: var(--color-secondary);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(20px);
  }
  20% {
    transform: translateY(0);
  }
}
