.settingsMenu {
  width: 140px;
  white-space: nowrap;
  height: auto;
  border-radius: 10px;
  background-color: var(--color-secondary);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 56px;
  right: 15px;
  transform-origin: top right;
  animation: menuOpen 250ms ease;
  z-index: 20;

  .setting-menu-list {
    li {
      border-bottom: 0.5px solid var(--color-border);
      padding-block: 8px;
      padding-inline: 0.875rem;
      margin-block-end: 5px;
      font-size: 0.875rem;
      color: var(--color-quaternary-l);
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
        margin-block-end: 0;
      }
    }
  }
}

@keyframes menuOpen {
  0% {
    scale: 0;
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}
