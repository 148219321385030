.reportPost {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .dialog {
    width: 425px;
    max-height: 90vh;
    height: fit-content;
    opacity: 0;
    scale: 0;
    background-color: var(--color-popup);
    border-radius: 1rem;
    animation: delay-dialog 0.3s ease-out forwards;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    // <--------------------------- Report Post -------------------------------->
    .report-wrapper {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 100%;

      .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 0.9375rem;
        // padding-block-end: 0.35rem;
        padding: 0.75rem 0.9375rem;
        border-block-end: 1px solid var(--color-border);

        .title {
          // display: inline-block;
          color: var(--color-quaternary-l);
          font-size: 1.125rem;
          font-weight: 400;
        }

        .hidden {
          width: 28px;
          height: 28px;
        }

        .btn-close {
          padding: 2px;

          position: static;
        }
      }

      .reportPost-info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 0.9375rem;
        padding-block-end: 1rem;

        .title {
          color: var(--color-quaternary-l);
          font-size: 1rem;
        }

        .info {
          color: var(--color-description);
          font-size: 0.8rem;
          line-height: 1.3;
        }
      }

      .report-reasons-list {
        display: flex;
        flex-direction: column;

        .report-reason {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 0.9375rem;
          border-block-end: 0.5px solid var(--color-border);
          cursor: pointer;

          &:hover,
          &:focus-visible {
            background-color: var(--color-selected-bg);
          }

          &:focus-visible {
            outline: 0;
          }

          .more-info {
            width: 7px;
          }

          .report-reason-title {
            display: inline-block;
            color: var(--color-quaternary-l);
            font-size: 0.8rem;
          }

          &:first-child {
            border-block-start: 0.5px solid var(--color-border);
          }

          &:last-child {
            border-block-end: 0;
          }
        }
      }
    }

    // <--------------------------- Report Something -------------------------->
    .report-something {
      display: flex;
      flex-direction: column;
      height: 100%;

      .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.9375rem;
        padding-block-end: 5px;
        border-block-end: 1px solid var(--color-border);

        .title {
          display: inline-block;
          color: var(--color-quaternary-l);
          font-size: 1rem;
          font-weight: 400;
        }

        .btn-close,
        .hidden {
          width: 1.5rem;
          height: 1.5rem;

          svg {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .report-something-info {
        padding: 0.9375rem;

        .info {
          color: var(--color-quaternary-l);
          font-size: 0.8rem;
          line-height: 1.25;
        }
      }

      .report-something-query {
        height: 100%;
        padding-inline: 0.9375rem;
        display: flex;
        flex-direction: column;
        position: relative;

        .query-wrapper {
          height: 100%;
          #query {
            resize: none;
            border: 0;
            outline: 0;
            padding: 10px;
            background-color: hsl(240, 4%, 48%, 0.12);
            color: var(--color-quaternary-l);
            border-radius: 7px;
            overflow: hidden auto;
            font-size: 0.875rem;
            width: 100%;
            height: 180px;
            line-height: 1.25;

            .query:not(:focus)::before {
              content: attr(placeholder-info);
            }

            .query:focus::before,
            .query:placeholder-shown::before {
              content: "";
            }
          }
        }

        .btn-submit {
          width: 100%;
          border-radius: 500px;
          background: var(--color-primary-d);
          color: var(--color-secondary);
          font-size: 1rem;
          font-weight: 600;
          padding-block: 0.625rem;
          margin-block-end: 1.25rem;
          align-self: flex-end;
        }
      }
    }

    // <--------------------------- Report Submitted -------------------------->
    .report-submitted {
      display: flex;
      flex-direction: column;
      height: 100%;

      .report-submitted-info {
        display: flex;
        flex-direction: column;

        .heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.625rem 0.9375rem;

          // background-color: var(--color-primary-d);
          padding-block-end: 0.625rem;
          border-block-end: 1px solid var(--color-border);

          .title {
            display: inline-block;
            color: var(--color-quaternary-l);
            font-size: 1.125rem;
            font-weight: 400;
          }
        }

        .report-done {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-block-start: 2rem;

          .done-image {
            width: 120px;
            height: 120px;
            margin-block-end: 0.625rem;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          .title {
            color: var(--color-quaternary-l);
            font-size: 1.25rem;
            text-align: center;
            line-height: 1.25;
            margin-block-end: 0.45rem;
          }

          .info {
            color: var(--color-description);
            font-size: 0.875rem;
            width: 40ch;
            text-align: center;
            line-height: 1.35;
          }
        }

        .reported-info {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-block: 2rem 2.5rem;
          height: 100%;

          .reported-info-list {
            display: flex;
            align-items: center;
            gap: 1rem;

            padding: 0 0.9375rem;
            // border-block-end: 0.5px solid var(--color-border);

            .cta-image {
              width: 22px;
              height: 22px;
              margin-inline-end: 5px;

              flex-shrink: 0;
            }

            .text {
              display: inline-block;
              color: var(--color-quaternary-l);
              font-size: 1rem;
              line-height: 1.35;
            }
          }
        }

        .btn-close {
          border-radius: 500px;
          background: var(--color-primary-d);
          color: var(--color-offwhite);
          font-size: 0.875rem;
          font-weight: 500;
          padding-block: 0.6rem;
          margin-block-end: 1.35rem;
          margin-inline: 1rem;

          position: static;
        }
      }
    }

    // <--------------------------- Block User -------------------------->
    .blockUser {
      .dialog-info {
        margin-block: 1.875rem 0.5rem;

        h4 {
          font-size: 1.125rem;
          font-weight: 400;
          color: var(--color-quaternary-l);
          text-align: center;
        }
      }

      .description {
        font-size: 0.8rem;
        color: var(--color-description);
        margin-block-end: 1.5rem;
        width: 43ch;
        text-align: center;
        margin-inline: auto;
        line-height: 1.25;
      }

      .discardPost-cta {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 22px;
        margin-block-end: 1.25rem;

        .btn-cancel,
        .btn-block {
          background-color: transparent;
          padding: 0.4rem 1.125rem;
          font-size: 0.875rem;
          color: var(--color-primary-d);
          border: 1px solid var(--color-primary-d);
          border-radius: 5px;
          cursor: pointer;
        }

        .active {
          background-color: var(--color-primary-d);
          color: var(--color-offwhite);
        }
      }
    }

    // <--------------------------- Report Nudity-------------------------->
    .reportNudity-wrapper {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .reportNudity {
        height: 100%;
        overflow-y: auto;

        .heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.9375rem;
          padding-block-end: 0.625rem;
          border-block-end: 1px solid var(--color-border);

          .title {
            display: inline-block;
            font-size: 1rem;
            color: var(--color-quaternary-l);
            font-weight: 400;
          }

          .btn-close,
          .btn-back {
            width: 1.5rem;
            height: 1.5rem;

            cursor: pointer;

            svg {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .reportPost-info {
          .why-report-list {
            .title {
              display: inline-block;
              color: var(--color-quaternary-l);
              font-size: 1.05rem;
              font-weight: 400;
              padding: 0.75rem 0.9375rem;
            }

            .report-reason {
              display: flex;
              justify-content: flex-start;
              gap: 0.625rem;
              align-items: center;
              padding: 0.875rem 0.9375rem;
              border-block-end: 0.5px solid var(--color-border);

              &:hover,
              &:focus-visible {
                background-color: var(--color-selected-bg);
              }
              &:focus-visible {
                outline: none;
              }

              &:last-child {
                border-block-end: 0;
              }

              cursor: pointer;

              // .group-check {
              //   input[type="radio"] {
              //     position: absolute;
              //     opacity: 0;
              //     height: 0;
              //     width: 0;
              //   }

              //   .checkmark {
              //     display: grid;
              //     place-items: center;

              //     width: 1.125rem;
              //     height: 1.125rem;
              //     border: 1.25px solid var(--color-radio-border);
              //     border-radius: 50%;
              //     background-color: transparent;

              //     transition: border 0.3s ease-out;
              //     cursor: pointer;

              //     &::after {
              //       content: "";
              //       width: 0.75rem;
              //       height: 0.75rem;
              //       border-radius: 50%;
              //       background-color: var(--color-primary-d);

              //       opacity: 0;
              //       visibility: hidden;
              //       transition: all 0.3s ease-out;
              //     }
              //   }

              //   input[type="radio"]:checked + .checkmark::after,
              //   input[type="radio"]:focus + .checkmark::after {
              //     opacity: 1;
              //     visibility: visible;
              //   }

              //   input[type="radio"]:checked + .checkmark,
              //   input[type="radio"]:focus + .checkmark {
              //     border: 1.25px solid var(--color-primary-d);
              //   }
              // }

              .group-check {
                margin-block-start: -2px;
                input[type="radio"] {
                  position: absolute;
                  opacity: 0;
                  height: 0;
                  width: 0;
                }

                .checkmark {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;

                  position: relative;

                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  vertical-align: middle;

                  &:focus-visible {
                    outline: 0;
                    border-radius: 2px;
                    box-shadow: 0 0 0 2px var(--color-focused);
                  }

                  .radio-cmn {
                    position: absolute;
                    top: 0;
                    left: 0;

                    width: 100%;
                    height: 100%;
                    border-radius: 50%;

                    line-height: 0;
                    white-space: nowrap;

                    cursor: pointer;
                  }

                  .offRadio {
                    border: 1.5px solid var(--color-description);
                    background-color: transparent;

                    transition: border 250ms ease;

                    flex-shrink: 0;
                  }

                  .onRadio {
                    will-change: transform;
                    background-color: var(--color-primary-d);

                    scale: 0;
                    transition: scale 250ms ease;
                  }
                }

                input[type="radio"]:checked + .checkmark .onRadio,
                input[type="radio"]:focus-visible + .checkmark .onRadio {
                  scale: 0.6;
                }
                input[type="radio"]:checked + .checkmark .offRadio,
                input[type="radio"]:focus-visible + .checkmark .offRadio {
                  border-color: var(--color-primary-d);
                }
              }

              .report-reason-title {
                color: var(--color-quaternary-l);
                font-size: 0.85rem;
                flex: 1;
              }

              &:first-child {
                border-block-start: 0.5px solid var(--color-border);
              }
            }

            // .report-reason.someoneElse {
            //   margin-block-end: 1.5rem;
            // }

            .more-info {
              width: 8px;
            }
          }
          .nudity-guidelines {
            .title {
              display: inline-block;
              font-size: 1rem;
              color: var(--color-quaternary-l);
              font-weight: 500;
              padding: 0.75rem 0.9375rem;
            }

            .we-remove {
              padding-inline: 0.9375rem;
              margin-block-end: 1.25rem;

              .subtitle {
                display: inline-block;
                font-size: 1rem;
                font-weight: 400;
                color: var(--color-quaternary-l);
                margin-block-end: 0.75rem;
              }

              .remove-list-wrapper {
                .remove-list {
                  display: flex;
                  align-items: flex-start;
                  gap: 10px;
                  margin-block-end: 0.5rem;
                  margin-inline-start: 10px;

                  .circle {
                    width: 3px;
                    height: 3px;
                    background-color: var(--color-description);
                    border-radius: 50%;
                    flex-shrink: 0;
                    transform: translateY(8px);
                  }

                  .info {
                    color: var(--color-description);
                    font-size: 0.75rem;
                    line-height: 1.45;
                  }
                }
              }
            }

            .btn-submit,
            .btn-resources {
              display: block;
              width: calc(100% - 1.875rem);
              border-radius: 500px;
              background: var(--color-primary-d);
              color: var(--color-offwhite);
              font-size: 0.75rem;
              font-weight: 500;
              padding-block: 0.5rem;
              margin-block-end: 1.125rem;
              margin-inline: auto;
            }

            .btn-resources {
              background: var(--color-secondary);
              border: 1px solid var(--color-primary-d);
              color: var(--color-primary-d);
            }
          }
        }
      }
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
