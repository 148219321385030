.profileView {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 50;
  animation: fadeIn 250ms ease forwards;
  transform-origin: center;
  // display: grid;
  // place-items: center;
  display: flex;
  justify-content: center;

  .profileView-wrapper {
    margin-top: 20px;
    background-color: var(--color-secondary);

    // height: 100%;
    overflow-y: auto;
    // width: 100%;
    width: calc(100% - 120px);
    max-width: 1000px;

    animation: scaleIn 250ms ease;

    overscroll-behavior: contain;

    transform: translateZ(0);
    will-change: transform;

    // -webkit-overflow-scrolling: touch;

    @media screen and (max-width: 1025px) {
      max-width: 900px;
    }

    .close-profileView {
      padding: 20px;
      padding-block: 10px 0;
      text-align: end;
    }

    .profileView-info-container {
      width: 100%;
      margin-inline: auto;

      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      // height: 100%;
      // overflow-y: auto;rem
      padding-block-end: 0.625rem;

      .profile-details {
        display: flex;
        align-items: center;
        .user-profile {
          display: flex;
          align-items: center;

          // width: 90%;
          // margin-inline-start: auto;

          .profile-image {
            // flex-basis: 25%;
            margin-inline: 5rem;

            @media screen and (max-width: 1025px) {
              margin-inline: 3.5rem;
            }

            @media screen and (max-width: 769px) {
              margin-inline: 2.5rem;
            }

            .profile-image-wrapper {
              width: 170px;
              height: 170px;
              border-radius: 50%;
              margin-inline-end: auto;

              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

              overflow: hidden;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              @media screen and (max-width: 769px) {
                width: 140px;
                height: 140px;
              }
            }
          }

          .user-profile-details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.125rem;

            margin-block: 0 1.5rem;
            padding-inline-end: 1.5rem;

            .user-stats {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              // max-width: 80%;

              .user-name {
                color: var(--color-quaternary-l);
                font-size: 1.625rem;
                font-weight: 500;

                @media screen and (max-width: 1281px) {
                  font-size: 1.45rem;
                }

                @media screen and (max-width: 769px) {
                  font-size: 1.35rem;
                }
              }

              .stats {
                display: flex;
                justify-content: center;
                align-items: center;

                .posts,
                .teps {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .number {
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: var(--color-primary-d);
                    line-height: 1.25;

                    @media screen and (max-width: 1281px) {
                      font-size: 1.35rem;
                    }

                    @media screen and (max-width: 769px) {
                      font-size: 1.25rem;
                    }
                  }

                  .visual-text {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .text {
                      font-size: 1.125rem;
                      line-height: 1.25;
                      color: var(--color-quaternary-l);

                      @media screen and (max-width: 1281px) {
                        font-size: 1.1rem;
                      }

                      @media screen and (max-width: 769px) {
                        font-size: 1rem;
                      }
                    }

                    .post-image,
                    .teps-image {
                      width: 1.5rem;
                      height: 1.5rem;

                      flex-shrink: 0;

                      svg {
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                }

                .posts {
                  padding-inline-end: 3.5rem;
                  position: relative;

                  &::after {
                    content: "";
                    display: inline-block;
                    width: 1.25px;
                    height: 40px;
                    background-color: rgb(0, 0, 0, 0.5);
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }

                .teps {
                  padding-inline-start: 3.5rem;
                }
              }
            }

            .user-status {
              .tagName {
                display: inline-block;
                font-size: 1.125rem;
                line-height: 1.25;
                font-weight: 500;
                color: var(--color-description);
                margin-block-end: 5px;

                @media screen and (max-width: 769px) {
                  font-size: 1rem;
                }
              }

              .profile-description,
              .sub-link {
                font-size: 1.125rem;
                color: var(--color-profile-description);

                @media screen and (max-width: 769px) {
                  font-size: 1rem;
                }
              }

              .profile-description {
                line-height: 1.25;
                margin-block-end: 5px;
                overflow: hidden;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              .profile-link {
                width: fit-content;
                .sub-link {
                  display: flex;
                  align-items: center;
                  gap: 2px;
                  line-height: 1.25;
                  color: var(--color-primary-d);

                  margin-block-end: 0.625rem;

                  svg {
                    margin-block-start: 1.875px;
                  }

                  &:focus-visible {
                    outline: 0;
                    border-radius: 2px;
                    box-shadow: 0 0 0 2px var(--color-focused);
                  }
                }
              }
            }

            .user-tepars-cta {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 1rem;

              flex-wrap: wrap;

              width: 100%;

              .editProfile,
              .editGroups {
                width: 175px;
                background-color: var(--color-primary-d);
                padding-block: 8px;
                padding-inline: 2rem;
                border-radius: 5px;
                // height: 2.5rem;

                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                flex-shrink: 0;

                cursor: pointer;

                &:focus-visible {
                  outline: 0;
                  border-radius: 2px;
                  box-shadow: 0 0 0 2px var(--color-focused);
                }

                @media screen and (max-width: 1281px) {
                  width: 150px;
                  padding-block: 5px;
                  padding-inline: 1.5rem;
                }

                .edit-profile-image,
                .edit-groups-image {
                  width: 1.5rem;
                  height: 1.5rem;

                  @media screen and (max-width: 1281px) {
                    width: 1.25rem;
                    height: 1.25rem;
                  }

                  svg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                .btn-text {
                  display: inline-block;
                  font-weight: 400;
                  font-size: 1.25rem;
                  color: var(--color-offwhite);
                  // margin-block-start: 3px;
                  line-height: 1.25;

                  @media screen and (max-width: 1281px) {
                    font-size: 1rem;
                  }
                }
              }

              // & > * {
              //   flex: 1;
              //   min-width: fit-content;
              // }
            }
          }
        }
      }

      .profile-medias {
        // --height: 50px;
        border-block-start: 2.25px solid var(--color-border);

        .mediaLinksDocs-btn {
          background-color: var(--color-secondary);
          // height: var(--height);

          display: flex;
          justify-content: center;
          align-items: center;

          position: sticky;
          top: -1px;
          z-index: 10;

          .btns-List {
            border-block-end: 2.25px solid var(--color-border);
            padding-block: 5px;

            display: flex;
            flex: 1;

            position: relative;
            overflow: hidden;

            & > * {
              flex-basis: 50%;
            }

            .repost,
            .media {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              gap: 5px;

              padding: 4px 13px;

              &.active {
                display: block;
              }

              &:focus-visible {
                outline: 0;
                border-radius: 2px;
                box-shadow: 0 0 0 2px var(--color-focused);
              }

              cursor: pointer;
              transition: border 0.3s ease-out;

              // .media-image,
              // .repost-image {
              //   width: 1.4375rem;
              //   height: 1.4375rem;

              //   @media screen and (max-width: 1281px) {
              //     width: 1.25rem;
              //     height: 1.25rem;
              //   }
              // }

              .text {
                color: var(--color-description);
                font-size: 1.25rem;

                @media screen and (max-width: 1281px) {
                  font-size: 1rem;
                }
              }
            }

            .repost.menu-selected,
            .media.menu-selected {
              .text {
                color: var(--color-primary-d);
                // color: var(--color-selected-text);
              }

              .media-image svg path {
                stroke: var(--color-primary-d);
                // fill: var(--color-selected-text);
              }

              .repost-image svg path {
                stroke: var(--color-primary-d);
                // stroke: var(--color-selected-text);
              }
            }

            .slider {
              position: absolute;
              bottom: 0;
              height: 2.5px;
              background-color: var(--color-primary-d);

              box-shadow: 0px 0px 5px 0px rgb(0, 0, 0, 0.15);
              transition: all 250ms ease;
            }
          }
        }

        .media-wrapper {
          position: relative;
          // height: calc(100svh - var(--height));
          // height: calc(100svh - 54px);
          overflow-y: auto;
          min-height: 50vh;
          scrollbar-gutter: stable;
          -ms-overflow-style: none;
          scrollbar-width: none;

          overscroll-behavior: auto;

          // -webkit-overflow-scrolling: touch;

          .container {
            height: 100%;
          }
        }

        .media-container {
          .media {
            width: 33%;
            padding-bottom: 33%;
            box-shadow: 0px 2px 4px rgb(0, 0, 0, 0.5);
            @media screen and (max-width: 769px) {
              width: 32.5%;
              padding-bottom: 32.5%;
            }
          }
        }
      }
    }

    // .default-repost-wrapper,
    // .default-media-wrapper {
    //   margin-block-start: 4rem;
    // }

    .spinner-wrapper {
      margin-block: 0.875rem;
    }

    // .default-repost-wrapper,
    // .default-media-wrapper {
    //   height: 50vh;
    // }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes scaleOut {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }
}
