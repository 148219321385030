// <------------------------------ Dharmik testing ------------------------------->
// #imageContainer {
//   background: rgb(24 145 162 / 30%);
//   // padding: 0.5em;
//   border-radius: 10px;
//   width: max-content;
//   /* height: 100px; */
//   // overflow: hidden;
//   // transform: translateY(60px);
//   transition: transform 0.5s ease-out;
//   animation: fileOpen 0.5s forwards;
//   // border: 2px solid blue;
//   display: flex;
//   // gap: 0.5rem;
//   // background: blur(8px);
//   position: absolute;
//   // top: -3rem;
//   top: -4.2rem;
//   right: 2rem;
//   z-index: 100;
//   // inset: 0;
//   //   background-color: rgb(0, 0, 0);
//   //   background-color: rgba(0, 0, 0, 0.4);
//   .file {
//     align-self: center;
//     width: 40px;
//     height: 40px;
//     object-fit: cover;
//     transform: scale(0);
//     transition: transform 0.3s ease-out;
//     position: relative;
//     margin: 5px;
//     // &::before {
//     //   content: "Hello";
//     //   display: inline-block;
//     //   width: 100px;
//     //   height: 30px;
//     //   font-size: 1rem;
//     //   color: var(--color-secondary);
//     //   background-color: #000;
//     //   position: absolute;
//     //   inset: 0;
//     // }
//     svg {
//       width: 100%;
//       height: 100%;
//     }
//     .tooltip {
//       display: inline-block;
//       width: fit-content;
//       height: fit-content;
//       background-color: #000;
//       color: #fff;
//       text-align: center;
//       border-radius: 4px;
//       padding: 5px;
//       z-index: 1;
//       position: absolute;
//       top: -3rem;
//       left: 50%;
//       // inset: 0;
//       // left: 50%;
//       // margin-left: -60px;
//       transform: translateX(-50%);
//       visibility: hidden;
//       opacity: 0;
//       transition: all 0.3s;
//       &::after {
//         content: " ";
//         border-left: solid transparent 6px;
//         border-right: solid transparent 6px;
//         border-top: 8px solid #000;
//         bottom: -8px;
//         height: 0;
//         left: 25%;
//         transform: translateX(-25%);
//         // margin-left: -13px;
//         position: absolute;
//         width: 0;
//       }
//     }
//     &:hover .tooltip {
//       visibility: visible;
//       opacity: 1;
//     }
//   }
// }

// <-------------------------------- Fenil Testing --------------------------->
#imageContainer {
  background-color: var(--color-popup);
  width: auto;
  // border-radius: 8px;
  padding-inline: 2.5px;

  display: flex;

  position: absolute;
  top: -5.5rem;
  right: 0;

  z-index: 100;
  animation: fileOpen 0.5s forwards;

  box-shadow: 0px 1px 2px 0px #00000026;

  .file {
    align-self: center;

    width: 45px;
    height: 45px;
    margin: 10px 5px;

    transform: scale(0);
    transition: transform 0.3s ease-out;

    position: relative;

    svg {
      width: 100%;
      height: 100%;
    }

    .tooltip {
      display: inline-block;

      width: fit-content;
      height: fit-content;
      background-color: #000;
      color: #fff;
      text-align: center;
      border-radius: 4px;
      padding: 5px;

      z-index: 1;

      position: absolute;
      top: -3rem;
      left: 50%;
      // inset: 0;
      // left: 50%;
      // margin-left: -60px;
      transform: translateX(-50%);
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;

      &::after {
        content: " ";
        border-left: solid transparent 6px;
        border-right: solid transparent 6px;
        border-top: 8px solid #000;
        bottom: -8px;
        height: 0;
        left: 25%;
        transform: translateX(-25%);

        // margin-left: -13px;
        position: absolute;
        width: 0;
      }
    }

    &:hover .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}
