.contactUs-popup {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: fadeIn 250ms ease forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  &.closing {
    animation: fadeOut 250ms ease forwards;
  }

  .dialog {
    width: 450px;

    background-color: var(--color-secondary);
    padding: 1.125rem 1.35rem;
    border-radius: 0.5rem;
    animation: scaleIn 250ms ease;

    &.closing {
      animation: scaleOut 250ms ease forwards;
    }

    .dialog-info {
      display: flex;
      //   gap: 1rem;
      //   margin-block-end: 1rem;

      h4 {
        width: 100%;
        font-size: 1.35rem;
        font-weight: 400;
        color: var(--color-quaternary-l);
        margin-block-end: 1.125rem;
      }
    }

    .contactUs-info {
      margin-block-end: 1.5rem;
      padding-block: 1em 0.35em;

      width: 100%;
      min-height: 3rem;
      max-height: 8rem;

      outline: 0;
      overflow-y: auto;
      overflow-x: hidden;
      user-select: text;
      white-space: pre-wrap;
      word-break: break-word;

      border-bottom: 1px solid var(--color-quaternary-l);
      transition: all 250ms ease;

      &:focus {
        border-bottom: 2px solid var(--color-primary-d);
      }

      .report-info {
        height: 100%;
        font-size: 0.875rem;
        background-color: var(--color-secondary);
        color: var(--color-description);

        &:empty:focus ::before {
          content: "";
        }

        &:empty::before {
          content: attr(placeholder);
          color: var(--color-description);
        }
      }
    }

    .contactUs-sub-info,
    .contactUs-main-info .info {
      color: var(--color-description);
      font-size: 0.875rem;
      line-height: 1.35;

      .more-info {
        padding-inline-start: 3px;
        &:active,
        &:visited,
        &:link {
          color: var(--color-primary-d);
        }
      }
    }

    .contactUs-sub-info {
      margin-block-end: 2rem;
    }

    .contactUs-main-info {
      margin-block-end: 0.625rem;

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .group-check {
        input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;
        }

        .checkmark {
          display: inline-flex; /* Initially, hide the checkmark */
          width: 1rem;
          height: 1rem;
          border: 1.5px solid var(--color-primary-d); /* Initial border color (white) */
          border-radius: 3px; /* Make it round */
          transition: border-color 0.3s, background-color 0.3s; /* Add transitions for smoother effects */
          background-color: transparent; /* Initial background color (transparent) */
          position: relative;
          justify-content: center;
          align-items: center;

          vertical-align: middle;

          cursor: pointer;

          &::after {
            content: "";
            width: 5px; /* Adjusted width for a larger checkmark */
            height: 9px; /* Adjusted height for a larger checkmark */
            border: solid transparent;
            border-width: 0 1.75px 1.75px 0;
            // position: absolute;
            // // top: 1.5px;
            // // left: 5px;
            // top: 50%;
            // left: 50%;
            transform: rotate(45deg);
            margin-block-start: -2px;

            transition: border-color 0.3s;
          }
        }

        input[type="checkbox"]:checked + .checkmark {
          background-color: var(--color-primary-d);
          border: 1.5px solid var(--color-primary-d);
        }

        input[type="checkbox"]:checked + .checkmark::after {
          // display: block;
          border-color: var(--color-secondary);
        }
      }
    }

    .support {
      color: var(--color-primary-d);
      font-size: 0.8rem;
      cursor: pointer;
    }

    .contactUs-cta {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 1.5rem;

      .btn-cancel,
      .btn-send {
        background-color: transparent;
        padding: 0.5rem 1.125rem;
        font-size: 0.875rem;
        color: var(--color-primary-d);
        border: 1px solid var(--color-primary-d);
        border-radius: 5px;
        cursor: pointer;
      }

      .active {
        background-color: var(--color-primary-d);
        color: var(--color-offwhite);
      }

      // .disabled {
      //   opacity: 0.5;
      //   cursor: not-allowed;
      // }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes scaleOut {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }
}
