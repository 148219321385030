.web-settings {
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary);

  position: absolute;
  inset: 0;
  z-index: 10;

  &.open-right {
    animation: slideInFromRight 250ms ease-out forwards;
  }

  &.close-right {
    animation: slideOutToRight 250ms ease-out forwards;
  }
  &.open-left {
    animation: slideInFromLeft 250ms ease-out forwards;
  }
  &.close-left {
    animation: slideOutToLeft 250ms ease-out forwards;
  }

  .web-settings-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;
      display: flex;
      align-items: center;
      gap: 0.875rem;

      min-height: 54px;

      .btn-back {
        // width: 1rem;
        // height: 1rem;
        padding: 4px;

        // svg {
        //   width: 100%;
        //   height: 100%;
        // }
      }

      .text {
        display: inline-block;
        font-size: 1rem;
        color: var(--color-offwhite);
        font-weight: 500;
      }
    }

    .settings-details {
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding-block-end: 0.9375rem;

      .profile-info-settings-wrapper {
        padding-block-start: 0.875rem;
        min-height: 110px;

        transition: background-color 0.3s ease-out;

        &:hover,
        &:focus-visible {
          background: var(--color-selected-bg);
        }

        &:focus-visible {
          outline: 0;
        }

        cursor: pointer;

        .profile-info-settings {
          display: flex;
          align-items: center;
          gap: 1.25rem;

          padding-inline-start: 0.9375rem;
          height: 100%;
          border-block-end: 1px solid var(--color-border);

          .profile-image {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .profile-info {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            padding-inline-end: 0.625rem;
            height: 100%;

            .info {
              display: flex;
              flex-direction: column;

              .profile-name {
                font-size: 1.25rem;
                color: var(--color-quaternary-l);
              }

              .profile-status {
                font-size: 0.8rem;
                color: var(--color-description);
                overflow-wrap: break-word;

                overflow: hidden;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }

            .more-info {
              width: 1.2rem;
              padding: 5px;
              cursor: pointer;
              flex-shrink: 0;

              svg {
                margin-inline-start: auto;
              }
            }
          }
        }
      }
    }

    .settings-listItem {
      display: flex;
      gap: 1.5rem;
      padding-inline-start: 0.9375rem;
      align-items: center;
      min-height: 4rem;
      border-block-end: 0.5px solid var(--color-border);

      transition: background 0.2s ease-out;
      cursor: pointer;

      &:hover,
      &:focus-visible {
        background: var(--color-selected-bg);
      }

      &:focus-visible {
        outline: 0;
      }

      .settings-listItem-image {
        width: 30px;
        height: 30px;
        background-color: var(--color-primary-d);
        border-radius: 5px;
        overflow: hidden;
        display: grid;
        place-items: center;
        flex-shrink: 0;

        svg {
          width: 65%;
        }
      }

      .settings-listItem-info {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline-end: 0.625rem;
        height: 100%;

        &:first-child {
          border-block-start: 0.5px solid var(--color-arrow);
        }

        .settings-listItem-name {
          color: var(--color-quaternary-l);
          font-size: 1rem;
        }

        .more {
          width: 1.2rem;
          padding: 5px;
          cursor: pointer;

          svg {
            margin-inline-start: auto;
          }
        }
      }
    }

    .logout-settings.settings-listItem {
      .settings-listItem-image {
        background-color: var(--color-secondary);
        border: 1px solid var(--color-border);
      }

      .settings-listItem-name {
        color: var(--color-error);
      }
    }
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutToLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
}
