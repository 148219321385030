.networkError {
  background-color: var(--color-networkError-bg);
  padding: 0.9375rem;

  .networkError-wrapper {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    .networkError-image {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: var(--color-primary-d);
      overflow: hidden;

      flex-shrink: 0;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .networkError-info {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      .networkError-info-wrapper {
        display: flex;
        flex-direction: column;

        .networkError-title {
          margin-block-end: 2px;
          .title {
            font-size: 0.875rem;
            color: var(--color-quaternary-l);
            line-height: 1.25;
          }
        }

        .networkError-text {
          //   margin-block-end: 1px;

          .info {
            font-size: 0.75rem;
            color: var(--color-description);
            line-height: 1.35;
          }
        }

        .networkError-reconnect {
          display: flex;
          align-items: center;
          gap: 5px;

          align-self: flex-start;

          cursor: pointer;

          &:hover .text {
            text-decoration: underline;
          }

          .text {
            font-size: 0.75rem;
            color: var(--color-description);
            line-height: 1.35;
          }

          .more-icon {
            display: inline-block;
          }
        }
      }

      .btn-close {
        width: 1rem;
        height: 1rem;

        flex-shrink: 0;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
