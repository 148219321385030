.requestAccountInfo {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 11;

  animation: slideInLeft 250ms ease forwards;

  &.open {
    animation: slideInLeft 250ms ease forwards;
  }
  
  &.close {
    animation: slideOutLeft 250ms ease forwards;
  }
  

  .requestAccountInfo-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }
    }

    .requestAccountInfo-info-wrapper {
      height: 100%;
      overflow-y: auto;

      padding-block-end: 1rem;

      .requestAccount-info,
      .more-infos {
        padding: 1.5rem 0.9375rem;

        .requestAccount-image {
          width: 75px;
          height: 75px;
          background-color: var(--color-primary-d);
          border-radius: 50%;
          overflow: hidden;

          display: grid;
          place-items: center;

          margin-inline: auto;

          margin-block-end: 1.5rem;

          svg {
            width: 30px;
          }
        }

        .info {
          color: var(--color-description);
          font-size: 0.8rem;

          .learnMore {
            display: inline-block;
            a {
              &:link,
              &:active,
              &:visited {
                font-size: 0.8rem;
                color: var(--color-primary-d);
                padding-inline-start: 2px;
              }
            }
          }
        }
      }

      .requestAccountInfo-cta-info {
        border-block: 0.5px solid var(--color-border);

        .requestAccount-settings {
          display: flex;
          gap: 1.5rem;
          padding-inline-start: 0.9375rem;
          align-items: center;
          height: 4rem;
          transition: background 0.2s ease-out;
          cursor: pointer;

          &:hover,
          &:focus-visible {
            background: var(--color-selected-bg);
          }

          &:focus-visible {
            outline: 0;
          }

          .requestAccount-listItem-image {
            width: 30px;
            height: 30px;
            background-color: var(--color-primary-d);
            border-radius: 5px;
            overflow: hidden;
            display: grid;
            place-items: center;
            flex-shrink: 0;

            svg {
              width: 0.9375rem;
              height: 0.9375rem;
            }
          }

          .requestAccount-listItem-info {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-inline-end: 0.625rem;
            border-block-end: 0.5px solid var(--color-border);
            height: 100%;

            &:last-child {
              border-block-end: 0;
            }

            .requestAccount-listItem-name {
              color: var(--color-quaternary-l);
              font-size: 1rem;
            }
          }
        }
      }

      .more-infos {
        .info {
          &:first-child {
            margin-block-end: 0.5rem;
          }
        }
      }
    }
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

