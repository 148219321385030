.post-wrapper {
  width: 100%;

  .advertisementPost {
    display: flex;
    flex-direction: column;

    margin-block-end: 0.625rem;

    .header {
      padding-inline: 18px 10px;

      .profile {
        display: flex;
        align-items: center;
        height: 4.375rem;

        .profile-image {
          width: 3.125rem;
          height: 3.125rem;
          // background-color: var(--color-primary-d);
          border-radius: 50%;
          margin-inline-end: 0.75rem;
          overflow: hidden;
          // outline-offset: 1px;
          // outline: 2px solid var(--color-primary-d);
          position: relative;

          flex-shrink: 0;

          cursor: pointer;

          &:focus-visible {
            outline: 0;
            border-radius: 50%;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .profile-info {
          display: flex;
          align-items: center;
          gap: 0.625rem;
          flex: 1;

          .profile-info-wrapper {
            flex: 1;

            display: flex;
            flex-direction: column;

            .profile-info-name {
              display: flex;
              flex-wrap: wrap;
              align-items: center;

              font-size: 1rem;
              line-height: 1.35;
              color: var(--color-quaternary-l);
              font-weight: 300;

              overflow: hidden;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

              .name {
                display: inline;
                color: var(--color-quaternary-l);
                font-size: 1rem;
                font-weight: 400;
                // margin-inline-end: 5px;
                line-height: 1.25;

                cursor: pointer;

                &:focus-visible {
                  outline: 0;
                  border-radius: 2px;
                  box-shadow: 0 0 0 2px var(--color-focused);
                }
              }
            }

            .profile-details {
              .profile-details-type {
                color: var(--color-description);
                font-size: 0.8rem;
              }
            }
          }

          .profile-cta {
            width: 36px;
            height: 36px;
            padding: 6px;
            border-radius: 50%;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;

            cursor: pointer;

            &:hover,
            &:focus-visible {
              background-color: var(--color-selected-bg);
            }
          }

          .advertisement-cta {
            display: flex;
            gap: 0.625rem;
          }
        }
      }
    }

    .post-info-wrapper {
      .post-info {
        margin-block: 0px 0.625rem;
        padding-inline: 18px;

        overflow-wrap: break-word;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        // -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        .info {
          color: var(--color-quaternary-l);
          font-size: 0.875rem;
          line-height: 1.25;
          white-space: pre-wrap;

          .hastags {
            display: inline-block;
            font-size: inherit;
            color: var(--color-primary-d);
            padding-inline-start: 3px;
          }
        }

        .read-more {
          display: inline-block;
          font-size: 0.8rem;
          color: var(--color-primary-d);
          margin-inline-start: 3px;
          cursor: pointer;
        }
      }

      .post-media-wrapper {
        width: 100%;

        position: relative;
        // overflow: hidden;

        img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .post-media-cta {
        .btn-clickHere {
          background-color: var(--color-primary-d);
          color: var(--color-offwhite);
          font-size: 0.8rem;
          width: 100%;
          margin-inline: auto;
          border-radius: 0.5rem;
          padding-block: 8px;
          margin-block-start: 6px;
          text-transform: uppercase;
        }
      }
    }
  }

  .advertisementPost {
    .post-media-wrapper {
      height: 250px;
    }
  }

  .default-advertisement {
    .post-media-wrapper {
      height: 300px;
    }
  }
}
