.chatWallpaper {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 11;

  animation: slideInLeft 250ms ease forwards;

  &.open {
    animation: slideInLeft 250ms ease forwards;
  }
  
  &.close {
    animation: slideOutLeft 250ms ease forwards;
  }

  .chatWallpaper-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;
      min-height: 54px;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }
    }

    .chatWallpaper-info-wrapper {
      height: 100%;
      overflow-y: auto;

      padding-block-end: 1rem;

      .tepnot-doodles {
        display: flex;
        gap: 0.75rem;
        align-items: center;
        justify-content: center;

        padding: 0.9375rem;

        .title {
          font-size: 0.9375rem;
          color: var(--color-quaternary-l);
          line-height: 16px;
          margin-block-start: 2px;

          cursor: pointer;
        }

        .group-check {
          input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }

          .checkmark {
            display: inline-flex;
            width: 1rem;
            height: 1rem;
            border: 1.2px solid var(--color-radio-border);
            border-radius: 3px;
            transition: border-color 0.3s, background-color 0.3s;
            background-color: transparent;
            position: relative;
            justify-content: center;
            align-items: center;

            vertical-align: middle;

            &::after {
              content: "";
              width: 5px;
              height: 9px;
              border: solid transparent;
              border-width: 0 1.75px 1.75px 0;
              // position: absolute;
              // // top: 1.5px;
              // // left: 5px;
              // top: 50%;
              // left: 50%;
              transform: rotate(45deg);
              margin-block-start: -2px;

              transition: border-color 0.3s;
            }
          }

          input[type="checkbox"]:checked + .checkmark {
            background-color: var(--color-primary-d);
            border: 1.2px solid var(--color-primary-d);
          }

          input[type="checkbox"]:checked + .checkmark::after {
            border-color: var(--color-radio-border);
          }
        }
      }

      .colors-options {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .color-listItem {
          width: 5rem;
          height: 5rem;
          margin-inline-start: 0.9375rem;
          margin-block-end: 0.9375rem;
          display: inline-block;

          flex-shrink: 0;

          border: 3px solid transparent;

          cursor: pointer;
          transition: all 0.3s ease-out;

          &:hover {
            border: 3px solid var(--color-primary-d);
          }
        }

        // .color-listItem.default {
        //   background-color: var(--color-secondary-m-d);
        // }

        .color-listItem.active {
          border: 3px solid var(--color-primary-d);
        }
      }
    }
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}


