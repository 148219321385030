.msgSendText,
.msgReceivedText {
  border-radius: 8px;
  padding: 4px 8px 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 4px;
  max-width: 60%;
  overflow-wrap: break-word;
  align-self: flex-end;
  position: relative;
  background-color: var(--color-quinary);

  .delete-message {
    display: flex;
    align-items: center;
    gap: 3px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;

      font-size: 0.875rem;
      color: var(--color-profile-description);
      line-height: 1.25;
      font-style: italic;

      display: flex;

      .space {
        display: inline-block;
        width: 60px;
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-inline-start: 3px;
      }
    }
  }

  .message-status {
    position: absolute;
    bottom: 2px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    font-size: 0.625rem;
    color: var(--color-tertiary-v-l);
  }
}
