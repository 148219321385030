.spinner {
  width: 2rem;
  margin-inline: auto;
  margin-block: 5px 0.625rem;

  animation-name: spin;
  animation-duration: 0.8s;

  animation-timing-function: steps(12, end);

  animation-iteration-count: infinite;
}
// .on {
//   animation-name: spin;
// }
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
