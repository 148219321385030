.sharemenu {
  width: 135px;
  height: auto;
  white-space: nowrap;
  border-radius: 10px;
  background-color: var(--color-popup);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  overflow: hidden;
  position: absolute;
  z-index: 50;

  .menu-list {
    li {
      font-size: 0.875rem;
      color: var(--color-quaternary-l);
      border-block-end: 1px solid var(--color-border);
      padding-block: 8px;
      padding-inline-start: 0.9375rem;

      cursor: pointer;
    }

    li:hover {
      background-color: rgba(23, 144, 163, 0.1);
    }

    &:last-child {
      border-bottom: 0;
      padding-block-end: 0;
      margin-block-end: 0;
    }
  }
}
