.whyPost-spinner {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.whyPost {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .dialog {
    width: 400px;
    opacity: 0;
    scale: 0;
    background-color: var(--color-secondary);
    // padding: 1.125rem 1.35rem;
    border-radius: 0.5rem;
    animation: delay-dialog 0.3s ease-out forwards;

    .whyPost-heading {
      font-size: 1.4rem;
      color: var(--color-quaternary-l);
      font-weight: 400;
      padding-inline: 1.25rem;

      margin-block: 1rem 0.875rem;
    }

    .whyPost-info {
      color: var(--color-description);
      font-size: 0.8rem;
      line-height: 1.35;

      padding-inline: 1.25rem 1.5rem;

      .learMore a {
        padding-inline-start: 2px;
        color: var(--color-primary-d);
      }
    }

    .whyPost-reasons {
      display: flex;
      flex-direction: column;

      // margin-block-start: 1rem;

      .reason {
        display: flex;
        align-items: center;
        gap: 12px;

        // padding-block-end: 1rem;
        // padding-inline: 1.25rem;
        padding: 1rem 1.25rem;

        .reason-image-wrapper {
          display: flex;

          .reason-image {
            width: 1.875rem;
            height: 1.875rem;
            border-radius: 50%;

            overflow: hidden;

            flex-shrink: 0;

            outline: 0.5px solid var(--color-secondary);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .reason-image:not(:first-child) {
            margin-left: -12px;
          }
        }

        .reason-info {
          display: flex;
          flex-direction: column;

          .heading {
            color: var(--color-quaternary-l);
            font-size: 0.9375rem;
            line-height: 1.25;
          }

          .info {
            color: var(--color-description);
            font-size: 0.77rem;
            padding-inline-end: 1rem;

            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        &:not(:first-child) {
          border-block-start: 0.5px solid var(--color-border);

          // margin-block-start: 1rem;
        }
      }

      .reason-group {
        cursor: pointer;
      }
    }
  }
}
@keyframes delay-dialog {
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
