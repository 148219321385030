.groupInfo {
  background-color: var(--color-settings-bg);
  height: 100%;
  overflow-y: auto;

  // position: absolute;
  // inset: 0;

  // animation: slideInRight 500ms cubic-bezier(1, 0, 0, 1) forwards;

  box-shadow: -4px 0px 22px 0px rgba(0, 0, 0, 0.0784313725);

  &.open {
    animation: slideInRight1 250ms ease forwards;
  }
  
  &.close {
    animation: slideOutRight2 250ms ease forwards;
  }
  

  .header {
    background-color: var(--color-primary-d);
    background-image: url("../image/bg-pattern.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;

    padding: 1.25rem 1rem 1rem;

    .main-cta {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn-back,
      .btn-settings {
        padding: 4px;

        &:focus-visible {
          outline: 0;
          border-radius: 2px;
          box-shadow: 0 0 0 2px var(--color-focused);
        }
      }
    }

    .group-image {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      overflow: hidden;
      margin-inline: auto;
      margin-block: 8px 10px;

      cursor: pointer;

      img {
        scale: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease;
      }

      &:hover img {
        scale: 1.125;
      }
    }

    .group-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      max-width: 75%;
      margin-inline: auto;

      .name {
        color: var(--color-offwhite);
        font-size: 1.25rem;
        font-weight: 600;
        margin-block-end: 5px;
        line-height: 1.35;
      }

      .status {
        color: var(--color-offwhite);
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        margin-block-end: 1rem;

        .more {
          color: var(--color-secondary);
          font-weight: 500;
          cursor: pointer;
        }
      }

      .sub-cta {
        display: flex;
        align-items: center;
        gap: 2.5rem;

        .call,
        .unmute,
        .search {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 2px;

          cursor: pointer;

          svg {
            width: 1.5rem;
            height: 1.5rem;
            object-fit: cover;
          }

          span {
            display: inline-block;
            color: #f6feff;
            text-align: center;
            font-size: 0.8rem;
          }

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }
        }
      }
    }
  }

  .group-description {
    padding: 0.75rem 0.9375rem;
    background-color: var(--color-secondary);

    border-block-end: 0.5px solid var(--color-border);

    .description {
      width: 85%;
      font-size: 0.9375rem;
      color: var(--color-quaternary-l);
      line-height: 1.25;

      .learnMore {
        font-weight: 500;
        padding-inline-start: 2px;

        cursor: pointer;
      }
    }
  }

  .group-links {
    padding-block: 0.35rem 0.75rem;
    background-color: var(--color-secondary);

    .group-chat-settings,
    .group-media-settings .wrapper {
      padding-inline: 0.9375rem;
      padding-block: 0.875rem;
      border-block-end: 0.5px solid var(--color-border);

      display: flex;
      align-items: center;
      justify-content: space-between;

      color: var(--color-quaternary-l);

      cursor: pointer;

      .settings {
        display: flex;
        align-items: center;
        gap: 0.75rem;
      }
    }

    .group-media-settings {
      .wrapper {
        border-block-end: 0;

        .media {
          display: flex;
          align-items: center;
          gap: 0.75rem;

          .icon {
            width: 1.875rem;
            height: 1.875rem;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          .group-setting-chat {
            color: var(--color-quaternary-l);
          }
        }
      }

      .media-content {
        display: flex;
        // justify-content: center;
        gap: 7px;

        padding-inline: 0.9375rem;

        .icon {
          //   flex-basis: 25%;

          width: 90px;
          height: 100px;
          background-color: var(--color-primary-d);
          border: 1px solid;
          border-radius: 8px;
          overflow: hidden;

          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .members {
    background-color: var(--color-secondary);
    .members-number {
      background-color: var(--color-settings-bg);
      padding-inline: 0.9375rem;
      padding-block: 0.875rem;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .number {
        color: var(--color-description);
        font-size: 0.8rem;
      }

      .search-member {
        width: 0.9375rem;
        height: 0.9375rem;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .members-info {
      display: flex;
      flex-direction: column;

      padding-block: 0.35rem 0.75rem;

      .add-participants,
      .invite-link,
      .pending-request {
        padding-inline: 0.9375rem;
        // padding-block: 0.625rem;

        display: flex;
        align-items: center;
        gap: 0.75rem;
        flex-shrink: 0;

        cursor: pointer;

        .participant-text,
        .link-text,
        .pending-text {
          //   padding-block: 0.875rem;
          padding-block: 1.125rem;
          border-block-end: 0.5px solid var(--color-border);
          flex: 1;
        }

        .text {
          font-size: 0.9375rem;
          color: var(--color-primary-d);
        }
      }

      .add-image,
      .link-image,
      .pending-image {
        width: 45px;
        height: 45px;
        background-color: var(--color-primary-d);
        border-radius: 50%;

        display: grid;
        place-items: center;
      }
    }

    .pending-request {
      .pending-image {
        background-color: #e9edf0;
      }
      .pending-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;

        .text {
          color: var(--color-quaternary-l);
          font-weight: 600;
        }

        .pending-number {
          background-color: #1890a01f;
          padding: 2px 10px;
          border-radius: 100px;
          font-size: 0.7rem;
          color: var(--color-primary-d);
        }
      }
    }

    .other-members {
      padding-block-start: 0.625rem;
      padding-inline: 0.625rem;
      text-align: right;
      color: var(--color-primary-d);

      cursor: pointer;
    }
  }

  //   .profiles {
  .profile,
  .encryption {
    cursor: pointer;

    // transition: all 3s cubic-bezier(1, 0, 0, 1);
    display: flex;
    padding-inline-start: 15px;
    // margin-block-end: 10px;
    align-items: center;

    .profile-image {
      width: 45px;
      height: 45px;
      background-color: var(--color-primary-d);
      border-radius: 50%;
      padding: 1px;
      // border: 1.5px solid var(--color-primary-d);
      margin-inline-end: 0.75rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .profile-info {
      flex: 1;
      display: flex;
      align-items: center;
      // align-items: flex-end;
      justify-content: space-between;
      padding-block: 13px;
      padding-inline-end: 15px;
      // margin-block-end: 10px;
      // margin-block: 10px;
      // border-block-end: 0.5px solid var(--color-arrow;
      border-block-end: 0.5px solid var(--color-border);
      // flex-direction: column;

      &:first-child {
        border-block-start: 0.5px solid var(--color-arrow);
      }

      .profile-info-name {
        p {
          font-size: 0.9375rem;
          // font-weight: 600;
          color: var(--color-quaternary-l);
        }
      }

      .status {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        img {
          width: 14px;
        }

        .profile-info-message {
          color: var(--color-description);
          font-size: 0.7rem;
          font-weight: 400;
          // word-wrap: break-word;
          overflow-wrap: break-word;
        }
      }
    }

    .profile-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 3px;

      .profile-details-status {
        width: fit-content;
        background-color: #1890a01f;
        padding: 3px 8px;
        border-radius: 100px;
        font-size: 0.7rem;
        color: var(--color-primary-d);
        text-align: center;

        align-self: flex-end;
      }

      .profile-number {
        display: flex;
        align-items: center;
        gap: 5px;

        .number {
          color: var(--color-description);
          font-size: 0.75rem;
        }
        .menu-image {
          width: 12px;
          height: 12px;
          cursor: pointer;

          transform: translateX(10px);
          display: none;
          opacity: 0;
          transition: all 0.3s cubic-bezier(1, 0, 0, 1);

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &:hover .menu-image {
      display: block !important;
      transform: translateX(0) !important;
      opacity: 1 !important;
    }
  }

  .encryption {
    margin-block: 0.9375rem;
    padding: 0.75rem 0.9375rem;
    background-color: var(--color-secondary);
    gap: 0.75rem;

    .encryption-image {
      width: 1.875rem;
      height: 1.875rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .encryption-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      .info {
        display: flex;
        flex-direction: column;

        .info-title {
          color: var(--color-quaternary-l);
          font-size: 0.875rem;
          margin-block-end: 3px;
        }

        .info-subtitle {
          color: var(--color-description);
          font-size: 0.7rem;
          line-height: 10px;
        }

        .info-more {
          color: var(--color-primary-d);
          font-size: 0.7rem;
          //   line-height: 10px;
          cursor: pointer;
        }
      }
    }

    .cta {
      display: flex;
      align-items: center;
      gap: 5px;

      span {
        color: var(--color-description);
        font-size: 0.75rem;
        line-height: 10px;
      }

      // img {
      //   width: 0.8rem;
      //   height: 0.8rem;
      // }
    }
  }

  .group-cta,
  .group-creation-info {
    // padding-block: 0.625rem;
    background-color: var(--color-secondary);

    display: flex;
    flex-direction: column;

    .report,
    .exit {
      padding-block: 0.75rem;
      padding-inline: 0.9375rem;
      border-block-end: 0.5px solid var(--color-border);

      display: flex;
      align-items: center;
      gap: 0.75rem;
      flex-shrink: 0;
      flex: 1;

      cursor: pointer;

      .report-image,
      .exit-image {
        width: 0.9375rem;
        height: 0.9375rem;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .text {
      font-size: 1rem;
      color: #e72915;
    }
  }

  .group-creation-info {
    padding: 0.75rem 0.9375rem;
    .name,
    .date {
      color: var(--color-description);
      font-size: 0.7rem;
      line-height: 1rem;
    }
  }

  @keyframes slideInRight1 {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutRight2 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

