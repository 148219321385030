.send-img,
.recieved-img {
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: inset 0px -31.3527px 28.5024px rgba(0, 0, 0, 0.5);
  position: relative;

  .image-blur,
  .image-unblur {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;

    .chat-img {
      width: 100%;
      height: 50%;
      object-fit: cover;
    }

    .loader {
      position: absolute;
      inset: 0;
      z-index: 1000;
      display: grid;
      place-content: center;

      img {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }

      .size {
        text-align: center;
      }
    }
  }

  .image-blur {
    .blur {
      filter: blur(8px);
    }
  }

  .message-status {
    position: absolute;
    bottom: 2px;
    right: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.625rem;
    color: var(--color-offwhite);
    z-index: 100;

    .important {
      width: 10px;
    }

    .img-status {
      width: 14px;
    }
  }

  .shadow-background {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.75rem;
    z-index: 10;
    background-image: linear-gradient(
      to top,
      rgb(11, 20, 26, 0.3),
      rgb(10, 20, 26, 0)
    );

    .image-container {
      width: fit-content;
      height: fit-content;
      position: relative;

      .chat-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .blur {
        filter: blur(8px);
      }

      .loader {
        position: absolute;
        inset: 0;
        display: grid;
        place-content: center;

        img {
          width: 50px;
          height: 50px;
        }

        .size {
          text-align: center;
        }
      }
    }
  }

  .down-arrow {
    position: absolute;
    top: 0.5rem;
    width: 20px;
    height: 20px;
    right: 0.5rem;
    cursor: pointer;
    display: none;
    animation: slideIn 0.2s ease-out;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .sendText {
    padding: 4px 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--color-secondary);

    .reply-message {
      padding-block-end: 10px;
      font-size: 0.875rem;
      color: var(--color-quaternary-m-d);
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-tertiary-v-l);

      .important {
        width: 10px;
      }

      .reply-status {
        width: 14px;
      }
    }
  }

  &:hover .down-arrow {
    display: block;
  }

  @keyframes slideIn {
    0% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }
}

.send-img {
  align-self: self-end;
}

.recieved-img {
  align-self: self-start;
}
