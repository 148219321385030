.deleteMessage {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;

  display: grid;
  place-items: center;

  animation: delay 0.3s ease forwards;

  .dialog {
    width: 450px;
    background-color: var(--color-popup);
    padding: 1.125rem 1.35rem;
    border-radius: 0.5rem;

    animation: delay-dialog 0.3s ease forwards;

    &:has(.dialog-info-wrapper) .dialog-info {
      margin-block-end: 1.125rem;
    }

    // &:has(:not(.dialog-info-wrapper)) .dialog-info {
    //   margin-block-end: 1.875rem;
    // }

    .dialog-info {
      display: flex;
      gap: 1rem;
      margin-block-end: 1.875rem;

      h4 {
        font-size: 1.35rem;
        font-weight: 400;
        color: var(--color-quaternary-l);
      }

      .back {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--color-primary-d);
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
      }
    }

    .dialog-info-wrapper {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      margin-block-end: 2rem;

      .group-check {
        margin-block-start: -2px;
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;
        }

        .checkmark {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          position: relative;

          min-width: 18px;
          width: 18px;
          height: 18px;
          vertical-align: middle;

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          .radio-cmn {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            border-radius: 50%;

            line-height: 0;
            white-space: nowrap;
          }

          .offRadio {
            border: 1.5px solid var(--color-description);
            background-color: transparent;

            transition: border 250ms ease;
          }

          .onRadio {
            will-change: transform;
            background-color: var(--color-primary-d);

            scale: 0;
            transition: scale 250ms ease;
          }
        }

        input[type="radio"]:checked + .checkmark .onRadio,
        input[type="radio"]:focus + .checkmark .onRadio {
          scale: 0.6;
        }
        input[type="radio"]:checked + .checkmark .offRadio,
        input[type="radio"]:focus + .checkmark .offRadio {
          border-color: var(--color-primary-d);
        }
      }

      .description {
        font-size: 0.875rem;
        color: var(--color-description);
        flex: 1;
      }
    }

    .cta {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      .btn-cancel,
      .btn-for-me,
      .btn-for-everyone {
        background-color: transparent;
        padding: 0.5rem 1.125rem;
        font-size: 0.875rem;
        color: var(--color-primary-d);
        border: 1px solid var(--color-primary-d);
        border-radius: 5px;
      }

      .active {
        background-color: var(--color-primary-d);
        color: var(--color-offwhite);
      }

      &:has(.btn-for-everyone) {
        justify-content: flex-start;
      }
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
