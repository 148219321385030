.allowCamera {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  opacity: 0;
  // animation: delay 0.3s ease-out forwards;
  animation: delay 0.3s ease forwards;

  display: grid;
  place-items: center;

  .dialog {
    width: 450px;
    background-color: var(--color-secondary);
    padding: 1.25rem 1.35rem;
    border-radius: 0.75rem;

    display: flex;
    flex-direction: column;

    animation: delay-dialog 0.3s ease forwards;

    .dialog-info {
      display: flex;
      align-items: flex-start;
      gap: 1rem;

      h4 {
        font-size: 1.35rem;
        font-weight: 400;
        color: var(--color-quaternary-l);
        margin-block-start: -4px;
      }

      .allow-image {
        border-radius: 50%;
      }
    }

    .description {
      font-size: 0.875rem;
      color: var(--color-quaternary-l);
      line-height: 1.45;
      margin-block: 0.45rem 3rem;
      margin-inline-start: 3.5rem;
    }

    // .description.space {
    //   padding-inline-start: 3.5rem;
    // }

    .btn-allow {
      font-size: 0.875rem;
      background-color: var(--color-primary-d);
      padding: 0.5rem 1.5rem;
      color: var(--color-offwhite);
      border-radius: 5px;
      align-self: flex-end;
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
