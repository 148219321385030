.teparsView {
  width: 100%;
  // height: 100%;
  overflow-y: auto;
  backface-visibility: hidden;
  // padding-block-start: 1.5rem;

  // -webkit-overflow-scrolling: touch;

  .teparsView-wrapper {
    width: min(450px, calc(100% - 2rem));
    margin-inline: auto;
    display: flex;
    flex-direction: column;

    background-color: var(--color-tepars-bg);
    padding-block-start: 1.25rem;
    // padding-inline: 18px;

    box-shadow: 0px 0px 10px 0px #00000026;

    // min-height: 100%;
    height: auto;

    .uptodate-post {
      width: 100%;
      padding-block: 2rem;
      margin-block-end: 10px;
      border-block-end: 0.5px solid var(--color-border);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .done-image {
        width: 90px;
        height: 90px;
        border: 2px solid var(--color-quaternary-l);
        border-radius: 50%;
        margin-block-end: 0.625rem;
        display: grid;
        place-items: center;

        svg {
          width: 70%;
        }
      }

      .done-text {
        color: var(--color-quaternary-l);
        font-size: 1rem;
        margin-block-end: 0.25rem;
      }

      .done-info {
        color: var(--color-description);
        font-size: 0.7rem;
      }
    }

    .post-wrapper {
      .reposted-notification {
        background-color: #fafafa;
        padding: 0.625rem 1rem;
        margin-block-start: 5px;

        .reposted-notification-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;

          .text {
            color: var(--color-description);
            font-size: 0.875rem;
            font-weight: 400;

            .reposted-user-name {
              margin-inline: 4px;
              color: var(--color-primary-d);
              cursor: pointer;

              display: inline-flex;
              align-items: center;
              gap: 1px;
            }
          }
        }
      }

      .post {
        display: flex;
        flex-direction: column;

        margin-block-end: 0.625rem;

        .header {
          padding-inline: 18px 10px;

          .profile {
            display: flex;
            align-items: center;
            height: 4.375rem;

            .profile-image {
              width: 3.125rem;
              height: 3.125rem;
              // background-color: var(--color-primary-d);
              border-radius: 50%;
              margin-inline-end: 0.75rem;
              overflow: hidden;
              // outline-offset: 1px;
              // outline: 2px solid var(--color-primary-d);
              position: relative;

              flex-shrink: 0;

              cursor: pointer;

              &:focus-visible {
                outline: 0;
                border-radius: 50%;
                box-shadow: 0 0 0 2px var(--color-focused);
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .profile-info {
              display: flex;
              align-items: center;
              gap: 0.625rem;
              flex: 1;

              .profile-info-wrapper {
                flex: 1;

                display: flex;
                flex-direction: column;

                // .profile-info-name {
                //   display: flex;
                //   align-items: center;

                //   font-weight: 300;

                //   .name {
                //     display: inline-block;
                //     color: var(--color-quaternary-l);
                //     font-size: 1rem;
                //     font-weight: 400;
                //     margin-inline-end: 5px;
                //     line-height: 1.25;

                //     cursor: pointer;

                //     &:focus-visible {
                //       outline: 0;
                //       border-radius: 2px;
                //       box-shadow: 0 0 0 2px var(--color-focused);
                //     }
                //   }

                //   .profile-location {
                //     display: flex;
                //     gap: 5px;
                //     align-items: center;
                //     font-size: 1rem;
                //     line-height: 1.25;
                //     color: var(--color-quaternary-l);

                //     .location-icon {
                //       margin-block-start: -3px;

                //       flex-shrink: 0;
                //     }

                //     .profile-location-wrapper {
                //       display: flex;
                //       align-items: center;
                //       gap: 5px;

                //       cursor: pointer;

                //       .location-name {
                //         color: var(--color-quaternary-l);
                //         font-weight: 400;

                //         overflow: hidden;
                //         word-break: break-all;
                //         text-overflow: ellipsis;
                //         display: -webkit-box;
                //         -webkit-line-clamp: 1;
                //         -webkit-box-orient: vertical;
                //         width: 17ch;
                //       }
                //     }
                //   }
                // }

                .circle-member {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;

                  line-height: 1.35;
                  font-size: 0.8rem;
                  color: var(--color-description);
                  font-weight: 400;

                  overflow: hidden;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;

                  .name {
                    display: inline;
                    // color: var(--color-quaternary-l);
                    // font-size: 1rem;
                    // font-weight: 400;
                    // // margin-inline-end: 5px;
                    line-height: 1.25;

                    cursor: pointer;

                    &:focus-visible {
                      outline: 0;
                      border-radius: 2px;
                      box-shadow: 0 0 0 2px var(--color-focused);
                    }
                  }

                  .location-name {
                    display: inline;
                    align-items: center;
                    cursor: pointer;
                    // color: var(--color-quaternary-l);
                    // font-weight: 400;

                    .location-icon {
                      display: inline-block;
                      margin-inline: 0px 5px;

                      flex-shrink: 0;
                    }
                  }
                }

                .profile-info-name {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;

                  font-size: 1rem;
                  line-height: 1.35;
                  color: var(--color-quaternary-l);
                  font-weight: 300;

                  overflow: hidden;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;

                  .name {
                    display: inline;
                    color: var(--color-quaternary-l);
                    font-size: 1rem;
                    font-weight: 400;
                    // margin-inline-end: 5px;
                    line-height: 1.25;

                    cursor: pointer;

                    &:focus-visible {
                      outline: 0;
                      border-radius: 2px;
                      box-shadow: 0 0 0 2px var(--color-focused);
                    }
                  }

                  .location-name {
                    display: inline;
                    align-items: center;
                    cursor: pointer;
                    color: var(--color-quaternary-l);
                    font-weight: 400;

                    .location-icon {
                      display: inline-block;
                      margin-inline: 0px 5px;

                      flex-shrink: 0;
                    }
                  }
                }

                .profile-details {
                  display: flex;
                  align-items: center;
                  gap: 0.3rem;

                  .profile-details-time {
                    color: var(--color-description);
                    font-size: 0.8rem;
                  }

                  .circle-image {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                  }

                  .name {
                    display: inline-block;
                    font-size: 1rem;
                    font-weight: 400;
                    color: var(--color-quaternary-l);
                    cursor: pointer;

                    &:focus-visible {
                      outline: 0;
                      border-radius: 2px;
                      box-shadow: 0 0 0 2px var(--color-focused);
                    }
                  }
                }
              }

              .profile-cta {
                width: 36px;
                height: 36px;
                padding: 6px;
                border-radius: 50%;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2px;

                cursor: pointer;

                &:hover,
                &:focus-visible {
                  background-color: var(--color-selected-bg);
                }
              }
            }
          }
        }
      }

      .post-info-wrapper {
        // margin-block-start: 5px;

        .post-info {
          // max-width: 96%;
          margin-block: 0px 0.625rem;
          padding-inline: 18px;

          overflow-wrap: break-word;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          // -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;

          .info {
            color: var(--color-quaternary-l);
            font-size: 0.875rem;
            line-height: 1.25;
            white-space: pre-wrap;

            .hastags {
              display: inline-block;
              font-size: inherit;
              color: var(--color-primary-d);
              // padding-inline-start: 3px;
            }
          }

          .read-more {
            display: inline-block;
            font-size: 0.8rem;
            color: var(--color-primary-d);
            margin-inline-start: 3px;
            cursor: pointer;
          }
        }

        .post-media-wrapper {
          width: 100%;
          position: relative;

          // overflow: hidden;

          .post-media {
            width: 100%;
            // height: 340px;
            overflow: hidden;
            background-color: rgb(0 0 0);

            img,
            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
              // object-fit: contain;
            }

            video {
              cursor: pointer;
            }
          }

          .image-number {
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 500px;
            // background: hsl(227, 9%, 20%, 40%);
            background-color: var(--color-popup);
            display: grid;
            place-items: center;

            .number {
              display: inline-block;
              padding: 3px 0.8rem;
              font-size: 0.75rem;
              color: var(--color-offwhite);
            }
          }

          .image-sound {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            // background-color: var(--color-popup);
            // opacity: 0.5;
            background-color: #2e303850;

            position: absolute;
            bottom: 10px;
            right: 10px;

            display: grid;
            place-items: center;

            cursor: pointer;

            svg {
              width: 60%;
            }
          }

          .video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 50px;
            height: 50px;
            background-color: rgb(0, 0, 0, 0.5);
            border-radius: 50%;
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              margin-inline-start: 3px;
            }
          }

          .doubleTepAnimation {
            position: absolute;
            top: 0;
            left: 0;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            // transform: scale(0);

            z-index: 10;

            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            pointer-events: none;
            will-change: transform, opacity;

            animation: doubleTeps 1000ms ease-in-out forwards;
          }
        }

        .post-media-cta,
        .advertisement-click {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin-block: 6px 0;
          padding-block: 6px;
          padding-inline: 12px;

          .btn-clickHere {
            text-transform: uppercase;
          }

          .media-cta {
            display: flex;
            align-items: center;
            gap: 0.875rem;

            .btn-comment,
            .btn-reply {
              // width: 1.25rem;
              // height: 1.25rem;
              // cursor: pointer;
              padding: 4px;

              // svg {
              //   width: 100%;
              //   height: 100%;
              // }
            }

            .btn-like {
              // width: 1.25rem;
              // height: 1.25rem;
              padding: 4px;

              img {
                width: 28px;
                height: 28px;
              }
            }

            .btn-like .animate {
              animation-name: likeAnimation;
              animation-timing-function: ease-in-out;
              animation-duration: 0.45s;
            }
          }

          .change-media {
            width: 60px;
            height: 12px;
            background-color: var(--color-primary-d);
          }

          .repost {
            display: flex;
            align-items: center;
            gap: 0.25rem;

            .btn-repost {
              // width: 1.25rem;
              // height: 1.25rem;
              padding: 4px;

              // svg {
              //   width: 100%;
              //   height: 100%;
              // }
            }

            .repost-number {
              display: inline-block;
              font-size: 0.9375rem;
              color: var(--color-description);
              line-height: 1;
              margin-block-start: 2px;
            }
          }
        }
      }

      .post-report {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding-inline: 18px;

        .users-liked {
          display: flex;
          align-items: center;
          gap: 5px;
          // margin-block: 0.625rem 0.35rem;
          // margin-block: 0.45rem;
          margin-block: 0px 10px;
          cursor: pointer;

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          .users-profiles-wrapper {
            display: flex;
            align-items: center;

            .users-profile {
              width: 1.125rem;
              height: 1.125rem;
              border-radius: 50%;
              // outline-offset: 1px;
              outline: 1.5px solid var(--color-white);
              z-index: 10;
              overflow: hidden;

              &:not(:first-child) {
                margin-inline-start: -5px;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          .users-liked-info {
            color: var(--color-quaternary-l);
            font-weight: 300;
            font-size: 0.875rem;

            span {
              font-weight: 400;
            }
          }
        }

        .users-comments {
          line-height: 1;
          margin-block-end: 8px;
          cursor: pointer;

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          .view-all {
            display: inline-block;
            color: var(--color-description);
            font-size: 0.875rem;
            line-height: inherit;
          }
        }

        .add-comment-wrapper {
          display: flex;
          align-items: center;
          gap: 5px;
          margin-block-end: 8px;

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          .comment-user-profile {
            width: 1.125rem;
            height: 1.125rem;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .add-comment {
            cursor: pointer;

            .comment {
              display: inline-block;
              color: var(--color-placeholder);
              font-size: 0.875rem;
            }
          }
        }

        .why-seen-post {
          // margin-block-end: 0.625rem;

          cursor: pointer;

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          .text {
            color: var(--color-primary-d);
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

.tepars-spinner {
  height: 100%;
  display: flex;

  .spinner {
    margin: auto;
  }
}

.upload-progress {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-block: 10px;
  border-block-end: 0.5px solid var(--color-border);
  width: min(480px, calc(100% - 2.25rem));
  margin-inline: auto;
  // margin-block-start: 2rem;

  .upload-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .upload-info-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      .profile-image {
        width: 40px;
        height: 40px;
        overflow: hidden;

        .spinner {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          margin: 0;

          svg {
            width: 1.125rem;
            height: 1.125rem;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .upload-progress-text {
        color: var(--color-quaternary-l);
        font-size: 0.75rem;
      }
    }
  }

  .upload-progress-range {
    // width: 100%;
    height: 5px;
    border-radius: 50px;
    background-color: #d9d9d9;
    position: relative;
    overflow: hidden;

    .selected-color {
      position: absolute;
      inset: 0;
      width: 15%;
      height: 5px;
      border-radius: 50px;
      background-color: var(--color-primary-d);
    }
    .initial-state {
      position: absolute;
      top: 0;
      left: -100%;
      height: 5px;
      width: 60%;
      border-radius: 50px;
      background-color: var(--color-primary-d);
      animation: slide 2s linear infinite;
    }
  }
}

// for slider customizations
.slick-dots {
  display: flex;
  justify-content: center;
  padding: 0 !important;

  .slick-dots ul {
    width: 2px;
  }
}

.slick-dots li {
  width: 2px !important;
  height: 0px !important;
}

.slick-prev {
  font-size: 20px;
  z-index: 999;
}

.slick-prev:before {
  font-size: 41px;
  position: relative;
  background: url("../image/SVG_Images/tepars-left.svg") no-repeat right;
  color: transparent;
}

.slick-next {
  font-size: 20px;
  z-index: 10;
}

.slick-next:before {
  font-size: 41px;
  position: relative;
  background: url("../image/SVG_Images/tepars-right.svg") no-repeat right;
  color: transparent;
}

.slick-dots li button:before {
  color: #d9d9d9;
  font-size: 6px;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: var(--color-primary-d);
  font-size: 8px;
}

.slick-dots li:not(.slick-active) .slick-dots li {
  width: 500px;
}

.teparsView-spinner {
  margin-block: 1rem;
}

// /* Ensure the slider container allows touch events */
// .post-media-wrapper {
//   touch-action: pan-y; /* Allow vertical scrolling, but handle horizontal ourselves */
// }

// /* Make sure the slider and its items don't have pointer-events set to none */
// .slick-list,
// .slick-track,
// .slick-slide,
// .slick-slide > div {
//   pointer-events: auto !important;
// }

// /* Ensure the slider items are not obscured by other elements */
// .post-media {
//   position: relative;
//   z-index: 1;
// }

// /* If you're using any 3D transforms, they might create issues. Try forcing 2D transforms */
// .slick-track,
// .slick-list {
//   transform-style: preserve-2d !important;
// }

// .post-media-wrapper {
//   touch-action: pan-y !important;
//   // user-select: none !important;
// }

@keyframes likeAnimation {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes doubleTeps {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  40% {
    opacity: 0.8;
    transform: scale(1.2) rotate(25deg);
  }

  60% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.9) translateY(-800px);
  }
}

@keyframes slide {
  0% {
    left: -100%;
    right: 100%;
  }
  50% {
    left: 100%;
    right: -100%;
  }
  100% {
    left: 100%;
    right: -100%;
  }
}
