.profileAvatarView {
  background-color: var(--color-secondary);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  width: 100%;
  height: 100%;

  .profile-details {
    width: 100%;
    height: 100%;

    .profile-detail {
      height: 65px;
      padding-inline: 2rem;
      padding-block: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .profile-image-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 0.75rem;

        .profile-image {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          color: var(--color-quaternary-l);
          font-size: 1rem;
          font-weight: 500;
          display: inline-block;
        }
      }

      .btn-close {
        // width: 2.5rem;
        padding: 4px;

        &:focus-visible {
          outline: 0;
          border-radius: 2px;
          box-shadow: 0 0 0 2px var(--color-focused);
        }
      }
    }

    .avatar-image-wrapper {
      height: calc(100% - 65px);
      display: grid;
      place-items: center;
      animation: delay-dialog 0.3s ease-out forwards;

      .avatar-image {
        width: 500px;
        height: 500px;
        overflow: hidden;
        display: grid;
        place-items: center;

        @media screen and (max-width: 1300px) {
          width: 400px;
          height: 400px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@keyframes delay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes delay-dialog {
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
