.msgSendText,
.msgReceivedText {
  border-radius: 8px;
  padding: 4px 8px 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 4px;
  max-width: 60%;
  overflow-wrap: break-word;
  align-self: flex-end;
  position: relative;
  background-color: var(--color-quinary);

  &:has(.important) .common-message .space {
    width: 78px;
  }

  .delete-img {
    width: 1rem;
  }

  .common-message {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    font-size: 0.875rem;
    color: var(--color-quaternary-m-d);
    line-height: 1.3;
    display: flex;

    .space {
      display: inline-block;
      width: 60px;
      height: 0;
      opacity: 0;
      visibility: hidden;
      margin-inline-start: 3px;
    }
  }

  .additional {
    color: var(--color-primary-d);
    &:hover {
      text-decoration: underline var(--color-primary-d);
    }
  }

  .read-more {
    margin-left: 5px;
    cursor: pointer;
    color: var(--color-primary-d);
  }

  .message-status {
    position: absolute;
    bottom: 2px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    font-size: 0.625rem;
    color: var(--color-tertiary-v-l);

    .important {
      width: 10px;
    }

    .text-status {
      width: 14px;
    }

    .pending {
      width: 10px;
    }
  }

  .down-arrow {
    width: 8px;
    height: 8px;
    position: absolute;
    cursor: pointer;
    display: inline-block;
    top: 8px;
    align-self: flex-end;
    display: none;

    animation: slideIn 0.3s ease-out;
  }

  &:hover .down-arrow {
    display: block;
  }
}

.msgReceivedText {
  align-self: flex-start;
  background-color: var(--color-secondary);

  .common-message {
    color: var(--color-quaternary-l);
    .space {
      width: 40px;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
