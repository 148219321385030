.notifications-settings {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 11;

  animation: slideInLeft 250ms ease forwards;

  .notifications-settings-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-settings-bg);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;
      min-height: 54px;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }
    }

    .messages,
    .sounds {
      // height: 100%;
      // overflow-y: auto;

      background-color: var(--color-secondary);

      .messages-heading {
        padding: 1.25rem 0.9375rem 0.625rem;

        .heading {
          font-size: 0.875rem;
          color: var(--color-primary-d);
        }
      }

      .notifications-setting {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;

        padding-inline: 0.9375rem;
        padding-block: 0.9375rem;
        // height: 4.375rem;
        border-block-end: 0.5px solid var(--color-border);

        cursor: pointer;
        // transition: background 0.2s ease-out;

        // &:hover {
        //   background: var(--color-selected-bg);
        // }

        .title {
          font-size: 1rem;
          color: var(--color-quaternary-l);
        }

        .info {
          flex: 1;

          display: flex;
          flex-direction: column;

          .title {
            font-size: 1rem;
            color: var(--color-quaternary-l);
          }

          .sub-title {
            color: var(--color-description);
            font-size: 0.7rem;
          }
        }

        .group-check {
          input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }

          .checkmark {
            display: inline-flex;
            width: 1rem;
            height: 1rem;
            border: 1.2px solid var(--color-radio-border);
            border-radius: 50%;
            transition: border-color 0.3s, background-color 0.3s;
            background-color: transparent;
            position: relative;
            justify-content: center;
            align-items: center;

            &::after {
              content: "";
              width: 5px;
              height: 9px;
              border: solid transparent;
              border-width: 0 1.75px 1.75px 0;
              // position: absolute;
              // // top: 1.5px;
              // // left: 5px;
              // top: 50%;
              // left: 50%;
              transform: rotate(45deg);
              margin-block-start: -2px;

              transition: border-color 0.3s;
            }
          }

          input[type="checkbox"]:checked + .checkmark {
            background-color: var(--color-primary-d);
            border: 1.2px solid var(--color-primary-d);
          }

          input[type="checkbox"]:focus + .checkmark {
            border-color: var(--color-primary-d);
          }

          input[type="checkbox"]:checked:hover + .checkmark {
            display: block;
          }

          input[type="checkbox"]:checked + .checkmark::after {
            display: block;
            border-color: var(--color-secondary);
          }
        }
      }

      .show-previews,
      .show-reaction-previews {
        border-block-end: 0;
      }
    }

    .messages {
      margin-block-end: 0.9375rem;
    }

    .sounds {
      height: 100%;
    }
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

.notifications-settings.open {
  animation: slideInLeft 250ms ease forwards;
}

.notifications-settings.close {
  animation: slideOutLeft 250ms ease forwards;
}

@keyframes delay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes delay-dialog {
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

// .themePopup {
//   background-color: hsl(0, 0%, 0%, 0.7);
//   position: fixed;
//   inset: 0;
//   z-index: 100;
//   animation: delay 0.3s ease-out forwards;
//   transform-origin: center;
//   display: grid;
//   place-items: center;

//   .dialog {
//     width: 450px;

//     background-color: var(--color-secondary);
//     padding: 1.125rem 1.35rem;
//     border-radius: 0.5rem;
//     animation: delay-dialog 0.3s ease-out;

//     .dialog-info {
//       display: flex;
//       gap: 1rem;
//       margin-block-end: 1.625rem;

//       h4 {
//         font-size: 1.35rem;
//         font-weight: 400;
//         color: var(--color-quaternary-l);
//       }
//     }

//     .dialog-info-wrapper {
//       display: flex;
//       flex-direction: column;
//       gap: 1.5rem;
//       margin-block-end: 2rem;

//       .light-wrapper,
//       .dark-wrapper,
//       .systemDefault-wrapper {
//         display: flex;
//         align-items: center;
//         gap: 0.875rem;

//         .group-check {
//           input[type="radio"] {
//             position: absolute;
//             opacity: 0;
//             height: 0;
//             width: 0;
//           }

//           .checkmark {
//             display: block;
//             width: 1.125rem;
//             height: 1.125rem;
//             border: 1.2px solid var(--color-description);
//             border-radius: 50%;
//             background-color: transparent;
//             position: relative;
//             transition: all 0.3s;
//             cursor: pointer;

//             &::after {
//               content: "";
//               width: 75%;
//               height: 75%;
//               border-radius: 50%;
//               background-color: var(--color-primary-d);
//               position: absolute;
//               top: 50%;
//               left: 50%;
//               transform: translate(-50%, -50%);
//               transition: all 0.3s ease-out;
//               opacity: 0;
//               visibility: hidden;
//             }
//           }

//           input[type="radio"]:checked + .checkmark::after,
//           input[type="radio"]:focus + .checkmark::after {
//             opacity: 1;
//             visibility: visible;
//           }

//           input[type="radio"]:checked + .checkmark,
//           input[type="radio"]:focus + .checkmark {
//             border: 1.2px solid var(--color-primary-d);
//           }
//         }

//         .description {
//           font-size: 1rem;
//           color: var(--color-quaternary-l);

//           cursor: pointer;
//         }
//       }
//     }

//     .cta {
//       display: flex;
//       align-items: center;
//       justify-content: flex-end;
//       width: 100%;
//       gap: 20px;

//       .cancel,
//       .for-me,
//       .for-everyone {
//         background-color: transparent;
//         padding: 0.4rem 1.125rem;
//         font-size: 0.875rem;
//         color: var(--color-primary-d);
//         border: 1px solid var(--color-primary-d);
//         border-radius: 5px;
//         cursor: pointer;
//       }

//       .active {
//         background-color: var(--color-primary-d);
//         color: var(--color-secondary);
//       }
//     }
//   }
// }
