.tepsView {
  width: 100%;
  height: 100%;

  .tepsView-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .search-bar {
      margin: 0.625rem 0.85rem;

      .search-bar-wrapper {
        // padding-inline: 6px;
        border-radius: 0.5rem;
        background-color: rgba(118, 118, 128, 0.12);
        height: 30px;
        // padding: 10px 6px;
        padding-inline: 6px;

        display: flex;
        align-items: center;
        gap: 10px;
        input {
          // color: rgba(60, 60, 67, 0.6);
          color: var(--color-quaternary-l);
          flex: 1;
          appearance: none;
          border: none;
          outline: none;
          background: transparent;
          font-size: 0.875rem;
          height: 100%;

          @media screen and (max-width: 1025px) {
            font-size: 0.8rem;
          }

          &::placeholder {
            color: var(--color-placeholder);
          }
        }
      }
    }
    .profiles {
      height: 100%;
      overflow-y: auto;

      .teps-header {
        display: flex;
        justify-content: space-between;
        padding: 0 0.9375rem 0.5rem 0.9375rem;

        .teps-by {
          font-size: 13px;
          font-weight: 400;
          color: var(--color-quaternary-l);
        }

        .count-teps {
          font-size: 13px;
          font-weight: 400;
          color: var(--color-description);
        }
      }

      .no-results {
        text-align: center;
        font-size: 1rem;
        color: var(--color-description);
      }

      .profile {
        display: flex;
        padding-inline-start: 0.9375rem;
        align-items: center;

        height: 3.75rem;

        cursor: pointer;
        transition: bachground-color 250ms ease-out;

        &:hover,
        &:focus-visible {
          background-color: var(--color-selected-bg);
        }

        &:focus-visible {
          outline: 0;
        }

        .profile-image {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          margin-inline-end: 0.75rem;
          overflow: hidden;

          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .profile-info-container {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-block: 0.8125rem;
          padding-inline-end: 0.9375rem;

          height: 100%;

          .profile-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;

            .name {
              font-size: 0.9375rem;
              color: var(--color-quaternary-l);
              font-weight: 400;
            }

            .description {
              font-size: 0.75rem;
              color: var(--color-description);
              line-height: 1.25;
              overflow-wrap: break-word;

              overflow: hidden;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }

          .status {
            display: flex;
            align-items: center;

            .emoji {
              display: inline-block;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
