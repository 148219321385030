.trimVideo {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--color-popup);

  .trimVideo-wrapper {
    // height: 100%;

    .trimVideo-warning {
      margin: 1.625rem 1.25rem;
      background-color: #f1f1f1;
      border-radius: 500px;
      padding: 0.625rem 1rem;

      .text {
        font-size: 1rem;
        color: var(--color-quaternary-l);
      }
    }

    .trimVideo-container-thumb {
      .video-thumb {
        display: flex;
        justify-content: space-around;
        margin-inline: 1.25rem;
        margin-block-end: 0.625rem;
        height: 95px;
        border-radius: 10px;
        // overflow: hidden;
      }
    }

    .trimVideo-container {
      .trimVideo-container-heading {
        margin-inline: 1rem;
        margin-block-end: 0.875rem;

        .text {
          font-size: 1.35rem;
          color: var(--color-quaternary-l);
        }
      }

      .trimVideo-container-trimSection {
        margin-inline: 1.25rem;
        margin-block-end: 0.625rem;
        height: 95px;

        border-radius: 8px;
        // background-color: #f1f1f1;

        .trimSection-container {
          width: 100%;
          height: 100%;

          position: relative;

          .trimSection-backgroundThumbs {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
            display: flex;

            .thumbs {
              background-image: url("../image/media1.webp");
              background-position: center;
              background-size: cover;

              width: 100%;
              height: 100%;
            }
          }

          .selectedContainer {
            height: 100%;
            width: 100%;

            position: absolute;
            // top: 0;
            // left: 50px;
            inset: 0;

            // .progress {
            //   // width: 6px !important;
            //   height: 110px;
            //   transform: translateY(-7px);
            //   // border-radius: 8px;
            //   border-right: 6px solid rgb(24, 146, 162);
            //   background-color: rgba(24, 146, 162, 0);

            //   position: absolute;
            //   top: 0;
            //   // left: 0;
            //   transition: left 0.1s linear;
            //   // left: 50px;
            //   z-index: 0;
            // }

            .seekable {
              position: absolute;
              inset: 0;
              overflow: hidden;
              border-radius: 8px;
            }

            .progress-container {
              width: 100%;
              height: 110px;
              position: absolute;
              top: -7px;
              left: 0;
              overflow: hidden;
              z-index: 0;

              .progress {
                width: 6px;
                height: 100%;
                background-color: rgb(24, 146, 162);
                position: absolute;
                top: 0;
                left: 0;
                transition: left 250ms linear;
                border-radius: 5px;
              }
            }

            .grabber {
              position: absolute;
              top: 0;

              background-color: var(--color-primary-d);
              width: 17px;
              height: 100%;


              display: flex;
              justify-content: center;
              align-items: center;

              cursor: ew-resize;

              overflow: hidden;
              z-index: 2;
              transition: left 250ms linear;

              &::after {
                content: "";
                display: inline-block;
                width: 1.8px;
                height: 35px;
                background-color: var(--color-white);
                border-radius: 8px;
                flex-shrink: 0;
                // cursor: ew-resize;
              }
            }

            .grabber.start {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;

              // left: 0;
            }

            .grabber.end {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              // right: 0;
            }
          }
        }
      }

      .wrapper {

        // playback
        .playback {
          position: relative;
          margin-top: 1rem;
          margin-left: 24px;
          margin-right: 24px;
          height: 10px;
          background: #2f3b44;
          margin-bottom: 1rem;
          border-radius: 1em;
        }

        .playback .seekable {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgb(240, 240, 240);
          cursor: pointer;
        }

        .playback .grabber {
          position: absolute;
          top: -4px;
          bottom: -4px;
          width: 18px;
          border-radius: 2px;
          z-index: 1;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
          cursor: pointer;
          transition: transform 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .playback .grabber.start {
          background: #a8d736;
        }

        .playback .grabber.end {
          background: #fc4242;
        }

        .playback .grabber:hover {
          transform: scaleY(1.4);
        }

        .playback .grabber svg {
          /* user-drag: none;
          -moz-user-select: none;
          -webkit-user-drag: none; */
        }

        .playback .progress {
          background: #0072cf;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          cursor: pointer;
          pointer-events: none;
          transition: width 250ms linear;
        }

        // controls 
        .controls {
          display: flex;
          justify-content: space-between;
          text-align: center;
          margin-inline: 1rem;
          margin-block-end: 0.875rem;
        }

        .controls .player-controls button {
          width: 34px;
          margin: 0 0.125rem;
        }

        .controls .player-controls .play-control {
          background: #2f3b44;
          border: 0;
          color: #a5b0b5;
          padding: 0.5rem;
          border-radius: 4px;
          cursor: pointer;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }

        .controls .player-controls .play-control:hover {
          background: #445562;
        }

        .controls .player-controls .play-control:active {
          color: #ffffff;
        }

        .controls .player-controls .seek-start {
          background: #2f3b44;
          border: 0;
          color: #a5b0b5;
          padding: 0.5rem;
          border-radius: 4px;
          cursor: pointer;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }

        .controls .player-controls .seek-start:hover {
          background: #445562;
        }

        .controls .player-controls .seek-start:active {
          color: #ffffff;
        }

        .controls .player-controls .seek-end {
          background: #2f3b44;
          border: 0;
          color: #a5b0b5;
          padding: 0.5rem;
          border-radius: 4px;
          cursor: pointer;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }

        .controls .player-controls .seek-end:hover {
          background: #445562;
        }

        .controls .player-controls .seek-end:active {
          color: #ffffff;
        }

        .controls .settings-control {
          background: #2f3b44;
          border: 0;
          color: #a5b0b5;
          padding: 0.5rem;
          border-radius: 4px;
          cursor: pointer;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }

        .controls .settings-control:active {
          color: #ffffff;
        }

        .controls .settings-control:hover {
          background: #445562;
        }

        .controls .trim-control {
          background: #0072cf;
          border: 0;
          color: #fff;
          padding: 0.5rem;
          border-radius: 4px;
          cursor: pointer;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.1em;
        }

        .controls .trim-control:hover {
          background: #038eff;
        }
      }

      .trim-video {
        padding: 11px 36px 1px 20px;
        width: 302px;
      }
    }

    .trimTiming {
      margin-block-start: 3px;
      margin-inline: 1.25rem;
      display: flex;
      gap: 3px;

      font-size: 1rem;
      color: var(--color-quaternary-l);
    }

    .slider-container {
      width: 300px;
      /* Fixed width for the slider */
      height: 50px;
      /* Adjust as needed */
      position: relative;
      margin: 20px 0;
      left: 33px;

      .slider-bar {
        width: 100%;
        height: 10px;
        /* Adjust as needed */
        background-color: #ccc;
        position: absolute;
        top: 27px;
        /* Adjust to center the bar */
      }

      .slider-button {
        width: 20px;
        /* Adjust as needed */
        height: 20px;
        /* Adjust as needed */
        background-color: #4CAF50;
        /* Adjust color as needed */
        position: absolute;
        top: -5px;
        /* Adjust to center the button */
        border-radius: 50%;
        cursor: pointer;
      }

      .slider-button.start {
        background-color: #C4FF61;
        /* Adjust color for start button */
      }

      .slider-button.end {
        background-color: #FF6161;
        /* Adjust color for end button */
      }

      .time-info {
        margin-top: 20px;
        /* Adjust to position below the slider */
        display: flex;
        justify-content: space-between;
      }

    }


  }
}