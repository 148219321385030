.useHere {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .dialog {
    width: 450px;
    opacity: 0;
    scale: 0;
    background-color: var(--color-secondary);
    padding: 1.125rem 1.35rem;
    border-radius: 0.5rem;
    animation: delay-dialog 0.3s ease-out forwards;

    .description {
      font-size: 0.875rem;
      color: var(--color-quaternary-l);
      flex: 1;
      margin-block-end: 2rem;
    }

    .useHere-cta {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 20px;

      .btn-cancel,
      .btn-useHere {
        background-color: transparent;
        padding: 0.5rem 1.125rem;
        font-size: 0.875rem;
        color: var(--color-primary-d);
        border: 1px solid var(--color-primary-d);
        border-radius: 5px;
        cursor: pointer;
      }

      .active {
        background-color: var(--color-primary-d);
        color: var(--color-white);
      }
    }
  }
}

@keyframes delay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes delay-dialog {
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
