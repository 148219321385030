.groups {
  width: 100%;
  // height: 100%;
  min-height: 100vh;
  //   position: relative;

  // position: fixed;
  // top: 0;
  // left: 0;

  background-color: hsl(0, 0%, 16%, 0.6);
  display: grid;
  place-items: center;

  //   padding-block-start: 1rem;

  position: relative;

  .groups-container {
    width: 400px;
    // height: 100%;
    // max-height: 100vh;
    margin-block: 1rem;
    background-color: var(--color-secondary);

    ::-webkit-scrollbar {
      width: 6px !important;
      height: 6px !important;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 5000px;
    }

    display: flex;
    flex-direction: column;

    // position: absolute;
    // top: 0;
    // left: 50%;
    // transform: translateX(-50%);
    .group-to {
      background-color: var(--color-primary-d);

      .group-to-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0.625rem;

        .title {
          font-size: 1.25rem;
          font-weight: 500;
          color: var(--color-secondary);
        }

        .close {
          width: 20px;
          height: 20px;
        }
      }
    }

    .group-search {
      height: 33px;
      padding-inline: 0.9375rem;
      margin-block: 0.9375rem;
      .search-bar {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;

        input {
          color: var(--color-quaternary);
          flex: 1;
          height: 100%;

          appearance: none;
          border: none;
          outline: none;
          background-color: rgba(118, 118, 128, 0.12);
          padding: 10px;
          padding-inline-start: 30px;
          // margin: 5px 10px;
          border-radius: 0.5rem;
          font-size: 0.875rem;

          &::placeholder {
            color: #3c3c4399;
          }
        }

        .search,
        .back {
          position: absolute;
          width: 12px;
          height: 12px;
          left: 10px;

          cursor: pointer;

          transition: all 0.3s;
        }

        .search.rotate,
        .back.rotate {
          opacity: 0;
          transform: rotate(135deg);
        }
      }
    }

    .group-details {
      overflow-y: auto;
      // height: 100%;
      max-height: 100vh;
      flex: 1;

      .group {
        display: flex;
        padding-inline-start: 15px;

        .group-image {
          width: 43px;
          height: 43px;
          background-color: var(--color-secondary);
          border-radius: 50%;
          padding: 0.1rem;
          //   border: 1.5px solid var(--color-primary-d);
          margin-inline-end: 0.75rem;
        }

        .group-info {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-block-end: 10px;
          padding-inline-end: 15px;
          margin-block-end: 10px;
          // border-block-end: 0.5px solid var(--color-arrow);
          border-block-end: 0.5px solid #cacaca;

          // flex-direction: column;

          .group-info-name {
            .group-name {
              font-size: 0.9375rem;
              font-weight: 500;
              color: var(--color-quaternary-l);
            }

            .group-info-message {
              color: var(--color-description);
              font-size: 0.7rem;
              font-weight: 400;
              // word-wrap: break-word;
              overflow-wrap: break-word;
            }
          }

          .group-check {
            display: revert;
            margin-inline-start: auto;

            input[type="checkbox"] {
              // appearance: none;
              // background-color: var(--color-primary-d);

              font: inherit;
              width: 1.15rem;
              height: 1.15rem;
              border: 1px solid var(--color-primary-d);
              // border-radius: 50%;
              transform: translateY(-0.075rem);

              display: grid;
              place-content: center;

              // &::before {
              //   content: "";
              //   width: 0.75rem;
              //   height: 0.75rem;
              //   border-radius: 50%;
              //   transform: scale(0);
              //   transition: 120ms transform ease-in-out;
              //   box-shadow: inset 1rem 1rem var(--color-primary-d);
              // }

              // &:checked::before {
              //   transform: scale(1);
              // }

              &:checked {
                accent-color: var(--color-primary-d);
              }
            }
          }
        }
      }
    }

    .group-message,
    .group-message-media {
      background-color: inherit;
      padding-inline: 0.9375rem;
      padding-block: 0.3rem;

      display: flex;
      align-items: center;

      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);

      .group-message-names {
        color: var(--color-description);
        flex: 1;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        padding-inline-end: 1.5rem;
        .name {
          display: inline-block;
          color: inherit;
          font-size: 0.9375rem;
        }
      }
      .send-message {
        width: 45px;
        height: 45px;

        cursor: pointer;
      }
    }

    .group-message-media {
      min-height: 80px;

      .wrapper {
        display: flex;
        gap: 0.5rem;
        justify-content: flex-start;

        background-color: var(--color-secondary);

        .media {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          border: 1px solid var(--color-primary-d);
          overflow: hidden;

          position: relative;

          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .media-type {
            position: absolute;
            inset: 0;

            background-color: hsl(0, 0, 0, 0.2);

            display: grid;
            place-items: center;

            img {
              width: 1rem;
              height: 1rem;
            }
          }
        }

        .input-wrapper {
          align-self: stretch;
          flex: 1;

          border-radius: 5px;
          border: 1px solid var(--color-footer-border);

          .messageInput {
            // resize: none;
            height: 100%;
            width: 100%;
            color: var(--color-quaternary);
            // color: #b3b3b3;
            border: none;
            outline: none;
            // width: 100%;
            // flex: 1;
            padding-inline: 0.5rem;
            max-height: 4rem;
            overflow: hidden auto;
            white-space: pre-wrap;
            word-break: break-word;

            // overflow-wrap: break-word;
            // white-space: pre-wrap;

            // &::placeholder {
            //   color: #b3b3b3;
            // }

            // &::-webkit-scrollbar {
            //   display: none;
            // }

            /* Hide the Placeholder */
            &:empty::before {
              content: attr(placeHolder);
              color: #b3b3b3;
            }

            &:empty:focus ::before {
              content: "";
            }
          }
        }
      }
    }
  }
}
