.newChatContact {
  display: flex;
  flex-direction: column;

  background-color: var(--color-secondary);

  position: absolute;
  inset: 0;
  z-index: 10;

  animation: slideInLeft 500ms cubic-bezier(1, 0, 0, 1) forwards;

  &.open {
    animation: slideInLeft 500ms cubic-bezier(1, 0, 0, 1) forwards;
  }
  
  &.close {
    animation: slideOutLeft 500ms cubic-bezier(1, 0, 0, 1) forwards;
  }
  

  .header {
    background-color: var(--color-primary-d);
    padding: 0.875rem 0.9375rem;
    padding-block-end: 12px;

    .header-container {
      display: flex;
      flex-direction: column;
      gap: 1.4rem;

      .logo-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .logo {
          width: 1.45rem;
          height: 1.45rem;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          font-size: 1.25rem;
          font-weight: 600;
          color: var(--color-offwhite);
          letter-spacing: 0.7px;
        }
      }

      .back-chat {
        display: flex;
        gap: 0.875rem;
        align-items: center;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .sub-title {
          font-size: 1.05rem;
          color: var(--color-offwhite);
        }
      }
    }
  }

  .search-container {
    padding: 0.9375rem;
    border-block-end: 0.5px solid var(--color-border);
    background-color: var(--color-secondary);

    .search-bar {
      display: flex;
      align-items: center;
      padding-inline: 6px;
      border-radius: 0.5rem;
      background: rgba(118, 118, 128, 0.12);
      height: 30px;
      position: relative;

      input {
        color: var(--color-quaternary-l);
        flex: 1;
        appearance: none;
        border: none;
        outline: none;
        background: transparent;
        padding: 10px;
        font-size: 0.875rem;

        &::placeholder {
          // color: #3c3c4499;
          font-size: 0.8rem;
          color: var(--color-placeholder);
        }
      }

      .search_white {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .cta-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 0.9375rem 1rem;
    border-block-end: 0.5px solid var(--color-border);

    .cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.25rem;
      cursor: pointer;

      .group-image {
        width: 2rem;
        height: 2rem;

        @media screen and (max-width: 1025px) {
          width: 1.875rem;
          height: 1.875rem;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .text {
        color: var(--color-quaternary-l);
        font-size: 0.75rem;

        @media screen and (max-width: 1025px) {
          font-size: 0.625rem;
        }
      }
    }
  }

  .profiles {
    height: 100%;
    overflow-y: auto;

    .search-text {
      display: inline-block;
      color: var(--color-description);
      font-size: 0.75rem;
      width: 100%;
      padding: 0.875rem 0.9375rem;
      padding-block-end: 0;
    }

    .profile {
      display: flex;
      padding-inline-start: 0.9375rem;
      align-items: center;
      height: 4.375rem;
      cursor: pointer;
      transition: 0.2s ease-out;

      &:hover {
        background: var(--color-selected-bg);
      }

      .profile-image {
        width: 3.125rem;
        height: 3.125rem;
        background-color: var(--color-primary-d);
        border-radius: 50%;
        margin-inline-end: 0.75rem;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .profile-info {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline-end: 0.9375rem;
        border-block-end: 0.5px solid var(--color-border);
        height: 100%;

        &:first-child {
          border-block-start: 0.5px solid var(--color-arrow);
        }

        .profile-info-name {
          p {
            font-size: 0.9375rem;
            color: var(--color-quaternary-l);
            font-weight: 500;
          }
        }

        .status {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          img {
            width: 14px;
          }

          .profile-info-message {
            color: var(--color-description);
            font-size: 0.7rem;
            font-weight: 400;

            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    .contact-not-found {
      width: 100%;
      // height: 80%;
      height: calc(100% - 35px);
      text-align: center;

      display: grid;
      place-items: center;

      span {
        color: var(--color-description);
        font-size: 0.75rem;
      }
    }
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes slideInBack {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

