.storyPrivacy {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 12;

  animation: slideInLeft 250ms ease forwards;

  &.open {
    animation: slideInLeft 250ms ease forwards;
  }

  &.close {
    animation: slideOutLeft 250ms ease forwards;
  }

  .storyPrivacy-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;

      min-height: 54px;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          padding: 4px;
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }
    }

    .story-visible {
      padding-block-start: 0.9375rem;

      .title {
        color: var(--color-quaternary-l);
        font-size: 1rem;
        font-weight: 500;
        margin-block-end: 8px;
        padding-inline: 0.9375rem;
      }

      .visible-to-contacts {
        .contact-select {
          padding: 0.9375rem;
          padding-block-end: 0.75rem;
          display: flex;
          align-items: center;
          gap: 0.9375rem;

          .group-check {
            margin-block-start: -2px;
            input[type="radio"] {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;
            }

            .checkmark {
              display: inline-flex;
              align-items: center;
              justify-content: center;

              position: relative;

              min-width: 20px;
              width: 20px;
              height: 20px;
              vertical-align: middle;

              &:focus-visible {
                outline: 0;
                border-radius: 2px;
                box-shadow: 0 0 0 2px var(--color-focused);
              }

              .radio-cmn {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;
                border-radius: 50%;

                line-height: 0;
                white-space: nowrap;

                cursor: pointer;
              }

              .offRadio {
                border: 1.5px solid var(--color-description);
                background-color: transparent;

                transition: border 250ms ease;
              }

              .onRadio {
                will-change: transform;
                background-color: var(--color-primary-d);

                scale: 0;
                transition: scale 250ms ease;
              }
            }

            input[type="radio"]:checked + .checkmark .onRadio,
            input[type="radio"]:focus + .checkmark .onRadio {
              scale: 0.6;
            }
            input[type="radio"]:checked + .checkmark .offRadio,
            input[type="radio"]:focus + .checkmark .offRadio {
              border-color: var(--color-primary-d);
            }
          }

          .contact-select-info {
            flex: 1;

            display: flex;
            align-items: center;
            gap: 10px;

            .wrapper {
              flex: 1;

              cursor: pointer;

              display: flex;
              flex-direction: column;

              .title {
                font-size: 1rem;
                font-weight: 500;
                margin-block-end: 0;

                padding-inline: 0;

                // @media screen and (max-width: 1025px) {
                //   font-size: 0.875rem;
                // }
              }

              .sub-text {
                color: var(--color-description);
                font-size: 0.875rem;

                // @media screen and (max-width: 1025px) {
                //   font-size: 0.75rem;
                // }
              }
            }

            .excluded {
              display: flex;
              align-items: center;
              gap: 7px;
              cursor: pointer;

              .excluded-info {
                color: var(--color-primary-d);
                font-size: 0.8rem;

                // @media screen and (max-width: 1025px) {
                //   font-size: 0.7rem;
                // }
              }

              .btn-more {
                width: 0.625rem;
                height: 1rem;

                cursor: pointer;

                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }

        .contact-select:not(:last-child) {
          border-block-end: 0.25px solid var(--color-border);
        }
      }

      .storyPrivacy-info {
        background-color: #f7f7f7;
        border-radius: 6px;
        padding: 0.75rem 0.625rem;
        margin-block-start: 0px;
        margin-inline: 0.9375rem;

        .info {
          color: var(--color-description);
          font-size: 0.875rem;
          line-height: 1.2;
        }
      }
    }
  }
}
