.commonGroups {
  background-color: hsl(0, 0%, 0%, 0.5);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .commonGroups-container {
    width: 325px;
    height: auto;
    min-height: 250px;
    max-height: 420px;

    background-color: var(--color-popup);
    border-radius: 0.75rem;
    overflow: hidden;

    animation: delay-dialog 0.3s ease-out forwards;

    display: flex;
    flex-direction: column;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.625rem 0.9375rem;

      background-color: var(--color-primary-d);
      padding-block-end: 0.625rem;
      border-block-end: 1px solid var(--color-border);

      .title {
        display: inline-block;
        color: var(--color-offwhite);
        line-height: 1.25;
        font-size: 1rem;
        font-weight: 400;
      }

      .title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .sub-title {
          color: var(--color-offwhite);
          font-size: 0.8rem;
          line-height: 1.35;
          padding-inline: 1.25rem 1.5rem;
        }
      }

      .btn-close {
        padding: 4px;
      }
    }

    .search-bar {
      margin: 0.625rem 0.85rem;

      .search-bar-wrapper {
        border-radius: 0.5rem;
        background-color: rgba(118, 118, 128, 0.12);
        height: 30px;
        padding-inline: 6px;

        display: flex;
        align-items: center;
        gap: 10px;
        input {
          color: var(--color-quaternary-l);
          flex: 1;
          appearance: none;
          border: none;
          outline: none;
          background: transparent;
          font-size: 0.875rem;
          height: 100%;

          @media screen and (max-width: 1025px) {
            font-size: 0.8rem;
          }

          &::placeholder {
            color: var(--color-placeholder);
          }
        }
      }
    }

    .no-data {
      margin: auto;
    }

    .all-groups {
      height: 100%;
      overflow-y: auto;

      // padding-block: 0 0.9375rem;

      .user-groups,
      .other-groups {
        .text {
          display: inline-block;
          color: var(--color-description);
          font-size: 0.75rem;
          padding-inline-start: 0.9375rem;
          margin-block: 13px;
        }

        .user-groups-list {
          // border-block: 0.5px solid var(--color-border);

          display: flex;
          flex-direction: column;

          &:last-child .user-groups-listItem {
            border-block-end: 0;
          }

          .user-groups-listItem {
            display: flex;
            padding-inline-start: 0.9375rem;
            align-items: center;

            height: 3.75rem;

            cursor: pointer;
            transition: bachground-color 250ms ease-out;

            border-block-end: 0.5px solid var(--color-border);

            &:hover,
            &:focus-visible {
              background-color: var(--color-selected-bg);
            }

            &:focus-visible {
              outline: 0;
            }

            .profile-image {
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 50%;
              margin-inline-end: 0.75rem;
              overflow: hidden;

              position: relative;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .profile-info {
              display: flex;
              align-items: center;
              justify-content: space-between;

              flex: 1 1;

              height: 100%;
              padding-inline-end: 0.9375rem;
              // border-block-end: 0.5px solid var(--color-border);

              .profile-info-name {
                display: flex;
                flex-direction: column;

                .name {
                  font-size: 0.9375rem;
                  color: var(--color-quaternary-l);
                  font-weight: 400;

                  overflow: hidden;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }

                .profile-info-message {
                  color: var(--color-description);
                  font-size: 0.8rem;

                  overflow: hidden;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }
              }

              .profile-details {
                align-self: flex-start;
                margin-block-start: 2px;

                .profile-details-date {
                  font-size: 8.25px;
                  color: var(--color-description);
                }
              }
            }
          }
        }
      }
    }
    .user-groups {
      .text {
        margin-block-end: 3px;
      }
    }
    .no-group-waraper {
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      flex: 1;

      .text {
        color: var(--color-quaternary-l);
        font-size: 0.9375rem;
      }
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }

    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
