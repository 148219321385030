.captureMedia {
  width: 100%;
  height: 100vh;
  position: relative;

  .captureMedia-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-block-start: 1.5rem;
    overflow: hidden;

    // .uploadededMedia {
    //   width: 600px;
    //   max-width: fit-content;
    //   margin-inline: auto;
    //   margin: auto;
    // }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-inline: 1.5rem;
      margin-block-end: 1.5rem;

      .close-wrapper,
      .retake-wrapper {
        display: flex;
        gap: 1.5rem;
        align-items: center;

        .close,
        .retake {
          // width: 20px;
          // height: 20px;
          padding: 4px;

          // cursor: pointer;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }
        }

        .text {
          font-size: 0.9375rem;
          color: var(--color-quaternary-l);
        }
      }

      .retake-wrapper {
        gap: 0.625rem;
        cursor: pointer;
      }
    }

    .filters {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1;

      .filters-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;

        .filter {
          transition: all 0.3s;
          width: 70px;
          height: auto;
          border-radius: 5px;
          background-color: var(--color-popup);
          padding-block: 5px;
          box-shadow: 0px 0px 2.5px 0px rgba(0, 0, 0, 0.25);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:active,
          &:focus-visible {
            background-color: var(--color-selected-bg);
          }

          &:focus-visible {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px var(--color-focused);
          }

          svg {
            width: 1rem;
            height: 1rem;
            margin-block-end: 1px;
          }

          .text {
            color: var(--color-quaternary-l);
            font-size: 0.65rem;
          }
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      height: 100%;

      .photo-container {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          height: 300px;

          @media screen and (min-width: 1440px) {
            height: revert;
          }
        }
      }
    }

    .footer-box {
      display: flex;
      gap: 0.9375rem;
      align-items: center;
      width: min(650px, 100%);
      margin-block-end: 1.25rem;
      margin-inline: auto;
      max-width: 70%;

      .message-wrapper {
        flex: 1;
        border-radius: 5000px;
        border: 1px solid var(--color-footer-border);
        font-size: 0.9375rem;
        padding: 10px;
        background-color: var(--color-secondary);
        display: flex;
        justify-content: space-between;
        min-width: 0;

        .input-wrapper {
          flex: 1;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          min-width: 0;
          max-height: 5rem;
          position: relative;
        }

        .messageInput {
          width: 100%;
          color: var(--color-quaternary-l);
          border: none;
          outline: none;
          padding-inline: 0.5rem;
          max-height: 5rem;
          overflow: hidden auto;
          white-space: pre-wrap;
          word-break: break-word;

          /* Hide the Placeholder */
          &:empty::before {
            content: attr(placeHolder);
            color: #b3b3b3;
            cursor: pointer;
          }

          &:empty:focus ::before {
            content: "";
          }
        }

        .placeHolder {
          position: absolute;
          inset: 0;
          display: inline-block;
          color: #b3b3b3;
        }
      }

      .send-Photomessage {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        padding: 4px;
        cursor: pointer;
        position: relative;

        &:focus-visible {
          outline: 0;
          box-shadow: 0 0 0 2px var(--color-focused);
        }

        .number {
          position: absolute;
          right: -3px;
          top: -3px;
          width: 1.25rem;
          height: 1.25rem;
          border-radius: 50%;
          background-color: var(--color-white);
          font-size: 0.7rem;
          color: var(--color-quaternary);
          display: grid;
          place-items: center;

          cursor: default;
          box-shadow: 0 1px 3px rgba(11, 20, 26, 0.4);
        }
      }
    }
  }
}
