.backgroundSyncMessage {
  background-color: var(--color-networkError-bg);
  padding: 0.9375rem;

  .backgroundSyncMessage-wrapper {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    .backgroundSyncMessage-image {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: var(--color-primary-d);
      overflow: hidden;

      flex-shrink: 0;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .backgroundSyncMessage-info {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      .backgroundSyncMessage-info-wrapper {
        display: flex;
        flex-direction: column;

        .backgroundSyncMessage-title {
          margin-block-end: 2px;
          .title {
            font-size: 0.875rem;
            color: var(--color-quaternary-l);
            line-height: 1.25;
          }
        }

        .backgroundSyncMessage-text {
          //   margin-block-end: 1px;

          .info {
            font-size: 0.75rem;
            color: var(--color-description);
            line-height: 1.35;
          }
        }
      }
    }
  }
}
