@keyframes delay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes delay-dialog {
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes delayOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes delay-dialogOut {
  0% {
    opacity: 1;
    scale: 1;
  }
  100% {
    opacity: 0;
    scale: 0;
  }
}

@mixin delay {
  animation: delay 0.3s ease-out forwards;
}

@mixin delay-dialog {
  animation: delay 0.3s ease-out forwards;
}

@mixin delayOut {
  animation: delay 0.3s ease-out forwards;
}

@mixin delay-dialogOut {
  animation: delay 0.3s ease-out forwards;
}

.delay {
  @include delay();
}

.delay-dialog {
  @include delay-dialog();
}

.delayOut {
  @include delayOut();
}

.delay-dialogOut {
  @include delay-dialogOut();
}
