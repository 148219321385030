.tags {
  width: 100%;
  height: 100%;

  .tags-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;
      min-height: 54px;

      display: flex;
      justify-content: space-between;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }

      .done {
        cursor: pointer;
        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          line-height: 1.35;
        }
      }
    }

    .search-container {
      padding: 0.9375rem;
      padding-block-start: 1.35rem;
      background-color: var(--color-secondary);

      .search-bar {
        display: flex;
        align-items: center;
        padding-inline: 6px;
        border-radius: 0.5rem;
        background: rgba(118, 118, 128, 0.12);
        // height: 30px;
        position: relative;

        input {
          color: var(--color-quaternary-l);
          flex: 1;
          appearance: none;
          border: none;
          outline: none;
          background: transparent;
          padding: 8px;
          // padding-block: 0.625rem;
          font-size: 0.875rem;

          &::placeholder {
            color: var(--color-placeholder);
            font-size: 0.875rem;
          }
        }

        .search_white {
          width: 14px;
          height: 14px;
          cursor: pointer;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .current {
      display: flex;
      flex-direction: column;
      gap: 5px;

      padding-inline: 0.9375rem;

      margin-block: 0.625rem;

      .current-tag-info {
        font-size: 0.8rem;
        color: var(--color-description);
      }

      .current-tag-name {
        width: 100%;
        padding: 0.5rem 0.625rem;
        border: 1px solid var(--color-border);
        border-radius: 5px;
        background-color: var(--color-secondary);

        .name {
          color: var(--color-quaternary-l);
          font-size: 0.9375rem;
        }
      }
    }

    .select-about-list {
      margin-block: 0.35rem 0.625rem;
      height: 100%;

      overflow-y: auto;

      display: flex;
      flex-direction: column;

      .about-list-info {
        display: inline-block;
        font-size: 0.8rem;
        color: var(--color-description);
        letter-spacing: 0.2px;
        padding-inline: 0.9375rem;

        margin-block-end: 13px;
      }

      .select-about-list-wrapper {
        display: flex;
        flex-direction: column;

        overflow-y: auto;

        .select-about-listItem {
          cursor: pointer;

          font-size: 1rem;
          color: var(--color-quaternary-l);

          &:hover,
          &:focus-visible {
            background-color: var(--color-selected-bg);
          }
          &:focus-visible {
            outline: none;
          }

          &-wrapper {
            padding-inline-end: 0.9375rem;
            border-block-start: 0.5px solid var(--color-border);
            display: flex;
            padding-block: 0.8rem;
            justify-content: space-between;
            align-items: center;
            margin-inline-start: 0.9375rem;
          }
        }

        .select-about-listItem.active {
          .select-about-list-value {
            color: var(--color-primary-d);
          }
        }
      }
    }

    .group-check {
      margin-block-start: -2px;
      input[type="radio"] {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
      }

      .checkmark {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        position: relative;

        min-width: 18px;
        width: 18px;
        height: 18px;
        vertical-align: middle;

        &:focus-visible {
          outline: 0;
          border-radius: 2px;
          box-shadow: 0 0 0 2px var(--color-focused);
        }

        .radio-cmn {
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;
          border-radius: 50%;

          line-height: 0;
          white-space: nowrap;

          cursor: pointer;
        }

        .offRadio {
          border: 1.5px solid var(--color-description);
          background-color: transparent;

          transition: border 250ms ease;
        }

        .onRadio {
          will-change: transform;
          background-color: var(--color-primary-d);

          scale: 0;
          transition: scale 250ms ease;
        }
      }

      input[type="radio"]:checked + .checkmark .onRadio,
      input[type="radio"]:focus + .checkmark .onRadio {
        scale: 0.6;
      }
      input[type="radio"]:checked + .checkmark .offRadio,
      input[type="radio"]:focus + .checkmark .offRadio {
        border-color: var(--color-primary-d);
      }
    }
  }

  .no-data {
    margin: auto;
  }
}
