.suggestedCircles {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: fadeIn 250ms ease forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  &.closing {
    animation: fadeOut 250ms ease forwards;
  }

  .suggestedCircles-container {
    width: 400px;
    margin-block: 1rem;
    background-color: var(--color-secondary);

    display: flex;
    flex-direction: column;
    height: 90vh;

    animation: scaleIn 250ms ease;

    &.closing {
      animation: scaleOut 250ms ease forwards;
    }

    @media screen and (max-width: 1025px) and (max-height: 1380px) {
      max-height: 30rem;
    }

    @media screen and (min-width: 1600px) {
      max-height: 40rem;
    }

    .suggested-to {
      background-color: var(--color-primary-d);

      .suggested-to-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.625rem;

        .title {
          font-size: 1.25rem;
          font-weight: 500;
          color: var(--color-offwhite);
        }

        .btn-close {
          padding: 4px;
        }
      }
    }

    .search-bar {
      margin: 0.625rem 0.85rem;

      .search-bar-wrapper {
        // padding-inline: 6px;
        border-radius: 0.5rem;
        background-color: rgba(118, 118, 128, 0.12);
        height: 30px;
        // padding: 10px 6px;
        padding-inline: 6px;

        display: flex;
        align-items: center;
        gap: 10px;
        input {
          // color: rgba(60, 60, 67, 0.6);
          color: var(--color-quaternary-l);
          flex: 1;
          appearance: none;
          border: none;
          outline: none;
          background: transparent;
          font-size: 0.875rem;
          height: 100%;

          @media screen and (max-width: 1025px) {
            font-size: 0.8rem;
          }

          &::placeholder {
            color: var(--color-placeholder);
          }
        }
      }
    }

    .suggested-contacts {
      overflow-y: auto;
      height: 100%;
      max-height: 100vh;
      flex: 1;

      .circle {
        display: flex;
        padding-inline-start: 0.9375rem;
        align-items: center;
        height: 4.375rem;

        cursor: pointer;
        transition: 0.2s ease-out;

        &:hover,
        &:focus-visible {
          background-color: var(--color-selected-bg);
        }

        &:focus-visible {
          outline: 0;
        }

        .circle-image {
          width: 3.125rem;
          height: 3.125rem;
          border-radius: 50%;
          margin-inline-end: 0.75rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .circle-info {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.625rem;

          padding-inline-end: 0.9375rem;
          border-block-end: 0.5px solid var(--color-border);

          height: 100%;

          &:first-child {
            border-block-start: 0.5px solid var(--color-arrow);
          }
          .circle-info-name {
            .circle-name {
              display: inline-block;
              font-size: 1rem;
              font-weight: 500;
              color: var(--color-quaternary-l);

              overflow: hidden;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;

              @media screen and (max-width: 1025px) {
                font-size: 1.1rem;
              }
            }

            .circle-info-message {
              color: var(--color-description);
              font-size: 0.8rem;
              font-weight: 400;
              overflow: hidden;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }

          .btn-circleJoin {
            padding: 0.35rem 1.25rem;
            border-radius: 3px;
            background-color: var(--color-primary-d);
            color: var(--color-offwhite);
            font-size: 0.8rem;
            letter-spacing: 0.3px;
          }
        }
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes scaleOut {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
}
