.content-profile {
  padding: 5px 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--color-popup);

  height: 3.75rem;

  .content-profile-details {
    display: flex;
    height: 100%;

    .content-profile-detail {
      display: flex;
      align-items: center;
      gap: 0.875rem;
      cursor: pointer;
      flex: 1;

      .content-profile-image {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .content-profile-info {
        width: 100%;

        .info-name {
          font-size: 0.9375rem;
          color: var(--color-quaternary-l);
        }

        .info-status {
          font-size: 0.7rem;
          color: var(--color-description);
        }
      }
    }

    .content-profile-search {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      position: relative;

      .btn-menuIcon,
      .btn-searchIcon {
        width: 36px;
        height: 36px;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;

        padding: 8px;
        border-radius: 50%;

        &:hover,
        &:focus-visible {
          background-color: var(--color-selected-bg);
        }

        svg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
