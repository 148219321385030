.videoSend,
.videoRecieved {
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;

  .wrapper {
    width: initial;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: inset 0px -31.3527px 28.5024px rgba(0, 0, 0, 0.5);
    position: relative;
    cursor: pointer;

    .send-video {
      width: auto;
      height: fit-content;
      max-width: 355px;
      max-height: 350px;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .message-status {
      position: absolute;
      bottom: 2px;
      right: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 0.625rem;
      color: var(--color-offwhite);
      z-index: 100;

      .important {
        width: 10px;
      }

      .video-status {
        width: 14px;
      }
    }

    .duration {
      position: absolute;
      bottom: 2px;
      left: 5px;
      font-size: 0.625rem;
      color: var(--color-secondary-m-d);
      z-index: 100;
    }

    .play {
      width: 45px;
      height: 45px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .loader {
      position: absolute;
      inset: 0;
      z-index: 1000;
      display: grid;
      place-content: center;

      img {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }

      .size {
        text-align: center;
      }
    }
  }

  .shadow-background {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.75rem;
    z-index: 10;
    background-image: linear-gradient(
      to top,
      rgb(11, 20, 26, 0.3),
      rgb(10, 20, 26, 0)
    );
  }

  .fullscreen,
  .down-arrow {
    position: absolute;
    top: 0.5rem;
    width: 20px;
    right: 0.5rem;
    height: 20px;
    display: none;
    animation: slideIn 0.3s ease-in;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover .down-arrow {
    display: block;
  }

  &:hover .fullscreen {
    display: block;
  }

  .fullscreen {
    left: 1rem;
    animation: none;
  }

  .down-arrow {
    top: 0.75rem;
    right: 0.5rem;
    width: 20px;
    height: 12px;
  }
}

.videoPlayer {
  border-radius: 1rem;
  background-color: var(--color-tertiary-l);
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 1rem;
  top: 3rem;
  z-index: 10;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    padding: 8px;
    margin-inline-start: auto;
    height: fit-content;

    .dragable {
      width: 50px;
      transform: translateX(-50%);
      cursor: move;
    }

    .close {
      width: 13px;
      height: 13px;
      padding: 0;
    }

    .close,
    .dragable,
    .play,
    .volume,
    .fullscreen {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  video {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
    flex-grow: 1;
    overflow: hidden;
  }

  .videoPlayer-cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    padding: 10px;

    .play,
    .volume,
    .fullscreen {
      width: 15px;
      height: 15px;
      padding: 0;
    }

    .volume {
      position: relative;

      &:hover .slider.vol {
        opacity: 1;
      }
    }

    /********** Range Input Styles **********/
    .slider,
    .slider.vol {
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      cursor: pointer;
      width: 100%;
      vertical-align: middle;

      &:focus {
        outline: none;
      }

      /***** Chrome, Safari, Opera and Edge Chromium styles *****/
      &::-webkit-slider-runnable-track {
        background-color: var(--color-secondary);
        border-radius: 0.5rem;
        height: 3px;
      }

      /* slider thumb */
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        margin-top: -5px;
        margin-left: -3px;

        /*custom styles*/
        background-color: var(--color-primary);
        width: 0.875rem;
        height: 0.875rem;
        border-radius: 50%;
      }

      /******** Firefox styles ********/
      &::-moz-range-track {
        background-color: var(--color-secondary);
        border-radius: 0.5rem;
        height: 3px;
      }

      /* slider thumb */
      &::-moz-range-thumb {
        border: none;
        border-radius: 0;

        background-color: var(--color-primary);
        width: 0.875rem;
        height: 0.875rem;
        border-radius: 50%;
      }
    }

    .slider.vol {
      position: absolute;
      top: -0.3rem;
      left: 50%;
      z-index: 1002;
      width: 70px !important;
      height: 15px;
      opacity: 0;
      transition: opacity 0.3s;
      transform: translateY(-50%) rotate(-90deg);
      transform-origin: left center;
    }
  }
}

.horizontal {
  width: 360px !important;
  height: 260px !important;
}

.vertical {
  width: 236px !important;
  height: 400px !important;
}

.videoSend {
  align-self: self-end;
}

.videoRecieved {
  align-self: self-start;
}

.pipOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: hsl(0, 0, 0, 0.3);
  display: grid;
  place-self: center;
  text-align: center;
  place-items: center;

  .pipOverlay-info {
    font-size: 0.875rem;
    color: var(--color-secondary);
    opacity: 0.3;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(20px);
  }
  20% {
    transform: translateY(0);
  }
}
