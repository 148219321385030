.helpInfo {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 11;

  &.open {
    animation: slideInFromRight 250ms ease-out forwards;
  }

  &.close {
    animation: slideOutToRight 250ms ease-out forwards;
  }

  .helpInfo-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;
      min-height: 54px;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }
    }

    .helpInfo-info-wrapper {
      height: 100%;
      overflow-y: auto;

      padding-block-end: 1rem;

      .helpInfo-info {
        padding: 2rem 0.9375rem;

        text-align: center;

        .helpInfo-info-image {
          width: 220px;
          height: 220px;
          overflow: hidden;

          margin-inline: auto;
          margin-block-end: 1.25rem;

          background-image: url("../image/SVG_Images/help.png");
          background-repeat: no-repeat;
          animation-name: help;
          background-size: 2400% auto;
          animation-timing-function: steps(23);
          animation-iteration-count: infinite;
          animation-duration: 1s;

          animation-play-state: running;

          // svg {
          //   width: 100%;
          //   height: 100%;
          //   object-fit: cover;
          // }
        }

        .helpInfo-info-image.paused {
          animation-play-state: paused;
        }

        .info {
          display: inline-block;
          font-size: 0.875rem;
          color: var(--color-quaternary-l);
          text-align: center;
        }
      }

      .helpInfo-cta-info {
        // border-block: 0.5px solid var(--color-border);

        .helpInfo-settings {
          display: flex;
          gap: 1.5rem;
          padding-inline-start: 0.9375rem;
          align-items: center;
          min-height: 4rem;
          transition: background 0.2s ease-out;
          cursor: pointer;

          border-block: 0.5px solid var(--color-border);

          &:hover,
          &:focus-visible {
            background: var(--color-selected-bg);
          }

          &:focus-visible {
            outline: 0;
          }

          .helpInfo-listItem-image {
            width: 30px;
            height: 30px;
            background-color: var(--color-primary-d);
            border-radius: 5px;
            overflow: hidden;
            display: grid;
            place-items: center;
            flex-shrink: 0;

            svg {
              width: 1.125rem;
            }
          }

          .helpInfo-listItem-info {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;

            padding-inline-end: 0.625rem;
            height: 100%;

            &:last-child {
              border-block-end: 0;
            }

            .helpInfo-listItem-name {
              color: var(--color-quaternary-l);
              font-size: 1rem;
            }
          }

          .details {
            flex: 1;

            display: flex;
            flex-direction: column;

            .title {
              font-size: 1rem;
              color: var(--color-quaternary-l);
            }

            .sub-title {
              color: var(--color-description);
              font-size: 0.75rem;
            }
          }

          .group-check {
            input[type="checkbox"] {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;
            }

            .checkmark {
              display: inline-flex;
              width: 1rem;
              height: 1rem;
              border: 1.2px solid var(--color-radio-border);
              border-radius: 50%;
              transition: border-color 0.3s, background-color 0.3s;

              position: relative;
              justify-content: center;
              align-items: center;

              &::after {
                content: "";
                width: 5px;
                height: 9px;
                border: solid transparent;
                border-width: 0 1.75px 1.75px 0;

                transform: rotate(45deg);
                margin-block-start: -2px;

                transition: border-color 0.3s;
              }
            }

            input[type="checkbox"]:checked + .checkmark {
              background-color: var(--color-primary-d);
              border: 1.2px solid var(--color-primary-d);
            }

            input[type="checkbox"]:focus + .checkmark {
              border-color: var(--color-primary-d);
            }

            // input[type="checkbox"]:not(:checked) + .checkmark {
            //   border-color: var(
            //     --color-border
            //   ); /* Border color when not checked */
            // }

            input[type="checkbox"]:checked:hover + .checkmark {
              display: block;
            }

            input[type="checkbox"]:checked + .checkmark::after {
              display: block;
              border-color: var(--color-white);
            }
          }
        }

        .helpCenter-listItem {
          .helpInfo-listItem-image {
            svg {
              height: 16px;
            }
          }
        }

        .joinBeta-listItem {
          .helpInfo-listItem-info {
            padding-block: 0.625rem;
          }
        }
      }
    }
  }
  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
  @keyframes help {
    100% {
      background-position-x: 100%;
    }
  }
}
