.themePopup {
  background-color: hsl(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: fadeIn 250ms ease forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  &.closing {
    animation: fadeOut 250ms ease forwards;
  }

  .dialog {
    width: 450px;

    background-color: var(--color-popup);
    padding: 1.125rem 1.35rem;
    border-radius: 0.5rem;
    animation: scaleIn 250ms ease;

    &.closing {
      animation: scaleOut 250ms ease forwards;
    }

    .dialog-info {
      display: flex;
      gap: 1rem;
      margin-block-end: 1.625rem;

      h4 {
        font-size: 1.35rem;
        font-weight: 400;
        color: var(--color-quaternary-l);
      }
    }

    .dialog-info-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-block-end: 2rem;

      .light-wrapper,
      .dark-wrapper,
      .systemDefault-wrapper {
        display: flex;
        align-items: center;
        gap: 0.875rem;

        .group-check {
          margin-block-start: -2px;
          input[type="radio"] {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }

          .checkmark {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            position: relative;

            min-width: 20px;
            width: 20px;
            height: 20px;
            vertical-align: middle;

            cursor: pointer;

            &:focus-visible {
              outline: 0;
              border-radius: 2px;
              box-shadow: 0 0 0 2px var(--color-focused);
            }

            .radio-cmn {
              position: absolute;
              top: 0;
              left: 0;

              width: 100%;
              height: 100%;
              border-radius: 50%;

              line-height: 0;
              white-space: nowrap;
            }

            .offRadio {
              border: 2px solid #999;
              background-color: transparent;

              transition: border 250ms ease;
            }

            .onRadio {
              will-change: transform;
              background-color: #1891a2;

              scale: 0;
              transition: scale 250ms ease;
            }

            .offRadio.active {
              border-color: #1891a2;
            }

            .onRadio.active {
              scale: 0.6;
            }
          }
        }

        .description {
          font-size: 1rem;
          color: var(--color-quaternary-l);

          cursor: pointer;
        }
      }
    }

    .cta {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 20px;

      .cancel,
      .for-me,
      .for-everyone {
        background-color: transparent;
        padding: 0.4rem 1.125rem;
        font-size: 0.875rem;
        color: var(--color-primary-d);
        border: 1px solid var(--color-primary-d);
        border-radius: 5px;
        cursor: pointer;
      }

      .active {
        background-color: var(--color-primary-d);
        color: var(--color-offwhite);
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes scaleOut {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }
}
