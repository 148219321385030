.takeProfilePhoto {
  background-color: hsl(0, 100%, 100%, 0.95);
  position: fixed;
  inset: 0;
  z-index: 100;
  animation: delay 0.3s ease-out forwards;
  transform-origin: center;
  display: grid;
  place-items: center;

  .dialog {
    width: 600px;

    background-color: var(--color-secondary);
    // border-radius: 10px;

    animation: delay-dialog 0.3s ease-out;
    overflow: hidden;

    box-shadow: 0px 0px 14px 0px #00000040;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: var(--color-primary-d);
      padding: 0.75rem 0.875rem;

      h4 {
        color: var(--color-offwhite);
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.35;
      }

      .btn-close {
        // width: 1.25rem;
        // height: 1.25rem;

        padding: 4px;

        // svg {
        //   width: 100%;
        //   height: 100%;
        // }

        &:focus-visible {
          outline: 0;
          border-radius: 2px;
          box-shadow: 0 0 0 2px var(--color-focused);
        }
      }
    }

    .photo-container {
      background-color: var(--color-quaternary);
      height: 400px;

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media screen and (max-width: 1025px) {
        height: 350px;
      }
    }

    .extra-space {
      height: 90px;
      background-color: var(--color-popup);

      position: relative;

      @media screen and (max-width: 1025px) {
        height: 70px;
      }

      .camera-icon {
        background-color: var(--color-primary-d);
        width: fit-content;
        margin-inline: auto;
        padding: 0.75rem;
        border-radius: 50%;

        cursor: pointer;

        transform: translateY(-50%);
        svg {
          width: 25px;
          height: 25px;
        }

        &:focus-visible {
          outline: 0;
          box-shadow: 0 0 0 2px var(--color-focused);
        }
      }
    }
  }

  @keyframes delay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes delay-dialog {
    0% {
      opacity: 0;
      scale: 0;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
