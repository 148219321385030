.messageReaction {
  width: fit-content;
  background-color: var(--color-popup);
  border-radius: 500px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  animation: delay 0.2s cubic-bezier(1, 0, 0, 1) forwards;
  transform-origin: left;
  z-index: 100;

  // ON closing
  // animation: delay 0.2s ease-out forwards;
  // transform-origin: center bottom;

  .messageReaction-wrapper {
    width: 100%;
    height: 100%;
    padding: 7px;

    display: flex;
    align-items: center;
    gap: 2px;

    .emoji-reaction {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 2px;

      .emoji {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 2px;
        overflow: hidden;
        font-size: 20px;

        display: grid;
        place-items: center;

        cursor: pointer;

        &:focus,
        &:active {
          background-color: var(--color-primary-d);
        }
      }

      .emoji.active {
        background-color: var(--color-primary-d);
      }
    }

    .add-emoji {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 2px;
      padding: 2px;
      overflow: hidden;
      display: grid;
      place-items: center;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@keyframes delay {
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
