.tepars-profile-media {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  min-height: 50vh;

  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // overflow-y: auto;
  // display: none;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .default-media-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .default-media {
      .default-media-image {
        width: 100px;
        height: 100px;
        margin-inline: auto;
        border-radius: 50%;
        border: 2px solid var(--color-quaternary-l);
        overflow: hidden;

        display: grid;
        place-items: center;

        margin-block-end: 10px;

        img {
          width: 60%;
        }
      }

      .default-text {
        color: var(--color-quaternary-l);
        font-size: 1.125rem;
      }
    }
  }

  .media-container {
    display: flex;
    gap: 3px;
    flex-wrap: wrap;

    // min-height: 50vh;

    .media {
      // width: 125px;
      // height: 125px;

      width: 32.5%;
      padding-bottom: 32.5%;
      height: 0;
      box-shadow: 0px 2px 4px rgb(0, 0, 0, 0.5);
      position: relative;
      overflow: hidden;
      cursor: pointer;

      @media screen and (max-width: 769px) {
        width: 49%;
        padding-bottom: 49%;
      }

      @media screen and (max-width: 501px) {
        width: 48%;
        padding-bottom: 48%;
      }

      img,
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;

        inset: 0;
      }

      .blank-image {
        position: absolute;
        inset: 0;
        background-color: var(--color-description);
      }

      &:focus-visible {
        outline: 0;
        border-radius: 2px;
        box-shadow: 0 0 0 2px var(--color-focused);
      }

      .media-type {
        position: absolute;
        top: 0px;
        right: 0px;

        // background: radial-gradient(50% 50% at 50% 50%, #000000 0%, rgba(0, 0, 0, 0) 100%);
        padding: 6px;
        // border: 1px solid white;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0) 100%
        );

        z-index: 5;

        // svg {
        //   filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
        // }
      }

      //   .story-viewed-number {
      //     width: 100%;
      //     height: 40px;

      //     padding: 3px 10px;
      //     padding-inline-end: 5px;

      //     opacity: 0.4;
      //     background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

      //     position: absolute;
      //     bottom: 0;
      //     right: 0;

      //     display: flex;
      //     align-items: flex-end;
      //     justify-content: flex-end;

      //     .viewIcon {
      //       display: inline-flex;
      //       flex-direction: row-reverse;
      //       align-items: center;
      //       gap: 3px;
      //       img {
      //         width: 0.75rem;
      //       }
      //       .viewed-number {
      //         color: var(--color-secondary);
      //         font-size: 0.75rem;
      //       }
      //     }
      //   }

      .story-viewed-number {
        &::after {
          content: "";
          width: 100%;
          height: 30px;
          display: inline-block;
          background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
          opacity: 0.4;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        .viewIcon {
          display: inline-flex;
          flex-direction: row-reverse;
          align-items: center;
          gap: 3px;

          position: absolute;
          right: 3px;
          bottom: 0;

          z-index: 10;
          img {
            width: 0.75rem;
          }
          .viewed-number {
            color: var(--color-secondary);
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

.media-post {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .default-media-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // height: 100%;
    // min-height: 50vh;
    // height: 100%;
    height: 50vh;

    .default-media {
      .default-media-image {
        width: 100px;
        height: 100px;
        margin-inline: auto;
        border-radius: 50%;
        border: 2px solid var(--color-quaternary-l);
        overflow: hidden;

        display: grid;
        place-items: center;

        margin-block-end: 10px;

        svg,
        img {
          width: 60%;
          height: 60%;
        }
      }

      .default-text {
        color: var(--color-quaternary-l);
        font-size: 1.125rem;
      }
    }
  }

  .settings-media {
    height: 100%;
  }
}
