.myGroups {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 11;

  &.open {
    animation: slideInFromRight 250ms ease-out forwards;
  }

  &.close {
    animation: slideOutToRight 250ms ease-out forwards;
  }

  .myGroups-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;

      min-height: 54px;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }
    }

    .search-bar {
      margin: 0.875rem;

      .search-bar-wrapper {
        // padding-inline: 6px;
        border-radius: 0.5rem;
        background-color: rgba(118, 118, 128, 0.12);
        height: 30px;
        // padding: 10px 6px;
        padding-inline: 6px;

        display: flex;
        align-items: center;
        gap: 10px;
        input {
          // color: rgba(60, 60, 67, 0.6);
          color: var(--color-quaternary-l);
          flex: 1;
          appearance: none;
          border: none;
          outline: none;
          background: transparent;
          font-size: 0.875rem;
          height: 100%;

          @media screen and (max-width: 1025px) {
            font-size: 0.8rem;
          }

          &::placeholder {
            color: var(--color-placeholder);
          }
        }
      }
    }

    .all-groups {
      height: 100%;
      overflow-y: auto;

      padding-block: 0 0.9375rem;

      .no-data {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .user-groups,
      .other-groups {
        .text {
          display: inline-block;
          color: var(--color-description);
          font-size: 0.75rem;
          padding-inline-start: 0.9375rem;
          margin-block: 13px;
        }

        .user-groups-list {
          border-block: 0.5px solid var(--color-border);

          display: flex;
          flex-direction: column;

          .user-groups-listItem {
            display: flex;
            padding-inline-start: 0.9375rem;
            align-items: center;

            height: 4.375rem;

            cursor: pointer;

            &:last-of-type {
              .profile-info {
                border-block-end: none;
              }
            }

            &:focus-visible {
              outline: 0;
              background: var(--color-selected-bg);
            }

            .profile-image {
              width: 3.125rem;
              height: 3.125rem;
              border-radius: 50%;
              margin-inline-end: 0.75rem;
              overflow: hidden;

              position: relative;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .profile-info {
              display: flex;
              align-items: center;
              justify-content: space-between;

              flex: 1 1;

              /* padding-block: 0.8125rem; */

              height: 100%;
              padding-inline-end: 0.9375rem;
              border-block-end: 0.5px solid var(--color-border);

              .profile-info-name {
                display: flex;
                flex-direction: column;

                .name {
                  font-size: 0.9375rem;
                  color: var(--color-quaternary-l);
                  font-weight: 400;

                  overflow: hidden;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }

                .profile-info-message {
                  color: var(--color-description);
                  font-size: 0.8rem;

                  overflow: hidden;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }
              }

              .profile-details {
                align-self: flex-start;
                margin-block-start: 2px;

                .profile-details-date {
                  font-size: 8.25px;
                  color: var(--color-description);
                }
              }
            }
          }
        }
      }
    }

    .no-group-waraper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .no-group {
        text-align: center;

        .no-group-img {
          width: 100px;
          height: 100px;
          border: 2px solid var(--color-quaternary-l);
          border-radius: 50%;
          display: grid;
          place-items: center;
          margin-block-end: 0.625rem;
        }

        .text {
          color: var(--color-quaternary-l);
          font-size: 0.9375rem;
        }
      }
    }

    .user-groups {
      .text {
        margin-block-end: 3px;
      }
    }
  }

  .no-data {
    margin: auto;
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
}
