.socialLinkPreviewChat-send,
.socialLinkPreviewChat-recieved {
  border-radius: 8px;
  width: fit-content;
  width: 240px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  align-self: flex-end;

  .wrapper {
    background-color: #eaf1f2;
    display: flex;
    flex-direction: column;

    .socialMedia-image {
      padding: 4px;
      width: 100%;
      height: 240px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      padding: 4px 8px;
      background-color: #e9f7fa;

      .social-heading {
        font-size: 0.75rem;
        color: #182b2f;
      }

      .social-name {
        font-size: 0.7rem;
        color: #182b2f;
      }

      .description {
        font-size: 0.625rem;
        color: #182b2f4a;
        margin-block-start: 0.25rem;
      }
    }
  }
}

.replyText {
  padding: 4px 8px;
  background-color: var(--color-quinary);

  .reply-message {
    display: block;
    font-size: 0.875rem;
    color: var(--color-primary-d);
    margin-block-end: 10px;

    .space {
      display: inline-block;
      width: 60px;
      height: 0;
      opacity: 0;
      visibility: hidden;
      margin-inline-start: 3px;
    }
  }

  .message-status {
    position: absolute;
    bottom: 2px;
    right: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.625rem;
    color: var(--color-tertiary-v-l);

    .important {
      width: 10px;
    }

    .reply-status {
      width: 14px;
    }
  }
}

.socialLinkPreviewChat-recieved {
  align-self: flex-start;
  .wrapper {
    background-color: var(--color-quinary);
  }

  .replyText {
    background-color: var(--color-secondary);
  }
}
