.socialLinkPreview {
  .wrapper {
    background-color: #f4f6f6;
    display: flex;
    align-items: center;
    border-radius: 5px;

    .socialMedia-preview {
      width: 4rem;
      height: 4rem;
      background-color: #f2f0f0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline: 10px 1rem;
      flex: 1;

      .info-wrapper {
        .social-name {
          font-size: 0.875rem;
          color: var(--color-quaternary-l);
        }

        .description {
          font-size: 0.625rem;
          color: var(--color-description);
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: pre-wrap;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .cancel {
        width: 1.125rem;
        height: 1.125rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
