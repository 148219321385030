.menu {
  width: 230px;
  position: absolute;
  height: auto;
  border-radius: 10px;
  background-color: var(--color-popup);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 50;

  overflow: hidden;

  animation: menuOpen 250ms linear;
  transform-origin: top right;

  .hidden {
    display: none;
  }

  .menu-list {
    li {
      font-size: 0.875rem;
      color: var(--color-quaternary-l);
      border-block-end: 1px solid var(--color-border);
      padding-block: 10px;
      padding-inline-start: 0.9375rem;

      cursor: pointer;
    }

    li:hover {
      background-color: rgba(23, 144, 163, 0.1);
    }

    .block {
      color: var(--color-error);
    }
  }
}

@keyframes menuOpen {
  0% {
    scale: 0;
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}
