.history {
  display: flex;
  flex-direction: column;
  background-color: var(--color-secondary);

  z-index: 100;

  height: 100%;

  .header {
    background-color: var(--color-primary-d);
    width: 100%;
    position: relative;

    .title {
      margin-block: 1.125rem 1.625rem;
      padding-inline: 0.9375rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .logo {
          width: 1.45rem;
          height: 1.45rem;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .mainTitle {
          font-size: 1.2rem;
          font-weight: 700;
          color: var(--color-offwhite);
          letter-spacing: 0.7px;
        }
      }

      .icons {
        display: flex;
        align-items: center;
        gap: 1rem;

        .btn-search,
        .btn-notification {
          padding: 4px;
          position: relative;

          .tild {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #f5f5f5;
            position: absolute;
            bottom: 1.325rem;
            left: 1.325rem;
          }
        }
      }
    }

    .search-bar {
      width: 100%;

      // position: absolute;
      // bottom: 0;
      // left: 0;
      // z-index: 10;

      transform-origin: right;
      transition: scale 250ms ease, opacity 250ms ease;

      .search-bar-wrapper {
        display: flex;
        align-items: center;
        gap: 7px;

        margin-block: 0.5rem;
        margin-inline: auto;
        padding-inline: 6px;
        border-radius: 0.5rem;
        height: 2rem;
        width: calc(100% - 1.875rem);
        background-color: white;

        box-shadow: 0px 4px 11.5px 0px #00000040;

        .search_white {
          flex-shrink: 0;
        }

        input {
          color: var(--color-quaternary);
          background: transparent;
          font-size: 0.875rem;

          flex: 1;

          appearance: none;
          border: none;
          outline: none;

          &::placeholder {
            color: var(--color-placeholder);
          }
        }
      }
    }

    .search-bar.open {
      animation: openRight 350ms ease forwards;
    }

    .search-bar.close {
      animation: closeRight 350ms ease forwards;
    }
  }

  .chat-channel {
    display: flex;
    align-items: center;
    background-color: rgb(var(--color-primary-d));

    cursor: pointer;
    position: relative;

    .chat,
    .channel {
      flex-basis: 50%;
      position: relative;

      text-align: center;
      color: #99d7de;

      transition: color 250ms ease;

      .tild {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #f2f2f2;
        position: absolute;
        top: 1rem;
        right: 1.875rem;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0.625px;
        padding-block: 8px;
      }
    }

    .slider {
      position: absolute;
      bottom: 0;
      height: 3px;
      background-color: rgb(var(--color-white));

      box-shadow: 0px 0px 5px 0px rgb(0, 0, 0, 0.15);
      transition: all 250ms ease;
    }

    .menu-selected {
      color: #f5f5f5;
    }
  }

  .profiles {
    display: flex;
    flex-direction: column;

    height: 100%;
    overflow-y: auto;
    flex: 1;

    position: relative;

    .chats,
    .groups,
    .messagess,
    .contacts {
      .search-title {
        font-size: 0.9375rem;
        color: var(--color-quaternary-l);
        margin-block-start: 10px;
        padding-inline-start: 20px;
      }

      .profile-info:first-child {
        border-block-start: 0.5px solid var(--color-arrow);
      }
    }

    .profile {
      display: flex;
      padding-inline-start: 0.9375rem;
      align-items: center;
      height: 4.375rem;
      cursor: pointer;
      transition: 0.2s ease-out;

      &:hover {
        background: var(--color-selected-bg);
      }

      .profile-image {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        margin-inline-end: 0.75rem;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .profile-info {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline-end: 0.9375rem;
        border-block-end: 0.5px solid var(--color-border);

        height: 100%;

        &:first-child {
          border-block-start: 0.5px solid var(--color-arrow);
        }

        .profile-info-name {
          p {
            font-size: 0.9375rem;
            color: var(--color-quaternary-l);
          }
        }

        .status {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          img {
            width: 14px;
          }

          .profile-info-message {
            color: var(--color-description);
            font-size: 0.7rem;
            font-weight: 400;
            // word-wrap: break-word;
            overflow-wrap: break-word;
          }
        }
      }

      .profile-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 3px;

        .profile-details-date {
          color: var(--color-tertiary-l);
          font-size: 0.55rem;
        }

        .cta {
          display: flex;
          align-items: center;
        }

        .cta-image {
          width: 15px;
          height: 15px;
          margin-inline-end: 5px;
        }

        .notifications {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: var(--color-primary-d);
          padding: 0.3rem;
          font-size: 0.625rem;
          color: var(--color-secondary);

          display: flex;
          justify-content: center;
          align-items: center;

          margin-left: auto;
        }
      }
    }

    .newChat {
      bottom: 10%;
      left: 75%;
      width: 50px;
      height: 50px;
      background-color: var(--color-primary-d);
      border-radius: 50%;

      display: grid;
      place-items: center;

      position: sticky;
      top: 75%;
      left: 75%;

      z-index: 5;
      flex-shrink: 0;
      overflow: hidden;
      cursor: pointer;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: cover;
      }
    }
  }

  .empty {
    height: 100%;
    display: grid;
    place-items: center;
    .empty-message {
      p {
        padding: 40px 1rem 15px;
        text-align: center;
        color: var(--color-description);
        font-size: 12px;
      }
    }
  }

  .archived-menu {
    display: flex;
    align-items: center;

    height: 2.625rem;

    padding-inline: 0.9375rem;

    .archived-image {
      width: 3.125rem;
      height: 2.625rem;
      margin-inline-end: 0.75rem;

      display: grid;
      place-items: center;

      overflow: hidden;
      svg {
        width: 18px;
      }
    }

    .title {
      font-size: 0.8rem;
      font-weight: 500;
      color: var(--color-quaternary-l);
      border-block-end: 0.5px solid var(--color-border);

      flex: 1;
      height: 100%;

      display: flex;
      align-items: center;
    }
  }

  @keyframes openRight {
    from {
      transform: scaleX(0);
      opacity: 0;
    }
    to {
      transform: scaleX(1);
      opacity: 1;
    }
  }

  @keyframes closeRight {
    from {
      transform: scaleX(1);
      opacity: 1;
    }
    to {
      transform: scaleX(0);
      opacity: 0;
    }
  }
}
