.lastSeen {
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: 12;

  &.open {
    animation: slideInFromRight 250ms ease-out forwards;
  }

  &.close {
    animation: slideOutToRight 250ms ease-out forwards;
  }

  .lastSeen-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-secondary);

    height: 100%;

    .header {
      background-color: var(--color-primary-d);
      // padding: 0.875rem 0.9375rem;
      padding: 0.875rem 0.9375rem 0.875rem 0.625rem;

      min-height: 54px;

      .back {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .btn-back {
          // width: 1rem;
          // height: 1rem;
          padding: 4px;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .text {
          color: var(--color-offwhite);
          font-size: 1rem;
          font-weight: 500;
          // line-height: 1.35;
        }
      }
    }

    .lastSeen-ctas-wrapper {
      height: 100%;
      overflow-y: auto;

      .lastSeen-cta,
      .online-cta {
        .lastSeen-heading,
        .online-heading {
          padding: 1rem 0.9375rem 0.625rem;
          .heading {
            font-size: 0.875rem;
            color: var(--color-primary-d);
          }
        }

        .lastSeen-list,
        .online-list {
          display: flex;
          flex-direction: column;
          // gap: 1.5rem;

          // padding-block-end: 1.5rem;
          // padding-inline: 0.9375rem;

          .lastSeen-listItem,
          .online-listItem {
            display: flex;
            align-items: center;
            gap: 0.75rem;

            padding: 1rem 0.9375rem;
            border-block-end: 0.5px solid var(--color-border);

            .group-check {
              margin-block-start: -2px;
              input[type="radio"] {
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;
              }

              .checkmark {
                display: inline-flex;
                align-items: center;
                justify-content: center;

                position: relative;

                min-width: 18px;
                width: 18px;
                height: 18px;
                vertical-align: middle;

                &:focus-visible {
                  outline: 0;
                  border-radius: 2px;
                  box-shadow: 0 0 0 2px var(--color-focused);
                }

                .radio-cmn {
                  position: absolute;
                  top: 0;
                  left: 0;

                  width: 100%;
                  height: 100%;
                  border-radius: 50%;

                  line-height: 0;
                  white-space: nowrap;

                  cursor: pointer;
                }

                .offRadio {
                  border: 1.5px solid var(--color-description);
                  background-color: transparent;

                  transition: border 250ms ease;
                }

                .onRadio {
                  will-change: transform;
                  background-color: var(--color-primary-d);

                  scale: 0;
                  transition: scale 250ms ease;
                }
              }

              input[type="radio"]:checked + .checkmark .onRadio,
              input[type="radio"]:focus + .checkmark .onRadio {
                scale: 0.6;
              }
              input[type="radio"]:checked + .checkmark .offRadio,
              input[type="radio"]:focus + .checkmark .offRadio {
                border-color: var(--color-primary-d);
              }
            }

            .description {
              font-size: 1rem;
              color: var(--color-quaternary-l);

              cursor: pointer;
            }
          }
        }
      }

      .lastSeen-note,
      .disappearingMessages-note {
        padding: 1.625rem 0.9375rem 0.875rem;

        .note {
          color: var(--color-description);
          font-size: 0.8rem;
          line-height: 1.25;

          .highlighted {
            color: var(--color-primary-d);
            padding-inline-start: 3px;
          }
        }
      }

      .learnMore {
        font-size: 0.8rem;
        padding: 0.625rem 0.9375rem;
        a {
          &:active,
          &:visited,
          &:link {
            color: var(--color-primary-d);
          }

          &:focus-visible {
            outline: 2px solid var(--color-primary-d);
            outline-offset: 2px;
          }
        }
      }
    }
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
}
